import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxAuthUserPermission } from './adx-auth-user-permission.model';

/**
 * The User object that is part of JWT
 */
export class AuthenticatedUser extends AdxResource {

  // NOTE: The field names in server and client are inconsistent. Like firstname & firstName. Hence duplicated fields here.
  // TODO: Need to update server and then make changes to client here

  username: string | null = null;
  password: string | null = null;
  firstname: string | null = null;
  firstName: string | null = null;
  middlename: string | null = null;
  middleName: string | null = null;
  lastname: string | null = null;
  lastName: string | null = null;
  lastLogin: string | null = null;
  userPermissions: AdxAuthUserPermission | null = null;


  constructor(id: number | null) {
    super(id);
  }

  /**
   * Utility to build AuthenticatedUser object from json input
   *
   * @param json
   * @returns AuthenticatedUser object
   */
  static buildObject(json: any): AuthenticatedUser {
    console.log('AuthenticatedUser buildObject');
      console.log(json);
    let user: AuthenticatedUser;
    if (json) {
      user = new AuthenticatedUser(json.id);
      user.username = json.username;
      user.password = json.password;
      user.firstName = json.firstName;
      user.middleName = json.middleName;
      user.lastName = json.lastName;
      user.lastLogin = json.lastLogin;
      console.log('AuthenticatedUser buildObject userPermissions');
      console.log(json.userPermissions);
      user.userPermissions = AdxAuthUserPermission.buildObject(json.userPermissions);
    } else {
      user = new AuthenticatedUser(null);
    }
    return user;
  }

}
