import {ActivatedRoute} from '@angular/router';
import {AdxResource} from '../../core/service/resource/adx-resource.model';

/**
 * Utility class to contain common code across the project
 */
export class CommonUtility {

  constructor() {}

  static getId(strId: string, activatedRoute: ActivatedRoute): number | null {
    const idString: string | null | undefined = activatedRoute.snapshot.paramMap.get(strId);

    if (idString) {
      if (isNaN(+idString)) {
        return null;
      }
    }
    else {
      return null;
    }
    return +idString;
  }

  /**
   * Returns generated random string
   *
   * @param length: length of string to be returned
   */
  static generateRandomString(length: number): string {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /*
   * Sort the input list by ID in descending
   */
  static sortByIdDescending(inputList: AdxResource[]): void {
    if (inputList) {
      inputList.sort(
        (a, b) => {
          let sortOrder1 = 0;
          if (a && a.id) {
            sortOrder1 = a.id;
          }
          let sortOrder2 = 0;
          if (b && b.id) {
            sortOrder2 = b.id;
          }
          if (sortOrder1 > sortOrder2) {
            return -1;
          }
          if (sortOrder1 < sortOrder2) {
            return 1;
          }
          return 0;
        });
    }
  }
}
