import { Component, OnInit } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxVPub} from '../vPub/model/adx-vpub.model';
import {AdxApplication} from '../application/model/adx-application.model';
import { Account } from '../account/model/account.model';
import {CommonUtility} from '../common/utils/common-utils';
import {AdxDisplayLevel} from '../common/model/data-transfer/adx-display-level';

@Component({
  selector: 'app-adx-adx-module',
  templateUrl: './adx-module.component.html',
  styleUrls: ['./adx-module.component.scss']
})
export class AdxModuleComponent implements OnInit {

  orgId: number | null = null;
  appln: AdxApplication | null = null;
  applnId: number | null = null;
  vPubId: number | null = null;
  accountId: number | null = null;
  vpubTitle: string | null = null;
  selectedAccount: Account | null = null;
  readonly vPubDisplayLevel = AdxDisplayLevel.VPUB_LEVEL;

  constructor(private logger: NGXLogger,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute);
    this.appln = this.activatedRoute.snapshot.data.appln;
    const vPub: AdxVPub = this.activatedRoute.snapshot.data.vpub;
    this.vPubId = vPub.id;
    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    if (this.selectedAccount && this.selectedAccount.id) {
      this.accountId = this.selectedAccount.id;
    }
    if(this.appln !== undefined && this.appln !== null) {
      this.applnId = this.appln.id;
    }
    this.logger.debug(`In AdxModuleComponent appln: ${this.appln?.title}`);
    this.logger.debug(vPub);
    this.logger.debug(this.selectedAccount);
    this.vpubTitle = vPub.title;
  }

}
