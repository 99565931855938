import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxImageUploaderModule } from './adx-image-uploader/adx-image-uploader.module';
import {AdxImageLibraryModule} from './adx-image-library/adx-image-library.module';
import { AdxConfirmDialogComponent } from './adx-confirm-dialog/adx-confirm-dialog.component';
import {MaterialModule} from '../../common/material/material.module';
import { AdxAlertDialogComponent } from './adx-alert-dialog/adx-alert-dialog.component';
import { AdxSelectDialogComponent } from './adx-select-dialog/adx-select-dialog.component';
import { AdxInsertContentDialogComponent } from './adx-insert-content-dialog/adx-insert-content-dialog.component';
import {AdxControlsModule} from '../controls';
import { AdxInsertTelephoneDialogComponent } from './adx-insert-telephone-dialog/adx-insert-telephone-dialog.component';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import { AdxFileUploadDropzoneDialogComponent } from './adx-file-upload-dropzone-dialog/adx-file-upload-dropzone-dialog.component';
import {CommonDirectiveModule} from '../../common/directive/common-directive.module';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import { AdxContentReportCreateDialogComponent } from './adx-content-report-create-dialog/adx-content-report-create-dialog.component';
import { AdxSendNotificationDialogComponent } from './adx-send-notification-dialog/adx-send-notification-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AdxImageLibraryModule,
    AdxImageUploaderModule,
    MaterialModule,
    AdxControlsModule,
    FlexModule,
    ReactiveFormsModule,
    CommonDirectiveModule,
    NgxMatIntlTelInputComponent
  ],
  exports: [
    AdxImageLibraryModule,
    AdxImageUploaderModule
  ],
  declarations: [
    AdxConfirmDialogComponent,
    AdxAlertDialogComponent,
    AdxSelectDialogComponent,
    AdxInsertContentDialogComponent,
    AdxInsertTelephoneDialogComponent,
    AdxFileUploadDropzoneDialogComponent,
    AdxContentReportCreateDialogComponent,
    AdxSendNotificationDialogComponent
  ]
})
export class AdxPopupsModule { }
