import {AdxResource} from '../../core/service/resource/adx-resource.model';

/**
 * model class used in form submission model
 */
export class AdxFormSubmissionAction extends AdxResource {
  emailAddresses: string | null = null;
  type: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxFormSubmissionAction {
    const toRet: AdxFormSubmissionAction = new AdxFormSubmissionAction(null);
    if (json.id !== undefined && json.id !== null) {
      toRet.id = json.id;
    }

    if (json.emailAddresses !== null && json.emailAddresses !== undefined) {
      toRet.emailAddresses = json.emailAddresses;
    }

    if (json.type !== null && json.type !== undefined) {
      toRet.type = json.type;
    }

    return toRet;
  }
}
