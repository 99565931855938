import {
  Component,
  Input, OnDestroy,
  OnInit
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {AdxStyle} from '../../common/model/adx-style.model';
import {AdxApplication} from '../../application/model/adx-application.model';
import {AdxModule} from '../../adx-module/model/adx-module.model';
import {AdxSimulatorService} from '../service/adx-simulator.service';
import {AdxModuleType} from '../../adx-module/model/adx-module-type';
import {AdxArs} from '../../adx-ars/model/adx-ars-model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-adx-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit, OnDestroy {

  @Input() app: AdxApplication | null = null;
  style: AdxStyle | null = null;
  title = '';
  showSimulator = true;
  moduleList: AdxModule[] = [];
  selectedModule: AdxModule;
  selectedAtom: AdxArs | null = null;
  atomList: AdxArs[] = [];
  changeSubscription: Subscription;

  constructor(private logger: NGXLogger, private simulatorService: AdxSimulatorService) {
    this.moduleList = this.simulatorService.moduleList;
    this.selectedModule = this.simulatorService.selectedModule;
    this.atomList = this.simulatorService.atomList;
    // incase the data is updated, this callback is triggered. We need to refetch data
    this.changeSubscription = this.simulatorService.contentUpdated.subscribe((data: boolean) => {
      this.logger.debug('Refresh received');
      this.setTitle();
      this.moduleList = this.simulatorService.moduleList;
      this.selectedModule = this.simulatorService.selectedModule;
      this.atomList = this.simulatorService.atomList;
      this.selectedAtom = this.simulatorService.selectedAtom;
    });
  }

  ngOnInit(): void {
    this.logger.debug(`In simulator component app:${this.app}`);
    if (this.app) {
      this.style = this.app.style;
      this.setTitle();
    }
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

  toggle(): void {
    this.showSimulator = !this.showSimulator;
  }

  getIconUrl(i: number): string {
    if (this.moduleList.length >= i && this.moduleList[i] && this.moduleList[i].iconImage
      && this.moduleList[i].iconImage?.preSignedUrl && this.moduleList[i].iconImage?.preSignedUrl !== null) {
      return this.moduleList[i].iconImage?.preSignedUrl as string;
    }
    else {
      return '';
    }
  }

  getModuleTitle(i: any): string {
    if (this.moduleList.length >= i && this.moduleList[i] && this.moduleList[i].title) {
      return this.moduleList[i].title as string;
    }
    else {
      return '';
    }
  }

  getSelectedModuleTitle(): string {
    return this.title;
  }

  getModuleType(strType: string): any {
    if (strType && AdxModuleType.GROUP.toString().toLowerCase() === strType) {
      return AdxModuleType.GROUP;
    }
    else if (strType && AdxModuleType.ARS.toString().toLowerCase() === strType) {
      return AdxModuleType.ARS;
    }
    else if (strType && AdxModuleType.HTML.toString().toLowerCase() === strType) {
      return AdxModuleType.HTML;
    }
    else {
      return AdxModuleType.WEB;
    }
  }

  getChildModuleList(): AdxModule[] {
    if (this.selectedModule) {
      return this.selectedModule.children;
    }
    return [];
  }

  getAtomList(): AdxArs[] {
    return this.atomList;
  }

  private setTitle(): void {
    if (this.selectedModule && this.selectedModule.html) {
      this.title = this.selectedModule.html.title as string;
    }
    if (this.selectedModule && this.selectedModule.ars && this.selectedAtom) {
      this.title = this.selectedAtom.title as string;
    }
    else if (this.selectedModule && this.selectedModule.title) {
      this.title = this.selectedModule.title as string;
    }
  }
}

