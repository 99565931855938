import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AdxCustomError} from '../error-handler/adx-custom-error-model';
import {CustomErrorNotifierService} from '../error-handler/custom-error-notifier.service';
import {CustomNotifierService} from '../notifier/custom-notifier.service';
import {CustomMessageType} from '../notifier/custom-message-type';

/**
 * Intercepts the error , extracts message and publishes the error message so that it can be displayed to user.
 * If server error, the error object received is of form error.error, which will have a code and detail
 */
@Injectable()
export class AdxHttpErrorInterceptor implements HttpInterceptor {
  constructor(private logger: NGXLogger, private router: Router,
              private readonly messageNotifier: CustomNotifierService,
              private errorNotifier: CustomErrorNotifierService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let errorObj;
        this.logger.debug(error);
        if (error.error instanceof ErrorEvent) {
          this.logger.debug(`error instance of ErrorEvent. Msg: ${error.error.message}`);
          errorObj = new AdxCustomError(error.status);
          // client-side error
          errorObj.errorMessage = error.error.message;
          this.errorNotifier.notifyError(errorObj.errorMessage);
        } else {
          this.logger.debug(`NOT an ErrorEvent. Status: ${error.status}`);
          errorObj = new AdxCustomError(error.status);

          switch (error.status) {
            case 401: // redirect to login
              errorObj.errorMessage = `Invalid Credentials`;
              this.router.navigateByUrl('/auth').then(r => {});
              break;
            case 403: // redirect to forbidden
              errorObj.errorMessage = `You do not have access to this resource`;
              this.router.navigateByUrl('/unauthorized').then(r => {});
              break;
            case 0: // client side error
              if (error.error instanceof ProgressEvent) {
                this.logger.debug(`error instance of ProgressEvent.`);
                // network related issue
                errorObj.errorMessage = 'Unable to connect to network';
              }
              this.logger.debug(`error status 0 and NOT a ProgressEvent.`);
              break;
            default: {
              // server-side error
              if (error.error) {
                this.logger.debug(`Serverside error. Msg: ${error.error}`);
                if (error.error.detail !== undefined && error.error.detail !== null && error.error.detail !== '') {
                  errorMessage = `${error.error.detail}`;
                }
                else if (error.error.message !== undefined && error.error.message !== null
                  && error.error.message !== '') {
                  errorMessage = `${error.error.message}`;
                }
                else if (error.error.error !== undefined && error.error.error !== null
                  && error.error.error !== '') {
                  errorMessage = `${error.error.status} : ${error.error.error}`;
                }
                errorObj.errorMessage = errorMessage;
              }
              else {
                this.logger.debug(`Serverside Else. Msg: ${error.message}`);
                errorObj.errorMessage = error.message;
              }
              break;
            }
          }
          this.logger.error(errorMessage);
        }
        this.errorNotifier.notifyError(errorObj.errorMessage);
        this.messageNotifier.notify(CustomMessageType.ERROR, 'Oops something went wrong!!!');
        return throwError(errorObj);
      })
    );
  }
}
