import { AdxRestService } from '../../core/service/resource/adx-rest-service.service';
import { Injectable } from '@angular/core';
import { Organization } from '../model/organization.model';
import { AdxQueryParams } from '../../core/service/resource/adx-query-params.model';
import { Observable, throwError } from 'rxjs';
import { OrganizationSerializer } from '../model/organization-serializer';
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';
import { AdxUserUtility } from 'src/app/adx-user-manage/adx-user-utils/adx-user-utility';

/**
 * Service to interact with backed for Organization REST API
 */
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private serializer: OrganizationSerializer = new OrganizationSerializer();

  constructor (private logger: NGXLogger, private restService: AdxRestService<Organization, Organization>,
    private readonly storageService: LocalStorageService) { }

  /**
   * Utility method to get the ids of organization accessible by user
   * @returns boolean. true if current user can edit org with input id
   */
  public getUserAccessibleOrgIds(user: AuthenticatedUser): number[] {
    let userAccessibleOrgIds: number[] = [];
    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(user.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleOrganizations !== undefined && accessibleEntities.accessibleOrganizations !== null) {
        userAccessibleOrgIds = accessibleEntities.accessibleOrganizations;
      }
    }
    return userAccessibleOrgIds;
  }

  /**
   * Utility method to get the list of org ids that the user has access to
   * user with org admin or org edit role is checked here.
   * If user has system admin or instance admin role, then he will be able to edit org
   *
   * @returns array of org ids that current user has access to
   */
  public canUserAddOrg(user: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (user === undefined || user === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(user)) {
      this.logger.debug('sys/inst admin user, allowed to add');
      return true;
    }
    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const orgAddAccess = user.userPermissions.ORGANIZATION_ADD;
      // check for org add permission
      if (orgAddAccess !== undefined && orgAddAccess !== null) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has orgadd permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to get the list of org ids that the user has access to
   * user with org admin or org edit role is checked here.
   * If user has system admin or instance admin role, then he will be able to edit org
   *
   * @returns array of org ids that current user has access to
   */
  public canUserEditOrg(orgId: number, user: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (user === undefined || user === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(user)) {
      return true;
    }
    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', user);
      if (isOrgAdmin) {
        return true;
      }
      //now check for org edit
      const hasOrgAddRole = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_EDIT', user);
      if (hasOrgAddRole) {
        return true;
      }
    }
    return false;
  }

  /**
   * Determins whether user has organizationadmin access for given organization id
   *
   * @param orgId: organization id
   * @param user: AuthenticatedUser
   * @returns: boolean true if given user is orgadmin for given org id
   */
  public hasUserOrgAdminAccess(orgId: number, user: AuthenticatedUser): boolean {

    //if user not present, send false
    if (user === undefined || user === null) {
      return false;
    }

    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const orgAdminAccess = user.userPermissions.ORGANIZATION_ADMIN;
      //first check for org admin
      if (orgAdminAccess !== undefined && orgAdminAccess !== null) {
        const selId = orgAdminAccess.find(id => id === orgId);
        if (selId) {
          //means input orgid is present in org-admin permission list for user. return true
          this.logger.debug('user has admin permission');
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Determins whether user has organizationreadonly access for given organization id
   *
   * @param orgId: organization id
   * @param user: AuthenticatedUser
   * @returns: boolean true if given user is orgReadonly for given org id
   */
  public hasUserOrgReadOnlyAccess(orgId: number, user: AuthenticatedUser): boolean {

    //if user not present, send false
    if (user === undefined || user === null) {
      return false;
    }

    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const orgReadonlyAccess = user.userPermissions.ORGANIZATION_READ_ONLY;
      // check for org edit
      if (orgReadonlyAccess !== undefined && orgReadonlyAccess !== null) {
        const selId = orgReadonlyAccess.find(id => id === orgId);
        if (selId) {
          //means input orgid is present in org-edit permission list for user. return true
          this.logger.debug('user has edit permission');
          return true;
        }
      }
    }
    return false;
  }

  /*
   * This API is used to fetch the list of organizations from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getOrganizations(): Observable<Organization[]> {

    return this.restService.list('orgs', new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        }));
  }

  /*
   * This API is used to fetch the organization from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getOrganization(id: number): Observable<Organization> {

    return this.restService.read('orgs', id, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        }));
  }


  /*
   * This API is used to add organization to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addOrganization(org: Organization): Observable<Organization> {
    return this.restService.create('orgs', org, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Organization:' + errorMsg);
        return throwError(errorMsg);
      }));
  }

  /*
   * This API is used to update organization in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateOrganization(org: Organization): Observable<Organization> {
    return this.restService.updateFileAndImage(`orgs/${org.id}`, org, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Organization:' + errorMsg);
        return throwError(errorMsg);
      }));
  }
}
