import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { Organization } from './organization.model';
import { AdxStyle } from '../../common/model/adx-style.model';
import { Address } from 'src/app/common/model/address.model';
import { AdxTimezone } from '../../common/model/adx-timezone.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';

/**
 * Serializer for Organization data object
 */
export class OrganizationSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    const org: Organization = new Organization(json.id, json.secret);
    if (json.title) {
      org.title = json.title;
    }
    if (json.description) {
      org.description = json.description;
    }
    if (json.helpInfo) {
      org.helpInfo = AdxHelpInfo.buildObject(json.helpInfo);
    }
    if (json.aboutUsInfo) {
      org.aboutUsInfo = AdxAboutUsInfo.buildObject(json.aboutUsInfo);
    }
    if (json.iconImage) {
      org.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.style) {
      const styleObj: AdxStyle = AdxStyle.buildObject(json.style);
      org.style = styleObj;
    }

    if (json.address) {
      const addrObj: Address = Address.buildObject(json.address);
      org.address = addrObj;
    }

    if (json.defaultLocale) {
      const defaultLocale: AdxLocale = AdxLocale.buildObject(json.defaultLocale);
      org.defaultLocale = defaultLocale;
    }

    if (json.locales) {
      const result: AdxLocale[] = [];
      const items = Object.keys(json.locales);
      items.forEach(item => {
        result.push(AdxLocale.buildObject(json.locales[item]));
      });
      org.locales = result;
    }

    if (json.timeZone) {
      const timezone: AdxTimezone = AdxTimezone.buildObject(json.timeZone);
      org.timeZone = timezone;
    }

    if (json.smtpHost) {
      org.smtpHost = json.smtpHost;
    }

    if (json.smtpPort) {
      org.smtpPort = json.smtpPort;
    }

    if (json.smtpUsername) {
      org.smtpUsername = json.smtpUsername;
    }

    if (json.smtpPassword) {
      org.smtpPassword = json.smtpPassword;
    }

    if (json.senderEmail) {
      org.senderEmail = json.senderEmail;
    }

    if (json.pushNotificationProjectName) {
      org.pushNotificationProjectName = json.pushNotificationProjectName;
    }

    if (json.pushNotifProjectFileName) {
      org.pushNotifProjectFileName = json.pushNotifProjectFileName;
    }

    if (json.pushNotifApiKey) {
      org.pushNotifApiKey = json.pushNotifApiKey;
    }

    if (json.pushNotifAppId) {
      org.pushNotifAppId = json.pushNotifAppId;
    }

    if (json.pushNotifAuthDomain) {
      org.pushNotifAuthDomain = json.pushNotifAuthDomain;
    }

    if (json.pushNotifProjectId) {
      org.pushNotifProjectId = json.pushNotifProjectId;
    }

    if (json.pushNotifMessagingSenderId) {
      org.pushNotifMessagingSenderId = json.pushNotifMessagingSenderId;
    }

    if (json.pushNotifStorageBucket) {
      org.pushNotifStorageBucket = json.pushNotifStorageBucket;
    }

    if (json.excludedModuleTypes) {
      org.excludedModuleTypes = json.excludedModuleTypes;
    }

    if (json.verifyConsumerSignup !== undefined && json.verifyConsumerSignup !== null) {
      org.verifyConsumerSignup = json.verifyConsumerSignup;
    }

    if (json.emailDomainOption !== undefined && json.emailDomainOption !== null) {
      org.emailDomainOption = json.emailDomainOption;
    }

    if (json.emailDomains !== undefined && json.emailDomains !== null) {
      org.emailDomains = json.emailDomains;
    }

    return org;
  }
  toJson(resource: Organization): string {
    const json = JSON.stringify(resource);
    return json;
  }

}
