import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {AdxLocale} from '../../model/locale/adx-locale.model';
import {Observable, of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AdxLocaleService} from '../../services/locale/adx-locale.service';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

/**
 * The Locale resolver is used to fetch locale data from backend.
 */
@Injectable({
  providedIn: 'root',
})
export class AdxLocaleResolver implements Resolve<AdxLocale[]> {

  constructor(private logger: NGXLogger, private localeService: AdxLocaleService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AdxLocale[] | Observable<AdxLocale[]> | Promise<AdxLocale[]> {

    this.logger.debug('locale resolve called');
    return this.localeService.getLocales().pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of([]);
      })
    );
  }
}
