<div class="add-module-add-container">
  <form class="add-module-form" [formGroup]="addModuleForm">

    <div class="add-module">
        <div class="add-module-icon">
          <mat-label i18n="@@addModule.iconUpload">Icon Upload</mat-label>
          <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                               (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId" [applnId] ="applnId" [vpubId] ="vpubId"> </app-adx-image-input>
        </div>
      <div>
          <mat-form-field style="width: 98%">
            <input matInput i18n="@@addModule.title" id="input-addModule-title" #moduleTitle maxlength="100" placeholder="Title"
              matTooltip="Enter between 3 to 100 chars" formControlName="title" />
            <mat-hint align="end">{{moduleTitle.value.length}} / 100</mat-hint>
          </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div>
        <mat-form-field>
          <mat-label i18n="@@addModule.sort-order">Sort Order</mat-label>
          <input type='number' matInput i18n="@@addModule.sort-order" id="input-addModuleGroup-sortOrder"
                 min="0" placeholder="Sort Order" formControlName="sortOrder"/>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <mat-form-field class="common-desc-editor">
          <mat-label i18n="@@addModule.desc">Description</mat-label>
          <textarea placeholder='Description' id="input-addModule-description" formControlName="desc"
                    matInput [errorStateMatcher]="adxErrorStateMatcher" #moduleDescription
                    [froalaEditor]="froalaEditorOptions(450)" (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
        <mat-hint align="end">{{moduleDescription.value.length}} / 450</mat-hint>
        <mat-error *ngIf="addModuleForm.get('desc')?.hasError('maxlength')">
          {{moduleDescription.value.length}} exceeds max limit of 450 HTML characters
        </mat-error>
        </mat-form-field>

      <mat-divider></mat-divider>

      <div class="add-module-button-container">
      <button i18n="@@editModule.cancel" id="input-editModuleGroup-cancel-Btn" mat-raised-button routerLink="{{moduleListUrl}}"  class="button-cancel">Cancel</button>
      <button i18n="@@editModule.save" id="input-editModuleGroup-save-Btn" mat-raised-button [disabled]="!(addModuleForm.valid && addModuleForm.dirty)" type="submit" (click)="saveModule()">Save</button>
    </div>

    </div>
  </form>
</div>
