import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../common/material/material.module';
import { NgxColorsModule } from 'ngx-colors';

import { ChannelListComponent } from './channel-list/channel-list.component';
import { AddChannelComponent } from './add-channel/add-channel.component';
import { EditChannelComponent } from './edit-channel/edit-channel.component';
import { ChannelRoutingModule } from './channel-routing.module';
import { ChannelComponent } from './channel.component';
import {AdxControlsModule} from '../shared';
import {AppChannelLinkModule} from '../app-channel-link/app-channel-link.module';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        RouterModule,
        MaterialModule,
        NgxColorsModule,
        ChannelRoutingModule,
        AdxControlsModule,
        ReactiveFormsModule,
        AppChannelLinkModule,
        FroalaEditorModule
    ],
  exports: [ChannelListComponent],
  providers: [
  ],
  declarations: [
    ChannelListComponent,
    AddChannelComponent,
    EditChannelComponent,
    ChannelComponent,
  ]
})
export class ChannelModule {}
