import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AdxChannel } from '../model/adx-channel.model';
import { AdxChannelService } from '../service/adx-channel.service';
import { of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Account } from '../../account/model/account.model';
import { Organization } from '../../organization/model/organization.model';
import { UntypedFormControl } from '@angular/forms';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss']
})

export class ChannelListComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['icon', 'channelName', 'visible', 'edit'];
  dataSource: MatTableDataSource<AdxChannel> | null = null;
  selectedAccount: Account | null = null;
  selectedOrg: Organization | null = null;
  urlForAcctListing = '/orgs';
  dataFetchSubscription: Subscription | null = null;
  statusFilterSubscription: Subscription | null = null;
  titleFilterSubscription: Subscription | null = null;
  statusFilter: UntypedFormControl = new UntypedFormControl('');
  titleFilter: UntypedFormControl = new UntypedFormControl('');
  private filterValues: any = {
    title: '',
    visibility: ''
  };
  private authSubscription: Subscription | undefined;

  constructor (private authNotifier: AuthNotifierService, private router: Router,
    private channelService: AdxChannelService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedOrg = this.activatedRoute.snapshot.data.org;

    this.logger.debug(this.selectedAccount);
    this.logger.debug(this.selectedOrg);
    this.urlForAcctListing = `/orgs/${this.selectedOrg?.id}/accts`;
    if (this.selectedAccount && this.selectedAccount.id
      && this.selectedOrg && this.selectedOrg.id) {
      this.dataFetchSubscription = this.fetchChannels(this.selectedOrg.id, this.selectedAccount.id);
    } else {
      this.logger.error('Either selected Object or its id is null');
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.fieldListener();
  }

  ngOnDestroy(): void {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
    if (this.statusFilterSubscription) {
      this.statusFilterSubscription.unsubscribe();
    }
    if (this.titleFilterSubscription) {
      this.titleFilterSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that returns true if logged-in user has permission to add new application
   *
   * @returns boolean. true if current user can add new application
   */
  canAddChannel(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.channelService.canUserAddChannel(this.selectedOrg.id, this.selectedAccount.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit channel
   *
   * @param number: channel id
   * @returns boolean: true if user can edit channel identified by given id
   */
  canEditChannel(channelId: number | null): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && channelId !== undefined && channelId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.channelService.canUserEditChannel(this.selectedOrg.id, this.selectedAccount.id, channelId, this.currentUser);
    }
    return false;
  }

  /*
   * This method fetches the channels list from backend
  */
  private fetchChannels(orgId: number, accountId: number): Subscription {
    this.logger.debug('fetching accounts for orgId:' + orgId + ' ; accountId:' + accountId);
    if (accountId && orgId) {
      return this.channelService.getChannels(orgId, accountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxChannel[]) => {
            // successfully received the data. so set it in variable
            this.processResponse(data);
          }
        });
    }
    else {
      return of({}).subscribe();
    }
  }

  /*
   * Utility to set accessible applications in the DataSource
   */
  private processResponse(data: AdxChannel[]) {
    if (this.currentUser === undefined || this.currentUser === null) {
      return;
    }
    //filter list based on user role based access
    const hasSystemAccess = AdxAuthUtils.hasSystemAccess(this.currentUser);
    let accessibleChannels = data; //if system access, then user will be displayed all channels
    if (!hasSystemAccess) {
      //get accessible list from storage and filter the input channels
      accessibleChannels = this.filterAccessibleChannelIds(data);
    }
    this.dataSource = new MatTableDataSource(accessibleChannels);
    this.dataSource.filterPredicate = this.createFilterFunction();
  }

  /*
   * For loggedion user, get the accessible applications from received list
  */
  private filterAccessibleChannelIds(channelsRetrieved: AdxChannel[]): AdxChannel[] {
    if (channelsRetrieved === undefined || channelsRetrieved === null) {
      return channelsRetrieved;
    }

    if (this.selectedOrg === undefined || this.selectedOrg === null
      || this.selectedOrg.id === undefined || this.selectedOrg.id === null) {
      return [];
    }

    if (this.currentUser === undefined || this.currentUser === null) {
      return [];
    }

    if (AdxAuthUtils.isSystemOrInstanceAdmin(this.currentUser)) {
      return channelsRetrieved; //no filtering as user is system/instance admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_ADMIN', this.currentUser)) {
      return channelsRetrieved; //no filtering as user is org admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_READ_ONLY', this.currentUser)) {
      return channelsRetrieved; //no filtering as user has org readonly permission
    }

    if (this.selectedAccount !== null && this.selectedAccount !== null
      && this.selectedAccount.id !== null && this.selectedAccount.id !== null
      && AdxAuthUtils.hasRoleForGivenId(this.selectedAccount.id, 'ACCOUNT_ADMIN', this.currentUser)) {
      return channelsRetrieved; //no filtering as user has account admin permission
    }

    if (this.selectedAccount !== null && this.selectedAccount !== null
      && this.selectedAccount.id !== null && this.selectedAccount.id !== null
      && AdxAuthUtils.hasRoleForGivenId(this.selectedAccount.id, 'ACCOUNT_READ_ONLY', this.currentUser)) {
      return channelsRetrieved; //no filtering as user has account readonly permission
    }

    const toRet: AdxChannel[] = []; // if nothing matches, then empty list is returned
    const accessbileChannels = this.channelService.getAccessiblChannelIdsForUser(this.currentUser);

    if (channelsRetrieved.length > 0) {
      //verify whether accessibleOrgs suppiled or not
      if (accessbileChannels !== undefined && accessbileChannels !== null && accessbileChannels.length > 0) {
        for (const chnlId of accessbileChannels) {
          const foundChannel = channelsRetrieved.find(chnl => chnl.id === chnlId);
          //if channel is present in accessible list, add it to list to be returned
          if (foundChannel !== undefined && foundChannel !== null) {
            toRet.push(foundChannel);
          }
        }
      }
    }

    return toRet;
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createFilterFunction(): (appln: AdxChannel, filter: string) => boolean {
    const filterFunction = (channel: AdxChannel, filter: string): boolean => {

      this.logger.debug('filter: ' + filter);
      const searchTerms = JSON.parse(filter);
      let visibilityValue = '';
      let visibilityFlag = -1;
      if (channel && channel.visibility) {
        visibilityValue = channel.visibility.toLowerCase();
        visibilityFlag = (visibilityValue.indexOf(searchTerms.visibility.toLowerCase()));
      }

      let titleValue = '';
      let titleFlag = -1;
      if (channel && channel.title) {
        titleValue = channel.title.toLowerCase();
        titleFlag = (titleValue.indexOf(searchTerms.title.toLowerCase()));
      }
      return -1 !== visibilityFlag && -1 !== titleFlag;
    };

    return filterFunction;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.statusFilterSubscription = this.statusFilter.valueChanges
      .subscribe({
        next: status => {
          this.filterValues.visibility = status;
          if (this.dataSource) {
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        }
      }
      );
    this.titleFilterSubscription = this.titleFilter.valueChanges
      .subscribe({
        next: value => {
          this.filterValues.title = value;
          if (this.dataSource) {
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        }
      }
      );
  }

}
