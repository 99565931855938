  <div class="adx-user-manage-container">
    <div>
      <mat-toolbar style="justify-content: center;">
        <span i18n="@@userMgmtTitle">User Management</span>
      </mat-toolbar>
    </div>

    <div class="common-container">


      <mat-tab-group class="common-tab-group" [selectedIndex]="0">
        <mat-tab label="User Add">
          <ng-template matTabContent>
            <app-adx-user-add [orgId]="orgId" [accountId]="accountId"></app-adx-user-add>
          </ng-template>
        </mat-tab>

        <mat-tab i18n="@@accounts-label" label="User List">
          <ng-template matTabContent>
            <app-adx-user-listing [orgId]="orgId" [accountId]="accountId"></app-adx-user-listing>
          </ng-template>
        </mat-tab>

        <!--
        <mat-tab i18n="@@accounts-label" label="Change User Profile">
          <ng-template matTabContent>
            <app-adx-user-profile-edit></app-adx-user-profile-edit>
          </ng-template>
        </mat-tab>
        -->
        <mat-tab i18n="@@accounts-label" label="Change User Role">
          <ng-template matTabContent>
            <app-adx-user-role-edit [orgId]="orgId" [accountId]="accountId" [displayLevel]="displayLevel"></app-adx-user-role-edit>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </div>

  </div>

