import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError} from 'rxjs/operators';
import {AdxChannel} from '../model/adx-channel.model';
import {AdxChannelService} from '../service/adx-channel.service';

@Injectable({
  providedIn: 'root'
})
export class AdxChannelResolver implements Resolve<AdxChannel | null> {

  constructor(private logger: NGXLogger, private applicationService: AdxChannelService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdxChannel | null> {
    this.logger.debug('In Channel route resolve:' + route.paramMap.keys);
    const orgIdString: string | null | undefined = route.paramMap.get('orgId');
    this.logger.debug(orgIdString);
    const accountIdString: string | null = route.paramMap.get('acctId');
    this.logger.debug(accountIdString);
    const channelIdString: string | null = route.paramMap.get('chnlId');
    this.logger.debug(channelIdString);

    if (orgIdString) {
      if (isNaN(+orgIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed org id is not a number: ${orgIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed org id is not a number: ${orgIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (accountIdString) {
      if (isNaN(+accountIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${accountIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${accountIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (channelIdString) {
      if (isNaN(+channelIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${channelIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${channelIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    const selectedOrgId: number = +orgIdString;
    const selectedAccountId: number = +accountIdString;
    const selectedChannelId: number = +channelIdString;
    this.logger.debug(`ids received: ${selectedOrgId}: ${selectedAccountId}`);
    return this.applicationService.getChannel(selectedOrgId, selectedAccountId, selectedChannelId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of(null);
      })
    );
  }
}
