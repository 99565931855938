import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-adx-form-field',
  templateUrl: './adx-form-field.component.html',
  styleUrls: ['./adx-form-field.component.scss']
})
export class AdxFormFieldComponent implements OnInit {

  @Input() label = '';
  @Input() required = false;
  @Input() isInline: boolean;
  @Input() control!: AbstractControl;

  constructor() {
    this.isInline = true;
  }

  ngOnInit(): void {
  }

  hasError(): boolean {
    return this.control && this.control.invalid && this.control.touched;
  }

  get errorKey() {
    return this.control && this.control.errors && Object.keys(this.control.errors)[0];
  }

}
