/**
 * Model that represents Import Process Result
 */
export class AdxImportProcessResult {

  eventId: number | null = null;
  result: string | null = null;
  message: string | null = null;
  processingTime: string | null = null;

  private SUCCESS = 'success';
  private FAIL = 'fail';
  private NOT_RUN = 'not_run';

  constructor() {}

  get isSuccess(): boolean {
    return this.result === this.SUCCESS;
  }

  get isFailure(): boolean {
    return this.result === this.FAIL;
  }

  get isNotRun(): boolean {
    return this.result === this.NOT_RUN;
  }

  static buildObject(json: any): AdxImportProcessResult {
    const toRet = new AdxImportProcessResult();
    if (json) {
      if (json.eventId) {
        toRet.eventId = json.eventId;
      }
      if (json.result) {
        toRet.result = json.result;
      }
      if (json.message) {
        toRet.message = json.message;
      }
      if (json.processingTime) {
        toRet.processingTime = json.processingTime;
      }
    }
    return toRet;
  }
}
