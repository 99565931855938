import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxImportProcessResponse} from './adx-import-process-response';

/**
 * Model that represents the Import Response
 */
export class AdxImportResponse extends AdxResource{

  accountId: number | null = null;
  applicationId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;
  refNo1: number | null = null;
  refNo2: number | null = null;
  refNo3: number | null = null;
  type: string | null = null;
  dataType: string | null = null;
  template: string | null = null;
  fileName: string | null = null;
  objectUrl: string | null = null;
  folderName: string | null = null;
  folderUrl: string | null = null;
  mimeType: string | null = null;
  inputFileName: string | null = null;
  convertedFileName: string | null = null;
  totalDuration: string | null = null;
  aceProcessToRun: string | null = null;
  availableProcesses: string | null = null;
  totalNumberOfPages: number | null = null;
  containsToc: boolean | null = null;
  executionTime: string | null = null;
  status: string | null = null;
  statusDescription: string | null = null;
  vpubTitle: string | null = null;
  moduleTitle: string | null = null;
  username: string | null = null;
  processes: AdxImportProcessResponse[] | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxImportResponse {
    const toRet  = new AdxImportResponse(null);
    if (json) {
      if (json.id) {
        toRet.id = json.id;
      }
      if (json.accountId) {
        toRet.accountId = json.accountId;
      }
      if (json.applicationId) {
        toRet.applicationId = json.applicationId;
      }
      if (json.vpubId) {
        toRet.vpubId = json.vpubId;
      }
      if (json.moduleId) {
        toRet.moduleId = json.moduleId;
      }
      if (json.refNo1) {
        toRet.refNo1 = json.refNo1;
      }
      if (json.refNo2) {
        toRet.refNo2 = json.refNo2;
      }
      if (json.refNo3) {
        toRet.refNo3 = json.refNo3;
      }
      if (json.type) {
        toRet.type = json.type;
      }
      if (json.dataType) {
        toRet.dataType = json.dataType;
      }
      if (json.template) {
        toRet.template = json.template;
      }
      if (json.fileName) {
        toRet.fileName = json.fileName;
      }
      if (json.objectUrl) {
        toRet.objectUrl = json.objectUrl;
      }
      if (json.folderName) {
        toRet.folderName = json.folderName;
      }
      if (json.folderUrl) {
        toRet.folderUrl = json.folderUrl;
      }
      if (json.mimeType) {
        toRet.mimeType = json.mimeType;
      }
      if (json.inputFileName) {
        toRet.inputFileName = json.inputFileName;
      }
      if (json.convertedFileName) {
        toRet.convertedFileName = json.convertedFileName;
      }
      if (json.totalDuration) {
        toRet.totalDuration = json.totalDuration;
      }
      if (json.aceProcessToRun) {
        toRet.aceProcessToRun = json.aceProcessToRun;
      }
      if (json.availableProcesses) {
        toRet.availableProcesses = json.availableProcesses;
      }
      if (json.totalNumberOfPages) {
        toRet.totalNumberOfPages = json.totalNumberOfPages;
      }
      if (json.containsToc) {
        toRet.containsToc = json.containsToc;
      }
      if (json.executionTime) {
        toRet.executionTime = json.executionTime;
      }
      if (json.status) {
        toRet.status = json.status;
      }
      if (json.statusDescription) {
        toRet.statusDescription = json.statusDescription;
      }
      if (json.vpubTitle) {
        toRet.vpubTitle = json.vpubTitle;
      }
      if (json.moduleTitle) {
        toRet.moduleTitle = json.moduleTitle;
      }
      if (json.username) {
        toRet.username = json.username;
      }
      if (json.processes) {
        toRet.processes = [];
        json.processes.forEach((processResp: any) => {
          const tempRespObj = (AdxImportProcessResponse.buildObject(processResp));
          if (tempRespObj && toRet.processes) {
            toRet.processes.push(tempRespObj);
          }
        });
      }
    }
    return toRet;
  }
}
