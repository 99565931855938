import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdxHtml} from '../../adx-module/model/adx-html-model';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AdxSimulatorService} from '../service/adx-simulator.service';
import {AdxWeb} from '../../adx-module/model/adx-web-model';

@Component({
  selector: 'app-web-module-simulator',
  templateUrl: './web-module-simulator.component.html',
  styleUrls: ['./web-module-simulator.component.scss']
})
export class WebModuleSimulatorComponent implements OnInit, OnDestroy {

  @Input() webModule: AdxWeb | null = new AdxWeb(-1);
  changeSubscription: Subscription | undefined;

  constructor(private logger: NGXLogger, private simulatorService: AdxSimulatorService) { }

  ngOnInit(): void {
    this.changeSubscription = this.simulatorService.contentUpdated.subscribe((data: boolean) => {
      if (this.simulatorService.selectedModule.html) {
        this.webModule = this.simulatorService.selectedModule.web;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }
}
