import {Injectable} from '@angular/core';
import {AdxImageLibraryItem} from '../../model/image-library/adx-image-library-item.model';
import {AdxImageLibrarySerializer} from '../../serializer/image-library/adx-image-library-serializer';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../../core/service/resource/adx-rest-service.service';
import {AdxImageLibraryType} from '../../../shared/utils/adx-image-library-type';
import {AdxQueryParams} from '../../../core/service/resource/adx-query-params.model';
import {AdxImageLibraryRequest} from '../../model/image-library/adx-image-library-request';

/**
 * Service to interact with Image Library service from backend. The producer url starts with /imglib/
 */
@Injectable({
  providedIn: 'root'
})
export class AdxImageLibraryService {

  private items: AdxImageLibraryItem[];
  private serializer: AdxImageLibrarySerializer = new AdxImageLibrarySerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxImageLibraryRequest, AdxImageLibraryItem>) {
    this.items = [];
  }

  /**
   * Get the images for the given type of image library.
   * If type is Org, id will be organization id
   * If type is Account, id value will be account id
   *
   * @param imgLibType - enum; indicates Account level or Organization level library
   * @param id: object id
   */
  getLibraryImagesByType(imgLibType: AdxImageLibraryType, id: number): Observable<AdxImageLibraryItem[]> {

    if (!imgLibType || !id) {
      this.logger.error(`Invalid input type:${imgLibType} ; id:${id}`);
      return EMPTY;
    }
    let resourceUrl: string;

    if (!imgLibType) {
      return throwError('Invalid input');
    }

    if (imgLibType === AdxImageLibraryType.ORG_IMG_LIB) {
      resourceUrl = `imglib/org/${id}/images`;
    }
    else {
      resourceUrl = `imglib/acct/${id}/images`;
    }

    this.logger.debug('fetching image library items in getLibraryImagesByType');
    return this.fetchImages(resourceUrl);
  }

  /**
   * Get the images for the given folder of image library.
   *
   * @param id - folder id
   */
  getLibraryImagesByFolder(id: number): Observable<AdxImageLibraryItem[]> {

    if (!id) {
      this.logger.error(`Invalid input id:${id}`);
      return EMPTY;
    }
    const resourceUrl = `imglib/folder/${id}/images`;

    this.logger.debug('fetching image library items in getLibraryImagesByFolder');
    return this.fetchImages(resourceUrl);
  }

  /**
   * API used to delete image from library
   *
   * @param id: id of the image to be deleted
   */
  deleteImageFromLibrary(id: number): Observable<any> {

    if (!id) {
      this.logger.debug('id not specified. Invalid request');
      return EMPTY;
    }
    const resourceUrl = `imglib/images/${id}`;
    return this.restService.delete(resourceUrl, null).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Image:' + errorMsg);
        return throwError(errorMsg);
      }));
  }

  addImageToOrgLibrary(imgLibType: AdxImageLibraryType, imgLibReq: AdxImageLibraryRequest): Observable<AdxImageLibraryItem[]> {

    if (!imgLibType || !imgLibReq) {
      this.logger.error(`Invalid input type:${imgLibType} ; req:${imgLibReq}`);
      return EMPTY;
    }

    let resourceUrl = '';

    if (AdxImageLibraryType.ORG_IMG_LIB === imgLibType) {
      resourceUrl = `imglib/org/${imgLibReq.organizationId}/images`;
    }
    else if (AdxImageLibraryType.ACCOUNT_IMG_LIB === imgLibType || AdxImageLibraryType.APPLICATION_IMG_LIB === imgLibType || 
      AdxImageLibraryType.VPUB_IMG_LIB === imgLibType || AdxImageLibraryType.MODULE_IMG_LIB === imgLibType ||
       AdxImageLibraryType.ATOM_IMG_LIB === imgLibType) { //adding support for multiple image library type like application/vpub/module/atom level image libarary type
      resourceUrl = `imglib/acct/${imgLibReq.accountId}/images`;
    }
    const serializer: AdxImageLibrarySerializer = new AdxImageLibrarySerializer();

    return this.restService.addImages(resourceUrl, imgLibReq, null, serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.error('Error while adding Image:' + errorMsg);
          return throwError(errorMsg);
        }));
  }

  /*
   * utility method to fetch data from backend
   */
  private fetchImages(resourceUrl: string): Observable<AdxImageLibraryItem[]> {
    return this.restService.list(resourceUrl, new AdxQueryParams(), null, this.serializer)
      .pipe(
        tap(data => this.items = data),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        })
      );
  }
}
