import { Injectable } from '@angular/core';
import { AdxRestService } from 'src/app/core/service/resource/adx-rest-service.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticatedUserSerializer } from '../../common/serializer/adx-auth/authenticated-user-serializer';
import { Observable } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private serializer: AuthenticatedUserSerializer = new AuthenticatedUserSerializer();

  constructor(private logger: NGXLogger,
     private restService: AdxRestService<AuthenticatedUser, AuthenticatedUser>) { }

  /*
   * This will call the REST API to validate user credentials.
   */
  public login(username: string, password: string): Observable<AuthenticatedUser> {

    // handle login
    let header: HttpHeaders = new HttpHeaders();
    header = header.set('X-Auth-Username', username);
    header = header.set('X-Auth-Password', password);

    return this.restService.read('auth/login', null, header, this.serializer);
  }

  /*
   * This will call REST Api to create new user
   */
  public signup(username: string, firstname: string, lastname: string, passwd: string): Observable<AuthenticatedUser> {

    const user = new AuthenticatedUser(null);
    user.username = username;
    user.password = passwd;
    user.firstName = firstname;
    user.lastName = lastname;
    return this.restService.create('auth/signup', user, null, this.serializer);
  }

  public changePassword(): any {

  }

  public forgotPassword(username: string): Observable<AuthenticatedUser> {
    const user = new AuthenticatedUser(null);
    user.username = username;
    return this.restService.create('auth/resetpassword', user, null, this.serializer);
  }

  public logout(authenticatedUser: AuthenticatedUser): Observable<AuthenticatedUser> {
      this.logger.debug('Logging out user: {}', authenticatedUser.username);
      return this.restService.create('auth/signout', authenticatedUser, null, this.serializer);
  }
}
