import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Organization } from '../model/organization.model';
import { Association } from './association.model';
import { ThemePalette } from '@angular/material/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { OrganizationService } from '../service/organization.service';
import { Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AdxImageLibraryType } from '../../shared/utils/adx-image-library-type';
import { AdxImageLibraryItem } from '../../common/model/image-library/adx-image-library-item.model';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationUtility } from '../utils/organization-utility';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';

const ASSOCIATE_DATA: Association[] = [];

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}


@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort = new MatSort();

  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<Organization> | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorMsg: string;
  libraryType: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;

  orgchecked = '';
  accountschecked = '';
  organisationFilter = new UntypedFormControl('');

  filterValues: any = {
    organisationFilter: ''
  };

  private organisationFilterSubscription: Subscription | null = null;
  private authSubscription: Subscription | undefined;

  constructor(private orgService: OrganizationService,
    private authNotifier: AuthNotifierService, private router: Router) {
    super();
    this.errorMsg = '';
    this.displayedColumns = [
      'icon',
      'title',
      'secret',
      'timezone',
      'defaultLocale',
      'orgListActions'
    ];
  }

  ngOnInit(): void {
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('In AdxHamburgerMenuComponent authSubscription');
        this.currentUser = user;
      }
    );
    // somehow if route is '', it is coming here.
    // tried to set the routing path correctly, it did not work.
    // for now this is a hack.
    if (this.router.url === '/') {
      this.router.navigate(['/auth/login']).then(r => { });
      return;
    }
    this.logger.debug(this.fetchOrganizations());
    this.fetchOrganizations();
    this.fieldListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
    if (this.organisationFilterSubscription) {
      this.organisationFilterSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  onImageSelect(imgItem: AdxImageLibraryItem): void {
    if (imgItem) {
      this.logger.debug(`In Org Listing; selected Image is ${imgItem.title}`);
    }
  }

  /**
   * Callback that returns true if logged-in user has permission to add new organization
   * @returns boolean. true if current user can add new org
   */
  canAddOrg(): boolean {
    if (this.currentUser === undefined || this.currentUser === null) {
      return false;
    }
    return AdxAuthUtils.isSystemOrInstanceAdmin(this.currentUser);
  }

  /**
   * Returns true if user has role to edit this org
   *
   * @param orgId organization id
   * @returns boolean true if user has permission to edit this org
   */
  canEditOrg(orgId: number): boolean {
    if (this.currentUser === undefined || this.currentUser === null) {
      return false;
    }
    if (this.orgService.canUserEditOrg(orgId, this.currentUser)) {
      return true;
    }
    return false;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.organisationFilterSubscription = this.organisationFilter.valueChanges
      .subscribe({
        next: value => {
          this.filterValues.title = value;
          if (this.dataSource) {
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        }
      }
      );

  }

  /*
   * once data is fetched from backend, set the datasource and filtering function
   * User should view only the orgs that he has permission to view. hence filter the orgList
   * based on accessible org ids here.
   */
  private handleOrganizations(organizations: Organization[]): void {

    if (this.currentUser === undefined || this.currentUser === null) {
      return;
    }
    //filter list based on access
    const hasSystemAccess = AdxAuthUtils.hasSystemAccess(this.currentUser);
    let accessibleOrgs = organizations; //if system access, then user will be displayed all orgs
    if (!hasSystemAccess) {
      //get accessible list from storage and filter the input orgs
      const accessibleOrgIds = this.orgService.getUserAccessibleOrgIds(this.currentUser);
      accessibleOrgs = OrganizationUtility.filterAccessibleOrgs(organizations, accessibleOrgIds);
    }

    this.dataSource = new MatTableDataSource(accessibleOrgs);
    this.dataSource.filterPredicate = this.createOrganizationFilterFunction();
    this.dataSource.sort = this.sort;
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createOrganizationFilterFunction(): (organization: Organization, filter: string) => boolean {
    const filterFunction = (organization: Organization, filter: string): boolean => {
      const searchTerms = JSON.parse(filter);
      let titleValue = '';
      let titleFlag = -1;
      if (organization && organization.title) {
        titleValue = organization.title.toLowerCase();
        titleFlag = (titleValue.indexOf(searchTerms.title.toLowerCase()));
        console.log(titleFlag);
      }
      return -1 !== titleFlag;
    };

    return filterFunction;
  }

  /*
   * This method fetches the organization list from backend
  */
  private fetchOrganizations(): void {
    this.logger.debug('In fetchOrganizations');
    this.orgService
      .getOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Organization[]) => {
        // successfully received the data. so set it in variable
        this.handleOrganizations(data);
      });
  }
}


