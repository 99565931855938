import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 * Class Help used in Organization and Account classes
 */
export class AdxHelpInfo extends AdxResource {
  text: string | null;
  scope: string | null;

  constructor(id: number | null, txt: string | null, strScope: string | null) {
    super(id);
    this.text = txt;
    this.scope = strScope;
  }

  static buildObject(json: any): AdxHelpInfo {
    return new AdxHelpInfo(json.id, json.text, json.scope);
  }
}
