import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdxModule} from '../model/adx-module.model';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Clipboard} from '@angular/cdk/clipboard';
import {AdxModuleService} from '../service/adx-module.service';
import {Organization} from '../../organization/model/organization.model';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AdxModuleType} from '../model/adx-module-type';
import {environment} from '../../../environments/environment';
import {AdxSimulatorService} from '../../adx-simulator/service/adx-simulator.service';
import {AdxImage} from '../../common/model/adx-image.model';
import {Account} from '../../account/model/account.model';
import { AdxVPub } from 'src/app/vPub/model/adx-vpub.model';
import { AdxDialogService } from 'src/app/common/services/dialog/adx-dialog.service';
import { CustomMessageType } from 'src/app/core/service/notifier/custom-message-type';
import {SelectionModel} from '@angular/cdk/collections';
import {CommonUtility} from '../../common/utils/common-utils';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AdxApplication } from 'src/app/application/model/adx-application.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Component({
  selector: 'app-adx-module-list',
  templateUrl: './adx-module-list.component.html',
  styleUrls: ['./adx-module-list.component.scss'],
})
export class AdxModuleListComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  treeControl = new NestedTreeControl<AdxModule>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AdxModule>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg: Organization | null = null;
  selectedAccount: Account | null = null;
  selectedApplication: AdxApplication | null = null;
  selectedVpub: AdxVPub | null = null;
  urlForVpubListing = '';
  addModuleToggle = false;
  moduleTypes: { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] = [];

  keepSorted = true;
  excludedModules: string[] = [];
  includedModules: string[] = [];
  allModules: string[] = [];
  format: any = { add: 'Exclude Module', remove: 'Include Module', all: 'Select All', none: 'Unselect All',
    direction: 'left-to-right', draggable: true, locale: undefined };
  display: any;
  filter = true;
  excludeModuleDualBoxChanged = false;

  /* The selection for checklist */
  checklistSelection = new SelectionModel<AdxModule>(true /* multiple */);
  allSelect = false; // indicates whether all check box selected

  // the Direct Access Link base URL is read from environment specific file
  private readonly baseDalUrl: string = environment.dalBaseUrl;

  private authSubscription: Subscription | undefined;

  constructor(private authNotifier: AuthNotifierService, private moduleService: AdxModuleService,
              private simulatorService: AdxSimulatorService, private clipboard: Clipboard,
              private router: Router, private activatedRoute: ActivatedRoute,
              private readonly messageNotifier: CustomNotifierService, private dialogService: AdxDialogService) {
                super();
              }

  hasChild = (_: number, node: AdxModule) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedApplication = this.activatedRoute.snapshot.data.appln;
    this.selectedVpub = this.activatedRoute.snapshot.data.vpub;
    const orgId: number | null = this.getId('orgId');
    const accountId: number | null = this.getId('acctId');
    const applnId: number | null = this.getId('applnId');
    const vpubId: number | null = this.getId('vpubId');
    if (!orgId || !accountId || !applnId || !vpubId) {
      this.logger.error('required params missing');
      return;
    }
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.urlForVpubListing = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs`;

    this.fetchModules(orgId, accountId, vpubId);

    // set the modules available to use for this account
    this.logger.debug(this.selectedAccount);
    this.logger.debug(this.selectedAccount?.accountAvailableModules);
    if (this.selectedAccount?.accountAvailableModules) {
      this.moduleTypes.push(...this.selectedAccount?.accountAvailableModules);
    }
    this.moduleTypes.push({displayName: 'GROUP', type: AdxModuleType.GROUP, icon: null});
    this.logger.debug(this.moduleTypes);
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that returns true if logged-in user has permission to view module
   *
   * @returns boolean. true if current user can view the module
   */
  canViewModule(moduleId: number | null): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.selectedVpub !== undefined && this.selectedVpub !== null
      && this.selectedVpub.id !== undefined && this.selectedVpub.id !== null
      && moduleId !== undefined && moduleId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserViewModule(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, this.selectedVpub.id, moduleId, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to add new module
   *
   * @returns boolean. true if current user can add new module
   */
  canAddModule(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.selectedVpub !== undefined && this.selectedVpub !== null
      && this.selectedVpub.id !== undefined && this.selectedVpub.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserAddModule(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, this.selectedVpub.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to delete module
   *
   * @returns boolean. true if current user can delete module
   */
  canDeleteModule(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.selectedVpub !== undefined && this.selectedVpub !== null
      && this.selectedVpub.id !== undefined && this.selectedVpub.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserDeleteModule(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, this.selectedVpub.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit module
   *
   * @param acctId
   * @returns boolean: true if user can edit module identified by given id
   */
  canEditModule(acctId: number | null): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.selectedVpub !== undefined && this.selectedVpub !== null
      && this.selectedVpub.id !== undefined && this.selectedVpub.id !== null
      && acctId !== undefined && acctId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserEditModule(this.selectedOrg.id,
        this.selectedAccount.id, this.selectedApplication.id, this.selectedVpub.id, acctId, this.currentUser);
    }
    return false;
  }

  displayModule(node: AdxModule): void {
    this.logger.debug(node);
    this.logger.debug(`module of type ${node.type} clicked`);
    if (!this.canViewModule(node.id)) {
      this.logger.debug(`User is not authorized to view the module`);
      this.alertDialog('Unauthorised', 'Do not have requisite permission to do the action', '', CustomMessageType.ERROR);
      return;
    }
    // TODO: need to support other modules later
    if (node.type === AdxModuleType.ARS) {
      this.router.navigate([node.id, 'ars'], {relativeTo: this.activatedRoute}).then(r => {});
    }
    else if (node.type === AdxModuleType.WEB) {
      this.router.navigate([node.id, 'web'], {relativeTo: this.activatedRoute}).then(r => {});
    }
    else if (node.type === AdxModuleType.HTML) {
      this.router.navigate([node.id, 'html'], {relativeTo: this.activatedRoute}).then(r => {});
    }
    else if (node.type === AdxModuleType.FORM) {
      this.router.navigate([node.id, 'form'], {relativeTo: this.activatedRoute}).then(r => {});
    }
    else if (node.type === AdxModuleType.MOTOR_AXLENUT_HUBMOUNT) {
      /* this.router.navigate([node.id, 'motor_axlenut_hubmount'],
        {relativeTo: this.activatedRoute}).then(r => {}); */
      this.logger.debug('MOTOR AXELNUL HBMOUNT module not yet supported');
    }
    else if (node.type === AdxModuleType.MOTOR_COOLANT) {
      /* this.router.navigate([node.id, 'motor_coolant'],
        {relativeTo: this.activatedRoute}).then(r => {}); */
      this.logger.debug('MOTOR COOLANT module not yet supported');
    }
    else if (node.type === AdxModuleType.MOTOR_TRUSPEED) {
      /* this.router.navigate([node.id, 'motor_truspeed'],
        {relativeTo: this.activatedRoute}).then(r => {}); */
      this.logger.debug('MOTOR TRUSPEED module not yet supported');
    }
    else {
      this.logger.debug('Not an Valid module');
    }
  }

  copyDalLink(node: AdxModule): void {
    if (!this.canViewModule(node.id)) {
      this.logger.debug(`User is not authorized to view the module`);
      this.alertDialog('Unauthorised', 'Do not have requisite permission to do the action', '', CustomMessageType.ERROR);
      return;
    }
    let msgTitle = 'Unable to create DAL link';
    let strMsg = '';
    const strSubMsg = '';
    let msgType = CustomMessageType.ERROR;
    if (this.selectedAccount?.visibility !== 'VISIBLE') {
      strMsg = 'DAL link is not generated as associated ACCOUNT is not visible';
      this.alertDialog(msgTitle, strMsg, strSubMsg, msgType);
      return;
     }
     if (this.selectedVpub?.isDraft) {
      strMsg = 'DAL link is not generated as associated VPub is still draft';
      this.alertDialog(msgTitle, strMsg, strSubMsg, msgType);
      return;
     }
     if (node.visibility !== 'VISIBLE') {
      strMsg = 'DAL link is not generated as associated Module is not visible';
      this.alertDialog(msgTitle, strMsg, strSubMsg, msgType);
      return;
     }
    const accountId: number | null = this.getId('acctId');
    const vpubId: number | null = this.getId('vpubId');

    // http://<server-details>/gadgetweb/<token>&<accountid>-<vpub-id>-<module-id>-<atom-id>
    let dalUrl = this.baseDalUrl;
    dalUrl = dalUrl.concat('/');
    if (this.selectedOrg?.secret && accountId && vpubId && node.id ) {
      dalUrl = dalUrl.concat(this.selectedOrg?.secret);
      dalUrl = dalUrl.concat('&');
      dalUrl = dalUrl.concat(accountId.toString());
      dalUrl = dalUrl.concat('-');
      dalUrl = dalUrl.concat(vpubId.toString());
      dalUrl = dalUrl.concat('-');
      dalUrl = dalUrl.concat(node.id.toString());
      this.clipboard.copy(dalUrl);
      this.logger.debug(`copied to clipboard ${dalUrl}`);
      msgTitle = 'DAL link is generated';
      strMsg = 'The DAL link is copied to clipboard';
      msgType = CustomMessageType.WARN;
      this.alertDialog(msgTitle, strMsg, strSubMsg, msgType);
    }
  }

  showChoice(): void {
    this.addModuleToggle = !this.addModuleToggle;
  }

  isModuleTypeSupported(moduleType: string): boolean {
    if ('MOTOR_AXLENUT_HUBMOUNT' !== moduleType && 'MOTOR_COOLANT' !== moduleType && 'MOTOR_TRUSPEED' !== moduleType) {
      return true;
    }
    return false;
  }

  addModule(value: AdxModuleType) {
    this.logger.debug(`add module of type ${value}`);
    if (AdxModuleType.GROUP === value) {
      this.router.navigate(['add/group'], {relativeTo: this.activatedRoute}).then(r => {});
    }
    else {
      const navigationExtras: NavigationExtras = {
        state: {
          moduleType: value
        },
        relativeTo: this.activatedRoute
      };
      this.logger.debug(`navigating to add/${value.toLowerCase()}`);
      this.router.navigate([`add/${value.toLowerCase()}`], navigationExtras).then(r => {});
    }
  }

  /**
   * Toggle as per the value of 'select all' checkbox
   */
  selectAll(): void {
    this.allSelect = !this.allSelect;
    if (this.allSelect) {
      this.treeControl.expandAll();
    }
    this.treeControl.dataNodes.forEach((r, index) => {
      this.logger.debug(`select: ${this.allSelect} ;; node: ${this.treeControl.dataNodes[index].title}`);
      if (this.allSelect) {
        this.checklistSelection.select(this.treeControl.dataNodes[index]);
      } else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[index]);
      }
      const descendants = this.treeControl.getDescendants(this.treeControl.dataNodes[index]);
      if (this.allSelect) {
        this.checklistSelection.select(...descendants);
      } else {
        this.checklistSelection.deselect(...descendants);
      }
    });
  }

  /**
   *  returns true if all the descendants of the node are selected
   */
  descendantsAllSelected(node: AdxModule): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /**
   *  returns true if part of the descendants are selected
   */
  descendantsPartiallySelected(node: AdxModule): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /**
   *  Toggle the expandable node selection. Select/deselect all the descendants node
   */
  nestedTreeSelectionToggle(node: AdxModule): void {
    this.logger.debug(`nestedTreeSelectionToggle: ${node.title}; isSelected:${this.checklistSelection.isSelected(node)}`);
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
      this.allSelect = false;//as one of the node is deselected, all select needs to be false
    }
  }

  selectionToggle(node: AdxModule): void {
    this.checklistSelection.toggle(node);
    if (!this.checklistSelection.isSelected(node)) {
      this.allSelect = false;//as one of the node is deselected, all select needs to be false
    }
  }

  /**
   * This is called when delete button is clicked. Used to bulk delete selected atoms
   */
  deleteSelected(): void {
    const selectedModules: AdxModule[] = this.checklistSelection.selected;
    if (!selectedModules || selectedModules.length < 1) {
      this.alertDialog('Invalid Operation','Please select content to delete',null,CustomMessageType.ERROR);
      return;
    }

    for (const selectedModule of selectedModules) {
      this.logger.debug(`toDel: ${selectedModule.id}::${selectedModule.title}`);
    }

    this.confirmDialog('Please confirm whether you want to delete the selected content.').subscribe({
      next: (data) => {
        if (data) {
          const deleteObj: AdxModule = new AdxModule(null);
          // user confirmed deletion
          for (const selectedModule of selectedModules) {
            if (selectedModule && typeof selectedModule.id === 'number') {
              // same atom can be associated with multiple categories, in which case it will appear more than once
              if (deleteObj.selectedIds.find(id => id === selectedModule.id) === undefined) {
                deleteObj.selectedIds.push(selectedModule.id);
                this.logger.debug(`toDelete: ${selectedModule.id}`);
              }
            }
          }
          // if there is data to delete, call backend to delete data
          if (deleteObj.selectedIds && deleteObj.selectedIds.length > 0) {
            const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
            const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
            const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
            if (orgId && accountId && vpubId) {
              this.deleteModules(orgId, accountId, vpubId, deleteObj);
            }
            else {
              this.logger.warn(`Required params are not set ${orgId}:${accountId}:${vpubId}`);
            }
          }
        }
      }
    });
  }

  private alertDialog(strTitle: string, msg: string, submsg: string | null, msgType: CustomMessageType): Observable<boolean> {
    return this.dialogService.alertDialog({
      title: strTitle,
      message: msg,
      submessage: submsg,
      type: msgType
    });
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for orgId:' + orgId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          this.dataSource.data = data;
          this.logger.debug(data);
          this.treeControl.dataNodes = data;
          this.allSelect = false; // reset the selection
          this.checklistSelection.clear(); // clear the selection
          this.simulatorService.updateModuleList(data);
        }
      });
  }

  private getId(strId: string): number | null {
    const idString: string | null | undefined = this.activatedRoute.snapshot.paramMap.get(strId);
    this.logger.debug(idString);

    if (idString) {
      if (isNaN(+idString)) {
        return null;
      }
    }
    else {
      return null;
    }
    return +idString;
  }

  private confirmDialog(msg: string): Observable<boolean> {
    return this.dialogService.confirmDialog({
      title: 'Please confirm action',
      message: msg,
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    });
  }

  private deleteModules(orgId: number, accountId: number, vpubId: number, delObj: AdxModule) {
    this.logger.debug(`orgId: ${orgId}`);
    this.logger.debug(`accountId: ${accountId}`);
    this.logger.debug(`vpubId: ${vpubId}`);
    this.logger.debug(`delObj: ${delObj.selectedIds}`);
    this.moduleService.deleteModules(orgId, accountId, vpubId, delObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Deletion Successful.`);
          this.fetchModules(orgId, accountId, vpubId);
        }
      });
  }
}
