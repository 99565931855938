import {Injector} from '@angular/core';

export class AdxInjector {

  private static injector: Injector;

  static setInjector(injector: Injector): void {
    AdxInjector.injector = injector;
  }

  static getInjector(): Injector {
    return AdxInjector.injector;
  }
}
