import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AdxNotification } from 'src/app/common/model/notification/adx-notification.model';
import { AdxNotificationService } from 'src/app/common/services/adx-notification/adx-notification.service';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { CustomMessageType } from 'src/app/core/service/notifier/custom-message-type';
import { CustomNotifierService } from 'src/app/core/service/notifier/custom-notifier.service';

/**
 * This disalog is used to send notification
 */
@Component({
  selector: 'app-adx-send-notification-dialog',
  templateUrl: './adx-send-notification-dialog.component.html',
  styleUrls: ['./adx-send-notification-dialog.component.scss']
})
export class AdxSendNotificationDialogComponent extends AdxBaseTemplate implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  sendNotificationForm: FormGroup; // form
  deliveryTypes: {label: string; value: string} [] = [{label: 'PUSH', value: 'PUSH_NOTIFICATION'}];

  constructor(
    private readonly notificationService: AdxNotificationService,
    private readonly messageNotifier: CustomNotifierService,
    public dialogRef: MatDialogRef<AdxSendNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData:
      { orgId: number; accountId: number; applicationId: number; vpubId: number }
      ) {
    super();
    this.sendNotificationForm = new FormGroup({
      title: new FormControl<string>('', {updateOn: 'blur', validators: [Validators.required]}),
      content: new FormControl<string>('', {updateOn: 'blur', validators: [Validators.required]}),
      deliveryType: new FormControl<string>('PUSH_NOTIFICATION', {})
    });
  }

  ngOnInit(): void {
  }

  /**
   * Callback to determine whether send button should be displaged
   * @returns boolean: true if form is not dirty and form is invalid
   */
  disableSendBtn(): boolean {
    return !this.sendNotificationForm.dirty && !this.sendNotificationForm.valid;
  }

  /**
   * Callback used to close the dialog, without sending notification.
   */
  close(){
    this.dialogRef.close(false);
  }

  /**
   * Callback used to send notification using backend service.
   * @returns void
   */
  sendNotification(): void {
    if (!this.sendNotificationForm.dirty && !this.sendNotificationForm.valid) {
      return;
    }
    const notificationToSend = new AdxNotification(null);
    notificationToSend.organizationId = this.inputData.orgId;
    notificationToSend.accountId = this.inputData.accountId;
    notificationToSend.applicationId = this.inputData.applicationId;
    notificationToSend.vpubId = this.inputData.vpubId;
    const titleElement = this.sendNotificationForm.get('title');
    if(titleElement && titleElement.dirty) {
      notificationToSend.title = titleElement.value;
    }

    const contentElement = this.sendNotificationForm.get('content');
    if(contentElement && contentElement.dirty) {
      notificationToSend.content = contentElement.value;
    }

    const typeElement = this.sendNotificationForm.get('deliveryType');
    if(typeElement && typeElement.dirty) {
      notificationToSend.deliveryType = typeElement.value;
    }

    this.notificationService.sentNotification(notificationToSend).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          //close the dialog
          this.dialogRef.close(true);
         },
         error: () => {
          //close the dialog
          this.dialogRef.close(false);
         },
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Notification Sent Successfully.`);
        }
      });
  }

}
