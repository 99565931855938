import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxImageLibraryComponent } from './adx-image-library.component';
import { AdxImageUploaderModule } from '../adx-image-uploader/adx-image-uploader.module';
import { AdxImageLibraryDirective } from './directives/adx-image-library.directive';
import {MaterialModule} from '../../../common/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    AdxImageLibraryComponent,
    AdxImageLibraryDirective
  ],
    imports: [
        CommonModule,
        AdxImageUploaderModule,
        MaterialModule,
        ReactiveFormsModule
    ],
  exports: [
    AdxImageLibraryDirective,
    AdxImageLibraryComponent
  ]
})
export class AdxImageLibraryModule { }
