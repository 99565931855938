import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Account} from '../account/model/account.model';
import { AdxBaseTemplate } from '../common/template/adx-base-template';
import { Subscription } from 'rxjs';
import { AuthenticatedUser } from '../common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from '../auth/service/auth-notifier.service';
import { CommonUtility } from '../common/utils/common-utils';
import { AdxAuthUtils } from '../auth/utils/adx-auth-utils';
import { AdxChannelService } from './service/adx-channel.service';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  orgId: number | null = null;
  accountId: number | null = null;
  accountName: string | null = '';

  private authSubscription: Subscription | undefined;

  constructor(private authNotifier: AuthNotifierService, private activatedRoute: ActivatedRoute,
    private readonly channelService: AdxChannelService) {
    super();
  }

  ngOnInit(): void {
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute);
    const selectedAccount: Account = this.activatedRoute.snapshot.data.account;

    if (this.orgId && selectedAccount && selectedAccount.id) {
      this.accountId = selectedAccount.id;
    }
    else {
      this.logger.warn(`Data error ${selectedAccount} ${this.orgId}`);
    }

    if (selectedAccount) {
      this.accountName = selectedAccount.title;
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that determines whether logged in user is admin
   * @returns boolean : true - if logged-in user is account admin
   */
  isUserAccountAdmin(): boolean {
    if (this.currentUser === undefined || this.currentUser === null) {
      return false;
    }
    if (this.orgId === undefined || this.orgId === null) {
      return false;
    }
    if (this.accountId === undefined || this.accountId === null) {
      return false;
    }

    return this.channelService.isUserAccountAdmin(this.orgId, this.accountId, this.currentUser);
  }

}
