<div class="common-container module-list-containerboundary" #boundary>
  <div class="module-list-container" fxLayout="column" fxFlex="100" #spacer1>
    <div class="row">
      <div fxFlex="100" class="vpubTitleDiv">
          <p i18n="@@vpubTitle" class="vpubTitleP">{{vpubTitle}}</p>
      </div>
    </div>
    <div class="common-container">
    <mat-tab-group class="common-tab-group" [selectedIndex]="0">

      <mat-tab label="Modules">
        <ng-template matTabContent>
        <router-outlet></router-outlet>
        </ng-template>
      </mat-tab>

      <mat-tab label="Content Reports">
        <ng-template matTabContent>
          <app-adx-content-report [displayLevel]="vPubDisplayLevel" [orgId]="orgId"
                                  [accountId]="accountId" [vpubId]="vPubId">
          </app-adx-content-report>
        </ng-template>
      </mat-tab>

      <mat-tab label="Notifications">
        <ng-template matTabContent>
          <app-adx-notification-list [orgId]="orgId" [accountId]="accountId" [applicationId]="applnId" [vpubId]="vPubId"></app-adx-notification-list>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
    </div>

  </div>

  <div *ngIf="this.selectedAccount?.simulatorDisplayProp !== null && this.selectedAccount?.simulatorDisplayProp?.value === 'true'" class="module-simulator-container" stickyThing [spacer]="spacer1" [boundary]="boundary" [enable]="true">
    <app-adx-simulator [app]="appln"></app-adx-simulator>
  </div>
</div>
