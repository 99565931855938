import {AdxModuleType} from './adx-module-type';
import {AdxResource} from '../../core/service/resource/adx-resource.model';

export class AdxModuleAddRequest extends AdxResource {

  title: string | null = null;
  numOfModuleMap: Map<AdxModuleType, number> = new Map<AdxModuleType, number>();

  constructor(id: number | null) {
    super(id);
  }
}
