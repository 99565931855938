import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 * Model class for Locale
 */
export class AdxLocale extends AdxResource {

  code: string | null = null;
  displayName: string | null = null;
  displayLanguage: string | null = null;
  enabled = true;

  constructor(id: number | null, strCode: string | null,
              strDisplayName: string | null, strDisplayLang: string | null, bEnabled: boolean) {
    super(id);
    this.code = strCode;
    this.displayName = strDisplayName;
    this.displayLanguage = strDisplayLang;
    this.enabled = bEnabled;
  }

  static buildObject(json: any): AdxLocale {
    let toRet: AdxLocale = new AdxLocale(null, null, null, null, true);
    if (json) {
      let id: number | null = null;
      let strCode: string | null = null;
      let strDispName: string | null = null;
      let strDispLang: string | null = null;
      let bEnabled = true;
      if (json.id) {
        id = json.id;
      }
      if (json.enabled) {
        bEnabled = json.enabled;
      }
      if (json.code) {
        strCode = json.code;
      }
      if (json.displayName) {
        strDispName = json.displayName;
      }
      if (json.displayLanguage) {
        strDispLang = json.displayLanguage;
      }
      toRet = new AdxLocale(id, strCode, strDispName, strDispLang, bEnabled);
    }
    return toRet;
  }
}
