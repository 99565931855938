import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

/**
 * Service deatling with JWT
 * It has methods for encoding and decoding
 * Uses jwt_decode library
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AdxJwtTokenService {

  jwtToken: string | null = null;
  decodedToken: { [key: string]: string } | undefined;

  constructor() {
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken() {
    if (this.jwtToken) {
      return jwt_decode(this.jwtToken);
    }
    return;
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.displayname : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expTime = this.getExpiryTime();
    if (expTime !== undefined && expTime !== null) {
      const expiryTime: number = Number.parseFloat(expTime);
      if (expiryTime) {
        return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
      } else {
        return false;
      }
    }
    return false;
  }
}

