import {AdxCountry} from '../model/country/adx-country.model';

export class AdxCountryUtility {

  constructor() {
  }

  static getSupportedCountries(): Array<AdxCountry> {
    const countries: Array<AdxCountry> = new Array<AdxCountry>();
    countries.push(new AdxCountry('USA', 'United States'));
    countries.push(new AdxCountry('IND', 'India'));
    countries.push(new AdxCountry('FRA', 'France'));
    countries.push(new AdxCountry('CAN', 'Canada'));
    return countries;
  }
}
