import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AdxRestService } from 'src/app/core/service/resource/adx-rest-service.service';
import { AdxNotification } from '../../model/notification/adx-notification.model';
import { AdxNotificationSerializer } from '../../serializer/notification/adx-notification.serializer';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AdxQueryParams } from 'src/app/core/service/resource/adx-query-params.model';

/**
 * This Service ise used for Notification functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class AdxNotificationService {

  private serializer: AdxNotificationSerializer = new AdxNotificationSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxNotification, AdxNotification>) { }

  /**
   * API to fetch the notifications from backend.
   *
   * @param orgId: number - org Id
   * @param accountId: number - account Id
   * @param applicationId: number - application Id
   * @param vpubId: number - vpub Id
   * @returns Observable
   */
  getNotifications(orgId: number| null, accountId: number | null, applicationId: number | null,
    vpubId: number | null): Observable<AdxNotification[]> {

    this.logger.debug(`Getting notifications for orgId:${orgId} ::
    accountId:${accountId} :: applicationId:${applicationId} :: vpubId:${vpubId}`);

    const resourceUrl = `notifications`;
    const queryParams = new AdxQueryParams();
    if (vpubId !== undefined && vpubId !== null) {
      queryParams.addQueryParam('vpubId', `${vpubId}`);
    }
    else if (applicationId !== undefined && applicationId !== null) {
      queryParams.addQueryParam('appId', `${applicationId}`);
    }
    else if (accountId !== undefined && accountId !== null) {
      queryParams.addQueryParam('acctId', `${accountId}`);
    }
    else if (orgId !== undefined && orgId !== null) {
      queryParams.addQueryParam('orgId', `${orgId}`);
    }
    else {
      const message =
        'Something went wrong while fetching the Notifications. Please try again';
      this.logger.error('Required parameters NOT specified');
      throwError(() => new Error(message));
      return of([]);
    }

    return this.restService.list(resourceUrl, queryParams, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /**
   * API to send notification
   *
   * @param notificationRequest: AdxNotification model object
   * @returns Observable with the AdxNotification model
   */
  sentNotification(notificationRequest: AdxNotification): Observable<AdxNotification>  {
    this.logger.debug('sending notification for vpubId:' + notificationRequest.vpubId);
    const resourceUrl = `notifications`;

    return this.restService.create(resourceUrl, notificationRequest, null, this.serializer);
  }
}
