import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticatedUser } from '../../common/model/adx-auth/authenticated-user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthNotifierService {

  authSubject = new Subject<AuthenticatedUser | null>();

  constructor() { }

  /**
   * Notify listerens about updated object
   *
   * @param auth AuthenticatedUser object
   */
  public notifyUserUpdate(auth: AuthenticatedUser): void {
    this.authSubject.next(auth);
  }

  /**
   * Notify listerens about login
   *
   * @param auth AuthenticatedUser object
   */
  public notifyUserLogin(auth: AuthenticatedUser): void {
    this.authSubject.next(auth);
  }

  /**
   * Notify listerens about logout
   */
  public notifyUserLogout(): void {
    this.authSubject.next(null);
  }

}
