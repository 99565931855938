<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree display-data">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <div class="container-fluid">
        <div class="row">
          <div fxFlex="10" class="col">
            <img class="sim-icon" [src]="node.iconImage && node.iconImage.preSignedUrl?
                          node.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
          </div>
          <div fxFlex="70" class="col">
            <div fxFlex="90" class="col">
              <div class="nodeTitle">
                {{ node.title }}
              </div>
              <div class="nodeSummary">
                {{ node.summary }}
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node ">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="container-fluid">
          <div class="row">
            <div fxFlex="10" class="col">
              <img class="sim-icon" [src]="node.iconImage && node.iconImage.preSignedUrl?
                          node.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
            </div>
            <div fxFlex="70" class="col">
              <div fxFlex="90" class="col">
                <div class="nodeTitle">
                  {{ node.title }}
                </div>
                <div class="nodeSummary">
                  {{ node.summary }}
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
