<div class="container">
  <div class="flex-container" fxLayout="column">
    <div class="fixed-bar">
      <mat-toolbar class="common-vpub-label">
        <span matTooltip="{{accountTitle}}">{{accountTitle}}</span>
      </mat-toolbar>
    </div>
    <div class="common-container">
      <mat-tab-group class="common-tab-group" [selectedIndex]="0">

        <mat-tab label="vPubs">
          <ng-template matTabContent>
          <router-outlet></router-outlet>
          </ng-template>
        </mat-tab>

        <mat-tab label="Transfer Status">
          <ng-template matTabContent>
          <app-adx-data-transfer [displayLevel]="vpubLevelDisplay" [orgId]="orgId" [applicationId]="applnId"></app-adx-data-transfer>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>
