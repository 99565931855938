import { Injectable, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AdxRestService } from '../../../core/service/resource/adx-rest-service.service';
import { AdxDataTransfer } from '../../model/data-transfer/adx-data-transfer-model';
import { Observable, of, throwError } from 'rxjs';
import { AdxQueryParams } from '../../../core/service/resource/adx-query-params.model';
import { catchError } from 'rxjs/operators';
import { AdxDataTransferSerializer } from '../../serializer/data-transfer/adx-data-transfer-serializer';

@Injectable({
  providedIn: 'root'
})
export class AdxDataTransferService {

  serializer: AdxDataTransferSerializer = new AdxDataTransferSerializer();

  constructor (private logger: NGXLogger, private restService: AdxRestService<AdxDataTransfer, AdxDataTransfer>) { }

  /*
   * This API is used to fetch the data transfer status from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getDataTransferStatus(orgId: number, accountId: number | null, applicationId: number | null,
    vpubId: number | null): Observable<AdxDataTransfer[]> {

    this.logger.debug('Getting data transfer Status for orgId:' + orgId + ' ; appId:' + accountId);
    const resourceUrl = `orgs/${orgId}/datatransfer/requests`;
    const queryParams = new AdxQueryParams();
    if (vpubId !== undefined && vpubId !== null) {
      queryParams.addQueryParam('vpubId', `${vpubId}`);
    }
    else if (applicationId !== undefined && applicationId !== null) {
      queryParams.addQueryParam('appId', `${applicationId}`);
    }
    else if (accountId !== undefined && accountId !== null) {
      queryParams.addQueryParam('acctId', `${accountId}`);
    }
    else {
      const message =
        'Something went wrong while fetching the Data Transfer Status. Please try again';
      this.logger.error('Required parameters NOT specified');
      throwError(() => new Error(message));
      return of([]);
    }

    return this.restService.list(resourceUrl, queryParams, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the data transfer status from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getFormSubmissionReportStatus(orgId: number, accountId: number, applicationId: number,
     vpubId: number, moduleId: number): Observable<AdxDataTransfer[]> {

    this.logger.debug('Getting data form ubmission report Status for orgId:' + orgId + ' ; appId:' + accountId);
    const resourceUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${moduleId}/form/submissions/reports/requests`;
    const queryParams = new AdxQueryParams();

    return this.restService.list(resourceUrl, queryParams, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the data transfer status from backend.
   * Returns an observable. Throws exception in case of error.
  */
  initiateFormSubmissionReportStatus(orgId: number, accountId: number, applicationId: number,
    vpubId: number, moduleId: number): Observable<AdxDataTransfer> {

   this.logger.debug('Getting data form ubmission report Status for orgId:' + orgId + ' ; appId:' + accountId);
   const resourceUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${moduleId}/form/submissions/reports/requests`;

   const input = new AdxDataTransfer(null);
   input.accountId = accountId;
   input.applicationId = applicationId;
   input.vpubId = vpubId;
   input.moduleId = moduleId;

    if (orgId === undefined || orgId === null || accountId === undefined || accountId === null
      || applicationId === undefined || applicationId === null || vpubId === undefined
      || vpubId === null || moduleId === undefined || moduleId === null) {
      const message =
        'Something went wrong while creating the Form Submission Report. Please try again';
      this.logger.error('Required parameters NOT specified');
      throwError(() => new Error(message));
    }

    return this.restService.create(resourceUrl, input, null, this.serializer);
  }

}
