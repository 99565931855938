import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxUserListingComponent } from './adx-user-listing/adx-user-listing.component';
import { MaterialModule } from '../common/material/material.module';
import { AdxUserAddComponent } from './adx-user-add/adx-user-add.component';
import { AdxUserDetailsComponent } from './adx-user-details/adx-user-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AdxUserRoleAddComponent } from './adx-user-role-add/adx-user-role-add.component';
import { AdxUserManageComponent } from './adx-user-manage.component';
import { AdxUserSearchComponent } from './adx-user-search/adx-user-search.component';
import { AdxUserProfileEditComponent } from './adx-user-profile-edit/adx-user-profile-edit.component';
import { AdxUserRoleEditComponent } from './adx-user-role-edit/adx-user-role-edit.component';
import { AdxUserRoleListComponent } from './adx-user-role-list/adx-user-role-list.component';

/**
 * UserManagement module
 */
@NgModule({
  declarations: [
    AdxUserListingComponent,
    AdxUserAddComponent,
    AdxUserDetailsComponent,
    AdxUserRoleAddComponent,
    AdxUserManageComponent,
    AdxUserSearchComponent,
    AdxUserProfileEditComponent,
    AdxUserRoleEditComponent,
    AdxUserRoleListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    AdxUserManageComponent
  ]
})
export class AdxUserManageModule { }
