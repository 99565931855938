import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AdxQueryParams} from '../../core/service/resource/adx-query-params.model';
import {catchError} from 'rxjs/operators';
import {AdxArsSerializer} from '../model/adx-ars-serializer';
import {AdxArs} from '../model/adx-ars-model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

/**
 * Service class to interact with ARS module in backend producer application
 */
@Injectable({
  providedIn: 'root'
})
export class AdxArsService {

  isArsHasAtoms  = false;
  activeModuleLength$: BehaviorSubject<any>;

  private serializer: AdxArsSerializer = new AdxArsSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxArs, AdxArs>) {

    // created the Subject that emits its current value whenever it is subscribed to.
    // and this value will be updated everytime there is is a call to fetchAtoms API
    // in ars listing screen and emits the updated value to its subscribers
    this.activeModuleLength$ = new BehaviorSubject<any>(this.isArsHasAtoms);

   }

  /*
   * This API is used to fetch the list of Accounts from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getAtoms(orgId: number, accountId: number, vpubId: number, mdlId: number): Observable<AdxArs[]> {
    this.logger.debug('Getting atoms for orgId:' + orgId + ' ; accountId:' + accountId);
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms`;
    return this.restService.list(restUrl, new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the Account from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getArs(orgId: number, accountId: number, vpubId: number, mdlId: number, id: number): Observable<AdxArs> {

    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms`;
    return this.restService.read(restUrl, id, null, this.serializer).pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }


  /*
   * This API is used to add Account to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addArs(orgId: number, accountId: number, vpubId: number, mdlId: number, ars: AdxArs): Observable<AdxArs>{
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms`;
    return this.restService.create(restUrl, ars, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Ars:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /*
   * This API is used to update Account in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateArs(orgId: number, accountId: number, vpubId: number, mdlId: number, ars: AdxArs): Observable<AdxArs> {
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms/${ars.id}`;
    return this.restService.update(restUrl, ars, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Ars:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * This API is used to delete atom.
   * Returns an observable. Throws exception in case of error.
   *
   */
  deleteAtom(orgId: number, accountId: number, vpubId: number, mdlId: number, ars: AdxArs): Observable<AdxArs> {
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms/${ars.id}`;
    return this.restService.delete(restUrl, null).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * API used to delete multiple atoms
   *
   * @param orgId - organization id
   * @param accountId - account id
   * @param vpubId - vpub id
   * @param mdlId - module id
   * @param delObj - object with list of ids
   */
  deleteAtoms(orgId: number, accountId: number, vpubId: number, mdlId: number, delObj: AdxArs): Observable<boolean> {
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms/delete-selected`;
    return this.restService.bulkDelete(restUrl, delObj, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while deleting Ars:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * API used to update multiple atoms
   *
   * @param orgId - organization id
   * @param accountId - account id
   * @param vpubId - vpub id
   * @param mdlId - module id
   * @param inputObj - object with list of ids
   */
  changeVisibilityOfAtoms(orgId: number, accountId: number, vpubId: number, mdlId: number, inputObj: AdxArs): Observable<boolean> {
    const restUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${mdlId}/atms/visibility-update`;
    return this.restService.bulkUpdate(restUrl, inputObj, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while deleting Ars:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * API that verifies whether the user has permission to view the atom.
   *
   * @param orgId : number
   * @param acctId : number
   * @param applnId : number
   * @param vpubId : number
   * @param moduleId : number
   * @param currentUser : AthenticatedUser
   * @returns boolean - true if user has permission to view atom
   */
  public canUserViewAtoms(orgId: number, acctId: number, applnId: number, vpubId: number,
    moduleId: number, currentUser: AuthenticatedUser): boolean {
   //if not logged-in send false
   if (currentUser === undefined || currentUser === null) {
     return false;
   }
   //if system or instance admin, return true
   if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
     this.logger.debug('sys/inst admin user, allowed to edit');
     return true;
   }
   if (currentUser !== undefined && currentUser !== null
     && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
     //first check for org admin
     const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
     if (isOrgAdmin) {
       return true;
     }

     //now check for account admin
     const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
     if (isAccountAdmin) {
       return true;
     }

     //now check for application admin
     const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
     if (isApplnAdmin) {
       return true;
     }

     //now check for vpub admin
     const isVpubAdmin = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser);
     if (isVpubAdmin) {
       return true;
     }

     //now check for module admin
     const isModuleAdmin = AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser);
     if (isModuleAdmin) {
      this.logger.debug(`ModuleId ${moduleId} in modue admin list, returning true`);
       return true;
     }

     //now check for whether module id in accessible list
     const accessibleModuleIds = this.getAccessiblModuleIdsForUser(currentUser);
     if (accessibleModuleIds !== undefined && accessibleModuleIds !== null && accessibleModuleIds.length > 0) {
      const foundModuleId = accessibleModuleIds.find(modId => modId === moduleId);
      if (foundModuleId !== undefined && foundModuleId !== null) {
        this.logger.debug(`ModuleId ${moduleId} in accessible list, returning true`);
        return true;
      }
     }
   }
   return false;
  }

  /**
   * API that verifies whether the user has permission to add the atom.
   *
   * @param orgId : number
   * @param acctId : number
   * @param applnId : number
   * @param vpubId : number
   * @param moduleId : number
   * @param currentUser : AthenticatedUser
   * @returns boolean - true if user has permission to view module
   */
  public canUserAddAtom(orgId: number, accountId: number, applnId: number, vpubId: number,
     moduleId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to add');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub admin
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser)) {
        //means input vpubid is present in vpub-admin permission list for user. return true
        this.logger.debug('user has vpub admin permission');
        return true;
      }

      //now check for module admin
      if (AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser)) {
        //means input moduleId is present in module-admin permission list for user. return true
        this.logger.debug('user has module admin permission');
        return true;
      }

      //now check for atom add permission
      if (AdxAuthUtils.hasRoleForGivenId(moduleId, 'ATOM_ADD', currentUser)) {
        //means input moduleId is present in module-admin permission list for user. return true
        this.logger.debug('user has atom add permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to delete atom
   * user with org/account/application/vpub admin/module admin or atom delete permission will be able to delete atom.
   * If user has system admin or instance admin role, then he will be able to delete module
   *
   * @param orgId : number organization id
   * @param accountId : number account id
   * @param applnId : number application id
   * @param vpubId : number vpub id
   * @param moduleId : number
   * @param currentUser : AuthenticatedUser logged in user
   * @returns boolean if user is able to edit atom
   */
  public canUserDeleteAtom(orgId: number, accountId: number, applnId: number, vpubId: number,
     moduleId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub admin
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser)) {
        //means input vpubid is present in vpub-admin permission list for user. return true
        this.logger.debug('user has vpub admin permission');
        return true;
      }

      //now check for module admin
      if (AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser)) {
        //means input vpubId is present in module-admin permission list for user. return true
        this.logger.debug('user has module admin permission');
        return true;
      }

      //now check for atom delete
      if (AdxAuthUtils.hasRoleForGivenId(moduleId, 'ATOM_DELETE', currentUser)) {
        //means input vpubId is present in module-delete permission list for user. return true
        this.logger.debug('user has module delete permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to edit atom
   * user with org admin or account admin or application admin or vpub admin or module admin or atom edit permission
   * will be able to edit atom.If user has system admin or instance admin role, then he will be able to edit atom
   *
   * @param orgId organization id
   * @param acctId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param moduleId module id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit vpub
   */
  public canUserEditAtom(orgId: number, acctId: number, applnId: number, vpubId: number,
     moduleId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for application admin
      const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
      if (isApplnAdmin) {
        return true;
      }

      //now check for vpub admin
      const isVpubAdmin = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser);
      if (isVpubAdmin) {
        return true;
      }

      //now check for module admin
      const isModuleAdmin = AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser);
      if (isModuleAdmin) {
        return true;
      }

      //now check for atom edit
      const isModuleEdit = AdxAuthUtils.hasRoleForGivenId(moduleId, 'ATOM_EDIT', currentUser);
      if (isModuleEdit) {
        return true;
      }
    }
    return false;
  }

  /*
   * Utility method get module ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organization/account/application/vpub admin or
   * organization/account/application/vpub readonly, then he can view all modules. That need to be handled by the caller method.
   * This method will only return module ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of modules accessible by user
   */
  private getAccessiblModuleIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleModuleIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleModules !== undefined && accessibleEntities.accessibleModules !== null) {
        userAccessibleModuleIds = accessibleEntities.accessibleModules;
      }
    }
    this.logger.debug(userAccessibleModuleIds);
    return userAccessibleModuleIds;
  }

}
