
<button mat-icon-button routerLink='/auth/login' class="example-icon loginBtn" *ngIf="!isUserAuthenticated">
  <mat-icon>login</mat-icon>
</button>
<button mat-stroked-button color="primary" class="example-icon accountBtn" id="input-account-btn" *ngIf="isUserAuthenticated" [matMenuTriggerFor]="usermenu" #menuTrigger>
  Account <mat-icon>person</mat-icon>
</button>
<mat-menu #usermenu="matMenu" class="matMenu">
  <div class="user-details">
    <h2 class="authenticatedUser">{{authenticatedUser?.firstname}}</h2>
    <h3 class="authenticatedUsername" matTooltip="{{ authenticatedUser?.username }}">{{authenticatedUser?.username}}</h3> 
    <div class="lastLogin" *ngIf="authenticatedUser?.lastLogin">
        Last Logged in on: <br/>
        <strong>{{authenticatedUser?.lastLogin | date:'long'}}</strong>
    </div>
  </div>
  <button mat-menu-item (click)="logout(authenticatedUser)" id="input-logout-btn">Logout</button>
</mat-menu>
