<div class="common-container add-account-container">
    <form class="add-account-form" [formGroup]="addAccountForm">
        <div class="flex-container add-account" fxLayout="column">
            <div fxLayout="row">
                <div class='input-label' fxFlex="10">
                </div>
                <div class="account-name-input" fxFlex="80">
                     <mat-form-field class="input-form">
                        <input matInput id="input-add-account-name" i18n="@@addAccountForm.name" #addAccountFormname maxlength="150" formControlName="accountTitle" placeholder="Title" required>
                        <mat-error>
                          This field is required
                        </mat-error>
                    </mat-form-field>
                    <mat-hint class="hintend">{{addAccountFormname.value.length}} / 150</mat-hint>
                </div>
                <div fxFlex="10"> &nbsp; </div>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="row">
                <div class="input-label" fxFlex="10">
                </div>
                <div class="input-field" fxFlex="80">
                    <mat-form-field class="input-form">
                        <input type='text' id="input-add-account-summary" matInput i18n="@@addAccountForm.summary" #addAccountFormsummary maxlength="150" placeholder="Summary" formControlName="summary" />
                        <mat-hint align="end">{{addAccountFormsummary.value.length}} / 150</mat-hint>
                    </mat-form-field>
                </div>
                <div fxFlex="10">
                </div>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="row" class="address-container">
              <div fxFlex="10" class="container-label">
              </div>
              <div fxFlex="90" class="common-desc-editor">
                <div class="common-address-editor" formGroupName='address'>
                  <mat-label i18n="@@addAccountForm.address">Address</mat-label>
                  <mat-form-field class="street1">
                    <input matInput #addAccountFormstreet1 maxlength="80" id="input-add-account-street1" i18n="@@editOrgForm.street1" formControlName="street1" placeholder="Street1">
                    <mat-hint align="end">{{addAccountFormstreet1.value.length}} / 80</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="street2">
                    <input matInput #addAccountFormstreet2 maxlength="80" id="input-add-account-street2" i18n="@@editOrgForm.street2" formControlName="street2" placeholder="Street2">
                    <mat-hint align="end">{{addAccountFormstreet2.value.length}} / 80</mat-hint>
                  </mat-form-field>
                  <div fxLayout="column" class="city-state-details">
                    <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                      <mat-form-field class="city">
                        <input matInput #addAccountFormcity maxlength="50" id="input-add-account-city" i18n="@@editOrgForm.city" formControlName="city" placeholder="City">
                        <mat-hint align="end">{{addAccountFormcity.value.length}} / 50</mat-hint>
                      </mat-form-field>
                      <mat-form-field class="state">
                        <input matInput #addAccountFormstate maxlength="50" id="input-add-account-state" i18n="@@editOrgForm.state" formControlName="state" placeholder="State">
                        <mat-hint align="end">{{addAccountFormstate.value.length}} / 50</mat-hint>
                      </mat-form-field>
                    </div>
                    <div fxLayoutAlign="space-between center zipcode-country-details" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                      <mat-form-field class="zipcode">
                        <input matInput #addAccountFormzipcode maxlength="6" id="input-add-account-zipcode" i18n="@@editOrgForm.zipcode" formControlName="zipcode" placeholder="Zipcode">
                        <mat-hint align="end">{{addAccountFormzipcode.value.length}} / 6</mat-hint>
                      </mat-form-field>
                      <mat-form-field class="country">
                        <mat-select formControlName="country" placeholder='Country' id="input-add-account-country">
                          <mat-option *ngFor="let cnt of countries" [value]="cnt.id" id="input-add-account-country-{{cnt.id}}">{{cnt.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="selectTimezone">
                        <mat-select formControlName="timezone" placeholder='Timezone' id="input-add-account-timezone">
                          <mat-option *ngFor="let tz of timezones" [value]="tz.id" id="input-add-account-country-{{tz.id}}">
                            {{tz.abbreviation}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <mat-divider></mat-divider>

          <div class="style-container associateCategories" fxLayout="row" fxLayout.lt-md="row" formGroupName="style">
            <div class="common-label" fxFlex="10" fxLayout="column">
            </div>
            <div class="input-label" fxLayout="column" fxFlex="80">
              <mat-label i18n="@@addAccountForm.style">Style</mat-label>
              <table>
                <tr>
                  <th class="numOfTabs">
                    <mat-label i18n="@@addAccountForm.numOfTabs">Number Of Tabs</mat-label>
                  </th>
                  <th class="common-numOfTabs-label">
                    <mat-form-field fxFlex="10" class="style-numOfTabs-input">
                      <input matInput type="number" i18n="@@addAccountForm.numOfTabs"
                             id="input-add-account-numOfTabs"
                             formControlName="numOfTabs" min="1" max="4">
                    </mat-form-field>
                  </th>
                </tr>
              </table>
              <table>
                <tr>
                    <td class='commonTitletd'>Top bar Background color</td>
                    <td class='common-td'>
                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-account-styleTopBarBackgroundColor" formControlName="topBarBackgroundColor">
                        </ngx-colors>
                    </td>
                    <td rowspan="4" class='common-td simulatorLookNFeel'>
                        <div class="flex-container" fxLayout="column" fxLayout.lt-md="column">
                            <div class="simulator-topbar" fxLayout="row" [style.background-color]="topBarBackgroundColor" [style.color]="topBarTextColor">
                                <div class="simulator-topbar-text" fxFlex="100">
                                    Top Bar
                                </div>
                            </div>
                            <div class="simulator-contentbar" fxLayout="row">
                                Conveyer
                            </div>
                            <div class="simulator-navbar" fxLayout="column" [style.background-color]="backgroundColor" [style.color]="backgroundTextColor">
                                <div class="simulator-navbar-icon" fxFlex="100">
                                    <img class="iconImageSimulator" src="assets/images/app-icon.png" alt="image"/>
                                </div>
                                <div class="simulator-navbar-text" fxFlex="100">
                                    Navigation
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class='commonTitletd'>Top bar Text color</td>
                    <td class='common-td'>
                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-account-styleTopBarTextColor" formControlName="topBarTextColor">
                        </ngx-colors>
                    </td>
                </tr>
                <tr>
                    <td class='commonTitletd'>Navigation bar Background color</td>
                    <td class='common-td'>
                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-account-styleBackgroundColor" formControlName="backgroundColor">
                        </ngx-colors>
                    </td>
                </tr>
                <tr>
                    <td class='commonTitletd'>Navigation bar Text color</td>
                    <td class='common-td'>
                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-account-styleBackgroundTextColor" formControlName="backgroundTextColor">
                        </ngx-colors>
                    </td>
                </tr>
            </table>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout="row">
              <div class="common-label" fxFlex="10">
              </div>
              <mat-form-field class="common-desc-editor" fxLayout="column" fxFlex="90">
                <mat-label i18n="@@addAccountForm.desc">Description</mat-label>
                  <textarea matInput placeholder='Description' id="input-add-account-description" #accountDescription
                            [froalaEditor]="froalaEditorOptions(450)" [errorStateMatcher]="adxErrorStateMatcher"
                            (froalaInit)="initializeFroalaEditor($event)" formControlName="accountDesc">
                   </textarea>
                <mat-hint align="end">{{accountDescription.value.length}} / 450</mat-hint>
                <mat-error *ngIf="addAccountForm.get('accountDesc')?.hasError('maxlength')">
                  {{accountDescription.value.length}} exceeds max limit of 450 HTML characters
                </mat-error>
              </mat-form-field>
          </div>

          <mat-divider></mat-divider>

        </div>

        <div class="button-container">
          <button i18n="@@addAccountForm.cancel" mat-raised-button routerLink="/orgs/{{selectedOrg?.id}}/accts" class="button-cancel" id="input-add-account-cancel-Btn">Cancel</button>
          <button i18n="@@addAccountForm.save" mat-raised-button type="submit" [disabled]="!(addAccountForm.valid && addAccountForm.dirty)" id="input-add-account-save-Btn" (click)="save()">Save</button>
        </div>

    </form>
</div>
