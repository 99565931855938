import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxModule} from '../model/adx-module.model';
import {AdxModuleService} from '../service/adx-module.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {blankValidator} from '../../common/utils/form-utility';
import {CommonUtility} from '../../common/utils/common-utils';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Component({
  selector: 'app-adx-edit-module',
  templateUrl: './adx-edit-module.component.html',
  styleUrls: ['./adx-edit-module.component.scss']
})
export class AdxEditModuleComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  editModuleForm: UntypedFormGroup;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.MODULE_IMG_LIB;
  typeId: number | null = null;
  modules: AdxModule[] = [];
  childModules: AdxModule[] = [];
  key = 'id';
  display = 'title';
  visibilityTypes: string[] = ['DISABLED', 'VISIBLE', 'HIDDEN'];
  isModuleGroup = false;
  hasUpdatedChildren = false;
  childSelectionError = false;

  selectedModule: AdxModule | null = null;
  moduleListUrl = '';
  orgId: number | null = null;
  applnId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;
  private authSubscription: Subscription | undefined;

  constructor(private moduleService: AdxModuleService, private readonly authNotifier: AuthNotifierService,
              private readonly messageNotifier: CustomNotifierService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.editModuleForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      }),
      navbarTitle: new FormControl<string | null>(null, {updateOn: 'blur',
        validators: [Validators.maxLength(100)]}),
      icon: new UntypedFormControl(),
      desc: new UntypedFormControl(null, {
        updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(450)
        ]
      }),
      visibility: new UntypedFormControl(),
      sortOrder: new FormControl<number>(0, {updateOn: 'change', validators: [Validators.required]}),
      childModules: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    this.typeId = accountId;
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null = CommonUtility.getId('modId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
      this.orgId = orgId;
    
    //reading the hierarchy id values to pass the same to image library
    this.applnId = applnId; this.vpubId = vpubId; this.moduleId = moduleId;

    if (!orgId || !accountId || !vpubId || !moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }

    this.selectedModule = this.activatedRoute.snapshot.data.modl;

    if (accountId && orgId) {
      this.moduleListUrl = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
    }

    this.editModuleForm.patchValue({
      title: this.selectedModule?.title,
      navbarTitle: this.selectedModule?.navigationBarTitle,
      icon: this.selectedModule?.iconImage,
      desc: this.selectedModule?.description,
      visibility: this.selectedModule?.visibility,
      sortOrder: this.selectedModule?.sortOrder
    });

    if (this.selectedModule?.children && this.selectedModule?.children.length > 0) {
      this.childModules = this.selectedModule?.children;
      this.isModuleGroup = true;
      this.logger.debug(`childModules: ${this.childModules}`);
    }

    // to populate the modules list
    this.fetchModules(orgId, accountId, vpubId);
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that returns true if logged-in user has permission to edit module
   *
   * @returns boolean: true if user can edit module
   */
  canEditModule(): boolean {
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null = CommonUtility.getId('modId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (orgId !== undefined && orgId !== null
      && accountId !== undefined && accountId !== null
      && applnId !== undefined && applnId !== null
      && vpubId !== undefined && vpubId !== null
      && moduleId !== undefined && moduleId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserEditModule(orgId, accountId, applnId, vpubId, moduleId, this.currentUser);
    }
    return false;
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: CommonUtility.getId('vpubId', this.activatedRoute),
      moduleId: CommonUtility.getId('modId', this.activatedRoute),
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.MODULE_IMG_LIB,
      editorRef: undefined,
      applicationId : CommonUtility.getId('applnId', this.activatedRoute),
      atomId : null
    };
    return toRet;
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  saveModule(): void {
    this.logger.debug(this.editModuleForm);
    if (!this.canEditModule()) {
      this.logger.error('logged-in user does not have permission to edit vpub');
      return;
    }
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null | undefined = this.selectedModule?.id;
    if (!orgId || !accountId || !vpubId || !moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }
    const moduleToUpdate: AdxModule = new AdxModule(moduleId);

    if (this.editModuleForm.get('title')?.dirty) {
      moduleToUpdate.title = this.editModuleForm.get('title')?.value;
    }
    if (this.editModuleForm.get('navbarTitle')?.dirty) {
      moduleToUpdate.navigationBarTitle = this.editModuleForm.get('navbarTitle')?.value;
    }
    if (this.editModuleForm.get('desc')?.dirty) {
      moduleToUpdate.description = this.editModuleForm.get('desc')?.value;
    }
    if (this.editModuleForm.get('sortOrder')?.dirty) {
      moduleToUpdate.sortOrder = this.editModuleForm.get('sortOrder')?.value;
    }
    if (this.editModuleForm.get('visibility')?.dirty) {
      moduleToUpdate.visibility = this.editModuleForm.get('visibility')?.value;
    }
    if (this.iconImage) {
      moduleToUpdate.iconImage = this.iconImage;
    }
    if (this.isModuleGroup) {
      // see whether children have changed
      if (this.hasUpdatedChildren) {
        this.logger.debug(`children changed to ${this.childModules.length}`);
        moduleToUpdate.children = this.childModules;
        if (!moduleToUpdate.childrenIds) {
          moduleToUpdate.childrenIds = [];
        }
        for (const child of this.childModules) {
          if (child.id) {
            moduleToUpdate.childrenIds.push(child.id);
          }
        }
      }
    }
    this.updateModule(orgId, accountId, vpubId, moduleToUpdate);
  }

  childrenChanged(): void {
    this.childSelectionError = false; // reset the flag
    // dual list box does not support reactive form.
    // as a workaround set the form to dirty, so that the save button is activated
    // setting the sortOrder as dirty here
    if (!this.childModules || this.childModules.length < 1) {
      this.childSelectionError = true; // set the flag to true, as there should be min of 1 child module
      this.editModuleForm.controls.childModules.setErrors({invalid: true});
      return;
    }
    this.editModuleForm.controls.childModules.setErrors(null); // reset errors
    this.editModuleForm.get('sortOrder')?.markAsDirty();
    this.hasUpdatedChildren = true;
  }

  private updateModule(orgId: number, accountId: number, vpubId: number, moduleToUpdate: AdxModule): void {
    if (orgId && accountId && vpubId && moduleToUpdate) {
      this.moduleService.updateModule(orgId, accountId, vpubId, moduleToUpdate)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Module updated Successfully.`);
          }
        });
    }
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for orgId:' + orgId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          data.forEach(item => {
            if (item && item.id) {
              const isEligible: boolean = this.canBeChild(item, data);
              this.logger.debug(`${item.title} is eligible ${isEligible}`);
              if (isEligible) {
                this.modules.push(item);
              }
            }
          });
          this.modules = [...this.modules, ...this.childModules];
        }
      });
  }

  private canBeChild(currentNode: AdxModule, nodeList: AdxModule[]): boolean {
    if (currentNode && currentNode.id) {

      // if this is same node as selectedNode, return false
      if (this.selectedModule && currentNode.id === this.selectedModule.id) {
        return false;
      }

      // if this is parent node of selectedNode, return false
      if (this.selectedModule && currentNode.id === this.selectedModule.parentId) {
        return false;
      }

      // if this is child or grandchild node of selectedNode, return false
      if (this.selectedModule && this.selectedModule.children && this.selectedModule.children.length > 0) {
        let isChild = false;
        this.selectedModule.children.forEach(child => {
          if (child.id === currentNode.id) {
            isChild = true;
          }
        });
        if (isChild) {
          return false;
        }
        let isGrandChild = false;
        this.selectedModule.children.forEach(child => {
          if (child.children && child.children.length > 0) {
            child.children.forEach(grandChild => {
              if (grandChild.id === currentNode.id) {
                isGrandChild = true;
              }
            });
          }
        });
        if (isGrandChild) {
          return false;
        }
      }

      // only 2 level of modules supported. Hence shortlist items who do not have:
      // grandparent OR grandchildren OR parent & Children both
      if (currentNode.parentId && currentNode.children && currentNode.children.length > 0) {
        this.logger.debug(`current node ${currentNode.title} has father and child`);
        return false; // has both parent and child
      } else if (currentNode.parentId) {
        // get parent and check whether parent has a parent
        const parent = nodeList.find(item => item.id === currentNode.parentId);
        if (parent && parent.parentId) {
          this.logger.debug(`current node ${currentNode.title} has grandfather`);
          return false; // parent has a parent
        }
      } else if (currentNode.children && currentNode.children.length > 0) {
        // if selected module has parent, then only display modules that do not have children
        if (this.selectedModule?.parentId) {
          this.logger.debug(`selected module is child and ${currentNode.title} has children, hence ignore`);
          return false;
        } else {
          // get children and check whether child has children
          let hasGrandChild = false;
          currentNode.children.forEach(child => {
            if (child.children && child.children.length > 0) {
              hasGrandChild = true; // child has child
            }
          });
          if (hasGrandChild) {
            this.logger.debug(`current node ${currentNode.title} has grandchild`);
            return false;
          }
        }
      }
    }
    return true;
  }
}
