import {AdxImage} from '../../common/model/adx-image.model';
import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxBaseSerializer} from '../../core/service/resource/adx-base-serializer';
import {AdxVPub} from './adx-vpub.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';

export class AdxVpubSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxVPub {
    const vpub: AdxVPub = new AdxVPub(json.id, json.accountId);
    if (json.locale) {
      vpub.locale = AdxLocale.buildObject(json.locale);
    }
    if (json.localeId) {
      vpub.localeId = json.localeId;
    }
    if (json.isDefault !== undefined && json.isDefault !== null) {
      vpub.isDefault = json.isDefault;
    }
    if (json.isDraft !== undefined && json.isDraft !== null) {
      vpub.isDraft = json.isDraft;
    }
    if (json.title) {
      vpub.title = json.title;
    }
    if (json.description) {
      vpub.description = json.description;
    }
    if (json.summary) {
      vpub.summary = json.summary;
    }
    if (json.iconImage) {
      vpub.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.createdByUser) {
      vpub.createdByUser = json.createdByUser;
    }
    if (json.updatedByUser) {
      vpub.updatedByUser = json.updatedByUser;
    }
    return vpub;
  }

  toJson(resource: AdxResource): any {
    return JSON.stringify(resource);
  }

}
