<div class="channel-link-container" fxLayout="column" *ngIf="canAssociateChannel()">
  <div class="channelApplBulkAssociation-container">
      <div class="button-container">
        <button mat-raised-button i18n="@@applnList.back" type="button" id="input-channelApplBulkAssociation-back-Btn" routerLink="{{urlForAcctListing}}">
          <mat-icon>keyboard_arrow_left</mat-icon> Accounts</button>
      </div>

      <div class="associate-disassociate-vpubs">
          <button i18n="@@listChannel.add" id="input-channelApplBulkAssociation-associate-Btn" class="channelApplBulkAssociationAssociateBtn" mat-raised-button color="primary" (click)="associate()">Associate</button>
          <button i18n="@@listChannel.add" id="input-channelApplBulkAssociation-disassociate-Btn" class="channelApplBulkAssociationDisassociateBtn" mat-raised-button color="primary" (click)="disAssociate()">Disassociate</button>
      </div>
  </div>

    <div class="channel-list-table-div">
      <table mat-table *ngIf="dataSource !== null"  [dataSource]="dataSource" class="common-list-table">

        <ng-container matColumnDef="Channel">
          <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Channel</th>
          <td i18n="@@name-val" mat-cell *matCellDef="let element" class="channelApplBulkAssociationTitle"> <!--  common-list-channel-td -->
            {{ element.title }}
            <ng-container *ngIf="element.visibility.toLowerCase() ==='visible';  else ChannelHidden">
              <mat-icon class="commonVisibilityIcon">visibility</mat-icon>
            </ng-container>
            <ng-template #ChannelHidden>
              <ng-container *ngIf="element.visibility.toLowerCase() ==='hidden';  else ChannelDisable">
                <mat-icon class="commonVisibilityIcon">visibility_off</mat-icon>
              </ng-container>
            </ng-template>
            <ng-template #ChannelDisable>
              <mat-icon class="commonVisibilityIcon">hide_source</mat-icon>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="Applications">
          <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Applications</th>
          <td i18n="@@name-val" mat-cell *matCellDef="let element" class="channelApplBulkAssociationAppId"> <!-- common-list-application-td-->
            <mat-list>
              <mat-list-item *ngFor="let app of element.applications;last as lastApp">
                {{getAppTitle(app.id) }}
                <ng-container *ngIf="app.visibility.toLowerCase() ==='visible';  else AccountHidden">
                  <mat-icon class="commonVisibilityIcon">visibility</mat-icon>
                </ng-container>
                <ng-template #AccountHidden>
                  <ng-container *ngIf="app.visibility.toLowerCase() ==='hidden';  else AccountDisable">
                    <mat-icon class="commonVisibilityIcon">visibility_off</mat-icon>
                  </ng-container>
                </ng-template>
                <ng-template #AccountDisable>
                  <mat-icon class="commonVisibilityIcon">hide_source</mat-icon>
                </ng-template>
                <mat-divider class="background-divider" *ngIf="!lastApp"></mat-divider>
              </mat-list-item>
            </mat-list>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
      </table>
    </div>
</div>
