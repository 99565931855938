<div>
  <h3>Submit request to generate Content Report</h3>
  <form [formGroup]="createForm" class="contentReport-container">
    <ng-container *ngIf="isVpubDisplayLevel(); else ACCOUNT_LEVEL">
      <mat-form-field>
        <mat-label i18n="@@createReportForm.module">Select ARS Module</mat-label>
        <mat-select formControlName="moduleControl" placeholder='ArsModules' id="input-createReport-module">
          <mat-option *ngFor="let module of arsModules" [value]="module.id" id="input-createReport-module-{{module.id}}">
            {{module.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #ACCOUNT_LEVEL>
      <div>
        <mat-form-field>
          <mat-label i18n="@@createReportForm.types">Choose Report Type</mat-label>
          <mat-select formControlName="typeControl" placeholder='Report Type'
                      id="input-createReport-reportType" (selectionChange)="selectType($event)">
            <mat-option *ngFor="let reportType of reportTypes" [value]="reportType.type"
                        id="input-createReport-reportType-{{reportType.type}}">{{reportType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="isSelARSType">
      <mat-form-field>
        <mat-label i18n="@@createReportForm.applications">Select Application</mat-label>
        <mat-select formControlName="appControl" placeholder='Applications'
                    id="input-createReport-appln" (selectionChange)="loadVpubs($event)">
          <mat-option *ngFor="let app of applications" [value]="app.id" id="input-createReport-appln-{{app.id}}">
            {{app.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div *ngIf="isSelARSType">
        <mat-form-field>
          <mat-label i18n="@@createReportForm.vpubs">Select vPub</mat-label>
          <mat-select formControlName="vpubControl" placeholder='vPubs' id="input-createReport-vPub">
            <mat-option *ngFor="let vpub of vpubs" [value]="vpub.id" id="input-createReport-vPub-{{vpub.id}}">
              {{vpub.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
    <div>
      <mat-form-field>
        <mat-label i18n="@@createReportForm.format">Select Format</mat-label>
        <mat-select formControlName="formatControl" placeholder='vPubs' id="input-createReport-format">
          <mat-option *ngFor="let format of reportFormats" [value]="format" id="input-createReport-vPub-{{format}}">
            {{format}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button mat-raised-button (click)="close()" class="button-cancel" id="input-channelApplBulkAssociation-Close-Btn">Close</button>
      <button mat-raised-button id="input-channelApplBulkAssociation-submit-Btn" [disabled]="disableSubmitBtn()"
              (click)="createContentReport()">Submit</button>
    </div>
  </form>
</div>
