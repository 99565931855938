<div class="container">
    <div class="app-container">
        <!-- Display header -->
        <div *ngIf="showHeader" class='app-header'>
            <app-header></app-header>
        </div>
        <!-- <app-sidebar></app-sidebar> -->
        <div class="app-content">
            <!-- Display content -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<notifier-container></notifier-container>
