import { Component, OnInit } from '@angular/core';
import { AdxUser } from '../model/adx-user-model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';

/**
 * Component used to edit the user profile
 */
@Component({
  selector: 'app-adx-user-profile-edit',
  templateUrl: './adx-user-profile-edit.component.html',
  styleUrls: ['./adx-user-profile-edit.component.scss']
})
export class AdxUserProfileEditComponent extends AdxBaseTemplate implements OnInit {

  isDisabled = true;
  userDetails: AdxUser | null = null;

  constructor() {
    super();
   }

  ngOnInit(): void {
  }

  updateSearchResult(input: AdxUser | null): void {
    this.logger.debug(input);
    const userArray: AdxUser[] = [];
    if (input) {
      this.userDetails = input;
      this.isDisabled = false;
    }
  }

}
