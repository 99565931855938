import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/**
 * This is model class for Image request and response
 */
export class AdxImage extends AdxResource {

  imageLibraryId: number | null;
  preSignedUrl: string | null;
  title: string | null;

  constructor(id: number| null, imgLibId: number| null, imgUrl: string | null, title: string | null) {
      super(id);
      this.imageLibraryId = imgLibId;
      this.preSignedUrl = imgUrl;
      this.title = title;
  }

  static buildObject(json: any): AdxImage {
    return new AdxImage(json.id, json.imageLibraryId, json.preSignedUrl, json.title);
  }
}
