import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxImage} from '../../common/model/adx-image.model';

/**
 * Model corresponding to AtomImageResponse object in backend producer app
 */
export class AdxArsImage extends AdxResource {

  image: AdxImage | null = null;
  caption: string | null = null;
  sortOrder: number | null = null;
  url: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxArsImage {
    const adxArsImage = new AdxArsImage(json.id);
    if (json.image) {
      adxArsImage.image = AdxImage.buildObject(json.image);
    }
    if (json.caption) {
      adxArsImage.caption = json.caption;
    }
    if (json.sortOrder !== undefined || json.sortOrder !== null) {
      adxArsImage.sortOrder = json.sortOrder;
    }
    if (json.url) {
      adxArsImage.url = json.url;
    }
    return adxArsImage;
  }
}
