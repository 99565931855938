import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 * This DTO is used for uploading image file into image library.
 */
export class AdxImageLibraryRequestFile extends AdxResource {
  title: string;
  description: string;
  file: File | null = null;
  isFolder: boolean;
  folderId: number | null = null;
  images: AdxImageLibraryRequestFile[] = [];
  tags : string | null = null;

  constructor(id: number | null, strTitle: string, strDesc: string, isFolder: boolean, folderId: number | null, tags : string) {
    super(id);
    this.title = strTitle;
    this.description = strDesc;
    this.isFolder = isFolder;
    this.folderId = folderId;
    this.tags = tags;
  }

  static buildObject(json: any): AdxImageLibraryRequestFile {
    return new AdxImageLibraryRequestFile(json.id, json.title,
      json.description, json.isFolder, json.folderId, json.tags);
  }
}
