import { Component, OnDestroy, OnInit } from '@angular/core';
import {AdxModuleService} from '../service/adx-module.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxModule} from '../model/adx-module.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CommonUtility} from '../../common/utils/common-utils';
import {AdxWeb} from '../model/adx-web-model';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {Subject, Subscription} from 'rxjs';
import { blankValidator } from 'src/app/common/utils/form-utility';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

@Component({
  selector: 'app-manage-web',
  templateUrl: './manage-web.component.html',
  styleUrls: ['./manage-web.component.scss']
})
export class ManageWebComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedModule: AdxModule | null = null;
  moduleTitle: string | null = null;
  manageWebForm: UntypedFormGroup;
  moduleListUrl = '';

  // Url Pattern for Validation, Reference https://www.regextester.com/94502
  urlPattern = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';

  private authSubscription: Subscription | undefined;

  constructor(private readonly authNotifier: AuthNotifierService, private moduleService: AdxModuleService,
              private readonly messageNotifier: CustomNotifierService,
              private router: Router, private activatedRoute: ActivatedRoute) {
                super();

    this.manageWebForm = new UntypedFormGroup({
      url: new UntypedFormControl(null, {
        updateOn: 'change', validators: [
          Validators.required,
          blankValidator,
          Validators.pattern(this.urlPattern),
          Validators.maxLength(250)
        ]
      })
    });
  }

  ngOnInit(): void {

    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null = CommonUtility.getId('modId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (!orgId || !accountId || !vpubId || !moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.selectedModule = this.activatedRoute.snapshot.data.modl;

    if (accountId && orgId) {
      this.moduleListUrl = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
    }

    if (!this.selectedModule) {
      // error. Unable to display component
      this.logger.debug(`Unable to display component as module details not available`);
      return;
    }

    this.moduleTitle = this.selectedModule.title;
    if (this.selectedModule.web) {
      this.manageWebForm.patchValue({
        url: this.selectedModule.web.endpoint
      });
    }
    else {
      this.logger.error('ERROR: Web Module Not Found.');
    }
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that returns true if logged-in user has permission to save module
   *
   * @returns boolean: true if user has permissions to save current module
   */
  canUserSaveModule(): boolean {
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null = CommonUtility.getId('modId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (orgId !== undefined && orgId !== null
      && accountId !== undefined && accountId !== null
      && applnId !== undefined && applnId !== null
      && vpubId !== undefined && vpubId !== null
      && moduleId !== undefined && moduleId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserSaveModule(orgId, accountId, applnId, vpubId, moduleId, this.currentUser);
    }
    return false;
  }

  save(): void {
    this.logger.debug(this.manageWebForm);
    if (!this.canUserSaveModule()) {
      this.logger.error('logged-in user does not have permission to save module');
      return;
    }
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null | undefined = this.selectedModule?.id;
    if (!orgId || !accountId || !vpubId || !moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }
    const moduleToUpdate: AdxModule = new AdxModule(moduleId);
    if (this.selectedModule && this.selectedModule.web) {
      moduleToUpdate.web = new AdxWeb(this.selectedModule.web.id);
    }

    if (this.manageWebForm.get('url')?.dirty) {
      if (!moduleToUpdate.web || !moduleToUpdate.web.id) {
        moduleToUpdate.web = new AdxWeb(null);
      }
      moduleToUpdate.web.endpoint = this.manageWebForm.get('url')?.value;
    }

    this.updateModule(orgId, accountId, vpubId, moduleToUpdate);
  }

  private updateModule(orgId: number, accountId: number, vpubId: number, moduleToUpdate: AdxModule): void {
    if (orgId && accountId && vpubId && moduleToUpdate) {
      this.moduleService.updateModule(orgId, accountId, vpubId, moduleToUpdate)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Module updated Successfully.`);
          }
        });
    }
  }

}
