import { Router } from '@angular/router';

import { Directive, AfterViewInit, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  // HTML Directive added from DAL code refered to HrefTranslate.directive.ts
  selector: '[htmlTranslate]'
})
export class HtmlTranslateDirective  {
  @Input() set htmlTranslate( value: any){
                                              this.htmlTranslateInternal = value;
                                              this.element.nativeElement.innerHTML = this.htmlTranslate;
                                          }
           get htmlTranslate(){ return this.htmlTranslateInternal; };
  @Input() internalNavigationEnabled = true;
  @Input() externalNavigationEnabled = true;
  htmlTranslateInternal: any | null = null;
  toastMessage: any = null;

  constructor( private element: ElementRef,
               private router: Router,
               private renderer: Renderer2 ) { }

  listeningToClickEvents(){

  };


  closeToastMessage(){

        if( this.toastMessage ){ this.toastMessage.dismiss(); this.toastMessage = null;}
  }

  }//End of directive
