import {AdxResource} from '../../core/service/resource/adx-resource.model';

/**
 * PopupInfo model
 */
export class AdxPopupInfo extends AdxResource {

  title: string | null = null;
  content: string | null = null;
  buttonLabel: string | null = null;
  scope: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxPopupInfo {
    const toRet = new AdxPopupInfo(json.id);
    if (json.title) {
      toRet.title = json.title;
    }
    if (json.content) {
      toRet.content = json.content;
    }
    if (json.buttonLabel) {
      toRet.buttonLabel = json.buttonLabel;
    }
    if (json.scope) {
      toRet.scope = json.scope;
    }
    return toRet;
  }
}
