import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../../core/service/resource/adx-rest-service.service';
import {Observable, of, throwError} from 'rxjs';
import {AdxQueryParams} from '../../../core/service/resource/adx-query-params.model';
import {AdxContentReportSerializer} from '../../serializer/adx-content-report/adx-content-report-serializer';
import {AdxContentReport} from '../../model/adx-content-report/adx-content-report-model';

/**
 * This service is used for Content Reports.
 * It exposes APIs to list and create content reports
 */
@Injectable({
  providedIn: 'root'
})
export class AdxContentReportService {

  serializer: AdxContentReportSerializer = new AdxContentReportSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxContentReport, AdxContentReport>) { }

  /*
   * This API is used to fetch the content report generation status from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getContentReportStatus(orgId: number, accountId: number | null, vpubId: number | null):
    Observable<AdxContentReport[]> {

    this.logger.debug('Getting data transfer Status for orgId:' + orgId + ' ; appId:' + accountId);
    const resourceUrl = `orgs/${orgId}/analytics/reports/requests`;
    const queryParams = new AdxQueryParams();
    if (vpubId !== undefined && vpubId !== null) {
      queryParams.addQueryParam('vpubId', `${vpubId}`);
    }
    else if (accountId !== undefined && accountId !== null) {
      queryParams.addQueryParam('acctId', `${accountId}`);
    }
    else {
      const message =
        'Something went wrong while fetching the Content Report Status. Please try again';
      this.logger.error('Required parameters NOT specified');
      throwError(() => new Error(message));
      return of([]);
    }

    return this.restService.list(resourceUrl, queryParams, null, this.serializer);
  }

  /**
   * This API is used to initiate a content report generation. The backend will return
   * immediately to confirm that request is received. The content report creation is done asynchronously by backend
   *
   * @param orgId
   * @param accountId
   * @param vpubId
   * @param moduleId
   * @param contentReportRequest
   */
  createContentReport(orgId: number, contentReportRequest: AdxContentReport): Observable<AdxContentReport>  {
    this.logger.debug('creating content report for orgId:' + orgId);
    const resourceUrl = `orgs/${orgId}/analytics/reports/requests`;
    if (orgId === undefined || orgId === null) {
      const message =
        'Something went wrong while creating the Content Report Status. Please try again';
      this.logger.error('Required parameters NOT specified');
      throwError(() => new Error(message));
    }

    return this.restService.create(resourceUrl, contentReportRequest, null, this.serializer);
  }
}
