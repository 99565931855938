import {AdxResource} from '../../core/service/resource/adx-resource.model';

export class AdxAuthor extends AdxResource {

  applicationId: number | null = null;

  firstName: string | null = null;

  lastName: string | null = null;

  constructor(id: number | null, applicationId: number | null, fName: string | null, lName: string | null) {
    super(id);
    this.applicationId = applicationId;
    this.firstName = fName;
    this.lastName = lName;
  }

  static buildObject(json: any): AdxAuthor {
    const toRet: AdxAuthor = new AdxAuthor(json.id, json.applicationId, json.firstName, json.lastName);
    return toRet;
  }
}
