<div fxLayout="row">
  <div fxFlex="80">
    <mat-label i18n="@@editOrgForm.locale">Locale</mat-label>
    <dual-list [source]="locales" [(destination)]="selectedLocales" [key]="key" [display]="display"
               (destinationChange)="onSelectionChange($event)">
    </dual-list>
  </div>
  <div fxFlex="18" class="combobox">
    <mat-label i18n="@@editOrgForm.defaultlocale">Default Locale</mat-label>
    <mat-select [(value)]="defaultLocale" class="defaultLocale-combobox"
                [compareWith]="objectComparisonFunction" (selectionChange)="onDefaultChange($event)">
      <mat-option [value]="defaultLocale" *ngIf="defaultLocale">{{defaultLocale.displayName}}</mat-option>
      <mat-option *ngFor="let l of localeOptions" [value]="l">
        {{l.displayName}}
      </mat-option>
    </mat-select>
  </div>
</div>
