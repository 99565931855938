import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  FormControl
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Address } from 'src/app/common/model/address.model';
import { AdxTimezone } from 'src/app/common/model/adx-timezone.model';
import { Organization } from '../model/organization.model';
import { AdxStyle } from 'src/app/common/model/adx-style.model';
import { OrganizationService } from '../service/organization.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxLocaleSelection} from '../../common/model/locale/adx-locale-selection';
import {AdxCountry} from '../../common/model/country/adx-country.model';
import {AdxCountryUtility} from '../../common/utils/adx-country-utility';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseTemplate} from '../../common/template/adx-base-template';
import {AdxErrorStateMatcher} from '../../common/error/AdxErrorStateMatcher';
import {OrganizationUtility} from '../utils/organization-utility';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AdxDialogService } from 'src/app/common/services/dialog/adx-dialog.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  editOrgForm: UntypedFormGroup;

  selectedOrg?: Organization;

  address?: Address;
  timezones: AdxTimezone[] = [];
  locales: AdxLocale[] = [];
  tzMap = new Map<number, AdxTimezone>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorMsg?: string;
  countries?: Array<AdxCountry>;
  orgIconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;
  typeId: number | null = null;
  defaultLocale: AdxLocale | null = null;
  selectedLocales: AdxLocale[] | null = null;

  // Email Pattern for Validation
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  domainPattern = "^[a-zA-Z0-9]{1,}[a-zA-Z0-9\.\-]+\.[a-z]{2,4}$";
  isValidDomain:boolean = true;

  keepSorted = true;
  excludedModules: string[] = [];
  includedModules: string[] = [];
  allModules: string[] = [];
  format: any = { add: 'Exclude Module', remove: 'Include Module', all: 'Select All', none: 'Unselect All',
    direction: 'left-to-right', draggable: true, locale: undefined };
  display: any;
  filter = true;
  excludeModuleDualBoxChanged = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  domainTagList: string[] = [];
  tagAddOnBlur = true;
  tagSelectable = true;
  tagRemovable = true;
  defaultDomainOption = "ALLOW_ALL";

  protected readonly options: object = OrganizationUtility.options;
  protected readonly adxErrorStateMatcher = new AdxErrorStateMatcher();
  protected uploadedFcmFileName: string | undefined;

  private authSubscription: Subscription | undefined;
  private pushApiKeyChangeSubscription: Subscription | undefined;
  private smtpHostChangeSubscription: Subscription | undefined;

  constructor(private orgService: OrganizationService,
              private formBuilder: UntypedFormBuilder,
              private authNotifier: AuthNotifierService,
              private readonly messageNotifier: CustomNotifierService,
              private readonly dialogService: AdxDialogService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.editOrgForm = this.formBuilder.group({
      orgName: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3)
        ]
      }),
      orgIcon: [],
      orgDescription: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(450)
      ]}),
      orgAbout: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(10000)
      ]}),
      orgHelp: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(10000)
      ]}),
      verifySignupCredentials: [],
      orgEmail: this.formBuilder.group({
        smtpHost: [Validators.maxLength(2000)],
        smtpPort: new FormControl<number>(0,
          {updateOn:'change', validators: [Validators.max(99999)]}),
        smtpUsername: [Validators.maxLength(2000)],
        smtpPsswd: [Validators.maxLength(2000)],
        smtpEmail: [null, [Validators.pattern(this.emailPattern),Validators.maxLength(2000)]]
      }),
      orgPush: this.formBuilder.group({
        pushApiKey: [Validators.required, Validators.maxLength(200)],
        pushAppId: [Validators.required, Validators.maxLength(200)],
        pushAuthDomain: [Validators.required, Validators.maxLength(200)],
        pushProjId: [Validators.required, Validators.maxLength(200)],
        pushProjFile: [Validators.required],
        pushMsgSendId: [Validators.required, Validators.maxLength(200)],
        pushProjName: [Validators.required, Validators.maxLength(200)],
        pushStorageBkt: [Validators.required, Validators.maxLength(200)]
      }),
      orgStyle: this.formBuilder.group({
        numOfTabs: new UntypedFormControl(),
        backgroundColor: [],
        backgroundTextColor: [],
        topBarBackgroundColor: [],
        topBarTextColor: [],
        bodyBackgroundColor: [],
        bannerBackgroundColor: [],
        bannerTextColor: [],
        buttonBackgroundColor: [],
        buttonTextColor: [],
        bodyTextColor: []
      }),
      orgAddress: this.formBuilder.group( {
        street1: new UntypedFormControl(),
        street2: new UntypedFormControl(),
        city: new UntypedFormControl(),
        state: new UntypedFormControl(),
        zipcode: new UntypedFormControl(),
        country: new UntypedFormControl(),
        orgTimezone: new UntypedFormControl(),
      }),
      orgLocale: [], 
      orgEmailDomain: this.formBuilder.group({
        emailDomainOption : new UntypedFormControl(null,{updateOn: 'change'}),
        emailDomains : new UntypedFormControl(null,{updateOn: 'change', 
        validators:[Validators.maxLength(250), Validators.pattern(this.domainPattern)]}),
        emailDomainList: new UntypedFormControl()
      })
    });
   }

   get emailDomainsControl(): UntypedFormControl {
    return this.editOrgForm.get('orgEmailDomain.emailDomainList') as UntypedFormControl;
  }

  get backgroundColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('backgroundColor')?.value;
  }

  get backgroundTextColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('backgroundTextColor')?.value;
  }

  get topBarBackgroundColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('topBarBackgroundColor')?.value;
  }
  get topBarTextColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('topBarTextColor')?.value;
  }
  get bannerBackgroundColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('bannerBackgroundColor')?.value;
  }
  get bannerTextColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('bannerTextColor')?.value;
  }
  get buttonBackgroundColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('buttonBackgroundColor')?.value;
  }
  get buttonTextColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('buttonTextColor')?.value;
  }

  get bodyBackgroundColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('bodyBackgroundColor')?.value;
  }

  get bodyTextColor(): string {
    return this.editOrgForm?.get('orgStyle')?.get('bodyTextColor')?.value;
  }

  ngOnInit(): void {

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug(`ORG_EDIT In AdxHamburgerMenuComponent authSubscription: ${user}`);
        this.currentUser = user;
      }
    );

    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.timezones = this.activatedRoute.snapshot.data.timezones;
    this.locales = this.activatedRoute.snapshot.data.locales;
    this.logger.debug('received data');
    this.logger.debug(this.selectedOrg);
    this.logger.debug('title:' + this.selectedOrg?.title);
    this.logger.debug('timezoneId:' + this.selectedOrg?.timeZone?.id);
    if (this.selectedOrg) {
      //if user is unauthorized to edit this org, no need to process further
      if (!this.canUserEditThisOrg()) {
        return;
      }
      this.typeId = this.selectedOrg.id;
      this.orgIconImage = this.selectedOrg.iconImage;
    }

    this.countries = AdxCountryUtility.getSupportedCountries();

    let strSmtpUsername = 'N/A';
    if (this.selectedOrg?.smtpUsername) {
      strSmtpUsername = this.selectedOrg?.smtpUsername;
    }
    let strSmtpPsswd = 'N/A';
    if (this.selectedOrg?.smtpPassword) {
      strSmtpPsswd = this.selectedOrg?.smtpPassword;
    }
    let smtpPortNumber = 0; //if data for port from backend is NotANumber, ignore it and set to smtpPort to 0
    if (this.selectedOrg && this.selectedOrg.smtpPort && !isNaN(+this.selectedOrg.smtpPort)) {
      smtpPortNumber = +this.selectedOrg.smtpPort; //convert to number
    }
    const localeSelection: AdxLocaleSelection = new AdxLocaleSelection();
    if (this.selectedOrg?.defaultLocale) {
      this.logger.debug(`defaultLocale: ${this.selectedOrg?.defaultLocale}`);
      localeSelection.defaultLocale = this.selectedOrg?.defaultLocale;
    } else {
      this.logger.debug('NO default locale');
    }
    if (this.selectedOrg?.locales) {
      localeSelection.locales = this.selectedOrg?.locales;
    } else {
      this.logger.debug('NO selected locales');
    }
    this.logger.debug(`excludedModules: ${this.selectedOrg?.excludedModuleTypes}`);
    this.logger.debug(`availableModules: ${this.selectedOrg?.orgAvailableModules}`);
    if(this.selectedOrg?.pushNotifProjectFileName){
      this.uploadedFcmFileName = this.selectedOrg?.pushNotifProjectFileName;
    }
    //we receive the domains as a single string hence we need to split by , to display them properly on UI
    this.domainTagList = (this.selectedOrg?.emailDomains && this.selectedOrg?.emailDomains != null) ? this.selectedOrg?.emailDomains?.split(',') : [];

    this.editOrgForm.patchValue({
      orgName: this.selectedOrg?.title,
      orgIcon: this.selectedOrg?.iconImage,
      orgDescription: this.selectedOrg?.description,
      orgAbout: this.selectedOrg?.aboutUsInfo?.text,
      orgHelp: this.selectedOrg?.helpInfo?.text,
      verifySignupCredentials: this.selectedOrg?.verifyConsumerSignup,
      orgStyle: ({
        numOfTabs: this.selectedOrg?.style?.numberOfTabs,
        backgroundColor: this.selectedOrg?.style?.navigationBackgroundColor,
        backgroundTextColor: this.selectedOrg?.style?.navigationTextColor,
        topBarBackgroundColor: this.selectedOrg?.style?.topBarBackgroundColor,
        topBarTextColor: this.selectedOrg?.style?.topBarTextColor,
        bodyBackgroundColor: this.selectedOrg?.style?.bodyBackgroundColor,
        bannerBackgroundColor: this.selectedOrg?.style?.bannerBackgroundColor,
        bannerTextColor: this.selectedOrg?.style?.bannerTextColor,
        buttonBackgroundColor: this.selectedOrg?.style?.buttonBackgroundColor,
        buttonTextColor: this.selectedOrg?.style?.buttonTextColor,
        bodyTextColor: this.selectedOrg?.style?.bodyTextColor
      }),
      orgAddress: ( {
        street1: this.selectedOrg?.address?.street1,
        street2: this.selectedOrg?.address?.street2,
        city: this.selectedOrg?.address?.city,
        state: this.selectedOrg?.address?.state,
        zipcode: this.selectedOrg?.address?.zipCode,
        country: this.selectedOrg?.address?.country,
        orgTimezone: this.selectedOrg?.timeZone?.id,
      }),
      orgEmail: ( {
        smtpHost: this.selectedOrg?.smtpHost,
        smtpPort: smtpPortNumber,
        smtpUsername: strSmtpUsername,
        smtpPsswd: strSmtpPsswd,
        smtpEmail: this.selectedOrg?.senderEmail
      }),
      orgPush: ( {
        pushApiKey: this.selectedOrg?.pushNotifApiKey,
        pushAppId: this.selectedOrg?.pushNotifAppId,
        pushAuthDomain: this.selectedOrg?.pushNotifAuthDomain,
        pushProjId: this.selectedOrg?.pushNotifProjectId,
        pushProjFile: this.selectedOrg?.pushNotificationProjectFile,
        pushMsgSendId: this.selectedOrg?.pushNotifMessagingSenderId,
        pushProjName: this.selectedOrg?.pushNotificationProjectName,
        pushStorageBkt: this.selectedOrg?.pushNotifStorageBucket
      }),
      orgLocale: localeSelection,
      orgEmailDomain:({
        emailDomainOption : this.selectedOrg?.emailDomainOption,
        emailDomains: ''
      })
    });
    if (this.selectedOrg && this.selectedOrg?.excludedModuleTypes) {
      this.excludedModules = this.selectedOrg?.excludedModuleTypes?.split(',');
      if (!this.excludedModules && this.selectedOrg.excludedModuleTypes && this.selectedOrg.excludedModuleTypes?.length > 0) {
        this.excludedModules = [];
        this.excludedModules.push(this.selectedOrg.excludedModuleTypes);
      }
    }
    if (this.selectedOrg) {
      const objAllAvailableModules = this.selectedOrg.getAllModules();
      for (const module of objAllAvailableModules) {
        this.allModules.push(module.type.toString());
      }
    }

    this.pushApiKeyChangeSubscription = this.editOrgForm.get('orgPush.pushApiKey')?.valueChanges
      .subscribe(value => { this.updateValidatorsForPUSH('pushApiKey', value); });

    this.smtpHostChangeSubscription = this.editOrgForm.get('orgEmail.smtpHost')?.valueChanges
      .subscribe(value => { this.updateValidatorsForSMTP('smtpHost', value); });

      //disable property was not updated with condition in HTML file hence adding the logic here
      this.editOrgForm.get('orgEmailDomain.emailDomainOption')?.valueChanges
      .subscribe(value => { 
        if(value == this.defaultDomainOption){
          this.editOrgForm.get('orgEmailDomain.emailDomainList')?.disable();
        }else{
          this.editOrgForm.get('orgEmailDomain.emailDomainList')?.enable();
        }
      });

      if(this.selectedOrg?.emailDomainOption == this.defaultDomainOption){
        this.editOrgForm.get('orgEmailDomain.emailDomainList')?.disable();
        this.editOrgForm.get('orgEmailDomain.emailDomains')?.disable();
      }
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.pushApiKeyChangeSubscription?.unsubscribe();
    this.smtpHostChangeSubscription?.unsubscribe();
  }

  onFileChange(event: any): void {
    const files: FileList | null = (event.currentTarget as HTMLInputElement).files;
    this.logger.debug(files);
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const f: File | null = files.item(i);
        if (f != null) {
          this.logger.debug(`Got uploaded file ${f.name}`);
          this.uploadedFcmFileName = f.name;
          this.editOrgForm.patchValue({
            orgPush: ( {
              pushProjFile: f,
            })
          });
          this.editOrgForm.get('orgPush.pushProjFile')?.markAsDirty();
          this.editOrgForm.get('orgPush.pushProjFile')?.updateValueAndValidity();
        }
      }
    }
  }

  update(): void {
    this.logger.debug(this.editOrgForm);

    if (!this.selectedOrg) {
      this.logger.debug('selected object is undefined');
      return;
    }
    const selOrgId: number | null = this.selectedOrg.id;

    //check if one field in PUSH group is specified, then all fields are provided
    let strPushApiKey = '';
    let strPushAppId = '';
    let strPushAuthDomain = '';
    let  strPushProjId = '';
    let strPushProjName = '';
    let strPushMsgSendId = '';
    let strPushStorageBkt = '';
    // set push details
    if (this.editOrgForm.get('orgPush')?.dirty) {
      // push settings
      strPushApiKey = this.editOrgForm.get('orgPush.pushApiKey')?.value;
      strPushAppId = this.editOrgForm.get('orgPush.pushAppId')?.value;
      strPushAuthDomain = this.editOrgForm.get('orgPush.pushAuthDomain')?.value;
      strPushProjId = this.editOrgForm.get('orgPush.pushProjId')?.value;
      strPushProjName = this.editOrgForm.get('orgPush.pushProjName')?.value;
      strPushMsgSendId = this.editOrgForm.get('orgPush.pushMsgSendId')?.value;
      strPushStorageBkt = this.editOrgForm.get('orgPush.pushStorageBkt')?.value;

      if (this.checkIfBlank(strPushApiKey) && this.checkIfBlank(strPushAppId) && this.checkIfBlank(strPushAuthDomain)
        && this.checkIfBlank(strPushProjId) && this.checkIfBlank(strPushProjName) && this.checkIfBlank(strPushMsgSendId)
        && this.checkIfBlank(strPushStorageBkt) && this.editOrgForm.get('orgPush.pushProjFile')?.value === undefined) {
        // means all of the fields is blank. so ignore
        this.logger.debug('all PUSH fields are blank');
      }
      else if (this.checkIfBlank(strPushApiKey) || this.checkIfBlank(strPushAppId) || this.checkIfBlank(strPushAuthDomain)
        || this.checkIfBlank(strPushProjId) || this.checkIfBlank(strPushProjName) || this.checkIfBlank(strPushMsgSendId)
        || this.checkIfBlank(strPushStorageBkt) || this.editOrgForm.get('orgPush.pushProjFile')?.value === undefined) {
        // means one of the fields is blank. so show error
        this.alertDialog('Validation Failure',
        'Please provide values for all PUSH notifications setting fields', null, CustomMessageType.ERROR);
        return;
      }
      else {
        // means all of the fields are provided
        this.logger.debug('all PUSH fields are provided');
      }
    }

    // FCM Settings
    const strFcmProjName: string = this.editOrgForm.get('fcmProjectName')?.value;

    const orgToUpdate: Organization = new Organization(selOrgId, this.selectedOrg.secret);
    if (this.editOrgForm.get('orgName')?.dirty) {
      orgToUpdate.title = this.editOrgForm.get('orgName')?.value.trim();
    }

    if (this.editOrgForm.get('orgDescription')?.dirty) {
      orgToUpdate.description = this.editOrgForm.get('orgDescription')?.value;
    }
    if (this.editOrgForm.get('orgAbout')?.dirty) {
      orgToUpdate.aboutUsInfo = new AdxAboutUsInfo(null, null, null);
      if (this.selectedOrg.aboutUsInfo?.id) {
        orgToUpdate.aboutUsInfo.id = this.selectedOrg.aboutUsInfo?.id;
      }
      orgToUpdate.aboutUsInfo.text = this.editOrgForm.get('orgAbout')?.value;
      orgToUpdate.aboutUsInfo.scope = 'ORGANIZATION';
    }
    if (this.editOrgForm.get('orgHelp')?.dirty) {
      orgToUpdate.helpInfo = new AdxHelpInfo(null, null, null);
      if (this.selectedOrg.helpInfo?.id) {
        orgToUpdate.helpInfo.id = this.selectedOrg.helpInfo?.id;
      }
      orgToUpdate.helpInfo.text = this.editOrgForm.get('orgHelp')?.value;
      orgToUpdate.helpInfo.scope = 'ORGANIZATION';
    }

    // set the icon
    if (this.editOrgForm.get('orgIcon')?.dirty) {
      orgToUpdate.iconImage = this.orgIconImage;
      this.logger.debug(`set icon with libId: ${orgToUpdate.iconImage?.imageLibraryId}`);
    }

    if (this.editOrgForm.get('verifySignupCredentials')?.dirty) {
      orgToUpdate.verifyConsumerSignup = this.editOrgForm.get('verifySignupCredentials')?.value;
      this.logger.debug(`set verifySignupCredentials: ${orgToUpdate.verifyConsumerSignup}`);
    }

    if (this.editOrgForm?.get('orgTimezone')?.dirty) {
      const tzId: number = +(this.editOrgForm.get('orgTimezone')?.value);
      const tz: AdxTimezone | undefined = this.timezones.find(tzObj => tzObj.id === tzId);
      if (tz) {
        orgToUpdate.timeZone = tz;
        orgToUpdate.timeZoneId = tzId;
      }
    }
    if (this.editOrgForm?.get('orgLocale')?.dirty) {
      this.logger.debug('Locale Changed');
      if (this.defaultLocale) {
        orgToUpdate.defaultLocale = this.defaultLocale;
      }
      if (this.selectedLocales) {
        orgToUpdate.locales = this.selectedLocales;
      }
    }

    // set smtp details
    if (this.editOrgForm.get('orgEmail')?.dirty) {
      // smtp settings
      const strSmtpHost: string = this.editOrgForm.get('orgEmail.smtpHost')?.value;
      const strSmtpPort: string = this.editOrgForm.get('orgEmail.smtpPort')?.value;
      const strSmtpUser: string = this.editOrgForm.get('orgEmail.smtpUsername')?.value;
      const strSmtpPsswd: string = this.editOrgForm.get('orgEmail.smtpPsswd')?.value;
      const strSmptEmail: string = this.editOrgForm.get('orgEmail.smtpEmail')?.value;

      if (this.checkIfBlank(strSmtpHost) && this.checkIfBlank(strSmtpPort) && this.checkIfBlank(strSmtpUser)
        && this.checkIfBlank(strSmtpPsswd) && this.checkIfBlank(strSmptEmail)) {
        // means all of the fields is blank. so ignore
        this.logger.debug('all SMTP fields are blank');
      }
      else if (this.checkIfBlank(strSmtpHost) || this.checkIfBlank(strSmtpPort) || this.checkIfBlank(strSmtpUser)
        || this.checkIfBlank(strSmtpPsswd) || this.checkIfBlank(strSmptEmail)) {
        // means one of the fields is blank. so show error
        this.alertDialog('Validation Failure',
        'Please provide values for all SMTP notifications setting fields', null, CustomMessageType.ERROR);
        return;
      }
      else {
        // means all of the fields are provided
        this.logger.debug('all SMTP fields are provided');
      }

      this.logger.debug('email:' + strSmtpHost + ':' + strSmtpPort + ':' + strSmtpUser +  ':' + strSmtpPsswd + ':' + strSmptEmail);

      orgToUpdate.smtpHost = strSmtpHost;
      orgToUpdate.smtpPort = strSmtpPort;
      orgToUpdate.smtpUsername = strSmtpUser;
      orgToUpdate.smtpPassword = strSmtpPsswd;
      orgToUpdate.senderEmail = strSmptEmail;
    }

    this.logger.debug(`Are push details updated? : ${this.editOrgForm.get('orgPush')?.dirty}`);
    // set push details
    if (this.editOrgForm.get('orgPush')?.dirty) {
      // push settings
      const projFile: File = this.editOrgForm.get('orgPush.pushProjFile')?.value;

      this.logger.debug(`strPushApiKey: ${strPushApiKey} :: strPushAppId: ${strPushAppId} :: strPushStorageBkt: ${strPushStorageBkt}`);

      orgToUpdate.pushNotifApiKey = strPushApiKey;
      orgToUpdate.pushNotifAppId = strPushAppId;
      orgToUpdate.pushNotifAuthDomain = strPushAuthDomain;
      orgToUpdate.pushNotifMessagingSenderId = strPushMsgSendId;
      orgToUpdate.pushNotifProjectId = strPushProjId;
      orgToUpdate.pushNotificationProjectName = strPushProjName;
      orgToUpdate.pushNotificationProjectFile = projFile;
      orgToUpdate.pushNotifStorageBucket = strPushStorageBkt;
    }

    // add address
    if (this.editOrgForm.get('orgAddress')?.dirty) {
      const strStreet1: string = this.editOrgForm.get('orgAddress.street1')?.value.trim();
      const strStreet2: string = this.editOrgForm.get('orgAddress.street2')?.value.trim();
      const strCity: string = this.editOrgForm.get('orgAddress.city')?.value.trim();
      const strState: string = this.editOrgForm.get('orgAddress.state')?.value.trim();
      const strZip: string = this.editOrgForm.get('orgAddress.zipcode')?.value;
      const strCountry: string = this.editOrgForm.get('orgAddress.country')?.value;

      let addr: Address;
      if (this.selectedOrg.address) {
        addr = this.selectedOrg.address;
        addr.street1 = strStreet1;
        addr.street2 = strStreet2;
        addr.city = strCity;
        addr.state = strState;
        addr.zipCode = strZip;
        addr.country = strCountry;
      } else {
        addr = new Address(
          NaN,
          strStreet1,
          strStreet2,
          strCity,
          strState,
          '',
          strCountry,
          strZip,
          'ORGANIZATION'
        );
      }
      if (this.currentUser) {
        addr.createdByUser = this.currentUser?.id;
      }
      orgToUpdate.address = addr;
    }

    // add style
    if (this.editOrgForm?.get('orgStyle')?.dirty) {
      const numOfTabs = this.editOrgForm?.get('orgStyle')?.get('numOfTabs')?.value;
      const strBackgroundColor = this.backgroundColor;
      const strBackgroundTextColor = this.backgroundTextColor;
      const strTopBarColor = this.topBarBackgroundColor;
      const strTopBarTextColor = this.topBarTextColor;
      const strBannerColor = this.bannerBackgroundColor;
      const strBannerTextColor = this.bannerTextColor;
      const strButtonColor = this.buttonBackgroundColor;
      const strButtonTextColor = this.buttonTextColor;
      const strBodyColor = this.bodyBackgroundColor;
      const strBodyTextColor = this.bodyTextColor;

      let style: AdxStyle;
      if (this.selectedOrg.style) {
        style = this.selectedOrg.style;
        style.numberOfTabs = numOfTabs;
        style.navigationBackgroundColor = strBackgroundColor;
        style.navigationTextColor = strBackgroundTextColor;
        style.topBarBackgroundColor = strTopBarColor;
        style.topBarTextColor = strTopBarTextColor;
        style.bodyBackgroundColor = strBodyColor;
        style.bodyTextColor = strBodyTextColor;
        style.bannerBackgroundColor = strBannerColor;
        style.bannerTextColor = strBannerTextColor;
        style.buttonBackgroundColor = strButtonColor;
        style.buttonTextColor = strButtonTextColor;
      } else {
        style = new AdxStyle(
          NaN,
          numOfTabs,
          strBackgroundColor,
          strBackgroundTextColor,
          ' ',
          strTopBarColor,
          strTopBarTextColor,
          strBodyColor,
          strBodyTextColor,
          strBannerColor,
          strBannerTextColor,
          strButtonColor,
          strButtonTextColor,
          9,
          'ORGANIZATION'
        );
      }
      // TO-DO Set the logged in user here
      style.createdByUser = 1;
      orgToUpdate.style = style;
    }

    //add email domain restriction details
    if(this.editOrgForm?.get('orgEmailDomain')?.dirty){      
      const domainOption = this.editOrgForm.get('orgEmailDomain.emailDomainOption')?.value;
      orgToUpdate.emailDomainOption = domainOption;
      if(domainOption == this.defaultDomainOption){ // is the selection is to allow all domains ehen send emaildomains empty.
        orgToUpdate.emailDomains = ""
      }else{
        orgToUpdate.emailDomains = this.domainTagList.toString();
      }
    }


    // update excluded modules
    if (this.excludeModuleDualBoxChanged) {
      if (this.excludedModules && this.excludedModules.length > 0){
        this.logger.debug(`this.excludedModules: ${this.excludedModules}`);
        orgToUpdate.excludedModuleTypes = String(this.excludedModules);
      }
      else {
        this.logger.debug(`ELSE this.excludedModules: ${this.excludedModules}`);
        orgToUpdate.excludedModuleTypes = '';
      }
    }

    // TO-DO Set the logged in user here
    orgToUpdate.createdByUser = 1;

    this.logger.debug('object to be updated');
    this.logger.debug(orgToUpdate);

    this.updateOrg(orgToUpdate);
  }

  /**
   * Called when icon is updated.
   */
  onIconSelected(selectedImg: AdxImage): void {
    this.orgIconImage = selectedImg;
  }

  /**
   * Determines whether logged in user has the role to edit current org
   *
   * @returns boolean true if logged in user is authorized to edit this org
   */
  canUserEditThisOrg(): boolean {
    if (this.selectedOrg === undefined || this.selectedOrg === null ||
       this.selectedOrg.id === undefined || this.selectedOrg.id === null
       || this.currentUser === undefined || this.currentUser === null) {
        return false; //if data not set, return false
       }
    return this.orgService.canUserEditOrg(this.selectedOrg.id, this.currentUser);
  }

  onDefaultLocaleChange(event: AdxLocale): void {
    this.logger.debug(`Default Locale Updated ${event.displayName}`);
    this.defaultLocale = event;
  }

  onSelectedLocaleUpdate(event: AdxLocale[]): void {
    this.logger.debug(`Selected Locales Updated to ${event.length}`);
    this.selectedLocales = event;
  }

  /**
   * Called when the dual list box is updated
   *
   * @param $event
   */
  onModuleExclude($event: any) {
    this.excludeModuleDualBoxChanged = true;
  }

  /*
   * This method calls service api to update organization
   */
  private updateOrg(org: Organization): void {
    if (!this.canUserEditThisOrg()) {
      return; //if user is unable to edit this org, then no need to proceed further
    }
    this.orgService
      .updateOrganization(org)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.router.navigateByUrl('orgs').then(r => { });
        },
        error: () => {
          // rest call failure
          this.router.navigateByUrl('orgs').then(r => { });
        },
        complete: () => {
          // rest call success
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Organization updated Successfully.`);
        }
      });
  }

  private updateValidatorsForPUSH(nameOfFieldChanged: string, valueOfFieldChanged: any): void {

    const pushFields: string[] = ['pushAppId', 'pushAuthDomain', 'pushProjId',
      'pushProjFile', 'pushMsgSendId', 'pushProjName', 'pushStorageBkt']; //fields other than fcmProjKey
    const pushGroup = this.editOrgForm.get('orgPush');
    for (const pushFieldName of pushFields) {
        const pushField = pushGroup?.get(pushFieldName);
        if (valueOfFieldChanged !== undefined && valueOfFieldChanged !== null && valueOfFieldChanged.trim().length !== 0) {
          pushField?.setValidators(Validators.required);//setting the field mandatory if other field is changed
        } else {
          pushField?.setValidators(Validators.nullValidator);
        }
        pushField?.updateValueAndValidity();
    }
  }

  private updateValidatorsForSMTP(nameOfFieldChanged: string, valueOfFieldChanged: any): void {

    const smtpFields: string[] = ['smtpPort', 'smtpUsername', 'smtpUsername', 'smtpPsswd', 'smtpEmail']; //fields other than smtp
    const smtpGroup = this.editOrgForm.get('orgEmail');
    for (const smtpFieldName of smtpFields) {
        const pushField = smtpGroup?.get(smtpFieldName);
        if (valueOfFieldChanged !== undefined && valueOfFieldChanged !== null && valueOfFieldChanged.trim().length !== 0) {
          pushField?.setValidators(Validators.required);//setting the field mandatory if other field is changed
        } else {
          pushField?.setValidators(Validators.nullValidator);
        }
        pushField?.updateValueAndValidity();
    }
  }

  private checkIfBlank(strVal: string | null | undefined): boolean {
    if (strVal === undefined || strVal === null || strVal.length < 1) {
      return true;
    }
    return false;
  }

  private alertDialog(strTitle: string, msg: string, submsg: string | null, msgType: CustomMessageType): Observable<boolean> {
    return this.dialogService.alertDialog({
      title: strTitle,
      message: msg,
      submessage: submsg,
      type: msgType
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value.trim();

    const len = this.domainTagList.join('');

  // Add our tag
  if ((value || '').trim() && len.length <= 250) { 
    this.validateDomain(value);
    if(this.isValidDomain){
      this.domainTagList.push(value);
      this.emailDomainsControl.setValue(this.domainTagList);
      this.emailDomainsControl.updateValueAndValidity();
      this.emailDomainsControl.markAsDirty();
    }     
  }else{
    
  }

    // Reset the input value
    if (input) {
      input.value = '';
    }    
  }

  removeTag(tag: string): void {
    const index = this.domainTagList.indexOf(tag);
    if (index >= 0) {
      this.domainTagList.splice(index, 1);
      this.emailDomainsControl.setValue(this.domainTagList);
      this.emailDomainsControl.updateValueAndValidity();
      this.emailDomainsControl.markAsDirty();
    }                
  }

//method to validate the domain
  validateDomain(domain:string){
        let isMatch = domain.match(this.domainPattern);
        this.isValidDomain = isMatch != null ? true : false;
    }
 
}
