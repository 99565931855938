import {Component, Inject, OnInit} from '@angular/core';
import {AdxImportResponse} from '../../common/model/data-transfer/adx-import-response';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {AdxImportProcessResult} from '../../common/model/data-transfer/adx-import-process-result';

/**
 * This component is used to display status of file import in ARS module
 */
@Component({
  selector: 'app-adx-import-submission-status',
  templateUrl: './adx-import-submission-status.component.html',
  styleUrls: ['./adx-import-submission-status.component.scss']
})
export class AdxImportSubmissionStatusComponent implements OnInit {

  importResponseData: AdxImportResponse;
  isXMLImport: boolean;
  isStatusSuccess: boolean;
  docProcessingResults: AdxImportProcessResult[] = [];
  aceRunProcessOptions: string[] = [];

  private SUCCESS = 'VALIDATION_SUCCESS';

  constructor(@Inject(MAT_DIALOG_DATA) public data: AdxImportResponse, private logger: NGXLogger) {
    this.importResponseData = data;
    this.isXMLImport = data.processes === null || data.processes === undefined || data.processes.length === 0;
    this.logger.debug(`isXMLImport: ${this.isXMLImport} ;; ${data.processes}`);
    this.isStatusSuccess = data.status === this.SUCCESS;
    if (!this.isXMLImport) {
      if (data.processes) {
        for (const process of data.processes) {
          if (process) {
            if (process.results) {
              for (const result of process.results) {
                this.docProcessingResults.push(result);
              }
            }
          }
        }
      }
    }
    if (this.importResponseData.availableProcesses) {
      // this will be comma separated values
      const processOptions = this.importResponseData.availableProcesses.split(',');
      if (processOptions && processOptions.length > 0) {
        this.aceRunProcessOptions = processOptions;
      }
    }
  }

  ngOnInit(): void {
  }
}
