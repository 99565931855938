import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AdxModuleAddRequest} from '../model/adx-module-add-request';
import {AdxModuleType} from '../model/adx-module-type';
import {AdxModuleAddRequestService} from '../service/adx-module-add-request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {blankValidator} from '../../common/utils/form-utility';
import {CommonUtility} from '../../common/utils/common-utils';
import {Account} from '../../account/model/account.model';
import {AdxImage} from '../../common/model/adx-image.model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxModuleService } from '../service/adx-module.service';

@Component({
  selector: 'app-add-adx-module',
  templateUrl: './add-multiple-adx-module.component.html',
  styleUrls: ['./add-multiple-adx-module.component.scss'],
})
export class AddMultipleAdxModuleComponent extends AdxBaseTemplate  implements OnInit, OnDestroy {

  selectedAccount: Account | null = null;
  addModuleForm: UntypedFormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  moduleListUrl = '';
  includedModuleTypes: { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly MIN_NUMBER_OF_MODULES = 1;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly MAX_NUMBER_OF_MODULES = 5;
  private checkboxValidatorSubscription: Subscription | undefined;
  private arsNumberValidationSubscription: Subscription | null = null;
  private formNumberValidationSubscription: Subscription | null = null;
  private webNumberValidationSubscription: Subscription | null = null;
  private htmlNumberValidationSubscription: Subscription | null = null;

  private authSubscription: Subscription | undefined;

  constructor(private readonly authNotifier: AuthNotifierService, private addModuleService: AdxModuleAddRequestService,
              private readonly messageNotifier: CustomNotifierService, private moduleService: AdxModuleService,
              private router: Router, private activatedRoute: ActivatedRoute) {

    super();
    this.addModuleForm = new UntypedFormGroup({
      moduleTitle: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]}),
      arsCheckbox: new UntypedFormControl(),
      numOfArsModules: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.min(this.MIN_NUMBER_OF_MODULES),
          Validators.max(this.MAX_NUMBER_OF_MODULES)
        ]}),
      formCheckbox: new UntypedFormControl(),
      numOfFormModules: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.min(this.MIN_NUMBER_OF_MODULES),
          Validators.max(this.MAX_NUMBER_OF_MODULES)
        ]}),
      webCheckbox: new UntypedFormControl(),
      numOfWebModules: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.min(this.MIN_NUMBER_OF_MODULES),
          Validators.max(this.MAX_NUMBER_OF_MODULES)
        ]}),
      htmlCheckbox: new UntypedFormControl(),
      numOfHtmlModules: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.min(this.MIN_NUMBER_OF_MODULES),
          Validators.max(this.MAX_NUMBER_OF_MODULES)
        ]})
    });
  }

  ngOnInit(): void {
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (!orgId || !accountId || !vpubId || !applnId) {
      this.logger.error('required params missing to fetch module');
      return;
    }
    if (accountId && orgId && applnId && vpubId) {
      this.moduleListUrl = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
    }
    this.setCheckboxValidator();
    this.setModuleSelectionValidators();

    // set the modules available to use for this account
    this.logger.debug(this.selectedAccount);
    this.logger.debug(this.selectedAccount?.accountAvailableModules);
    if (this.selectedAccount?.accountAvailableModules) {
      this.includedModuleTypes.push(...this.selectedAccount?.accountAvailableModules);
    }
    this.includedModuleTypes.push({displayName: 'GROUP', type: AdxModuleType.GROUP, icon: null});
    this.logger.debug(this.includedModuleTypes);
  }

  ngOnDestroy() {
    if (this.checkboxValidatorSubscription) {
      this.checkboxValidatorSubscription.unsubscribe();
    }
    if (this.arsNumberValidationSubscription) {
      this.arsNumberValidationSubscription.unsubscribe();
    }
    if (this.formNumberValidationSubscription) {
      this.formNumberValidationSubscription.unsubscribe();
    }
    if (this.webNumberValidationSubscription) {
      this.webNumberValidationSubscription.unsubscribe();
    }
    if (this.htmlNumberValidationSubscription) {
      this.htmlNumberValidationSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  addModule(): void {
    this.logger.debug(this.addModuleForm.value);
    if (!this.canAddModule()){
      return; // no need to proceed as current user does not have permission to add module
    }
    const addReq: AdxModuleAddRequest = new AdxModuleAddRequest(null);
    const addForm: boolean = this.addModuleForm.get('formCheckbox')?.value;
    const addArs: boolean = this.addModuleForm.get('arsCheckbox')?.value;
    const addWeb: boolean = this.addModuleForm.get('webCheckbox')?.value;
    const addHtml: boolean = this.addModuleForm.get('htmlCheckbox')?.value;
    const strTitle: string = this.addModuleForm.get('moduleTitle')?.value;

    this.logger.debug(`In addModule strTitle: ${strTitle}`);
    if (!addForm && !addArs && !addWeb && !addHtml) {
      return;
    }

    const numOfForms = this.addModuleForm.get('numOfFormModules')?.value;
    if (addForm && numOfForms && numOfForms > 0) {
      addReq.numOfModuleMap.set(AdxModuleType.FORM, numOfForms);
    }

    const numOfArs = this.addModuleForm.get('numOfArsModules')?.value;
    if (addArs && numOfArs && numOfArs > 0) {
      addReq.numOfModuleMap.set(AdxModuleType.ARS, numOfArs);
    }

    const numOfWebModules = this.addModuleForm.get('numOfWebModules')?.value;
    if (addWeb && numOfWebModules && numOfWebModules > 0) {
      addReq.numOfModuleMap.set(AdxModuleType.WEB, numOfWebModules);
    }

    const numOfHtmlModules = this.addModuleForm.get('numOfHtmlModules')?.value;
    if (addHtml && numOfHtmlModules && numOfHtmlModules > 0) {
      addReq.numOfModuleMap.set(AdxModuleType.HTML, numOfHtmlModules);
    }

    addReq.title = strTitle;
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    if (!orgId || !accountId || !! !applnId || !vpubId) {
      this.logger.error('required params missing');
      return;
    }
    this.addModuleService.addModule(orgId, accountId, vpubId, addReq)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // navigate user to listing screen
          this.router.navigateByUrl(`/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`).then(r => {});
        },
        error: () => {
          // navigate user to listing screen
          this.router.navigateByUrl(`/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`).then(r => {});
        },
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Modules created Successfully.`);
        }
      });
  }

  isFormInvalid(): boolean {
    if (!this.addModuleForm.dirty) {
      return true;
    }

    const arsCheckboxControl = this.addModuleForm.get('arsCheckbox');
    const arsNumberControl = this.addModuleForm.get('numOfArsModules');
    const formCheckboxControl = this.addModuleForm.get('formCheckbox');
    const formNumberControl = this.addModuleForm.get('numOfFormModules');
    const webCheckboxControl = this.addModuleForm.get('webCheckbox');
    const webNumberControl = this.addModuleForm.get('numOfWebModules');
    const htmlCheckboxControl = this.addModuleForm.get('htmlCheckbox');
    const htmlNumberControl = this.addModuleForm.get('numOfHtmlModules');
    const addForm: boolean = formCheckboxControl?.value;
    const addArs: boolean = arsCheckboxControl?.value;
    const addWeb: boolean = webCheckboxControl?.value;
    const addHtml: boolean = htmlCheckboxControl?.value;

    if (this.checkInputValid(formCheckboxControl, formNumberControl, addForm)) {
      return true;
    }
    if (this.checkInputValid(arsCheckboxControl, arsNumberControl, addArs)) {
      return true;
    }
    if (this.checkInputValid(webCheckboxControl, webNumberControl, addWeb)) {
      return true;
    }
    if (this.checkInputValid(htmlCheckboxControl, htmlNumberControl, addHtml)) {
      return true;
    }
    // atleast one of the checkboxes should be selected, else form is invalid
    if (!addHtml && !addWeb && !addArs && !addForm) {
      return true;
    }
    this.logger.debug(`Returning ${!this.addModuleForm.valid} for form invalid`);
    return !this.addModuleForm.valid;
  }

  isArsModuleIncluded(): boolean {
    return this.isModuleIncluded(AdxModuleType.ARS);
  }

  isFormModuleIncluded(): boolean {
    return this.isModuleIncluded(AdxModuleType.FORM);
  }

  isHtmlModuleIncluded(): boolean {
    return this.isModuleIncluded(AdxModuleType.HTML);
  }

  isWebModuleIncluded(): boolean {
    return this.isModuleIncluded(AdxModuleType.WEB);
  }

  /*
   * This is used to determine whether loggedin user can add module
  */
  private canAddModule(): boolean {
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (orgId !== undefined && orgId !== null
       && accountId !== undefined && accountId !== null
       && applnId !== undefined && applnId !== null
       && vpubId !== undefined && vpubId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserAddModule(orgId, accountId, applnId, vpubId, this.currentUser);
    }
    return false;
  }

  /*
   * This function will validate that atleast one checkbox is selected in the reactive form
   */
  private setCheckboxValidator(): void {
    this.addModuleForm.setErrors({required: true});
    this.checkboxValidatorSubscription = this.addModuleForm.valueChanges.subscribe((newValue) => {
      if (newValue.arsCheckbox === true || newValue.formCheckbox === true ||
        newValue.webCheckbox === true || newValue.htmlCheckbox === true) {
        this.addModuleForm.setErrors(null);
      } else {
        this.addModuleForm.setErrors({required: true});
      }
    });
  }

  /*
   * This function will validate that if checkbox is selected, corresponding number of modules is specified
   */
  private setModuleSelectionValidators(): void {
    const arsCheckboxControl = this.addModuleForm.get('arsCheckbox');
    const arsNumberControl = this.addModuleForm.get('numOfArsModules');
    const formCheckboxControl = this.addModuleForm.get('formCheckbox');
    const formNumberControl = this.addModuleForm.get('numOfFormModules');
    const webCheckboxControl = this.addModuleForm.get('webCheckbox');
    const webNumberControl = this.addModuleForm.get('numOfWebModules');
    const htmlCheckboxControl = this.addModuleForm.get('htmlCheckbox');
    const htmlNumberControl = this.addModuleForm.get('numOfHtmlModules');

    this.arsNumberValidationSubscription = this.addNumberValidation(arsCheckboxControl, arsNumberControl);

    this.formNumberValidationSubscription = this.addNumberValidation(formCheckboxControl, formNumberControl);

    this.webNumberValidationSubscription = this.addNumberValidation(webCheckboxControl, webNumberControl);

    this.htmlNumberValidationSubscription = this.addNumberValidation(htmlCheckboxControl, htmlNumberControl);
  }

  /*
   * This function will add or remove validators for numeric field, based on selection of corresponding checkbox
   */
  private addNumberValidation(checkboxControl: AbstractControl | null,
                              numberControl: AbstractControl | null): Subscription | null {
    if (checkboxControl && numberControl) {
      return checkboxControl.valueChanges
        .subscribe(isSelected => {

          if (isSelected) {
            numberControl.setValidators([Validators.required, Validators.min(this.MIN_NUMBER_OF_MODULES),
              Validators.max(this.MAX_NUMBER_OF_MODULES)]);
          } else {
            numberControl.setValidators(null);
          }
          numberControl.updateValueAndValidity();
        });
    }
    return null;
  }

  private checkInputValid(checkboxControl: AbstractControl | null, numberControl: AbstractControl | null, checkboxVal: boolean): boolean {
    if (checkboxControl && checkboxControl.dirty) {
      if (checkboxVal && !numberControl?.valid) {
        return true;
      }
    }
    return false;
  }

  /*
   * Utility method to check whether module type is included for this account
   *
   * @param moduleType
   */
  private isModuleIncluded(moduleType: AdxModuleType): boolean {
    for(const module of this.includedModuleTypes) {
      if (module.type === moduleType) {
        return true;
      }
    }
    return false;
  }
}
