import { Component, OnDestroy, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {Organization} from '../../organization/model/organization.model';
import {Account} from '../../account/model/account.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxAccessType} from '../../common/model/access/adx-access-type';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {AdxVpubService} from '../service/adx-vpub.service';
import {AdxVPub} from '../model/adx-vpub.model';
import {AdxApplication} from '../../application/model/adx-application.model';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {CommonUtility} from '../../common/utils/common-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

@Component({
  selector: 'app-add-vpub',
  templateUrl: './add-vpub.component.html',
  styleUrls: ['./add-vpub.component.scss']
})
export class AddVpubComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg?: Organization;
  selectedAccount?: Account;
  selectedApplication?: AdxApplication;
  defaultLocale: AdxLocale | null = null;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.VPUB_IMG_LIB;
  typeId: number | null = null;
  availableLocales: AdxLocale[] | null = null;
  visibilityTypes: string[] = [AdxVisibilityType.DISABLED.toString(),
    AdxVisibilityType.VISIBLE.toString(), AdxVisibilityType.HIDDEN.toString()];
  accessTypes: string[] = [AdxAccessType.REQUIRE_APPROVAL.toString(), AdxAccessType.UNLOCKED.toString()];

  addVpubForm: UntypedFormGroup;
  vpubListUrl: string | null = null;
  vPubList: AdxVPub[] = [];
  orgId : number | null = null; // need org id in image library
  applnId : number | null = null;
  vpubId : number | null = null;
  private authSubscription: Subscription | undefined;

  constructor(private vpubService: AdxVpubService,
              private readonly messageNotifier: CustomNotifierService,
              private readonly authNotifier: AuthNotifierService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.addVpubForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(150)
        ]}),
      icon: new UntypedFormControl(),
      desc: new UntypedFormControl(null, {updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(250)
        ]}),
      summary: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          Validators.maxLength(150)
        ]}),
      locale: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required
        ]})
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.selectedApplication = this.activatedRoute.snapshot.data.appln;

    if (this.selectedAccount && this.selectedOrg && this.selectedApplication) {
      this.defaultLocale = this.selectedOrg?.defaultLocale;
      if (this.selectedOrg.locales && this.defaultLocale) {
        this.availableLocales = this.selectedOrg.locales;
      }
      this.vPubList = history.state.vPubList;
      this.updateAvailableLocales(this.vPubList);

      this.typeId = this.selectedAccount?.id;
      this.vpubListUrl = `/orgs/${this.selectedOrg.id}/accts/${this.selectedAccount?.id}/applns/${this.selectedApplication?.id}/vpubs`;
      this.applnId = this.selectedApplication?.id;
      this.orgId = this.selectedOrg?.id;
    }
    this.logger.debug(`vPubListUrl: ${(this.vpubListUrl)}`);

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: null,
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.VPUB_IMG_LIB,
      editorRef: undefined,
      applicationId : CommonUtility.getId('applnId', this.activatedRoute),
      atomId : null
    };
    return toRet;
  }

  save(): void {
    this.logger.debug(this.addVpubForm);
    if (!this.canAddVpub()){
      return; // no need to proceed as current user does not have permission to add vpub
    }
    const strTitle = this.addVpubForm.get('title')?.value.trim(); // title is mandatory field
    if (this.selectedApplication && strTitle) {
      const vpubToAdd: AdxVPub = new AdxVPub(null, this.selectedApplication?.id);
      vpubToAdd.title = strTitle;
      vpubToAdd.createdByUser = 1;
      if (this.addVpubForm.get('desc')?.dirty) {
        vpubToAdd.description = this.addVpubForm.get('desc')?.value;
      }
      if (this.addVpubForm.get('summary')?.dirty) {
        vpubToAdd.summary = this.addVpubForm.get('summary')?.value.trim();
      }
      if (this.addVpubForm.get('icon')?.dirty) {
        vpubToAdd.iconImage = this.iconImage;
      }
      if (this.addVpubForm.get('locale')?.dirty) {
        const localeId = this.addVpubForm.get('locale')?.value;
        vpubToAdd.localeId = localeId;
        const selectedLocale = this.availableLocales?.find(locale => locale.id === localeId);
        this.logger.debug(`localeId: ${localeId} ; selectedLocale: ${selectedLocale}`);
        if (selectedLocale) {
          vpubToAdd.locale = selectedLocale;
        }
      }
      this.addVpub(vpubToAdd);
    }
    else {
      this.logger.error('Title & Account Details not specified!! Unable to add Channel');
    }
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  /*
   * This is used to determine whether loggedin user can add vpub
  */
  private canAddVpub(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
       && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
       && this.selectedAccount !== undefined && this.selectedAccount !== null
       && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
       && this.selectedApplication !== undefined && this.selectedApplication !== null
       && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.vpubService.canUserAddVpub(this.selectedOrg.id, this.selectedAccount.id,
         this.selectedApplication.id, this.currentUser);
    }
    return false;
  }

  private addVpub(vpubToAdd: AdxVPub): void {
    if (!this.canAddVpub()){
      return; // no need to proceed as current user does not have permission to add vpub
    }
    if (this.selectedOrg && this.selectedOrg.id && this.selectedAccount && this.selectedAccount.id
      && this.selectedApplication && this.selectedApplication.id && vpubToAdd) {
      this.vpubService.addVpub(this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, vpubToAdd)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.vpubListUrl) {
              this.router.navigateByUrl(this.vpubListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.vpubListUrl) {
              this.router.navigateByUrl(this.vpubListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `${vpubToAdd.title} created Successfully.`);
          }
        });
    }
  }

  /*
   * Build the list of locale available, by filtering from all available locales, the locales already used in existing vPubs
   */
  private updateAvailableLocales(vPubList: AdxVPub[]) {
    for (const vPub of vPubList) {
      if (vPub && vPub.locale && this.availableLocales) {
        this.availableLocales = this.availableLocales.filter(locale => locale.id !== vPub.locale?.id);
      }
    }
  }
}
