import { Injectable } from '@angular/core';
import {AdxModuleGroupAddRequest} from '../model/adx-module-group-add-request';
import {Observable, throwError} from 'rxjs';
import {AdxModule} from '../model/adx-module.model';
import {catchError} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {AdxModuleSerializer} from '../model/adx-module-serializer';

@Injectable({
  providedIn: 'root'
})
export class AdxModuleGroupAddService {

  private serializer: AdxModuleSerializer = new AdxModuleSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxModuleGroupAddRequest, AdxModule>) { }

  addModuleGroup(orgId: number, accountId: number, vpubId: number,
                 addReq: AdxModuleGroupAddRequest): Observable<AdxModule> {
    const addModulesUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/group`;
    return this.restService.createModuleGroup(addModulesUrl, addReq, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }
}
