import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {AdxAlertDialogData} from '../../utils/adx-alert-dialog-data';
import {CustomMessageType} from '../../../core/service/notifier/custom-message-type';

@Component({
  selector: 'app-adx-alert-dialog',
  templateUrl: './adx-alert-dialog.component.html',
  styleUrls: ['./adx-alert-dialog.component.scss']
})
export class AdxAlertDialogComponent implements OnInit {

  buttonColor = 'green';
  displayErrorIcon = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: AdxAlertDialogData, private logger: NGXLogger) { }

  ngOnInit(): void {
    if (this.data && this.data.type) {
      if (this.data.type === CustomMessageType.ERROR) {
        this.buttonColor = 'warn';
        this.displayErrorIcon = true;
      }
      else if (this.data.type === CustomMessageType.WARN) {
        this.buttonColor = 'primary';
      }
    }
  }

}
