<div class="dialog-header">
  <h2 mat-dialog-title>{{data.title}}</h2>
</div>

<div mat-dialog-content>
  {{data.message}}
</div>

<div mat-dialog-actions [align]="'center'">
  <button mat-raised-button [mat-dialog-close]="false">{{data.cancelText}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">{{data.confirmText}}</button>
</div>
