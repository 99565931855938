import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export const blankValidator: ValidatorFn = (control: AbstractControl) => {
  const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export class FormUtility {

  constructor() {}

  static getUpdatedValues(formItem: UntypedFormGroup | UntypedFormArray | UntypedFormControl, name?: string): Map<string, string> {
    let updatedValues: Map<string, string> = new Map<string, string>();

      if (formItem instanceof UntypedFormControl) {
        if (name && formItem.dirty) {
          updatedValues.set(name, formItem.value);
        }
      } else {
        Object.entries(formItem.controls)
            .map(([key, formControl], index) => {
        const isGroup = formControl instanceof UntypedFormGroup;

            if (formControl instanceof UntypedFormControl) {
              const returned = this.getUpdatedValues(formControl, key);
              updatedValues = new Map([...updatedValues.entries(), ...returned.entries()]);
            } else if (formControl instanceof UntypedFormArray &&
                        formControl.dirty &&
                        formControl.controls.length > 0) {
              const returned = this.getUpdatedValues(formControl);
              updatedValues = new Map([...updatedValues.entries(), ...returned.entries()]);
            } else if (formControl instanceof UntypedFormGroup && formControl.dirty) {
              const returned = this.getUpdatedValues(formControl);
              updatedValues = new Map([...updatedValues.entries(), ...returned.entries()]);
            }
        });
      }
      return updatedValues;
    }

  /**
   * Utility method that will convert input object into FormData
   *
   * @param data: Object contains key-value pair data
   * @return FormData : formdata with key and value from input data
   */
  static getFormDataFromObj(data: any): FormData {
    const formData = new FormData();

    this.appendFormData(formData, data, '');

    return formData;
  }

  /**
   * Retains only Alphabets and numbers in the string. Replaces everything else with underscore.
   * It ensures only one underscore instead of consecutive underscores.
   *
   * @param input: string
   * @return string: with special characters replaced by underscore
   */
  static replaceSpecialCharsWithUnderscore (input: string): string {
    const output = input.replace(/[^a-zA-Z0-9]/g,'_').replace(/_{2,}/g,'_');
    return output;
  }

  /*
   * This is the utility method that will append key-value from data into FormData.
   * This is called recursively to take care of arrays that might be present in data object
   */
  private static appendFormData(formData: FormData, data: any, rootName: string): void {

    const root: string = rootName || '';
    if (data instanceof File) {
      // get the file name without the extension
      const fileNameSansExtension = data.name.substring(0, data.name.lastIndexOf('.'));
      const fileExtension = data.name.substring(data.name.lastIndexOf('.'));
      // in the file name, replace special characters with underscores
      let newFileName = this.replaceSpecialCharsWithUnderscore(fileNameSansExtension);
      newFileName = newFileName + fileExtension;
      formData.append(root, data, newFileName);
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        this.appendFormData(formData, data[i], root + '[' + i + ']');
      }
    } else if (typeof data === 'object' && data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (root === '') {
            this.appendFormData(formData, data[key], key);
          } else {
            this.appendFormData(formData, data[key], root + '.' + key);
          }
        }
      }
    } else {
      if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data);
      }
    }
  }
}
