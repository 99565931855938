<div class="common-container edit-account-container" *ngIf="canUserEditAccount()">
    <form class="edit-account-form" [formGroup]="editAccountForm" autocomplete="off">
      <div class="flex-container edit-account" fxLayout="column">
        <div fxLayout="row" class="associateCategories">
          <div fxFlex="10" class="container-label">
            <div class="applicationName-label">
              <mat-label i18n="@@editAccountForm.iconUpload">Icon Upload</mat-label>
              <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId"
                              formControlName="accountIcon"
                              (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId"></app-adx-image-input>
            </div>
          </div>
          <div fxFlex="90" class="applicationName-input-head">
            <div class="applicationName-input">
              <mat-form-field class="input-form">
                  <input matInput id="input-edit-account-name" #editAccountFormaccountName maxlength="150" i18n="@@editAccountForm.accountName" placeholder="Account Title" formControlName="accountTitle" required/>
                  <mat-error>
                    This field is required
                  </mat-error>
              </mat-form-field>
              <mat-hint class="hintend">{{editAccountFormaccountName.value.length}} / 150</mat-hint>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

          <div class="new-account-name" fxLayout="row" fxLayout.lt-md="column">
            <div class="input-label" fxFlex="10">
            </div>
            <div class="input-field" fxLayout="column" fxFlex="90">
              <mat-form-field class="input-form">
                <input type='text' id="input-edit-account-summary" matInput #addAccountFormsummary maxlength="150" i18n="@@addAccountForm.summary" placeholder="Summary" formControlName="summary" />
                <mat-hint align="end">{{addAccountFormsummary.value.length}} / 150</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="new-account-name" fxLayout="row" fxLayout.lt-md="column">
            <div class="input-label" fxFlex="10">
            </div>
            <div class="input-field" fxLayout="column" fxFlex="44">
              <mat-form-field>
                <input matInput id="input-edit-account-number" #addAccountFormaccountNumber maxlength="50" i18n="@@addAccountForm.accountNumber" placeholder="Account Number" formControlName="accountNumber" />
                <mat-hint align="end">{{addAccountFormaccountNumber.value.length}} / 50</mat-hint>
              </mat-form-field>
            </div>
            <div class="input-label" fxFlex="1">
            </div>
            <div class="input-field" fxLayout="column" fxFlex="44">
              <mat-form-field>
                <input matInput id="input-edit-account-name" #addAccountFormaccountName maxlength="50" i18n="@@addAccountForm.accountName" placeholder="Account Name" formControlName="accountName" />
                <mat-hint align="end">{{addAccountFormaccountName.value.length}} / 50</mat-hint>
              </mat-form-field>
            </div>
            <div class="input-label" fxFlex="1">
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="new-account-name" fxLayout="row" fxLayout.lt-md="column">
            <div class="input-label" fxFlex="10">
            </div>
            <div class="input-field" fxLayout="column" fxFlex="22">
              <mat-form-field class="input-form">
                <mat-label i18n="@@addAccountForm.startDate">Start Date</mat-label>
                <input matInput id="input-edit-account-startDate" [matDatepicker]="startDate" formControlName='startDate'>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="input-field" fxLayout="column" fxFlex="23">
              <mat-form-field class="input-form">
                <mat-label i18n="@@addAccountForm.endDate">End Date</mat-label>
                <input matInput id="input-edit-account-endDate" [matDatepicker]="endDate" formControlName='endDate'>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="input-field" fxLayout="column" fxFlex="44">
              <mat-form-field>
                <input type='text' id="input-edit-account-notes" #addAccountFormnotes maxlength="50" matInput i18n="@@addAccountForm.notes" placeholder="Account Notes" formControlName="accountNotes" />
                <mat-hint align="end">{{addAccountFormnotes.value.length}} / 50</mat-hint>
              </mat-form-field>
            </div>
            <div class="input-label" fxFlex="1">
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="new-account-name" fxLayout="row" fxLayout.lt-md="column">
            <div class="input-label" fxFlex="10">
            </div>
            <div class="input-field" fxLayout="column" fxFlex="45">
              <mat-form-field class="input-form">
                <mat-chip-list #tags>
                  <mat-chip *ngFor="let tag of tagList" [selectable]="tagSelectable" [removable]="tagRemovable" (removed)="removeTag(tag)">
                    {{tag}}
                    <mat-icon matChipRemove *ngIf="tagRemovable">cancel</mat-icon>
                  </mat-chip>
                  <input i18n="@@addAccountForm.tags" id="input-edit-account-tags" formControlName='accountTag' placeholder="Tags" [matChipInputFor]="tags" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="tagAddOnBlur" (matChipInputTokenEnd)="addTag($event)">
                </mat-chip-list>
              </mat-form-field>
            </div>
            <div class="input-field" fxLayout="column" fxFlex="44">
              <mat-form-field>
                <input matInput id="input-edit-account-websiteUrl" i18n="@@addAccountForm.websiteUrl" placeholder="Website URL" formControlName="websiteUrl" />
              </mat-form-field>
            </div>
            <div class="input-label" fxFlex="1">
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="tag" fxLayout="row" fxLayout.lt-md="column">
                <div class="input-label" fxFlex="10">
                </div>
                <div class="account-search-placeholder" fxFlex="90" fxLayout="row">
                    <mat-label i18n="@@editAccountForm.search">Customized search bar placeholder for clients</mat-label>
                </div>
          </div>

          <div class="tag" fxLayout="row" fxLayout.lt-md="column">
              <div class="input-label" fxFlex="10">
              </div>
              <div class="account-search-placeholder" fxFlex="90" fxLayout="row" fxLayoutGap="50px">
                  <mat-form-field appearance="outline" class="account-search-input" fxLayout="column" fxFlex="49">
                      <input matInput id="input-edit-account-search" i18n="@@editAccountForm.accountSearch" formControlName="accountPlaceholder"/>
                  </mat-form-field>
                  <div class="account-client-search" fxLayout="column" fxFlex="49">
                      <mat-form-field appearance="outline">
                          <mat-icon matSuffix>search</mat-icon>
                          <input matInput id="input-edit-account-searchPlaceHolder" type="search" placeholder={{searchPlaceholder}} class="search-input">
                      </mat-form-field>
                  </div>
              </div>
          </div>

            <mat-divider></mat-divider>

            <div fxLayout="row">
                    <div fxFlex="10" class="common-label">
                    </div>
                    <div fxFlex="90" class="common-desc-editor">
                        <div class="common-address-editor" formGroupName='accountAddress'>
                          <mat-label i18n="@@editAccountForm.address">Address</mat-label>
                            <mat-form-field class="street1">
                                <input matInput i18n="@@editAccountForm.street1" id="input-edit-account-street1" maxlength="80" formControlName="street1" placeholder="Street1" #street1>
                                <mat-hint align="end">{{street1.value.length}} / 80</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="street2">
                                <input matInput i18n="@@editAccountForm.street2" id="input-edit-account-street2" maxlength="80" formControlName="street2" placeholder="Street2" #street2>
                                <mat-hint align="end">{{street2.value.length}} / 80</mat-hint>
                            </mat-form-field>
                            <div fxLayout="column" class="city-state-details">
                                <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                                    <mat-form-field class="city">
                                        <input matInput i18n="@@editAccountForm.city" id="input-edit-account-city" formControlName="city" placeholder="City">
                                    </mat-form-field>
                                    <mat-form-field class="state">
                                        <input matInput i18n="@@editAccountForm.state" id="input-edit-account-state" formControlName="state" placeholder="State">
                                    </mat-form-field>
                                </div>
                                <div fxLayoutAlign="space-between center zipcode-country-details" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                                    <mat-form-field class="zipcode">
                                        <input matInput i18n="@@editAccountForm.zipcode" id="input-edit-account-zipcode" formControlName="zipcode" placeholder="Zipcode">
                                    </mat-form-field>
                                    <mat-form-field class="country">
                                        <mat-select formControlName="country" placeholder='Country' id="input-edit-account-country">
                                            <mat-option *ngFor="let cnt of countries"  id="input-edit-account-country-{{cnt.id}}" [value]="cnt.id">{{cnt.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="selectTimezone">
                                        <mat-select formControlName="accountTimezone" placeholder='Timezone' id="input-edit-account-timezone">
                                            <mat-option *ngFor="let tz of timezones" [value]="tz.id" id="input-edit-account-timezone-{{tz.id}}">
                                                {{tz.abbreviation}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                    </div>
                  </div>

        <mat-divider></mat-divider>

        <mat-divider></mat-divider>

          <div class="style-container associateCategories" fxLayout="row" fxLayout.lt-md="row" formGroupName="accountStyle">
            <div class="common-label" fxFlex="10" fxLayout="column">
            </div>
            <div class="style-input" fxLayout="column" fxFlex="80">
              <mat-label i18n="@@editAccountForm.style">Style</mat-label>
                <table>
                    <tr>
                        <th class="numOfTabs">
                            <mat-label i18n="@@editAccountForm.numOfTabs">Number Of Tabs</mat-label>
                        </th>
                        <th class="common-numOfTabs-label">
                            <mat-form-field fxFlex="10" class="account-style-numOfTabs-input">
                                <input matInput type="number" i18n="@@editAccountForm.numOfTabs" id="input-edit-account-numOfTabs"
                                        formControlName="numOfTabs" min="1" max="4">
                              </mat-form-field>
                            </th>
                    </tr>
                </table>
                <table>
                  <tr>
                      <td class='commonTitletd'>Top bar Background color</td>
                      <td class='common-td'>
                          <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-account-styleTopBarBackgroundColor" formControlName="topBarBackgroundColor">
                          </ngx-colors>
                      </td>
                      <td rowspan="4" class='common-td simulatorLookNFeel'>
                          <div class="flex-container" fxLayout="column" fxLayout.lt-md="column">
                              <div class="simulator-topbar" fxLayout="row" [style.background-color]="topBarBackgroundColor" [style.color]="topBarTextColor">
                                  <div class="simulator-topbar-text" fxFlex="100">
                                      Top Bar
                                  </div>
                              </div>
                              <div class="simulator-contentbar" fxLayout="row">
                                  Conveyer
                              </div>
                              <div class="simulator-navbar" fxLayout="column" [style.background-color]="backgroundColor" [style.color]="backgroundTextColor">
                                  <div class="simulator-navbar-icon" fxFlex="100">
                                      <img class="iconImageSimulator" src="assets/images/app-icon.png" alt="image"/>
                                  </div>
                                  <div class="simulator-navbar-text" fxFlex="100">
                                      Navigation
                                  </div>
                              </div>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td class='commonTitletd'>Top bar Text color</td>
                      <td class='common-td'>
                          <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-account-styleTopBarTextColor" formControlName="topBarTextColor">
                          </ngx-colors>
                      </td>
                  </tr>
                  <tr>
                      <td class='commonTitletd'>Navigation bar Background color</td>
                      <td class='common-td'>
                          <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-account-styleBackgroundColor" formControlName="backgroundColor">
                          </ngx-colors>
                      </td>
                  </tr>
                  <tr>
                      <td class='commonTitletd'>Navigation bar Text color</td>
                      <td class='common-td'>
                          <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-account-styleBackgroundTextColor" formControlName="backgroundTextColor">
                          </ngx-colors>
                      </td>
                  </tr>
              </table>
            </div>
            </div>

        <mat-divider></mat-divider>

        <mat-form-field class="editOrg-desc">
          <mat-label i18n="@@editAccountForm.description">Description</mat-label>
          <textarea name="desc-editor" id="input-edit-account-description" formControlName="accountDescription"
                    matInput [errorStateMatcher]="adxErrorStateMatcher" #accountDescription
                    [froalaEditor]="froalaEditorOptions(450)"
                    (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
          <mat-hint align="end">{{accountDescription.value.length}} / 450</mat-hint>
          <mat-error *ngIf="editAccountForm.get('accountDescription')?.hasError('maxlength')">
            {{accountDescription.value.length}} exceeds max limit of 450 HTML characters
          </mat-error>
      </mat-form-field>

      <mat-divider></mat-divider>

        <mat-form-field fxLayout="column" class="editOrg-desc">
          <mat-label i18n="@@editOrgForm.aboutScreen">About Screen</mat-label>
          <textarea [froalaEditor]="options" formControlName="aboutUs" #orgAbout
                    matInput [errorStateMatcher]="adxErrorStateMatcher"></textarea>
          <mat-hint align="end">{{orgAbout.value.length}} / 10000</mat-hint>
          <mat-error *ngIf="editAccountForm.get('aboutUs')?.hasError('maxlength')">
            {{orgAbout.value.length}} exceeds max limit of 10000 HTML characters
          </mat-error>
        </mat-form-field>
        <mat-divider></mat-divider>
        <mat-form-field class="editOrg-desc">
          <mat-label i18n="@@editOrgForm.helpScreen">Help Screen</mat-label>
          <textarea [froalaEditor]="options" formControlName="helpInfo" #orgHelp
                    matInput [errorStateMatcher]="adxErrorStateMatcher"></textarea>
          <mat-hint align="end">{{orgHelp.value.length}} / 10000</mat-hint>
          <mat-error *ngIf="editAccountForm.get('helpInfo')?.hasError('maxlength')">
            {{orgHelp.value.length}} exceeds max limit of 10000 HTML characters
          </mat-error>
        </mat-form-field>

        <mat-divider></mat-divider>

        <div class="editOrg-exclude-module">
          <mat-label i18n="@@editOrgForm.excludeModule">Include / Exclude Modules for this Account</mat-label>
          <dual-list [sort]="keepSorted" [source]="allModules" [format]="format"
                     (destinationChange)="onModuleExclude($event)"
                     [(destination)]="excludedModules" height="180px">
          </dual-list>
        </div>

        <mat-divider></mat-divider>

        </div>
        <div class="button-container">
          <button mat-raised-button i18n="@@editAccountForm.cancel" type="button" class="button-cancel" id="input-edit-account-cancel-Btn" [routerLink]="'/orgs/' + selectedOrgId + '/accts'">Cancel</button>
          <button mat-raised-button i18n="@@editAccountForm.save" type="button"
                  id="input-edit-account-save-Btn"
                  [disabled]="!(editAccountForm.valid && (editAccountForm.dirty || excludeModuleDualBoxChanged))"
                  (click)="editAccount()">Save</button>
        </div>

    </form>
</div>

