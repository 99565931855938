<form [formGroup]="searchUserForm" class="adx-user-search">
  <mat-form-field class="adx-user-search-input">
    <input matInput placeholder="Search User by Username" formControlName="searchUser" required="true" type="email"/>
    <mat-error>
      This is a required field!
    </mat-error>
  </mat-form-field>
<div class="adx-user-search-action">
  <button mat-raised-button color="primary" (click)="onSearch()" [disabled]="canDisable()">
    <mat-icon class="mat-18">search</mat-icon>
  </button>
</div>
</form>
