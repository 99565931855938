<div fxLayout="column" class="insert-embed-container">     
  <div class="row">
    <div fxFlex="100">
    <h4 class="embedContent">
      <span i18N="@embed-header">Insert your embed code below</span>
    </h4>
  </div>
  </div>
  <div fxFlex="100" class="embed-container">
    <div fxLayout="row" [formGroup]="insertEmbedForm">
      <div fxFlex="80" class="input-category-label">
      <mat-form-field class="input-form">
        <mat-label>Embed Content</mat-label>
        <textarea matInput
                  placeholder="<iframe width='x' height='y' src='https://my-embed-url.com' frameborder='0' allowfullscreen></iframe>"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="9"
                  formControlName="embed">
        </textarea>
      </mat-form-field>
      </div>
      <div fxFlex="20">
        <button mat-raised-button i18n="@@editAccountForm.save" type="button" [disabled]="!(insertEmbedForm.valid && insertEmbedForm.dirty)" (click)="insertEmbed()">Save</button>
      </div>
    </div>
  </div>
</div>
