import { AdxStyle } from 'src/app/common/model/adx-style.model';
import { AdxTimezone } from 'src/app/common/model/adx-timezone.model';
import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { Organization } from 'src/app/organization/model/organization.model';
import { Address } from '../../common/model/address.model';
import { Account } from './account.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxAccountProperty} from './adx-account-property.model';
import {AdxPopupInfo} from '../../common/model/adx-popup-info.model';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';

export class AccountSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {

    const acct: Account = new Account(json.id, json.title);
    acct.description = json.description;
    acct.summary = json.summary;
    acct.accountName = json.accountName;
    acct.accountNumber = json.accountNumber;
    acct.notes = json.notes;
    acct.startDate = json.startDate;
    acct.endDate = json.endDate;
    acct.websiteUrl = json.websiteUrl;
    acct.visibility = json.visibility;
    acct.tier = json.tier;
    acct.tags = json.tags;
    acct.signinEnabled = json.signinEnabled;
    acct.weblinkShareEnabled = json.webLinkShareEnabled;

    if (json.iconImage) {
      acct.iconImage = AdxImage.buildObject(json.iconImage);
    }

    if (json.organization) {
      const org: Organization = new Organization(json.organization.id, json.organization.secret);
      if (json.organization.title) {
        org.title = json.organization.title;
      }
      if (json.organization.description) {
        org.description = json.organization.description;
      }
      if (json.organization.helpInfo) {
        org.helpInfo = AdxHelpInfo.buildObject(json.organization.helpInfo);
      }
      if (json.organization.aboutUsInfo) {
        org.aboutUsInfo = AdxAboutUsInfo.buildObject(json.organization.aboutUsInfo);
      }
      if (json.organization.style) {
        const styleObj: AdxStyle = AdxStyle.buildObject(json.organization.style);
        org.style = styleObj;
      }

      if (json.organization.address) {
        const addrObj: Address = Address.buildObject(json.organization.address);
        org.address = addrObj;
      }

      if (json.organization.timeZone) {
        const timezone: AdxTimezone = AdxTimezone.buildObject(json.organization.timeZone);
        org.timeZone = timezone;
      }
      acct.organization = org;
    }

    if (json.style) {
      const styleObj: AdxStyle = AdxStyle.buildObject(json.style);
      acct.style = styleObj;
    }

    if (json.address) {
      const addrObj: Address = Address.buildObject(json.address);
      acct.address = addrObj;
    }

    if (json.timeZone) {
      const timezone: AdxTimezone = AdxTimezone.buildObject(json.timeZone);
      acct.timeZone = timezone;
      acct.timeZoneId = timezone.id;
    }
    if (json.timeZoneId !== undefined && json.timeZoneId !== null) {
      acct.timeZoneId = json.timeZoneId;
    }

    if (json.sortOrder !== undefined && json.sortOrder !== null) {
      acct.sortOrder = json.sortOrder;
    }

    if (json.validEmailDomains) {
      acct.validEmailDomains = AdxAccountProperty.buildObject(json.validEmailDomains);
    }

    if (json.simulatorDisplayProp) {
      acct.simulatorDisplayProp = AdxAccountProperty.buildObject(json.simulatorDisplayProp);
    }

    if (json.popupInfo) {
      acct.popupInfo = AdxPopupInfo.buildObject(json.popupInfo);
    }
    if (json.excludedModuleTypes) {
      acct.excludedModuleTypes = json.excludedModuleTypes;
    }
    if (json.helpInfo) {
      acct.helpInfo = AdxHelpInfo.buildObject(json.helpInfo);
    }
    if (json.aboutUsInfo) {
      acct.aboutUsInfo = AdxAboutUsInfo.buildObject(json.aboutUsInfo);
    }

    return acct;
  }

  toJson(resource: AdxResource): string {
    const json = JSON.stringify(resource);
    return json;
  }
}
