import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AdxUser } from '../model/adx-user-model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AdxUserUtility } from '../adx-user-utils/adx-user-utility';
import { Subject, of, takeUntil } from 'rxjs';
import { Account } from 'src/app/account/model/account.model';
import { AccountService } from 'src/app/account/service/account.service';
import { AdxModule } from 'src/app/adx-module/model/adx-module.model';
import { AdxModuleService } from 'src/app/adx-module/service/adx-module.service';
import { AdxApplication } from 'src/app/application/model/adx-application.model';
import { AdxApplicationService } from 'src/app/application/service/adx-application.service';
import { AdxChannel } from 'src/app/channel/model/adx-channel.model';
import { AdxChannelService } from 'src/app/channel/service/adx-channel.service';
import { Organization } from 'src/app/organization/model/organization.model';
import { OrganizationService } from 'src/app/organization/service/organization.service';
import { AdxVPub } from 'src/app/vPub/model/adx-vpub.model';
import { AdxVpubService } from 'src/app/vPub/service/adx-vpub.service';
import { AdxUserRole } from '../model/adx-user-role-model';
import { AdxUserService } from '../service/adx-user.service';
import { CustomMessageType } from 'src/app/core/service/notifier/custom-message-type';
import { CustomNotifierService } from 'src/app/core/service/notifier/custom-notifier.service';
import { AdxDisplayLevel } from 'src/app/common/model/data-transfer/adx-display-level';
import { AdxModuleType } from 'src/app/adx-module/model/adx-module-type';

/**
 * Component used to edit the user roles
 * Provides ability to search for user, for the valid searched user, will list the existing roles.
 * It also allows to add additional roles to the user
 */
@Component({
  selector: 'app-adx-user-role-edit',
  templateUrl: './adx-user-role-edit.component.html',
  styleUrls: ['./adx-user-role-edit.component.scss']
})
export class AdxUserRoleEditComponent extends AdxBaseTemplate implements OnInit, OnChanges {

  @Input() orgId: number | null = null; // ORG level userMgmt - orgId for which the user role needs to be modified
  @Input() accountId: number | null = null; // ACCOUNT level user Mgmt - account id for which the user role needs to be modified
  @Input() displayLevel = AdxDisplayLevel.ORG_LEVEL; // display level. default, organization. can be set as ACCOUNT using input param

  orgLevelApplicableUserRoles: AdxUserRole[] = []; //contains the user roles for current organization
  orgLevelNonApplicableUserRoles: AdxUserRole[] = []; //user roles that do not belong to current organization

  accountLevelApplicableUserRoles: AdxUserRole[] = []; //contains the user roles for current account
  accountLevelNonApplicableUserRoles: AdxUserRole[] = []; //user roles that do not belong to current account

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedUser: AdxUser | null = null; //the user returned by the user search

  private orgTitles: { orgId: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime
  private accountTitles: { id: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime
  private applicationTitles: { id: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime
  private channelTitles: { id: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime
  private vpubTitles: { id: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime
  private moduleTitles: { id: number; title: string | null }[] = []; //used to avoid fetching data from backend everytime

  private screenDisplayLevel = 'ORGANIZATION';

  constructor (private readonly orgService: OrganizationService,
    private readonly accountService: AccountService,
    private readonly applnService: AdxApplicationService,
    private readonly channelService: AdxChannelService,
    private readonly vpubService: AdxVpubService,
    private readonly moduleService: AdxModuleService,
    private readonly userService: AdxUserService,
    private readonly messageNotifier: CustomNotifierService) {
    super();
  }

  ngOnInit(): void {
    // init
    if (this.displayLevel === AdxDisplayLevel.ACCOUNT_LEVEL) {
      this.screenDisplayLevel = 'ACCOUNT';
    }

    //if orgId and accountId are specified, get all titles populated
    if (this.orgId && this.accountId) {
      this.fetchAllApplications(this.orgId, this.accountId);
      this.fetchAllChannels(this.orgId, this.accountId);
    }
  }

  /**
   * called when then input changes
   */
  ngOnChanges(): void {
    this.logger.debug(`RoleEdit OnChanges called orgId:${this.orgId} selUser: ${this.selectedUser?.firstName}`);
    this.setRoleArrays();
  }

  /**
   * Called on getting result from search user child component
   */
  updateSearchResult(input: AdxUser | null): void {
    this.logger.debug(input);
    this.selectedUser = input;
    this.setRoleArrays();
    this.logger.debug(`added ${input?.username} to the list`);
  }

  /**
   * callback on click of add button for user role add
   *
   * @param userRole role to be added
   */
  onRoleAdd(userRole: AdxUserRole): void {
    if (userRole) {
      this.logger.debug(`Adding role ${userRole.role?.id}`);
      if (this.screenDisplayLevel === 'ORGANIZATION') {
        this.orgLevelApplicableUserRoles.push(userRole);
      }
      else {
        this.accountLevelApplicableUserRoles.push(userRole);
      }
      this.updateUserRoles();
    }
  }

  /**
   * Callback on click of delete button for user role delete
   * @param userRolesToDelete the array with userrole objects to be deleted
   */
  onDeleteRoles(userRolesToDelete: AdxUserRole[]): void {
    this.logger.debug(`onDeleteRoles ${userRolesToDelete.length}`);
    this.logger.debug(userRolesToDelete);
    let applicableRoles: AdxUserRole[] = [];
    if (this.screenDisplayLevel === 'ORGANIZATION') {
      applicableRoles = this.orgLevelApplicableUserRoles;
    }
    else {
      applicableRoles = this.accountLevelApplicableUserRoles;
    }
    // from the applicable roles, delete the roles from input list
    for (const delUserRole of userRolesToDelete) {
      const indexToDelete = applicableRoles.findIndex((obj) => (this.canDeleteRole(obj, delUserRole)));

      this.logger.debug(`index to delete ${indexToDelete} : ${delUserRole.role?.title} :: ${delUserRole.accountId}`);
      if (indexToDelete !== -1) {
        applicableRoles.splice(indexToDelete, 1);
      }
    }
    this.logger.debug(applicableRoles);
    //update the role
    this.updateUserRoles();
  }

  /*
   * for selected user, adds all roles not belonging to current org. Then adds all roles for current organization
   * calls backend code to update user
  */
  private updateUserRoles(): void {
    if (this.selectedUser) {
      // first add all roles that are from different org. They would not have been changed
      this.selectedUser.organizationRoles = [];
      this.selectedUser.accountRoles = [];
      this.selectedUser.applicationRoles = [];
      this.selectedUser.channelRoles = [];
      this.selectedUser.vpubRoles = [];
      this.selectedUser.moduleRoles = [];

      if (this.screenDisplayLevel === 'ORGANIZATION') {

        // first add all non-applicable roles
        const diffOrgUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'ORGANIZATION');
        if (diffOrgUserRoles) {
          this.selectedUser.organizationRoles = diffOrgUserRoles;
        }
        const acctUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'ACCOUNT');
        if (acctUserRoles) {
          this.selectedUser.accountRoles = acctUserRoles;
        }
        const applnUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'APPLICATION');
        if (applnUserRoles) {
          this.selectedUser.applicationRoles = applnUserRoles;
        }
        const channelUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'CHANNEL');
        if (channelUserRoles) {
          this.selectedUser.channelRoles = channelUserRoles;
        }
        const vpubUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'VPUB');
        if (vpubUserRoles) {
          this.selectedUser.vpubRoles = vpubUserRoles;
        }
        const moduleUserRoles = AdxUserUtility.filterRolesByScope(this.orgLevelNonApplicableUserRoles, 'MODULE');
        if (moduleUserRoles) {
          this.selectedUser.moduleRoles = moduleUserRoles;
        }

        // get all roles currently applicable and add them
        const orgLevelRoles = AdxUserUtility.filterRolesByScope(this.orgLevelApplicableUserRoles, 'ORGANIZATION');
        this.logger.debug(orgLevelRoles);
        if (orgLevelRoles) {
          this.selectedUser.organizationRoles.push(...orgLevelRoles);
        }
      }
      else { // account level
        // first add all non-applicable roles
        const orgUserRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'ORGANIZATION');
        if (orgUserRoles) {
          this.selectedUser.organizationRoles = orgUserRoles;
        }

        const diffOrgAccountRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'ACCOUNT');
        if (diffOrgAccountRoles) {
          this.selectedUser.accountRoles = diffOrgAccountRoles;
        }
        const diffOrgApplnRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'APPLICATION');
        if (diffOrgApplnRoles) {
          this.selectedUser.applicationRoles = diffOrgApplnRoles;
        }
        const diffOrgChannelRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'CHANNEL');
        if (diffOrgChannelRoles) {
          this.selectedUser.channelRoles = diffOrgChannelRoles;
        }
        const diffOrgVpubRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'VPUB');
        if (diffOrgVpubRoles) {
          this.selectedUser.vpubRoles = diffOrgVpubRoles;
        }
        const diffOrgModuleRoles = AdxUserUtility.filterRolesByScope(this.accountLevelNonApplicableUserRoles, 'MODULE');
        if (diffOrgModuleRoles) {
          this.selectedUser.moduleRoles = diffOrgModuleRoles;
        }

        // now add all applicable roles
        const accountRoles = AdxUserUtility.filterRolesByScope(this.accountLevelApplicableUserRoles, 'ACCOUNT');
        this.logger.debug(accountRoles);
        if (accountRoles) {
          this.selectedUser.accountRoles.push(...accountRoles);
        }
        const applnRoles = AdxUserUtility.filterRolesByScope(this.accountLevelApplicableUserRoles, 'APPLICATION');
        if (applnRoles) {
          this.selectedUser.applicationRoles.push(...applnRoles);
        }
        const channelRoles = AdxUserUtility.filterRolesByScope(this.accountLevelApplicableUserRoles, 'CHANNEL');
        if (channelRoles) {
          this.selectedUser.channelRoles.push(...channelRoles);
        }
        const vpubRoles = AdxUserUtility.filterRolesByScope(this.accountLevelApplicableUserRoles, 'VPUB');
        if (diffOrgVpubRoles) {
          this.selectedUser.vpubRoles.push(...vpubRoles);
        }
        const moduleRoles = AdxUserUtility.filterRolesByScope(this.accountLevelApplicableUserRoles, 'MODULE');
        if (moduleRoles) {
          this.selectedUser.moduleRoles.push(...moduleRoles);
        }
      }

      // update user
      this.updateUser(this.selectedUser);
    }
  }

  /*
   * Utility to help decide whether given role is to be deleted
   */
  private canDeleteRole(userRole: AdxUserRole, roleToDelete: AdxUserRole): boolean {
    let returnVal = false;
    if (userRole === undefined || userRole === null || roleToDelete === undefined || roleToDelete === null) {
      return returnVal;
    }
    // if entity are not same, no need to proceed further
    if (userRole.entityType !== roleToDelete.entityType) {
      return returnVal;
    }

    // if roles are not same, no need to proceed further
    if (userRole.role?.id !== roleToDelete.role?.id) {
      return returnVal;
    }

    this.logger.debug(userRole);
    this.logger.debug(roleToDelete);

    // now check whether orgIds are same and if account id is mentioned, they are same
    if (userRole.organizationId === roleToDelete.organizationId) {
      if (roleToDelete.accountId !== undefined && roleToDelete.accountId !== null) {
        if (userRole.accountId === roleToDelete.accountId) {
          // now check whether application id or channel id are defined
          if (roleToDelete.applicationId !== undefined && roleToDelete.applicationId !== null) {
            if (userRole.applicationId === roleToDelete.applicationId) {
              // now check whether vpub id is defined
              if (roleToDelete.vpubId !== undefined && roleToDelete.vpubId !== null) {
                if (userRole.vpubId === roleToDelete.vpubId) {
                  // now check whether module id is defined
                  if (roleToDelete.moduleId !== undefined && roleToDelete.moduleId !== null) {
                    if (userRole.moduleId === roleToDelete.moduleId) {
                      returnVal = true;
                    }
                    else { //moduleId id is specified, but not same; return false
                      returnVal = false;
                    }
                  }
                  else { //vpubId are same and moduleId not specified. return true
                    returnVal = true;
                  }
                }
                else { //vpub id is specified, but not same; return false
                  returnVal = false;
                }
              }
              else { //applicationid are same and vpubid not specified. return true
                returnVal = true;
              }
            }
            else { //applicationId specified, but not same. return false
              returnVal = false;
            }
          } // now chec whether channelId specified
          else if (roleToDelete.channelId !== undefined && roleToDelete.channelId !== null) {
            if (userRole.channelId === roleToDelete.channelId) {
              returnVal = true;
            }
            else { //channelId is specified, but not same; return false
              returnVal = false;
            }
          }
          else {
            // accountId specified and they are same. return true
            this.logger.debug(`accountId: ${roleToDelete.accountId} role:: ${roleToDelete.role?.title}`);
            returnVal = true;
          }
        }
        else { //accountId specified, but not same. return false
          returnVal = false;
        }
      }
      else { //orgId are same and accountId not specified. return true
        returnVal = true;
      }
    }

    this.logger.debug(`canDeleteRole returning ${returnVal}`);
    return returnVal;
  }

  /*
   * calls service to update user
  */
  private updateUser(inputUser: AdxUser): void {
    if (inputUser && inputUser.id !== undefined && inputUser.id !== null) {
      this.userService.updateUserRole(inputUser)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxUser) => {
            // set user variable
            this.selectedUser = data;
            this.setRoleArrays();
            this.logger.debug(this.selectedUser);
          },
          error: () => {
            // navigate user to listing screen
            this.messageNotifier.notify(CustomMessageType.ERROR, `${this.selectedUser?.username} updation failed.`);
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `${this.selectedUser?.username} updated.`);
          }
        });
    }
  }

  /*
   * utility method that populates applicableRoles and userRolesNotBelongingToCurrentOrg arrays
  */
  private setRoleArrays() {
    if (this.accountId !== undefined && this.accountId !== null
      && this.selectedUser !== undefined && this.selectedUser !== null) {
      let applicableRoles = AdxUserUtility.filterUserRolesBasedOnAccount(this.selectedUser, this.accountId, this.screenDisplayLevel);
      if (applicableRoles !== null && applicableRoles.length > 0) {
        applicableRoles = AdxUserUtility.filterAssignableRoles(applicableRoles);
        applicableRoles.forEach(userRole => this.setTitle(userRole));
        this.accountLevelApplicableUserRoles = applicableRoles;
      }
      const nonApplicableRoles = AdxUserUtility.excludeUserRolesBasedOnAccount(this.selectedUser, this.accountId, this.screenDisplayLevel);
      if (nonApplicableRoles !== null && nonApplicableRoles.length > 0) {
        this.accountLevelNonApplicableUserRoles = nonApplicableRoles;
      }
    }
    else if (this.orgId !== undefined && this.orgId !== null
      && this.selectedUser !== undefined && this.selectedUser !== null) {
      let applicableRoles = AdxUserUtility.filterUserRolesBasedOnOrganization(this.selectedUser, this.orgId, this.screenDisplayLevel);
      if (applicableRoles !== null && applicableRoles.length > 0) {
        applicableRoles = AdxUserUtility.filterAssignableRoles(applicableRoles);
        applicableRoles.forEach(userRole => this.setTitle(userRole));
        this.orgLevelApplicableUserRoles = applicableRoles;
      }
      const nonApplicableRoles = AdxUserUtility.excludeUserRolesBasedOnOrganization(this.selectedUser, this.orgId, this.screenDisplayLevel);
      if (nonApplicableRoles !== null && nonApplicableRoles.length > 0) {
        this.orgLevelNonApplicableUserRoles = nonApplicableRoles;
      }
    }
    else {
      // as orgId or selectedUser is undefined, reset the roles
      this.orgLevelApplicableUserRoles = [];
      this.orgLevelNonApplicableUserRoles = [];
      this.accountLevelNonApplicableUserRoles = [];
    }
    this.logger.debug(`orgId: ${this.orgId} :: accountId: ${this.accountId} :: applicable roles`);
    this.logger.debug(this.orgLevelApplicableUserRoles);
    this.logger.debug(this.accountLevelNonApplicableUserRoles);
    this.logger.debug(this.orgLevelNonApplicableUserRoles);

    //remove all roles that have blank module titles
    this.accountLevelApplicableUserRoles = this.accountLevelApplicableUserRoles.filter(
      role => this.filterOutRolesWithBlankModuleTitle(role));
  }

  /*
   * Assumption is that all the titles are populated during component init
   * for given role, based on entity type and entity id, set the title to be displayed in the user role listing ui
  */
  private setTitle(userRole: AdxUserRole): void {
    this.logger.debug(userRole);
    if (userRole) {
      const organizationId = userRole.organizationId;
      const accountId = userRole.accountId;
      const applnId = userRole.applicationId;
      const channelId = userRole.channelId;
      const vpubId = userRole.vpubId;
      const moduleId = userRole.moduleId;

      this.logger.debug(`organizationId: ${organizationId}`);

      // check if module
      if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null
        && vpubId !== undefined && vpubId !== null
        && moduleId !== undefined && moduleId !== null) {
        let modTitle: string | null = null;
        for (const obj of this.moduleTitles) {
          if (obj && obj.id === moduleId) {
            modTitle = obj.title;
            break;
          }
        }
        if (modTitle !== undefined && modTitle !== null) {
          userRole.moduleTitle = modTitle;
          userRole.entityType = 'MODULE';
        }
        else {
          this.logger.warn(`Module Title NOT present for id ${moduleId}`);
        }
      }
      //check if vpub
      else if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null
        && applnId !== undefined && applnId !== null
        && vpubId !== undefined && vpubId !== null) {
        let vpubTitle: string | null = null;
        for (const obj of this.vpubTitles) {
          if (obj && obj.id === vpubId) {
            vpubTitle = obj.title;
            break;
          }
        }
        if (vpubTitle !== undefined && vpubTitle !== null) {
          userRole.vpubTitle = vpubTitle;
          userRole.entityType = 'VPUB';
        }
        else {
          this.logger.warn(`vPub Title NOT present for id ${vpubId}`);
        }
      }
      //check if channel
      else if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null
        && channelId !== undefined && channelId !== null) {
        let channelTitle: string | null = null;
        for (const obj of this.channelTitles) {
          if (obj && obj.id === applnId) {
            channelTitle = obj.title;
            break;
          }
        }
        if (channelTitle !== undefined && channelTitle !== null) {
          userRole.channelTitle = channelTitle;
          userRole.entityType = 'CHANNEL';
        }
        else {
          this.logger.warn(`Channel Title NOT present for id ${channelId}`);
        }
      }
      //check if application
      else if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null
        && applnId !== undefined && applnId !== null) {
        let applnTitle: string | null = null;
        for (const obj of this.applicationTitles) {
          if (obj && obj.id === applnId) {
            applnTitle = obj.title;
            break;
          }
        }
        if (applnTitle !== undefined && applnTitle !== null) {
          userRole.applicationTitle = applnTitle;
          userRole.entityType = 'APPLICATION';
        }
        else {
          this.logger.warn(`application Title NOT present for id ${applnId}`);
        }
      }
      //check if account
      else if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null) {
        let accTitle: string | null = null;
        for (const obj of this.accountTitles) {
          if (obj && obj.id === accountId) {
            accTitle = obj.title;
            break;
          }
        }
        if (accTitle !== undefined && accTitle !== null) {
          userRole.accountTitle = accTitle;
          userRole.entityType = 'ACCOUNT';
        }
        else {
          this.accountService.getAccount(organizationId, accountId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (data: Account) => {
                // successfully received the data. so set it in variable
                if (data) {
                  userRole.accountTitle = data.title;
                  userRole.entityType = 'ACCOUNT';
                  this.accountTitles.push({ id: accountId, title: data.title });
                }
              }
            });
        }
      }
      //check if organization
      else if (organizationId !== undefined && organizationId !== null) {
        this.logger.debug(`organizationId: set orgTitle`);
        let orgTitle: string | null = null;
        for (const obj of this.orgTitles) {
          if (obj && obj.orgId === organizationId) {
            orgTitle = obj.title;
            break;
          }
        }
        if (orgTitle !== undefined && orgTitle !== null) {
          userRole.orgTitle = orgTitle;
          userRole.entityType = 'ORGANIZATION';
        }
        else {
          this.logger.debug(`organizationId: getting from backend`);
          this.orgService.getOrganization(organizationId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (data: Organization) => {
                // successfully received the data. so set it in variable
                if (data) {
                  this.logger.debug(`received from backend: ${data}`);
                  userRole.orgTitle = data.title;
                  userRole.entityType = 'ORGANIZATION';
                  this.orgTitles.push({ orgId: organizationId, title: data.title });
                }
              }
            });
        }
      }
    }
  }

  /*
   * For given role, check if the module title is blank.
   * If blank, return false, else return true.
  */
  private filterOutRolesWithBlankModuleTitle(userRole: AdxUserRole): boolean {
    if (userRole) {
      const organizationId = userRole.organizationId;
      const accountId = userRole.accountId;
      const vpubId = userRole.vpubId;
      const moduleId = userRole.moduleId;
      this.logger.debug(`filterOutRolesWithBlankModuleTitle called for moduleId ${userRole.moduleId}`);
      // check if module
      if (organizationId !== undefined && organizationId !== null
        && accountId !== undefined && accountId !== null
        && vpubId !== undefined && vpubId !== null
        && moduleId !== undefined && moduleId !== null) {
          this.logger.debug(`filterOutRolesWithBlankModuleTitle called for title: ${userRole.moduleTitle}`);
        if (userRole.moduleTitle === undefined || userRole.moduleTitle === null || userRole.moduleTitle.trim().length === 0) {
          return false;
        }
        else {
        }
      }
      return true;
    }
    return true;
  }

  /*
   * Fetch all applications and populate the applicationTitles map
   * For each application, fetch the vpubs
  */
  private fetchAllApplications(organizationId: number, acctId: number) {
    if (organizationId && acctId) {
      return this.applnService.getApplications(organizationId, acctId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxApplication[]) => {
            // successfully received the applications. now for each application fetch vpubs
            if(data) {
              data.forEach(appln => {
                if (appln && appln.id) {
                  this.applicationTitles.push({ id: appln.id, title: appln.title });
                  this.fetchAllVpubs(organizationId, acctId, appln.id);
                }
              });
            }
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }

  /*
   * Fetch all Channels and populate the channelTitles map
  */
  private fetchAllChannels(organizationId: number, acctId: number) {
    if (organizationId && acctId) {
      return this.channelService.getChannels(organizationId, acctId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxChannel[]) => {
            // successfully received the channels.
            if(data) {
              data.forEach(chnl => {
                if (chnl && chnl.id) {
                  this.channelTitles.push({ id: chnl.id, title: chnl.title });
                }
              });
            }
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }

  /*
   * Fetch all vPubs and populate the vpubTitles map
   * For each vPub, fetch the modules
  */
  private fetchAllVpubs(organizationId: number, acctId: number, applId: number) {
    if (organizationId && acctId && applId) {
      return this.vpubService.getVpubs(organizationId, acctId, applId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxVPub[]) => {
            // successfully received the vpubs. now for each vpub fetch modules
            if(data) {
              data.forEach(vpub => {
                if (vpub && vpub.id) {
                  this.vpubTitles.push({ id: vpub.id, title: vpub.title });
                  this.fetchAllModules(organizationId, acctId, vpub.id);
                }
              });
            }
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }

  /*
   * Fetch all Modules and populate the moduleTitles map
  */
  private fetchAllModules(organizationId: number, acctId: number, vpubId: number) {
    this.logger.debug('fetching modules for orgId:' + organizationId);
    return this.moduleService.getModules(organizationId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          const arsModules = this.convertModuleTreeToList(data);
          if(arsModules) {
            arsModules.forEach(modl => {
              if (modl && modl.id) {
                this.moduleTitles.push({ id: modl.id, title: modl.title });
              }
            });
            this.logger.debug(`All modules titles are set`);
          }
        }
      });
  }

  /*
   * Utility function to convert module tree to list of ars modules
   * @param inputTree: list received from backend
   */
  private convertModuleTreeToList(inputTree: AdxModule[]): AdxModule[] {
    const toRet: AdxModule[] = [];
    if (!inputTree || inputTree.length < 1) {
      return toRet;
    }

    for (const module of inputTree) {
      if (module) {
        if (module.type === AdxModuleType.GROUP) {
          toRet.push(...this.convertModuleTreeToList(module.children));
        }
        else {
          toRet.push(module);
        }
      }
    }
    return toRet;
  }

}
