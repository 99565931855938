import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdxImageInputComponent} from './adx-image-input.component';
import {MaterialModule} from '../../../common/material/material.module';
import {AdxImageLibraryModule} from '../../popups';
import {FlexLayoutModule} from '@angular/flex-layout';

/**
 * Module containing the ImageSelection component AdxImageInputComponent
 */
@NgModule({
  declarations: [
    AdxImageInputComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AdxImageLibraryModule,
    FlexLayoutModule
  ],
  exports: [
    AdxImageInputComponent
  ]
})
export class AdxImageInputModule { }
