import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/**
 * This is model class for ImageLibrary item that is similar to response received from backend.
 */
export class AdxImageLibraryItem extends AdxResource {
    organizationId: number | null;
    accountId: number | null = null;
    title: string;
    description: string;
    preSignedUrl: string | null;
    isFolder = false;
    contentType: string;
    dataSize: number;
    images: AdxImageLibraryItem[];
    createdByUser: string | null = null;
    createdUserFullname: string | null;
    createdOn: string | null;
    updatedByUser: string | null = null;
    updatedUserFullname: string | null;
    updatedOn: string | null;
    tags : string | null = null;
    applicationId: number | null = null;
    vpubId: number | null = null;
    moduleId: number | null = null;
    atomId: number | null = null;


    constructor(id: number, orgId: number | null, acctId: number | null, strTitle: string, strDesc: string,
                strUrl: string | null, isFolder: boolean, strContentType: string, imgSize: number,
                containedItems: AdxImageLibraryItem[], createdUserFullName: string | null,
                strCreatedDate: string | null, updatedUserFullname: string | null,
                strUpdatedDate: string | null, tags : string | null, applnId: number | null = null, vpubId: number | null = null, modlId: number | null = null, atomId: number | null = null) {
        super(id);
        this.organizationId = orgId;
        this.accountId = acctId;
        this.title = strTitle;
        this.description = strDesc;
        this.preSignedUrl = strUrl;
        this.isFolder = isFolder;
        this.contentType = strContentType;
        this.dataSize = imgSize;
        this.images = containedItems;
        this.createdUserFullname = createdUserFullName;
        this.createdOn = strCreatedDate;
        this.updatedUserFullname = updatedUserFullname;
        this.updatedOn = strUpdatedDate;
        this.tags = tags;
        this.applicationId = applnId;// adding additional info like application/vpub/module/atom id for saving an image which helps in filtering
        this.vpubId = vpubId;
        this.moduleId = modlId;
        this.atomId = atomId;
    }

    static buildObject(json: any): AdxImageLibraryItem {
      return new AdxImageLibraryItem(json.id, json.organizationId, json.accountId, json.title,
                          json.description, json.preSignedUrl, json.isFolder, json.contentType,
                          json.dataSize, json.images, json.createdByUser,
                          json.createdOn, json.updatedByUser, json.updatedOn,json.tags, json.applicationId, json.vpubId,json.moduleId,json.atomId);
    }    

    get modifiedBy(): string | null {
      if (this.updatedUserFullname) {
        return this.updatedUserFullname;
      } else {
        return this.createdUserFullname;
      }
    }

    get modifiedDate(): string | null {
      if (this.updatedOn) {
        return this.updatedOn;
      } else {
        return this.createdOn;
      }
    }
}
