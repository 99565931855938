<div class="dialog-header">
  <h2 mat-dialog-title style="align-content: center; font-size: 2rem">Validation Status</h2>
</div>

<div class="centered-flex">
  <div class="dialog-content">
    <div class="file-name-display">
      <div *ngIf="isStatusSuccess; else IMPORT_FAIL" class="icon-wrap">
        <mat-icon class="icon-class" style="color: green">insert_drive_file</mat-icon>
      </div>
      <ng-template #IMPORT_FAIL>
        <div class="icon-wrap">
          <mat-icon class="icon-class" style="color: red">insert_drive_file</mat-icon>
        </div>
      </ng-template>
      <div>
        <div class="dataFileName">{{data.fileName}}</div>
      </div>
    </div>
    <div>
    <ng-container *ngIf="isXMLImport; else DOC_IMPORT">
      <div class="xml-result-display">
        <span>{{data.statusDescription}}</span>
      </div>
    </ng-container>
    <ng-template #DOC_IMPORT>
      <div class="doc-result-display">
        <div *ngFor="let result of docProcessingResults" class="doc-result-row">
          <div *ngIf="result.isSuccess; else DOC_IMPORT_FAIL" class="icon-wrap">
            <mat-icon class="icon-class" style="color: green">check_circle</mat-icon>
          </div>
          <ng-template #DOC_IMPORT_FAIL>
            <div *ngIf="result.isFailure; else DOC_IMPORT_NOTRUN" class="icon-wrap">
              <mat-icon class="icon-class" style="color: red">error</mat-icon>
            </div>
          </ng-template>
          <ng-template #DOC_IMPORT_NOTRUN>
            <div *ngIf="result.isNotRun" class="icon-wrap">
              <mat-icon class="icon-class" style="color: red">block</mat-icon>
            </div>
          </ng-template>
          <div>
            <span style="font-size: 1rem">{{ result.message }}</span>
          </div>
        </div>
        <div *ngIf="isStatusSuccess" class="process-to-run">
          <div><span style="text-align: center; font-size: 1.25rem">Process to run on ACE: </span></div>
          <div>
            <mat-form-field style="max-width: 7rem; padding: 0 0 0 0.5rem">
              <mat-select [(value)]="importResponseData.aceProcessToRun">
                <mat-option *ngFor="let processToRun of aceRunProcessOptions" [value]="processToRun">
                  {{processToRun}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-template>
    </div>
  </div>

<div mat-dialog-actions style="margin-bottom: 0; align-content: center; justify-content: center; padding: 0.5rem 0">
  <div *ngIf="(isXMLImport || !isStatusSuccess); else PROCEED">
    <button mat-raised-button [mat-dialog-close]="''" color="primary">OK</button>
  </div>
  <ng-template #PROCEED>
    <button mat-raised-button [mat-dialog-close]="''">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="data.aceProcessToRun" color="primary">Proceed</button>
  </ng-template>
</div>
</div>
