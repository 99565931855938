import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../common/material/material.module';
import { NgxColorsModule } from 'ngx-colors';

import { OrganizationListComponent } from './organization-list/organization-list.component';
import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { EditOrganizationComponent } from './edit-organization/edit-organization.component';
import { CoreModule } from '../core/core.module';
import { OrganizationRoutingModule } from './organization.routing';
import { OrganizationComponent } from './organization.component';
import {AdxControlsModule, AdxLocaleInputModule, AdxPopupsModule} from '../shared';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';
import {AngularDualListBoxModule} from 'angular-dual-listbox';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        NgxColorsModule,
        CoreModule,
        AdxPopupsModule,
        OrganizationRoutingModule,
        AdxControlsModule,
        AdxLocaleInputModule,
        FroalaEditorModule,
        AngularDualListBoxModule
    ],
    exports: [],
  providers: [
  ],
  declarations: [
    OrganizationListComponent,
    AddOrganizationComponent,
    EditOrganizationComponent,
    OrganizationComponent
  ],
})
export class OrganizationModule {}
