<div class="dialog-header">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div>
    <div>
      {{data.message}}
    </div>
    <div>
      <mat-form-field class="dialog-content">
        <mat-select formControlName="visibility">
          <mat-option *ngFor="let type of data.options" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions [align]="'end'">
  <button mat-raised-button [mat-dialog-close]="false">{{data.cancelText}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="selectedValue">{{data.confirmText}}</button>
</div>
