import {Injectable} from '@angular/core';
import {AdxModuleSerializer} from '../model/adx-module-serializer';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {AdxModule} from '../model/adx-module.model';
import {AdxModuleAddRequest} from '../model/adx-module-add-request';
import {Observable, throwError} from 'rxjs';
import {Account} from '../../account/model/account.model';
import {catchError} from 'rxjs/operators';
import {AdxModuleGroupAddRequest} from '../model/adx-module-group-add-request';

@Injectable({
  providedIn: 'root'
})
export class AdxModuleAddRequestService {

  private serializer: AdxModuleSerializer = new AdxModuleSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxModuleAddRequest, AdxModule>) {
  }

  addModule(orgId: number, accountId: number, vpubId: number, addReq: AdxModuleAddRequest): Observable<AdxModule[]> {
    const addModulesUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/bulk`;
    return this.restService.createModules(addModulesUrl, addReq, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }
}
