import {Component, Inject, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdxFileUploadDropzoneDialogModel} from '../../../common/model/adx-file-upload-dropzone-dialog-model';
import {FormUtility} from "../../../common/utils/form-utility";
import {CustomMessageType} from "../../../core/service/notifier/custom-message-type";
import {AdxDialogService} from "../../../common/services/dialog/adx-dialog.service";

/**
 * Popup dialog that can be used anywhere a file upload needs to be supported with dropzone
 */
@Component({
  selector: 'app-adx-file-upload-dropzone-dialog',
  templateUrl: './adx-file-upload-dropzone-dialog.component.html',
  styleUrls: ['./adx-file-upload-dropzone-dialog.component.scss']
})
export class AdxFileUploadDropzoneDialogComponent implements OnInit {

  dialogTitle = '';
  acceptedTypes = '';
  multiple = false;
  maxFileSize: number;
  files: File[] = [];
  isHovering = false;
  isError = false;
  errorMsg = '';
  updatedFileNames: { name: string; newName: string }[] = [];

  constructor(private dialogRef: MatDialogRef<AdxFileUploadDropzoneDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private readonly inputData: AdxFileUploadDropzoneDialogModel,
              private logger: NGXLogger,
              private dialogService: AdxDialogService) {
    this.dialogTitle = inputData.dialogTitle;
    this.acceptedTypes = inputData.acceptedTypes;
    this.multiple = inputData.multiple;
    this.maxFileSize = inputData.maxFileSize;
  }

  ngOnInit(): void {
  }

  toggleHover(input: boolean): void {
    this.isHovering = input;
  }

  onDrop(files: FileList): void {
    this.isError = false;

    this.logger.debug(files);
    if (files !== null && !this.multiple && files.length > 1) {
      this.isError = true;
      this.errorMsg = 'Choose Only One File';
      return;
    }

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const f: File | null = files.item(i);
        if (f != null && (f.type.toString().length === 0 || this.acceptedTypes.indexOf(f.type.toString()) === -1)) {
          this.isError = true;
          this.errorMsg = `File of type ${f.type} not supported`;
          return;
        }

        if (f != null && (f.size > this.maxFileSize)) {
          const fileSizeInMegabytes = (f.size / 1024 / 1024).toFixed(2);
          this.logger.debug(`fileSize: ${f.size} ;; maxFileSize:${this.maxFileSize}`);
          this.isError = true;
          this.errorMsg = `Size of file ${f.name} is ${fileSizeInMegabytes} MB which is greater than the limit of ${this.maxFileSize / 1024 / 1024} MB`;
          return;
        }
      }
    }

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const f: File | null = files.item(i);
        if (f != null) {
          this.files.push(f);
          // get the file name without the extension
          const fileNameSansExtension = f.name.substring(0, f.name.lastIndexOf('.'));
          // in the file name, replace special characters with underscores
          const changedFileName = FormUtility.replaceSpecialCharsWithUnderscore(fileNameSansExtension);
          // if the filename is changed, update the array
          if (changedFileName !== fileNameSansExtension) {
            this.updatedFileNames.push({name: fileNameSansExtension, newName:changedFileName});
          }
        }
      }
    }

    // if the file names are changed, display message to user
    if (this.updatedFileNames && this.updatedFileNames.length > 0) {
      let strMsg = '';
      let strSubMsg = '';
      for (const updatedName of this.updatedFileNames) {
        strMsg = strMsg + `File name will be changed from ${updatedName.name} to ${updatedName.newName} \n`;
      }
      // if file name contains special characters, replace it with underscore character and let user know about the same
      const dialogRef = this.dialogService.alertDialog({
        title: 'File Name Changed',
        message: strMsg,
        submessage: strSubMsg,
        type: CustomMessageType.WARN
      });
      // after displaying the message, submit the file to backend
      dialogRef.subscribe(result => {
        this.dialogRef.close(this.files);
      });
    }
    else {
      this.dialogRef.close(this.files);
    }
  }

  onChange(event: Event): void {
    const files: FileList | null = (event.currentTarget as HTMLInputElement).files;
    this.logger.debug(files);
    if (files) {
      this.onDrop(files);
    }
  }
}
