import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxLocaleInputComponent } from './adx-locale-input.component';
import {MaterialModule} from '../../../common/material/material.module';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import { FlexLayoutModule } from '@angular/flex-layout';

/**
 * Module containing the LocaleSelection component AdxLocaleInputComponent
 */
@NgModule({
  declarations: [
    AdxLocaleInputComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    AngularDualListBoxModule
  ],
  exports: [
    AdxLocaleInputComponent
  ]
})
export class AdxLocaleInputModule { }
