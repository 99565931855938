/* eslint-disable @typescript-eslint/naming-convention */


/**
 * This is the custom message type enum definition.
 * This enum is used with angular-notifier library
 */
export enum CustomMessageType {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warning',
  DEFAULT = 'default',
  ERROR = 'error'
}
