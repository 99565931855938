import { AdxResource } from '../../core/service/resource/adx-resource.model';

/*
* This is class for Style model
*/
export class AdxStyle extends AdxResource {

  public createdByUser: number | null = null;

  numberOfTabs: number;
  navigationBackgroundColor: string;
  navigationTextColor: string;
  topBarLabel: string;
  topBarBackgroundColor: string;
  topBarTextColor: string;
  bodyBackgroundColor: string;
  bodyTextColor: string;
  bannerBackgroundColor: string;
  bannerTextColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  baseTextSize: number;
  scope: string;

  constructor(id: number, numTabs: number, navBgColor: string, navTxtColor: string, topBarLbl: string,
              topBarBkgColor: string, topBarTxtColor: string, bodyBkgColor: string, bodyTxtColor: string,
              bnrBkgColor: string, bnrTxtColor: string, btnBkgColor: string, btnTxtColor: string, txtSize: number, strScope: string) {
      super(id);
      this.numberOfTabs = numTabs;
      this.navigationBackgroundColor = navBgColor;
      this.topBarLabel = topBarLbl;
      this.navigationTextColor = navTxtColor;
      this.topBarBackgroundColor = topBarBkgColor;
      this.topBarTextColor = topBarTxtColor;
      this.bodyBackgroundColor = bodyBkgColor;
      this.bodyTextColor = bodyTxtColor;
      this.bannerBackgroundColor = bnrBkgColor;
      this.bannerTextColor = bnrTxtColor;
      this.buttonBackgroundColor = btnBkgColor;
      this.buttonTextColor = btnTxtColor;
      this.baseTextSize = txtSize;
      this.scope = strScope;
  }

  static buildObject(json: any): AdxStyle {
    return new AdxStyle(json.id, json.numberOfTabs, json.navigationBackgroundColor,
      json.navigationTextColor, json.topBarLabel, json.topBarBackgroundColor,
      json.topBarTextColor, json.bodyBackgroundColor, json.bodyTextColor,
      json.bannerBackgroundColor, json.bannerTextColor, json.buttonBackgroundColor,
      json.buttonTextColor, json.baseTextSize, json.scope);
  }
}
