<div class="dialog-header">
  <h2 mat-dialog-title i18n="@insert-content-label">Insert Telephone</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="common-desc-editor" mat-dialog-content fxLayout="column">
  <div fxFlex="100">
    <div fxLayout="row" [formGroup]="insertTelephoneForm">
      <div fxFlex="40">
        <mat-form-field class="input-form" appearance="outline">
          <ngx-mat-intl-tel-input formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
                                  [preferredCountries]="['us', 'in', 'gb' ]"   name="phone" #phone (keyup)="onKeyup($any($event.target).value)">
          </ngx-mat-intl-tel-input>
          <mat-hint *ngIf="phone.selectedCountry">eg : {{phone.selectedCountry.placeHolder}}</mat-hint>
        </mat-form-field>
      </div>
      <div fxFlex="40" class="input-label">
        <mat-form-field class="input-form">
          <input matInput i18n="@@telephone-label" value="{{phone.value}}" formControlName="telLabel"/>
        </mat-form-field>
      </div>
      <div fxFlex="20">
        <button class="button-cancel" i18n="@@insert-telephone-save" mat-raised-button [disabled]="!(insertTelephoneForm.valid && insertTelephoneForm.dirty)" type="submit" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div>
