<div class="dialog-header">
  <mat-icon *ngIf="displayErrorIcon" color="error">error</mat-icon>
  <h2 mat-dialog-title>{{data.title}}</h2>
</div>

<div mat-dialog-content style="word-break: break-word;">
  {{data.message}}
</div>
<p *ngIf="data.submessage">{{data.submessage}}</p>

<div mat-dialog-actions [align]="'center'">
  <button mat-raised-button color="{{buttonColor}}" [mat-dialog-close]="true">OK</button>
</div>
