import { Injectable } from '@angular/core';
import { AdxTimezone } from 'src/app/common/model/adx-timezone.model';

/**
 * Service to store data locally.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage: Storage;
  constructor() {
    this.localStorage = window.localStorage;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      const toReturn: string | null = this.localStorage.getItem(key);
      if (toReturn) {
        return JSON.parse(toReturn);
      } else {
        return null;
      }
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }


  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}

