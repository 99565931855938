import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {NGXLogger} from 'ngx-logger';

import {ImageUtility} from '../../../../utils/image-utility';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {blankValidator} from '../../../../../common/utils/form-utility';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData, InternalDataFile} from '../../adx-image-uploader.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-adx-cropper',
  templateUrl: './adx-cropper.component.html',
  styleUrls: ['./adx-cropper.component.scss']
})
export class AdxCropperComponent implements OnInit {

  updateImageForm: UntypedFormGroup;
  croppedImage = '';
  selDataFile: InternalDataFile;
  selImageFile: File;
  isIcon: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tagList: string[] = [];
  tagAddOnBlur = true;
  tagSelectable = true;
  tagRemovable = true;

  constructor(private logger: NGXLogger, private dialogRef: MatDialogRef<AdxCropperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {selectedDataFile: InternalDataFile; isIcon: boolean}) {
    this.selDataFile = data.selectedDataFile;
    this.selImageFile = data.selectedDataFile.file as File;
    this.isIcon = data.isIcon;

    this.updateImageForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]}),
      desc: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.maxLength(150)
        ]}),
      imageTag : new UntypedFormControl(null, {updateOn : 'blur'}),
      cropImage: new UntypedFormControl(false)
    });
  }

  ngOnInit(): void {
    this.updateImageForm.patchValue({
      name: this.selDataFile.name,
      desc: this.selDataFile.description
    });

    this.tagList = (this.selDataFile.tags && this.selDataFile.tags != null) ? this.selDataFile.tags?.split(',') : [];
  }

  imageCropped(event: ImageCroppedEvent): void {
    if (event.base64) {
      this.croppedImage = event.base64;
    }

  }

  onCrop(): void {
    let toRet = new InternalDataFile();
    if (this.updateImageForm.dirty) {
      toRet.position = this.selDataFile.position;
      toRet.name = this.updateImageForm.get('name')?.value;
      toRet.description = this.updateImageForm.get('desc')?.value;
      toRet.tags = this.tagList.toString();      
      if (this.updateImageForm.get('cropImage')?.value === true) {
        if (this.croppedImage != null) {
          toRet.url = this.croppedImage;
          toRet.file = ImageUtility.convertBase64ToFile(this.croppedImage, this.selImageFile.name);
          toRet.size = toRet.file.size;
          this.logger.debug(`updated ${toRet.file.name} ;; ${toRet.size} `);
        }
        else {
          toRet.file = this.selDataFile.file;
          toRet.size = this.selDataFile.size;
        }
      }
      else {
        toRet.url = this.selDataFile.url;
        toRet.file = this.selDataFile.file;
        toRet.size = this.selDataFile.size;
      }
    }
    else {
      toRet = this.selDataFile;
    }
    this.logger.debug(`returning ${toRet.name}`);
    this.dialogRef.close(toRet);
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    const len = this.tagList.join('');

  // Add our tag
  if ((value || '').trim() && len.length <= 500) {   
    this.tagList.push(value.trim());
  }else{
    
  }

    // Reset the input value
    if (input) {
      input.value = '';
    }    
  }

  removeTag(tag: string): void {
    const index = this.tagList.indexOf(tag);
    if (index >= 0) {
      this.tagList.splice(index, 1);
    }        
  }

}
