<div class="channel-container" fxLayout="column">
  <div>

    <div class="channel-list-table-div">
      <table mat-table *ngIf="dataSource !== null"  [dataSource]="dataSource" class="common-list-table">

        <ng-container matColumnDef="Channel">
          <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Channel</th>
          <td i18n="@@name-val" mat-cell *matCellDef="let element" class="common-list-channel-td">
            {{ element.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Applications">
          <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Applications</th>
          <td i18n="@@name-val" mat-cell *matCellDef="let element" class="common-list-application-td">
            <div class="common-list" *ngFor="let appln of element.applications">
              <mat-checkbox (change)="onApplSelectionChange(element, appln.id, $event)" id="input-channelApplBulkAssociation-delink-{{appln.id}}">
                {{ getApplicationTitle(appln.id) }}
              </mat-checkbox>
            </div>
          </td>
        </ng-container>

        <mat-divider></mat-divider>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="button-container">
      <button mat-raised-button (click)="close()" id="input-channelApplBulkAssociation-delink-close-Btn" class="button-cancel">Close</button>
      <button mat-raised-button [disabled]="isDisabled()" id="input-channelApplBulkAssociation-delink-submit-Btn" (click)="disassociate()">Submit</button>
    </div>
  </div>
</div>
