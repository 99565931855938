import {AdxBaseSerializer} from 'src/app/core/service/resource/adx-base-serializer';
import {AdxResource} from 'src/app/core/service/resource/adx-resource.model';
import {AuthenticatedUser} from '../../model/adx-auth/authenticated-user.model';
import { AdxAuthUserPermission } from '../../model/adx-auth/adx-auth-user-permission.model';

/**
 * Serializer for AuthenticatedUser
 * In case of resetpassword and logout, the user is not returned. Handled that case by returning dummy object.
 */
export class AuthenticatedUserSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    console.log(json);
    let user: AuthenticatedUser;
    if (json && json.user) {
      user = new AuthenticatedUser(json.user.id);
      user.username = json.user.username;
      user.password = json.user.password;
      user.firstName = json.user.firstName;
      user.middleName = json.user.middleName;
      user.lastName = json.user.lastName;
      user.lastLogin = json.user.lastLogin;
      console.log(json.user.userPermissions);
      user.userPermissions = AdxAuthUserPermission.buildObject(json.user.userPermissions);
    } else {
      user = new AuthenticatedUser(null);
    }
    return user;
  }

  toJson(resource: AuthenticatedUser): string {
    const json = JSON.stringify(resource);
    return json;
  }

}
