import { Injectable } from '@angular/core';
import {AdxChannelSerializer} from '../model/adx-channel-serializer';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {AdxChannel} from '../model/adx-channel.model';
import {Observable, throwError} from 'rxjs';
import {AdxQueryParams} from '../../core/service/resource/adx-query-params.model';
import {catchError} from 'rxjs/operators';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Injectable({
  providedIn: 'root'
})
export class AdxChannelService {

  private serializer: AdxChannelSerializer = new AdxChannelSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxChannel, AdxChannel>) { }

  /*
   * This API is used to fetch the list of Channels from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getChannels(orgId: number, accountId: number): Observable<AdxChannel[]> {
    this.logger.debug('Getting applications for orgId:' + orgId + ' ; appId:' + accountId);
    const url = `orgs/${orgId}/accts/${accountId}/chnls`;
    return this.restService.list(url, new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the Channel from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getChannel(orgId: number, accountId: number, id: number): Observable<AdxChannel> {

    const url = `orgs/${orgId}/accts/${accountId}/chnls`;
    return this.restService.read(url, id, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }


  /*
   * This API is used to add Channel to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addChannel(orgId: number, accountId: number, channel: AdxChannel): Observable<AdxChannel>{
    const url = `orgs/${orgId}/accts/${accountId}/chnls`;
    return this.restService.create(url, channel, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /*
   * This API is used to update Channel in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateChannel(orgId: number, accountId: number, channel: AdxChannel): Observable<AdxChannel> {
    const url = `orgs/${orgId}/accts/${accountId}/chnls/${channel.id}`;
    return this.restService.update(url, channel, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * Utility method get channel ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organizationadmin, organization readonly, account admin or acount readonly
   * then he can view all channel ids. That need to be handled by the caller method.
   * This method will only return channel ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of channel accessible by user
   */
  public getAccessiblChannelIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleChannelIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleChannels !== undefined && accessibleEntities.accessibleChannels !== null) {
          userAccessibleChannelIds = accessibleEntities.accessibleChannels;
      }
    }
    return userAccessibleChannelIds;
  }

  /**
   * API used to determine if user has account admin role
   *
   * @param orgId : number - organization id
   * @param acctId : number - account id
   * @param currentUser : logged in user
   * @returns boolean: true if logged in user can add channel
   */
   public isUserAccountAdmin(orgId: number, acctId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    //check for org admin
    const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
    if (isOrgAdmin) {
      return true;
    }

    //check for account admin
    const isAcctAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
    if (isAcctAdmin) {
      return true;
    }

    return false;
  }

  /**
   * API used to determine if user has permission to add channel
   *
   * @param orgId : number - organization id
   * @param acctId : number - account id
   * @param currentUser : logged in user
   * @returns boolean: true if logged in user can add channel
   */
  public canUserAddChannel(orgId: number, acctId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    //check for org admin
    const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
    if (isOrgAdmin) {
      return true;
    }

    //check for account admin
    const isAcctAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
    if (isAcctAdmin) {
      return true;
    }

    //check for application add role
    const isApplicationAddRole = AdxAuthUtils.hasRoleForGivenId(acctId, 'CHANNEL_ADD', currentUser);
    if (isApplicationAddRole) {
      return true;
    }
    return false;
  }

  /**
   * API used to determine if user has permission to edit channel
   *
   * @param orgId : number - organization id
   * @param acctId : number - account id
   * @param applnId : number - channel id
   * @param currentUser : logged in user
   * @returns boolean: true if logged in user can edit channel
   */
  public canUserEditChannel(orgId: number, acctId: number, applnId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for channel admin
      const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'CHANNEL_ADMIN', currentUser);
      if (isApplnAdmin) {
        return true;
      }

      //now check for channel edit
      const isApplnEdit = AdxAuthUtils.hasRoleForGivenId(applnId, 'CHANNEL_EDIT', currentUser);
      if (isApplnEdit) {
        return true;
      }
    }
    return false;
  }
}
