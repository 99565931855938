import {Component, Inject, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {AdxChannel} from '../../channel/model/adx-channel.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {NGXLogger} from 'ngx-logger';
import {DelinkChannelApplicationRequest} from '../model/delink-channel-application-request';
import {BulkActionService} from '../../core/service/resource/bulk-action.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdxApplication} from '../../application/model/adx-application.model';

@Component({
  selector: 'app-delink-channel-app',
  templateUrl: './delink-channel-app.component.html',
  styleUrls: ['./delink-channel-app.component.scss']
})
export class DelinkChannelAppComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['Channel', 'Applications'];
  dataSource: MatTableDataSource<AdxChannel> | null = null;
  applications: AdxApplication[];
  isDataChanged = false;
  private delinkList: DelinkChannelApplicationRequest[] = [];

  constructor(private logger: NGXLogger, private bulkService: BulkActionService,
              private readonly messageNotifier: CustomNotifierService,
              public dialogRef: MatDialogRef<DelinkChannelAppComponent>,
              @Inject(MAT_DIALOG_DATA) public inputData:
                {orgId: number; acctId: number; channels: AdxChannel[]; applications: AdxApplication[]}) {
    this.applications = inputData.applications;
  }

  ngOnInit(): void {
    this.logger.debug('channels:' + this.inputData.channels.length);
    this.dataSource = new MatTableDataSource(this.inputData.channels);
  }

  /**
   * On checkbox selection change, need to update the list
   * if applicationId is selected, it means that the channel and application need to be delinked.
   * If applicationId is deselected, it means that channelId and applicationId will remain linked.
   * Each DelinkChannelApplicationRequest has a channel and associated applicationId list.
   * This list is of applicationIds that need to be delinked/unlinked
   * The list of DelinkChannelApplicationRequest objects will be sent to backend
   *
   * @param element: The channel for which selection/deselection is happenning
   * @param applnId: The applicationId that needs to be linked or unlinked with the channel
   * @param event: The checkbox check or uncheck event
   */
  onApplSelectionChange(element: AdxChannel, applnId: number, event: MatCheckboxChange): void {
    this.isDataChanged = true;
    if (applnId) {
      this.logger.debug(`event.checked: ${event.checked}`);
      let temp: DelinkChannelApplicationRequest | null = null;
      if (event.checked) {
        const chnlList: DelinkChannelApplicationRequest[] = this.delinkList.filter(delinkObj => delinkObj.channelId === element.id);
        if (chnlList && chnlList.length > 0) {
          // there will be only 1 entry for given channel Id
          temp = chnlList[0];
          this.logger.debug(`Found ${temp.channelId} in the list`);
        }
        else if (element.id) {
          temp = new DelinkChannelApplicationRequest(element.id);
          // not found, so add to list
          this.delinkList.push(temp);
        }

        this.logger.debug(`removing ${applnId} from channel ${element.id}`);
        if (temp) {
          temp.addApplicationId(applnId);
        }
      } else {
        this.logger.debug(`${applnId} remains linked with channelId ${element.id}`);
        // previously selected applicationId is unselected. Remove from applicationId list
        const objToDelink: DelinkChannelApplicationRequest[] = this.delinkList.filter(delinkObj => delinkObj.channelId === element.id);
        this.logger.debug(`${objToDelink.length} found for modification`);

        if (objToDelink && objToDelink.length > 0) {
          // there will be only 1 entry for given channel Id
          temp = objToDelink[0];
          this.logger.debug(`${temp.applicationIds} applicationList associated with channel ${temp.channelId}`);
          // remove the applicationId from the application list for the channel
          if (temp && temp.applicationIds) {
            temp.applicationIds.forEach((appId, index) => {
              if (appId === applnId) {
                this.logger.debug(`${appId} found at index ${index}`);
                this.logger.debug(`keep ${element.id} channel linked to application ${applnId}`);
                if (temp && temp.applicationIds.length > 0) {
                  temp.applicationIds.splice(index, 1);
                }
              }
            });
          }
        }
      }
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  disassociate(): void {
    /*
    let requestObj: DelinkChannelApplicationRequest = null;
    this.bulkService.linkChannelApplication(requestObj).pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Associated Successfully.`);
          this.dialogRef.close(true);
        }
      });
      */
  }

  isDisabled(): boolean {
    return !this.isDataChanged;
  }

  getApplicationTitle(id: number): string {
    if (this.applications) {
      const applns: AdxApplication[] = this.applications.filter(appln => appln.id === id);
      if (applns && applns.length > 0 && applns[0].title) {
        return applns[0].title;
      }
    }
    return id.toString();
  }
}
