import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {Observable, throwError} from 'rxjs';
import {AdxQueryParams} from '../../core/service/resource/adx-query-params.model';
import {catchError} from 'rxjs/operators';
import {AdxVpubSerializer} from '../model/adx-vpub-serializer';
import {AdxVPub} from '../model/adx-vpub.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

@Injectable({
  providedIn: 'root'
})
export class AdxVpubService {

  private serializer: AdxVpubSerializer = new AdxVpubSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxVPub, AdxVPub>) { }

  /*
   * This API is used to fetch the list of Channels from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getVpubs(orgId: number, accountId: number, applnId: number): Observable<AdxVPub[]> {
    this.logger.debug('Getting applications for orgId:' + orgId + ' ; appId:' + accountId);
    const url = `orgs/${orgId}/accts/${accountId}/apps/${applnId}/vpubs`;
    return this.restService.list(url, new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the Channel from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getVpub(orgId: number, accountId: number, applnId: number, id: number): Observable<AdxVPub> {

    const url = `orgs/${orgId}/accts/${accountId}/apps/${applnId}/vpubs`;
    return this.restService.read(url, id, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }


  /*
   * This API is used to add Channel to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addVpub(orgId: number, accountId: number, applnId: number, vPub: AdxVPub): Observable<AdxVPub>{
    const url = `orgs/${orgId}/accts/${accountId}/apps/${applnId}/vpubs`;
    return this.restService.create(url, vPub, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /*
   * This API is used to update Channel in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateVpub(orgId: number, accountId: number, applnId: number, vpub: AdxVPub): Observable<AdxVPub> {
    const url = `orgs/${orgId}/accts/${accountId}/apps/${applnId}/vpubs/${vpub.id}`;
    return this.restService.update(url, vpub, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * Utility method get vpub ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organization/account/application admin or
   * organization/account/application readonly, then he can view all vpubids. That need to be handled by the caller method.
   * This method will only return vpub ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of vpubs accessible by user
   */
  public getAccessiblVpubIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleVpubIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleVpubs !== undefined && accessibleEntities.accessibleVpubs !== null) {
        userAccessibleVpubIds = accessibleEntities.accessibleVpubs;
      }
    }
    this.logger.debug(userAccessibleVpubIds);
    return userAccessibleVpubIds;
  }

  /**
   * Utility method to verify whether the logged in user has access to add vpub
   * user with org/account/application admin or vpub add permission will be able to add vpub.
   * If user has system admin or instance admin role, then he will be able to add vpub
   *
   * @param orgId organization id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit account
   */
  public canUserAddVpub(orgId: number, accountId: number, applnId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub add
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'VPUB_ADD', currentUser)) {
        //means input vpubId is present in vpub-add permission list for user. return true
        this.logger.debug('user has vpub add permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to delete vpub
   * user with org/account/application admin or vpub delete permission will be able to delete vpub.
   * If user has system admin or instance admin role, then he will be able to delete vpub
   *
   * @param orgId organization id
   * @param accountId account id
   * @param applnId application id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit vpub
   */
  public canUserDeleteVpub(orgId: number, accountId: number, applnId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub add
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'VPUB_DELETE', currentUser)) {
        //means input vpubId is present in vpub-delete permission list for user. return true
        this.logger.debug('user has vpub delete permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to edit vpub
   * user with org admin or account admin or application admin or vpub edit permission will be able to edit vpub.
   * If user has system admin or instance admin role, then he will be able to edit vpub
   *
   * @param orgId organization id
   * @param acctId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit vpub
   */
  public canUserEditVpub(orgId: number, acctId: number, applnId: number, vpubId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for application admin
      const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
      if (isApplnAdmin) {
        return true;
      }

      //now check for vpub admin
      const isVpubAdmin = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser);
      if (isVpubAdmin) {
        return true;
      }

      //now check for vpub edit
      const isVpubEdit = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_EDIT', currentUser);
      if (isVpubEdit) {
        return true;
      }
    }
    return false;
  }

  /**
   * Utility function to get accessible vpubs for current user
   * @param user : logged in user
   * @returns array of numbers
   */
  public getUserAccessibleVpubIds(user: AuthenticatedUser): number[] {
    let userAccessibleVpubIds: number[] = [];
    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(user.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleVpubs !== undefined && accessibleEntities.accessibleVpubs !== null) {
        userAccessibleVpubIds = accessibleEntities.accessibleVpubs;
      }
    }
    return userAccessibleVpubIds;
  }
}
