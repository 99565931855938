import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxRole } from '../model/adx-role-model';
/**
 * Class that implements Base Serializer and used to serialize the AdxRole object
 */
export class AdxRoleSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    return AdxRole.buildObject(json);
  }
  toJson(resource: AdxResource) {
    const json = JSON.stringify(resource);
    return json;
  }

}
