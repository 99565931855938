import {AdxBaseSerializer} from '../../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxImageLibraryItem} from '../../model/image-library/adx-image-library-item.model';

/**
 * Used to serialize from and deserialize to AdxImageLibraryItem object
 */
export class AdxImageLibrarySerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const imageLibItem: AdxImageLibraryItem = AdxImageLibraryItem.buildObject(json);
    return imageLibItem;
  }

  toJson(resource: AdxImageLibraryItem): any {
    const json = JSON.stringify(resource);
    return json;
  }

}
