import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotifierModule } from 'angular-notifier';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { environment } from 'src/environments/environment';
import { MaterialModule } from './common/material/material.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { ChannelModule } from './channel/channel.module';
import { VpubModule } from './vPub/vpub.module';
import { AdxMdlModule } from './adx-module/adx-module.module';
import { AdxSimulatorModule } from './adx-simulator/adx-simulator.module';
import { CustomRequestInterceptor } from './auth/interceptor/custom-request-interceptor';
import { CustomResponseInterceptor } from './auth/interceptor/custom-response-interceptor';
import { CoreModule } from './core/core.module';
import { AdxHttpErrorInterceptor } from './core/service/resource/adx-http-error-interceptor';
import { SpinnerOverlayModule} from './shared';
import {SpinnerInterceptor} from './shared/spinner-overlay/interceptor/spinner.interceptor';
import {AdxInjector} from './common/utils/adx-injector';
// Import Angular plugin.
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.logApiUrl,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
    }),
    CoreModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    LayoutModule,
    ChannelModule,
    VpubModule,
    AdxMdlModule,
    AdxSimulatorModule,
    AppRoutingModule,
    NotifierModule,
    SpinnerOverlayModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdxHttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AdxInjector.setInjector(injector);
  }
}
