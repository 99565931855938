import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import {MaterialModule} from '../../common/material/material.module';
import {OrganizationModule} from '../../organization/organization.module';
import {AccountModule} from '../../account/account.module';



@NgModule({
    declarations: [
        SettingsComponent
    ],
    exports: [
        SettingsComponent
    ],
  imports: [
    CommonModule,
    MaterialModule,
    OrganizationModule,
    AccountModule
  ]
})
export class SettingsModule { }
