<div [formGroup]="addUserRoleForm" class="adx-user-role-add-container">
  <div class="adx-user-role-add-header">
    <h4> Add Role </h4>
  </div>
  <mat-divider></mat-divider>
  <form class="adx-user-role-add-select">
    <!--role-->
    <mat-form-field class="adx-user-role-add-role">
      <mat-select formControlName="assignableRole" placeholder='Role' id="adx-user-role-add-roles">
        <mat-option *ngFor="let role of assignableRoles" (onSelectionChange)="onRoleChange(role.id, $event)"
        matTooltip="{{role.title}}"  [value]="role.id" id="adx-user-role-add-role-{{role.id}}">{{role.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--org name-->
    <mat-form-field class="adx-user-role-add-org" *ngIf="hasOrgOptions">
      <mat-select formControlName="selOrg" placeholder='Organization' id="adx-user-role-add-org">
        <mat-option *ngFor="let org of organizations" matTooltip="{{org.title}}"
         [value]="org.id" id="adx-user-role-add-org-{{org.id}}">{{org.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--account name-->
    <mat-form-field class="adx-user-role-add-acct" *ngIf="hasAccountOptions">
      <mat-select formControlName="selAccount" placeholder='Account' id="adx-user-role-add-account">
        <mat-option *ngFor="let acct of accounts" matTooltip="{{acct.title}}"
         [value]="acct.id" id="adx-user-role-add-account-{{acct.id}}">{{acct.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--application name-->
    <mat-form-field class="adx-user-role-add-appln" *ngIf="hasApplicationOptions">
      <mat-select formControlName="selAppln" placeholder='Application' id="adx-user-role-add-appln" [(value)]="applnSelected">
        <mat-option value=''>Select an Application</mat-option>
        <mat-option *ngFor="let obj of applications" matTooltip="{{obj.title}}" (onSelectionChange)="onApplicationChange(obj.id, $event)"
         [value]="obj.id" id="adx-user-role-add-appln-{{obj.id}}">{{obj.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--channel name-->
    <mat-form-field class="adx-user-role-add-chnl" *ngIf="hasChannelOptions">
      <mat-select formControlName="selChannel" placeholder='Channel' id="adx-user-role-add-channel">
        <mat-option *ngFor="let obj of channels" matTooltip="{{obj.title}}"
         [value]="obj.id" id="adx-user-role-add-channel-{{obj.id}}">{{obj.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--vpub name-->
    <mat-form-field class="adx-user-role-add-vpub" *ngIf="hasVpubOptions">
      <mat-select formControlName="selVpub" placeholder='vPub' id="adx-user-role-add-vpub">
        <mat-option *ngFor="let obj of vpubs" matTooltip="{{obj.title}}" (onSelectionChange)="onVpubChange(obj.id, $event)"
         [value]="obj.id" id="adx-user-role-add-vpub-{{obj.id}}">{{obj.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--module name-->
    <mat-form-field class="adx-user-role-add-module" *ngIf="hasModuleOptions">
      <mat-select formControlName="selModule" placeholder='Module' id="adx-user-role-add-module">
        <mat-option *ngFor="let obj of modules" matTooltip="{{obj.title}}"
         [value]="obj.id" id="adx-user-role-add-module-{{obj.id}}">{{obj.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- save and reset buttons -->
    <div class="adx-user-role-add-actions">
      <button mat-raised-button type="submit" (click)="onSave()" [disabled]="!(addUserRoleForm.dirty && addUserRoleForm.valid)">Add</button>
    </div>
  </form>
  <mat-divider></mat-divider>
</div>
