import {AdxBaseSerializer} from '../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxApplication} from './adx-application.model';

export class AdxApplicationSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    return AdxApplication.buildObject(json);
  }

  toJson(resource: AdxResource): any {
    return JSON.stringify(resource);
  }
}
