import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AdxImageUploaderComponent} from './adx-image-uploader.component';
import {AdxImageLibraryType} from '../../utils/adx-image-library-type';
import { CommonUtility } from 'src/app/common/utils/common-utils';
import {ActivatedRoute, Router} from '@angular/router';

@Directive({
  selector: '[appAdxImageUpload]'
})
export class AdxImageUploadDirective {

  @Input() multiple = true;
  @Input() crop = true;
  @Input() id: number | undefined;
  @Input() type: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;
  @Input() isIcon = true;

 //additional info for saving an image to library.
  @Input() orgId : number | null = null;//org and account id is required to fetch the applications.
  @Input() accountId : number | null = null;
 @Input() applnId : number | null = null;
 @Input() vpubId : number | null = null;
 @Input() moduleId : number | null = null;
 @Input() atomId : number | null = null;
  @Output() imgUploadEmitter = new EventEmitter<string | string []>();

  constructor(private dialog: MatDialog,private activatedRoute: ActivatedRoute) { }

  @HostListener('click', ['$event']) onClick(): void{
    this.openDialog();
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(AdxImageUploaderComponent, {
      width: '600px',
      height: '500px',
      data: {
        multiple: this.multiple,
        crop: this.crop,
        libraryType: this.type,
        id: this.id,
        isIcon: this.isIcon,
        applicationId : this.applnId,
        vpubId : this.vpubId,
        moduleId : this.moduleId,
        atomId : this.atomId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.imgUploadEmitter.emit(result || null);
    });
  }

}
