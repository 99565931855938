import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxUser } from '../model/adx-user-model';

/**
 * Class that implements Base Serializer and used to serialize the AdxUser object
 */
export class AdxUserSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    return AdxUser.buildObject(json);
  }
  toJson(resource: AdxResource) {
    const json = JSON.stringify(resource);
    return json;
  }

}
