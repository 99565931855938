import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxDataTransferComponent } from './data-transfer/adx-data-transfer/adx-data-transfer.component';
import { MaterialModule } from '../../common/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AdxContentReportComponent } from './adx-content-report/adx-content-report.component';
import { AdxNotificationListComponent } from './notification-list/adx-notification-list.component';


@NgModule({
  declarations: [
    AdxDataTransferComponent,
    AdxContentReportComponent,
    AdxNotificationListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    AdxDataTransferComponent,
    AdxContentReportComponent,
    AdxNotificationListComponent
  ]
})
export class AdxPagesModule { }
