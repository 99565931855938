import {Directive, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {NgControl, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
  selector: '[workaroundMatFormFieldAccessor]',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: WorkaroundMatFormFieldAccessorDirective
    }
  ]
})
export class WorkaroundMatFormFieldAccessorDirective implements MatFormFieldControl<boolean>, OnInit, OnDestroy {

  static nextId = 0;
  @Input('aria-describedby') userAriaDescribedBy?: string;
  @HostBinding()
  id = `workaround-mat-form-field-noop-${WorkaroundMatFormFieldAccessorDirective.nextId++}`;

  empty = false;
  shouldLabelFloat = true;
  stateChanges = new Subject<void>();

  controlType = 'workaround-mat-form-field-noop';
  autofilled = false;



  private stateChangeSubscription?: Subscription;

  constructor(
    public elRef: ElementRef,
    @Inject(NgControl) public ngControl: NgControl) {   }


  get focused(): boolean{
    return this.elRef.nativeElement.classList.contains('cdk-keyboard-focused');
  }

  get required(): boolean{
    return this.ngControl.control ? this.ngControl.control.hasValidator(Validators.required) : false;
  }

  get disabled(): boolean {
    return this.ngControl.disabled ? this.ngControl.disabled : false;
  }

  get errorState(): boolean{
    return this.ngControl.invalid ? this.ngControl.invalid : false;
  }

  get value(): boolean | null {
    return this.ngControl.control ? this.ngControl.control.value : null;
  }

  set value(value: boolean | null) {
    if (this.ngControl.control) {
      this.ngControl.control.setValue(value);
      this.stateChanges.next();
    }
  }

  set placeholder(str: string) {
    return;
  }

  set disabled(value: boolean) {
    if (this.ngControl.control) {
      if (coerceBooleanProperty(value)){
        this.ngControl.control.enable();
      }else{
        this.ngControl.control.disable();
      }
    }
  }

  setDescribedByIds(ids: string[]): void {
    // const controlElement = this._elementRef.nativeElement
    //   .querySelector('.example-tel-input-container')!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      // Material Input Components mostly have a focus() Method
      // https://material.angular.io/components/slide-toggle/api
      (this.elRef.nativeElement as any).focus();
      this.elRef.nativeElement.classList.add('cdk-keyboard-focused');
    }
  }

  ngOnInit(): void {
    if (this.ngControl.valueChanges) {
      this.stateChangeSubscription = this.ngControl.valueChanges.subscribe(changed => {
        this.stateChanges.next();
      });
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }

}
