import { Injectable } from '@angular/core';
import {AdxLocaleSerializer} from '../../serializer/locale/adx-locale-serializer';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../../core/service/resource/adx-rest-service.service';
import {Observable, of, throwError} from 'rxjs';
import {AdxQueryParams} from '../../../core/service/resource/adx-query-params.model';
import {catchError, tap} from 'rxjs/operators';
import {AdxLocale} from '../../model/locale/adx-locale.model';

/**
 * Service to interact with Locale resource at the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class AdxLocaleService {

  private locales: AdxLocale[];
  private serializer: AdxLocaleSerializer = new AdxLocaleSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxLocale, AdxLocale>) {
    this.locales = [];
  }

  /*
   * This API is used to fetch the list of locales from backend.
   * Returns an observable. Throws exception in case of error.
   */
  getLocales(): Observable<AdxLocale[]> {

    if (this.locales.length > 0) {
      this.logger.debug('Locale already fetched. No need to fetch again');
      return of(this.locales);
    }

    this.logger.debug('fetching locales in getLocales');
    return this.restService.list('locales', new AdxQueryParams(), null, this.serializer)
      .pipe(
        tap(data => this.locales = data),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        })
      );
  }
}
