<div class="adx-user-role-edit-container">
  <div class="adx-user-role-edit-search">
    <app-adx-user-search #search_component (searchResult)="updateSearchResult($event)"
      style="width: 95%; height: 20%;"></app-adx-user-search>
  </div>
  <div class="adx-user-role-edit-list">
    <div *ngIf="displayLevel === 'ORG_LEVEL'; else ACCT_DISP_LEVEL">
      <app-adx-user-role-list [selectedUser]="selectedUser" [userRoles]="orgLevelApplicableUserRoles"
        (deleteUserRoles)="onDeleteRoles($event)" style="height: 30%">
      </app-adx-user-role-list>
    </div>
    <ng-template #ACCT_DISP_LEVEL>
      <app-adx-user-role-list [selectedUser]="selectedUser" [userRoles]="accountLevelApplicableUserRoles"
        (deleteUserRoles)="onDeleteRoles($event)" style="height: 30%">
      </app-adx-user-role-list>
    </ng-template>
  </div>
  <div *ngIf="selectedUser !== undefined && selectedUser !== null">
    <!--display user role add component-->
    <app-adx-user-role-add [adxUser]="selectedUser" [orgId]="orgId" [accountId]="accountId" [roleScope]="displayLevel"
      (roleAdd)="onRoleAdd($event)" style="height: 50%"></app-adx-user-role-add>
  </div>
</div>
