<div class="add-multiple-module-container">
  <form class="add-module-form" [formGroup]="addModuleForm">

    <div class="flex-container add-module-container" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row">
        <div class='common-locale-label' fxFlex="10">
        </div>
        <div class="module-name-input input-label" fxFlex="90">
          <mat-form-field class="input-form">
            <input matInput i18n="@@addModuleForm.name" id="input-addModule-title" #addModuleFormTitle maxlength="100"
                   formControlName="moduleTitle" placeholder="Title" required>
            <mat-hint align="end">{{addModuleFormTitle.value.length}} / 100</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- Add ARS Module  -->
      <div *ngIf="isArsModuleIncluded()" fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-label" fxFlex="17">
          <mat-checkbox formControlName="arsCheckbox" #arsField
                        id="input-addModule-arsCheckbox" color="primary"></mat-checkbox>
          <mat-label i18n="@@addModuleForm.ars" class="input-module-label">ARS Modules</mat-label>
        </div>
        <div class="input-form-label" fxFlex="5">
          <mat-form-field class="input-form">
            <input matInput type="number" formControlName="numOfArsModules" [attr.disabled]="arsField.checked?null:''"
                   id="input-addModule-numOfArsModules" min="1" step="1" max="5" class="ars-count">
          </mat-form-field>
        </div>
        <div class="input-label" fxFlex="68"> &nbsp;
        </div>
      </div>

      <mat-divider *ngIf="isArsModuleIncluded()" ></mat-divider>

      <!-- Add Form Module -->
      <div *ngIf="isFormModuleIncluded()" fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-label" fxFlex="17">
          <mat-checkbox formControlName="formCheckbox" #formField
                        id="input-addModule-formCheckbox" color="primary"></mat-checkbox>
          <mat-label i18n="@@addModuleForm.form" class="input-module-label">Form Modules</mat-label>
        </div>
        <div class="input-form-label" fxFlex="5">
          <mat-form-field class="input-form">
            <input matInput type="number" formControlName="numOfFormModules" [attr.disabled]="formField.checked?null:''"
                   id="input-addModule-numOfFormModules" min="1" step="1" max="5" class="ars-count">
          </mat-form-field>
        </div>
        <div class="input-label" fxFlex="68"> &nbsp;
        </div>
      </div>

      <mat-divider *ngIf="isFormModuleIncluded()"></mat-divider>

      <!-- Add Web Module -->
      <div *ngIf="isWebModuleIncluded()" fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-label" fxFlex="17">
          <mat-checkbox formControlName="webCheckbox" #webField
                        id="input-addModule-webCheckbox" color="primary"></mat-checkbox>
          <mat-label i18n="@@addModuleForm.form" class="input-module-label">Web Modules</mat-label>
        </div>
        <div class="input-form-label" fxFlex="5">
          <mat-form-field class="input-form">
            <input matInput type="number" formControlName="numOfWebModules" [attr.disabled]="webField.checked?null:''"
                   id="input-addModule-numOfWebModules" min="1" step="1" max="5" class="ars-count">
          </mat-form-field>
        </div>
        <div class="input-label" fxFlex="68"> &nbsp;
        </div>
      </div>

      <mat-divider *ngIf="isWebModuleIncluded()"></mat-divider>

      <!-- Add HTML Module -->
      <div *ngIf="isHtmlModuleIncluded()" fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-label" fxFlex="17">
          <mat-checkbox formControlName="htmlCheckbox" #htmlField
                        id="input-addModule-htmlCheckbox" color="primary"></mat-checkbox>
          <mat-label i18n="@@addModuleForm.form" class="input-module-label">HTML Modules</mat-label>
        </div>
        <div class="input-form-label" fxFlex="5">
          <mat-form-field class="input-form">
            <input matInput type="number" formControlName="numOfHtmlModules" [attr.disabled]="htmlField.checked?null:''"
                   id="input-addModule-numOfHtmlModules" min="1" step="1" max="5" class="ars-count">
          </mat-form-field>
        </div>
        <div class="input-label" fxFlex="68"> &nbsp;
        </div>
      </div>

      <mat-divider *ngIf="isHtmlModuleIncluded()"></mat-divider>

      <div class="button-container">
        <button i18n="@@addModuleForm.cancel" id="input-addModule-cancel-Btn" mat-raised-button routerLink="{{moduleListUrl}}" class="button-cancel">
          Cancel
        </button>
        <button i18n="@@addModuleForm.save" class="button-cancel" mat-raised-button id="input-addModule-save-Btn"
                [disabled]="isFormInvalid()" type="submit" (click)="addModule()">Save
        </button>
      </div>

    </div>

  </form>
</div>
