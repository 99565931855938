
export class LinkChannelApplicationRequest {
  orgId: number;
  acctId: number;
  applicationIds: number[] = [];
  channelIds: number[] = [];

  constructor(orgId: number, acctId: number) {
    this.orgId = orgId;
    this.acctId = acctId;
  }
}
