<div class="notification-list-container">
  <div class="notification-list-header">
    <div>
      <button mat-raised-button color="primary" id="send-notification-Btn" matTooltip="Send Notifications" (click)="sendPopup()">Send Notification</button>
    </div>
    <div>
      <button mat-raised-button id="notification-list-reload-Btn" matTooltip="Reload" (click)="loadData()"><mat-icon>refresh</mat-icon></button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="notification-list-title">
    <h2>Sent Notifications</h2>
  </div>
  <div class="notification-list-table">
    <table mat-table *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table">

      <ng-container matColumnDef="title">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Title</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title" matTooltip="{{element.content}}">
          {{ element.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">User</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th">Type</th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          {{ element.deliveryType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th">Sent Timestamp</th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          {{ element.createdOn | date:'long' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>

  </div>
</div>
