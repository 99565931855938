import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {OrganizationService} from '../../organization/service/organization.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Organization} from '../../organization/model/organization.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {CommonUtility} from '../../common/utils/common-utils';

/**
 * This component is used for Organization setting.
 * This will be displayed in a popup.
 */
@Component({
  selector: 'app-organization-setting',
  templateUrl: './organization-setting.component.html',
  styleUrls: ['./organization-setting.component.scss']
})
export class OrganizationSettingComponent implements OnInit {

  @Input() id: number | null = null;

  orgSettingsForm: UntypedFormGroup;
  selectedOrg: Organization | undefined;

  imgLibType: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private logger: NGXLogger,
              private readonly orgService: OrganizationService,
              private formBuilder: UntypedFormBuilder,
              private readonly messageNotifier: CustomNotifierService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

    this.orgSettingsForm = this.formBuilder.group({
      orgName: [],
      consumerSignup: [],
      verifyCredentials: [],
      displayDefaultIcon: [],
      defaultIcon: []
    });
  }

  ngOnInit(): void {
    if (!this.id) {
      this.id = CommonUtility.getId('orgId', this.activatedRoute);
    }
    this.logger.debug(`Calling fetchOrg in init with id ${this.id}`);
    // fetch the organization using the id
    if (this.id !== undefined && this.id !== null) {
      this.fetchOrg(this.id);
    }
  }

  onIconSelected($event: AdxImage): void {
    this.logger.debug('In onIconSelected');
  }

  update(): void {
    this.logger.debug('In update');
    /*
    if (this.selectedOrg) {
      const orgToUpdate: Organization = new Organization(this.selectedOrg.id, this.selectedOrg.secret);
      let hasDataChanged = this.orgSettingsForm.get('consumerSignup')?.dirty;
      if (hasDataChanged) {
        orgToUpdate. = this.orgSettingsForm.get('consumerSignup')?.value;
      }
      hasDataChanged = this.accountSettingsForm.get('signinEnabled')?.dirty;
      if (hasDataChanged) {
        accountToUpdate.signinEnabled = this.accountSettingsForm.get('signinEnabled')?.value;
      }
      this.saveAccount(accountToUpdate);
    }
     */
  }

  cancel(): void {
    this.logger.debug('In close');
  }

  /*
   * Get the details of the org that this setting is for.
   */
  private fetchOrg(id: number): void {
    this.orgService.getOrganization(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.logger.debug(`received data: ${data.id}`);
          this.selectedOrg = data;
          this.logger.debug(`selected org title: ${this.selectedOrg?.title}`);
          this.updateUI();
        }
      });
  }

  private updateUI(): void {
    if (this.selectedOrg) {
      this.orgSettingsForm.patchValue({
        orgName: this.selectedOrg.title,
        consumerSignup: true,
        verifyCredentials: false,
        displayDefaultIcon: false
      });
    }
  }
}
