import {AdxBaseSerializer} from '../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxStyle} from '../../common/model/adx-style.model';
import {AdxModule} from './adx-module.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxModuleAddRequest} from './adx-module-add-request';
import {AdxArsSettings} from './adx-ars-settings-model';
import {AdxFormSettings} from './adx-form-settings-model';
import {AdxWeb} from './adx-web-model';
import {AdxHtml} from './adx-html-model';

export class AdxModuleSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    const module: AdxModule = new AdxModule(json.id);
    if (json.title) {
      module.title = json.title;
    }
    if(json.visibility) {
      module.visibility = json.visibility;
    }
    if (json.sortOrder) {
      module.sortOrder = json.sortOrder;
    }
    if(json.type) {
      module.type = json.type;
    }
    if (json.code) {
      module.code = json.code;
    }
    if (json.description) {
      module.description = json.description;
    }
    if (json.summary) {
      module.summary = json.summary;
    }
    if (json.parentId !== undefined && json.parentId !== null) {
      module.parentId = json.parentId;
    }
    if (json.isSharable !== undefined && json.isSharable !== null) {
      module.isSharable = json.isSharable;
    }
    if (json.signinEnabled !== undefined && json.signinEnabled !== null) {
      module.signinEnabled = json.signinEnabled;
    }
    if (json.readOnly !== undefined && json.readOnly !== null) {
      module.readOnly = json.readOnly;
    }
    if (json.children) {
      let tempModule: AdxModule;
      json.children.forEach((modl: any) => {
        tempModule = (this.fromJson(modl) as AdxModule);
        module.children.push(tempModule);
      });
    }
    if (json.style) {
      module.style = AdxStyle.buildObject(json.style);
    }
    if (json.iconImage) {
      module.iconImage = AdxImage.buildObject(json.iconImage);
    }

    if (json.ars) {
      module.ars = AdxArsSettings.buildObject(json.ars);
    }

    if (json.form) {
      module.form = AdxFormSettings.buildObject(json.form);
    }

    if (json.web) {
      module.web = AdxWeb.buildObject(json.web);
    }

    if (json.html) {
      module.html = AdxHtml.buildObject(json.html);
    }

    if (json.excludedModuleTypes) {
      module.excludedModuleTypes = json.excludedModuleTypes;
    }

    if (json.navigationBarTitle) {
      module.navigationBarTitle = json.navigationBarTitle;
    }

    return module;
  }

  toJson(resource: AdxResource): any {
    if (resource instanceof AdxModuleAddRequest) {
      let toRet = '{"title":';
      toRet = toRet.concat(JSON.stringify(resource.title));
      toRet = toRet.concat(', "numOfModuleMap":');
      toRet = toRet.concat(JSON.stringify(Object.fromEntries(resource.numOfModuleMap)));
      toRet = toRet.concat('}');
      return toRet;
    }
    else {
      return JSON.stringify(resource);
    }
  }
}
