import {AdxLocale} from './adx-locale.model';

/**
 * Utility class to hold selected default locale and selected locale list, for given Organization, Account or Application.
 */
export class AdxLocaleSelection {
  defaultLocale: AdxLocale | null = null;
  locales: AdxLocale[] | null = null;

  constructor() {}
}
