<div class="edit-channel-container" *ngIf="canEditChannel()">
  <form class="add-channel-form" [formGroup]="editChannelForm">
    <div class="flex-container add-channel" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="10" class="input-label associateCategories">
          <div class="channelName-label">
            <mat-label i18n="@@editChannelForm.iconUpload">Icon Upload</mat-label>
            <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                                 (imageSelect)="onIconSelected($event)"> </app-adx-image-input>
          </div>
        </div>
        <div fxFlex="90" class="common-locale-label">
          <div class="channelName-input">
            <mat-form-field class="input-form">
              <input matInput i18n="@@editChannelForm.orgName" id="input-edit-channel-title" #channelName maxlength="150" placeholder="Title"
                     formControlName="title"/>
              <mat-hint align="end">{{channelName.value.length}} / 150</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="90">
          <mat-form-field class="input-form">
            <input type='text' matInput i18n="@@editChannelForm.summary" id="input-edit-channel-summary" #addChannelSummary maxlength="150" placeholder="Summary" formControlName="summary" />
            <mat-hint align="end">{{addChannelSummary.value.length}} / 150</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="40">
          <mat-form-field class="application-sort-order-input">
            <input type='number' matInput i18n="@@addApplnForm.sort-order" id="input-edit-channel-sortOrder"
                   min="0" placeholder="Sort Order" formControlName="sortOrder"/>
          </mat-form-field>
        </div>
        <div class="input-label" fxFlex="1">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="20">
          <mat-form-field>
            <mat-label i18n="@@applicationEditForm.visibility">Visibility</mat-label>
            <mat-select formControlName="visibility" id="input-edit-channel-visibility">
              <mat-option *ngFor="let type of visibilityTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="29">
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="container-label" fxFlex="10">
        </div>
        <mat-form-field class="common-desc-editor" fxLayout="column" fxFlex="90">
          <mat-label i18n="@@editChannelForm.desc">Description</mat-label>
          <textarea placeholder='Description' id="input-edit-channel-description" #channelDescription
                    formControlName="desc" [froalaEditor]="froalaEditorOptions(450)"
                    matInput [errorStateMatcher]="adxErrorStateMatcher"
                    (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
          <mat-hint align="end">{{channelDescription.value.length}} / 450</mat-hint>
          <mat-error *ngIf="editChannelForm.get('desc')?.hasError('maxlength')">
            {{channelDescription.value.length}} exceeds max limit of 450 HTML characters
          </mat-error>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="button-container">
        <button i18n="@@editChannelForm.cancel" id="input-edit-channel-cancel-Btn" mat-raised-button routerLink="{{channelListUrl}}"  class="button-cancel">Cancel</button>
        <button i18n="@@editChannelForm.save" id="input-edit-channel-save-Btn" mat-raised-button [disabled]="!(editChannelForm.valid && editChannelForm.dirty)" type="submit" (click)="save()">Save</button>
      </div>

    </div>
  </form>
</div>
