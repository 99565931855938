<div class="vpublist-container">
<div class="statusInfo">
    <mat-icon matTooltip="Hide:The vpub will not be viewed on client. But, it can be accessed through PASSKEY &#13;
                     Show: The vPub can be viewed on client." matTooltipClass="status-tooltip" class="status-tooltip">
        info</mat-icon>
</div>

  <div class="vpublist-option">
    <div class="button-container">
      <button mat-raised-button i18n="@@applnList.back" id="input-vpubList-back-Btn" class="vpubListBackBtn" type="button" routerLink='{{urlForApplnListing}}'>
        <mat-icon>keyboard_arrow_left</mat-icon> Applications</button>
    </div>

    <div class="button-container">
    <mat-form-field class="vpub-search" appearance="fill">
        <mat-icon matSuffix>search</mat-icon>
        <input i18n="@@serachVpub" id="input-vpubList-titleFilter" matInput placeholder="Search vPubs" [formControl]="titleFilter">
    </mat-form-field>
    </div>
    <div class="vpubListAddDelete">
        <button i18n="@@addVpub" class="vpubListAddBtn" id="input-vpubList-add-Btn" *ngIf="canAddVpub()"
        mat-raised-button color="primary" (click)="navigateToAdd()">Add vPub</button>
        <button i18n="@@addVpubs" class="vpubListDeleteBtn"  id="input-vpubList-delete-Btn" *ngIf="canDeleteVpub()"
        mat-raised-button color="warn" [disabled]="true">Delete</button>
    </div>
  </div>
<div class="vpub-list-table">
    <table mat-table *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table commonOrgAccountAppl">

        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="common-list-th common-title">
                <mat-checkbox class="selectAll-checkbox" id="input-vpubList-selectAllCheckbox" color="primary" [disabled]="true"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="select-checkbox" [value]="element.id" id="input-vpubList-selectCheckbox-{{element.id}}" (click)="$event.stopPropagation()" color="primary"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef class="common-list-th common-icon">Icon</th>
            <td mat-cell *matCellDef="let element">
              <img class="iconImage" [src]="element.iconImage && element.iconImage.preSignedUrl?
                          element.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
            </td>
        </ng-container>

        <ng-container matColumnDef="vpubtitle">
            <th i18n="@@vpub-name" mat-header-cell *matHeaderCellDef class="common-list-th">Title</th>
            <td i18n="@@vpub-name-val" mat-cell *matCellDef="let element">
                <p class="elementTitle" matTooltip="{{ element.title }}" TooltipPosition="after" matTooltipClass="elementTitleTooltip" TooltipVisibility="visible" >{{ element.title }}</p>
            </td>
        </ng-container>

      <ng-container matColumnDef="locale">
        <th i18n="@@vpub-name" mat-header-cell *matHeaderCellDef class="common-list-th">Locale</th>
        <td i18n="@@vpub-name-val" mat-cell *matCellDef="let element">
          <p class="elementLocaleDisplayName" matTooltip="{{ element.locale.displayName }}">{{ element.locale.displayName }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="isDefault">
        <th i18n="@@vpub-name" mat-header-cell *matHeaderCellDef class="common-list-th">Default vPub?</th>
        <td i18n="@@vpub-name-val" mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.isDefault;  else Default">
            <span>Yes</span>
          </ng-container>
          <ng-template #Default>
            <span>No</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="vpubisDraft">
        <th i18n="@@vpub-name" mat-header-cell *matHeaderCellDef class="common-list-th vpubElementIsDraft">Document Status</th>
        <td i18n="@@vpub-name-val" mat-cell *matCellDef="let element" class="common-list-td vpubElementIsDraft">
          <ng-container *ngIf="element.isDraft;  else Draft">
            <span matTooltip="Work in progress. Content not yet visible">Draft</span>
          </ng-container>
          <ng-template #Draft>
            <span matTooltip="Document is completed">Published</span>
          </ng-template>
        </td>
      </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef class="common-list-th common-title"></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                <div class="orgAccountsApplicationBtns">
                  <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item id="input-vpubList-edit-{{element.id}}" i18n="@@vpud-edit"
                            *ngIf="canEditVpub(element.id)" routerLink="edit/{{element.id}}">Edit</button>
                  </mat-menu>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" routerLink="{{row.id}}/modls/"></tr>
    </table>
</div>
</div>
