<div class="account-container" fxLayout="column">
      <div class="transfer-page-header">
        <div style="flex: initial">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@authRequired">Type</mat-label>
        <mat-select [formControl]="typeFilter">
          <mat-option value="">-- Select a Option --</mat-option>
          <mat-option value="import">Import</mat-option>
          <mat-option value="export">Export</mat-option>
        </mat-select>
      </mat-form-field>
        </div>
        <div style="flex: initial">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@status">Status</mat-label>
        <mat-select [formControl]="statusFilter">
          <mat-option value="">-- Select a Option --</mat-option>
          <mat-option value="UPLOADED">UPLOADED</mat-option>
          <mat-option value="VALIDATION_SUCCESS">VALIDATION_SUCCESS</mat-option>
          <mat-option value="VALIDATION_FAILED">VALIDATION_FAILED</mat-option>
          <mat-option value="ACE_PROCESS_INITIATED">ACE_PROCESS_INITIATED</mat-option>
          <mat-option value="ACE_PROCESS_FAILED">ACE_PROCESS_FAILED</mat-option>
          <mat-option value="ACE_PROCESS_SUCCESS">ACE_PROCESS_SUCCESS</mat-option>
          <mat-option value="SUBMITTED">SUBMITTED</mat-option>
          <mat-option value="PROCESSING">PROCESSING</mat-option>
          <mat-option value="SUCCESS">SUCCESS</mat-option>
          <mat-option value="FAILED">FAILED</mat-option>
        </mat-select>
      </mat-form-field>
        </div>
      <div style="flex: none">
        <button mat-raised-button id="transfer-reload-Btn" matTooltip="Reload" (click)="loadData()"><mat-icon>refresh</mat-icon></button>
      </div>
      </div>

  <div class="transfer-list-table">
    <table mat-table *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table">

      <ng-container matColumnDef="id">
        <th i18n="@@id" mat-header-cell *matHeaderCellDef class="common-list-th common-title">ID</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">User</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th">Type</th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          {{ element.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th i18n="@@status" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Status</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title"
            matTooltip="{{element.statusDescription}}">
          <div *ngIf="element.statusDescription; else NODETAILS">
            <a role="button" (click)="showStatusDetails(element)" class="desc_link">{{ element.status }}</a>
          </div>
          <ng-template #NODETAILS>
            {{ element.status }}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="vpubTitle">
        <div *ngIf="isArsDisplayLevel() || isSystemDisplayLevel(); else DisplayVpub">
        <th i18n="@@vpub" mat-header-cell *matHeaderCellDef class="common-list-th common-title">File Name</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.fileName }}
        </td>
        </div>
        <ng-template #DisplayVpub>
          <th i18n="@@vpub" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Publication Title</th>
          <td mat-cell *matCellDef="let element" class="common-list-td common-title">
            {{ element.vpubTitle }}
          </td>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="!isSystemDisplayLevel()" matColumnDef="moduleTitle">
        <div *ngIf="isArsDisplayLevel(); else DisplayModule">
        <th i18n="@@module" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Download</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          <ng-container *ngIf="(element.type.toLowerCase() === 'export');  else ImportData" mat-cell>
            <ng-container *ngIf="(element.objectUrl !== undefined && element.objectUrl !== null); else ExportFailure">
              <a [attr.href]="element.objectUrl" target="_blank">Download</a>
              </ng-container>
              <ng-template #ExportFailure>
                  Download
              </ng-template>
            </ng-container>
          <ng-template #ImportData>
            <ng-container *ngIf="element.zipFileUrl !== undefined && element.zipFileUrl !== null; else ExportText">
                <a [attr.href]="element.zipFileUrl" target="_blank">Download</a>
            </ng-container>
            <ng-template #ExportText>
              <a [attr.href]="(element.objectUrl !== undefined && element.objectUrl !== null) ? element.objectUrl : null" target="_blank">Download</a>
            </ng-template>
          </ng-template>
        </td>
        </div>
        <ng-template #DisplayModule>
          <th i18n="@@module" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Module Title</th>
          <td mat-cell *matCellDef="let element" class="common-list-td common-title">
            <ng-container *ngIf="(element.type.toLowerCase() === 'export');  else ImportData" mat-cell>
              <ng-container *ngIf="(element.objectUrl !== undefined && element.objectUrl !== null); else ExportFailure">
              <a [attr.href]="element.objectUrl" target="_blank">{{ element.moduleTitle }}</a>
              </ng-container>
              <ng-template #ExportFailure>
                  {{ element.moduleTitle }}
              </ng-template>
            </ng-container>
            <ng-template #ImportData>
              <ng-container *ngIf="(element.zipFileUrl !== undefined && element.zipFileUrl !== null); else ImportFailure">
                <a [attr.href]="element.zipFileUrl" target="_blank">{{ element.moduleTitle }}</a>
              </ng-container>
              <ng-template #ImportFailure>
                <a [attr.href]="(element.objectUrl !== undefined && element.objectUrl !== null) ? element.objectUrl : null" target="_blank">{{ element.moduleTitle }}</a>
              </ng-template>
            </ng-template>
          </td>
        </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>

</div>
</div>
