import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxJwtTokenService } from 'src/app/common/services/adx-jwt-token/adx-jwt-token.service';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';
import { AdxAuthUtils } from '../utils/adx-auth-utils';
import { AuthNotifierService } from '../service/auth-notifier.service';

@Injectable()
export class CustomResponseInterceptor implements HttpInterceptor {
  constructor(private logger: NGXLogger, private readonly jwtTokenService: AdxJwtTokenService,
     private localStorageService: LocalStorageService, private authNotifier: AuthNotifierService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // We use the map operator to change the data from the response
      map((event) => {
        // Several HTTP events go through that Observable
        // so we make sure that this is an HTTP response
        if (event instanceof HttpResponse) {
          const token: string | null = event.headers.get('token');

          if (token && token !== null) {
            this.jwtTokenService.setToken(token);
            const decodedToken = this.jwtTokenService.getDecodeToken();
            if (decodedToken !== undefined && decodedToken !== null) {
              //get the user details from token
              const subject: AuthenticatedUser = AuthenticatedUser.buildObject(JSON.parse((decodedToken as any).sub));
              // add user object in storage
              this.localStorageService.set('auth_user', subject);
              //get the permissions and set it in storage
              if (subject.userPermissions !== undefined && subject.userPermissions !== null) {
                const accessibleEntities = AdxAuthUtils.getAccessibleEntites(subject.userPermissions);
                this.localStorageService.set('accessibleEntities', accessibleEntities);
                this.authNotifier.notifyUserUpdate(subject); //notify listeners that there we recieved latest user details
              }
            }

            this.localStorageService.set('java_web_token', token);
            this.logger.debug('set token in storage');
          }
        }
        return event;
      })
    );
  }
}
