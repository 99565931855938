
export class ImageUtility {

  public static convertBase64ToBlob(base64String: string  ): Blob  {
    if (!base64String) {
      return new Blob();
    }
    const splitDataURI = base64String.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
  }

  public static convertBase64ToFile(base64String: string, fileName: string  ): File  {
    if (!base64String) {
      return new File([], fileName);
    }
    const splitDataURI = base64String.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ia], fileName, { type: mimeString });
  }
}
