
/*
* This is base model class for all data models that need to be fetched from backend
*/
export class AdxResource {
  public id: number | null;

  constructor(input: number | null){
    this.id = input;
  }

}
