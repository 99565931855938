<div class="common-container">
  <form [formGroup]="linkAppChannelForm">
    <div class="row">
      <div class="column input-label"><span>Channels</span></div>
      <div class="column input-label"><span>Applications</span></div>
    </div>
    <mat-divider></mat-divider>
    <div class="row input-label">
      <div class="column">
        <div class="common-list" *ngFor="let channel of channels">
          <mat-checkbox formArrayName="channels" id="input-channelBulkAssociation-link-{{channel.id}}"
                        (change)="onChannelSelectionChange(channel.id, $event)">
            {{ channel.title }}
          </mat-checkbox>
        </div>
      </div>
      <div class="column">
        <div class="common-list" *ngFor="let appln of applications">
          <mat-checkbox formArrayName="applications" id="input-applBulkAssociation-link-{{appln.id}}"
                        (change)="onApplSelectionChange(appln.id, $event)">
            {{ appln.title }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="button-container">
      <button mat-raised-button (click)="close()" class="button-cancel" id="input-channelApplBulkAssociation-Close-Btn">Close</button>
      <button mat-raised-button [disabled]="isDisabled()" id="input-channelApplBulkAssociation-submit-Btn" (click)="associate()">Submit</button>
    </div>
  </form>
</div>
