import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AdxUser } from '../model/adx-user-model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { Subject, takeUntil } from 'rxjs';
import { CustomNotifierService } from 'src/app/core/service/notifier/custom-notifier.service';
import { AdxRole } from '../model/adx-role-model';
import { AdxUserService } from '../service/adx-user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/account/service/account.service';
import { Account } from 'src/app/account/model/account.model';
import { Organization } from 'src/app/organization/model/organization.model';
import { OrganizationService } from 'src/app/organization/service/organization.service';
import { AdxApplication } from 'src/app/application/model/adx-application.model';
import { AdxChannel } from 'src/app/channel/model/adx-channel.model';
import { AdxVPub } from 'src/app/vPub/model/adx-vpub.model';
import { AdxModule } from 'src/app/adx-module/model/adx-module.model';
import { AdxUserRole } from '../model/adx-user-role-model';
import { AdxApplicationService } from 'src/app/application/service/adx-application.service';
import { AdxChannelService } from 'src/app/channel/service/adx-channel.service';
import { AdxVpubService } from 'src/app/vPub/service/adx-vpub.service';
import { AdxModuleService } from 'src/app/adx-module/service/adx-module.service';
import { AdxModuleType } from 'src/app/adx-module/model/adx-module-type';

/**
 * Component used to add role to user
 */
@Component({
  selector: 'app-adx-user-role-add',
  templateUrl: './adx-user-role-add.component.html',
  styleUrls: ['./adx-user-role-add.component.scss']
})
export class AdxUserRoleAddComponent extends AdxBaseTemplate implements OnInit, OnChanges {

  @Input() adxUser: AdxUser | null = null;
  @Input() roleScope: string | null = null; // indicates at which level usermgmt screen is being displayed, Org level or Account Level
  @Input() orgId: number | null = null;
  @Input() accountId: number | null = null;

  @Output() roleAdd = new EventEmitter<AdxUserRole>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  addUserRoleForm: FormGroup<any>;
  organizations: Organization[] = [];
  accounts: Account[] = [];
  applications: AdxApplication[] = [];
  channels: AdxChannel[] = [];
  vpubs: AdxVPub[] = [];
  modules: AdxModule[] = [];
  assignableRoles: AdxRole[] = [];

  applnSelected = '';

  hasOrgOptions = false;
  hasAccountOptions = false;
  hasApplicationOptions = false;
  hasChannelOptions = false;
  hasVpubOptions = false;
  hasModuleOptions = false;

  constructor (private readonly service: AdxUserService,
    private readonly orgService: OrganizationService,
    private readonly accountService: AccountService,
    private readonly applnService: AdxApplicationService,
    private readonly channelService: AdxChannelService,
    private readonly vpubService: AdxVpubService,
    private readonly moduleService: AdxModuleService,
    private readonly messageNotifier: CustomNotifierService) {
    super();
    this.addUserRoleForm = new FormGroup({
      assignableRole: new FormControl('', {
        updateOn: 'change', validators: [
          Validators.required
        ]
      }),
      selOrg: new FormControl(),
      selAccount: new FormControl(),
      selAppln: new FormControl(),
      selChannel: new FormControl(),
      selVpub: new FormControl(),
      selModule: new FormControl()
    });
  }

  ngOnInit(): void {
    // on init
  }

  ngOnChanges(): void {
    if (this.orgId !== undefined && this.orgId !== null) {
      this.fetchOrganization(this.orgId);
      this.fetchRoles(this.orgId, this.accountId);
      if (this.accountId) {
        this.fetchAccount(this.orgId, this.accountId);
        this.fetchApplications(this.orgId, this.accountId);
        this.fetchChannels(this.orgId, this.accountId);
      }
    }
    // re initialize
    this.addUserRoleForm.reset();
    this.hasOrgOptions = false;
    this.hasAccountOptions = false;
    this.hasApplicationOptions = false;
    this.hasChannelOptions = false;
    this.hasVpubOptions = false;
    this.hasModuleOptions = false;

    this.addUserRoleForm.patchValue({
      selOrg: this.orgId,
      selAccount: this.accountId
    });
  }

  onRoleChange(roleId: number | null, event: any): void {
    // A selection change event is fired not only when an option is selected but also when it is deselected.
    // So when an option is selected, the event fires on it, and also on any option that is deselected.
    if (event.isUserInput === false) {
      return; //only process user inputs/option selected
    }
    this.logger.debug(`onRoleChange id : ${roleId}`);
    const selectedRole = this.assignableRoles.find(role => role.id === roleId);
    this.logger.debug(selectedRole);
    if (selectedRole) {
      if (selectedRole.scope === 'SYSTEM') {
        this.hasOrgOptions = false;
        this.hasAccountOptions = false;
        this.hasApplicationOptions = false;
        this.hasChannelOptions = false;
        this.hasVpubOptions = false;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'ORGANIZATION') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = false;
        this.hasApplicationOptions = false;
        this.hasChannelOptions = false;
        this.hasVpubOptions = false;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'ACCOUNT') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = true;
        this.hasApplicationOptions = false;
        this.hasChannelOptions = false;
        this.hasVpubOptions = false;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'APPLICATION') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = true;
        this.hasApplicationOptions = true;
        this.hasChannelOptions = false;
        this.hasVpubOptions = false;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'CHANNEL') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = true;
        this.hasApplicationOptions = false;
        this.hasChannelOptions = true;
        this.hasVpubOptions = false;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'VPUB') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = true;
        this.hasApplicationOptions = true;
        this.hasChannelOptions = false;
        this.hasVpubOptions = true;
        this.hasModuleOptions = false;
      }
      else if (selectedRole.scope === 'MODULE') {
        this.hasOrgOptions = true;
        this.hasAccountOptions = true;
        this.hasApplicationOptions = true;
        this.hasChannelOptions = false;
        this.hasVpubOptions = true;
        this.hasModuleOptions = true;
      }

      //reset other fields
      this.applnSelected = '';
      this.addUserRoleForm.get('selAppln')?.reset();
      this.addUserRoleForm.get('selChannel')?.reset();
      this.addUserRoleForm.get('selVpub')?.reset();
      this.addUserRoleForm.get('selModule')?.reset();
      this.channels = [];
      this.vpubs = [];
      this.modules = [];
    }
  }

  onApplicationChange(appId: number | null, event: any): void {
    this.logger.debug(`onApplicationChange : ${appId}`);
    // A selection change event is fired not only when an option is selected but also when it is deselected.
    // So when an option is selected, the event fires on it, and also on any option that is deselected.
    if (event.isUserInput === false) {
      return; //only process user inputs/option selected
    }
    if (appId) {
      const orgId = this.addUserRoleForm.get('selOrg')?.value;
      this.logger.debug(`orgId ${orgId}`);

      const accId = this.addUserRoleForm.get('selAccount')?.value;
      this.logger.debug(`accId ${accId}`);

      if (orgId && accId) {
        this.fetchVpubs(orgId, accId, appId);
      }
    }
    //reset other fields
    this.addUserRoleForm.get('selVpub')?.reset();
    this.addUserRoleForm.get('selModule')?.reset();
    this.vpubs = [];
    this.modules = [];
  }

  onVpubChange(vpubId: number | null, event: any): void {
    this.logger.debug(`onVpubChange : ${vpubId}`);
    // A selection change event is fired not only when an option is selected but also when it is deselected.
    // So when an option is selected, the event fires on it, and also on any option that is deselected.
    if (event.isUserInput === false) {
      return; //only process user inputs/option selected
    }
    if (vpubId) {
      const orgId = this.addUserRoleForm.get('selOrg')?.value;
      this.logger.debug(`orgId ${orgId}`);

      const accId = this.addUserRoleForm.get('selAccount')?.value;
      this.logger.debug(`accId ${accId}`);

      if (orgId && accId) {
        this.fetchModules(orgId, accId, vpubId);
      }
    }
    //reset other fields
    this.addUserRoleForm.get('selModule')?.reset();
    this.modules = [];
  }

  onSave() {
    if (this.addUserRoleForm && this.addUserRoleForm.dirty) {
      const userRole = new AdxUserRole(null);
      this.logger.debug(`assignableTole: ${this.addUserRoleForm.get('assignableRole')?.dirty}`);
      if (this.addUserRoleForm.get('assignableRole')?.dirty) {
        const roleId = this.addUserRoleForm.get('assignableRole')?.value;
        const role = this.assignableRoles.find(obj => obj.id === roleId);
        if (role) {
          userRole.role = role;
        }
      }
      this.logger.debug(`selOrg: ${this.addUserRoleForm.get('selOrg')?.dirty}`);
      const orgId = this.addUserRoleForm.get('selOrg')?.value;
      userRole.organizationId = orgId;
      this.logger.debug(`set orgId to ${orgId}`);

      this.logger.debug(`selAccount: ${this.addUserRoleForm.get('selAccount')?.dirty}`);
      const accId = this.addUserRoleForm.get('selAccount')?.value;
      userRole.accountId = accId;
      this.logger.debug(`set accountId to ${accId}`);

      this.logger.debug(`selAppln: ${this.addUserRoleForm.get('selAppln')?.dirty}`);
      const appId = this.addUserRoleForm.get('selAppln')?.value;
      userRole.applicationId = appId;
      this.logger.debug(`set applicationId to ${appId}`);

      this.logger.debug(`selChannel: ${this.addUserRoleForm.get('selChannel')?.dirty}`);
      const chnId = this.addUserRoleForm.get('selChannel')?.value;
      userRole.channelId = chnId;
      this.logger.debug(`set chnId to ${chnId}`);

      this.logger.debug(`selVpub: ${this.addUserRoleForm.get('selVpub')?.dirty}`);
      const vId = this.addUserRoleForm.get('selVpub')?.value;
      userRole.vpubId = vId;
      this.logger.debug(`set vId to ${vId}`);

      this.logger.debug(`selModule: ${this.addUserRoleForm.get('selModule')?.dirty}`);
      const mId = this.addUserRoleForm.get('selModule')?.value;
      userRole.moduleId = mId;
      this.logger.debug(`set mId to ${mId}`);

      // just send the added role to parent
      this.roleAdd.emit(userRole);
    }
    this.addUserRoleForm.reset();

  }

  /*
  * makes backend call to fetch the user
  */
  private fetchOrganization(orgId: number): void {
    const existingOrg = this.organizations.find(obj => obj.id === orgId);
    if (!existingOrg) {
      this.organizations = [];
      this.orgService.getOrganization(orgId).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: Organization) => {
            // set organizations variable
            if (data) {
              this.organizations.push(data);
            }
          }
        });
    }
  }

  /*
  * makes backend call to fetch the account
  */
  private fetchAccount(orgId: number, acctId: number): void {
    const existingOrg = this.organizations.find(obj => obj.id === orgId);
    if (!existingOrg) {
      this.organizations = [];
      this.accountService.getAccount(orgId, acctId).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: Account) => {
            // set account variable
            if (data) {
              this.accounts.push(data);
            }
          }
        });
    }
  }

  /*
  * makes backend call to fetch the applications
  */
  private fetchApplications(orgId: number, acctId: number): void {
    const existingOrg = this.organizations.find(obj => obj.id === orgId);
    if (!existingOrg) {
      this.organizations = [];
      this.applnService.getApplications(orgId, acctId).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxApplication[]) => {
            // set application variable
            if (data) {
              this.applications = data;
            }
          }
        });
    }
  }

  /*
  * makes backend call to fetch the channels
  */
  private fetchChannels(orgId: number, acctId: number): void {
    const existingOrg = this.organizations.find(obj => obj.id === orgId);
    if (!existingOrg) {
      this.organizations = [];
      this.channelService.getChannels(orgId, acctId).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxChannel[]) => {
            // set channel variable
            if (data) {
              this.channels = data;
            }
          }
        });
    }
  }

  /*
  * makes backend call to fetch the applications
  */
  private fetchVpubs(orgId: number, acctId: number, applnId: number): void {
    this.logger.debug(`fetching vpubs ${applnId}`);
    this.vpubService.getVpubs(orgId, acctId, applnId).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxVPub[]) => {
          // set application variable
          if (data) {
            this.vpubs = data;
            this.logger.debug(`fetched vpubs`);
            this.logger.debug(data);
          }
        }
      });
  }

  /*
  * makes backend call to fetch the modules
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): void {
    this.logger.debug(`fetching modules ${vpubId}`);
    this.moduleService.getModules(orgId, acctId, vpubId).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // set application variable
          if (data) { //data returned is a tree set. Convert it to array
            this.modules = this.convertModuleTreeToList(data);
          }
        }
      });
  }

  /*
   * Utility function to convert module tree to list of modules
   * @param inputTree: list received from backend
   */
  private convertModuleTreeToList(inputTree: AdxModule[]): AdxModule[] {
    let toRet: AdxModule[] = [];
    if (!inputTree || inputTree.length < 1) {
      return toRet;
    }

    for (const module of inputTree) {
      if (module) {
        if (module.type === AdxModuleType.GROUP) {
          toRet.push(...this.convertModuleTreeToList(module.children));
        }
        else {
          toRet.push(module);
        }
      }
    }

    //filter the list to display relavant modules based on the role selected
    if (this.addUserRoleForm.get('assignableRole')) {
      const roleId = this.addUserRoleForm.get('assignableRole')?.value;
      this.logger.debug(`assignableRole value ${roleId}`);
      const role = this.assignableRoles.find(obj => obj.id === roleId);
      if (role) {
        this.logger.debug(`selected role is ${role.title}`);
        if (role.title && role.title.startsWith('ATOM')) {
          //needs to only display ARS modules
          toRet = toRet.filter(mod => mod.type === 'ARS');
        }
        else if (role.title && role.title.startsWith('FORM')) {
          //needs to only display FORM modules
          toRet = toRet.filter(mod => mod.type === 'FORM');
        }
      }
    }

    this.logger.debug(`returning ${toRet.length} modules`);
    return toRet;
  }

  /*
  * makes backend call to fetch the assignable roles
  */
  private fetchRoles(orgId: number, acctId: number | null): void {
    if (this.assignableRoles === undefined || this.assignableRoles === null
      || this.assignableRoles.length < 1) {
      this.service.getRoles(orgId, acctId).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxRole[]) => {
            // set user variable
            this.assignableRoles = data;
            this.logger.debug(this.assignableRoles);
          }
        });
    }
  }

  /*
   * used to fetch OrgReadonly role from given list of roles
   */
  private filterOrgReadOnlyRole(roles: AdxRole[]): AdxRole {
    const filteredRoles = roles.filter((role) => role.permissions[0].code === 'ORGANIZATION_READ_ONLY');
    return filteredRoles[0];
  }
}
