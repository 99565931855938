import {Component, OnDestroy, OnInit} from '@angular/core';
import {of, Subject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {AdxChannel} from '../channel/model/adx-channel.model';
import {NGXLogger} from 'ngx-logger';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxChannelService} from '../channel/service/adx-channel.service';
import {CommonUtility} from '../common/utils/common-utils';
import {takeUntil} from 'rxjs/operators';
import {AdxChannelType} from '../channel/model/adx-channel-type';
import {LinkChannelAppComponent} from './link-channel-app/link-channel-app.component';
import {AdxApplicationService} from '../application/service/adx-application.service';
import {AdxApplication} from '../application/model/adx-application.model';
import {DelinkChannelAppComponent} from './delink-channel-app/delink-channel-app.component';
import { AuthNotifierService } from '../auth/service/auth-notifier.service';
import { AdxBaseTemplate } from '../common/template/adx-base-template';
import { AuthenticatedUser } from '../common/model/adx-auth/authenticated-user.model';

@Component({
  selector: 'app-app-channel-link',
  templateUrl: './app-channel-link.component.html',
  styleUrls: ['./app-channel-link.component.scss']
})
export class AppChannelLinkComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['Channel', 'Applications'];
  orgId: number | null = null;
  accountId: number | null = null;
  dataSource: MatTableDataSource<AdxChannel> | null = null;
  channelSubscription: Subscription | null = null;
  applicationSubscription: Subscription | null = null;
  channelList: AdxChannel[] | null = null;
  applicationList: AdxApplication[] | null = null;
  urlForAcctListing = '/orgs';

  private authSubscription: Subscription | undefined;

  constructor(private authNotifier: AuthNotifierService, private router: Router, private matDialog: MatDialog,
              private channelService: AdxChannelService, private adxAppService: AdxApplicationService,
              private activatedRoute: ActivatedRoute) {
                super();
               }

  ngOnInit(): void {
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute);
    this.accountId = CommonUtility.getId('acctId', this.activatedRoute);
    this.urlForAcctListing = `/orgs/${this.orgId}/accts`;
    if (this.orgId && this.accountId) {
      this.channelSubscription = this.fetchChannels(this.orgId, this.accountId);
      this.applicationSubscription = this.fetchApplications(this.orgId, this.accountId);
    } else {
      this.logger.error('Mandatory Parameters not available');
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.channelSubscription) {
      this.channelSubscription.unsubscribe();
    }
    if (this.applicationSubscription) {
      this.applicationSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  getAppTitle(appId: number): string {
    const selApp = this.applicationList?.find(app => app.id === appId);
    if (selApp && selApp.title) {
      return selApp.title;
    }
    else {
      return `${appId}`;
    }
  }

  /**
   * Callback that returns true if logged-in user has permission to associate channels
   *
   * @returns boolean. true if current user can associate channels
   */
  canAssociateChannel(): boolean {
    if (this.orgId !== undefined && this.orgId !== null
       && this.accountId !== undefined && this.accountId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.channelService.isUserAccountAdmin(this.orgId, this.accountId, this.currentUser);
    }
    return false;
  }

  associate(): void {
    const dialogRef = this.matDialog.open(LinkChannelAppComponent, {
      width: '6000px',
      maxHeight: '90vh',
      data: {orgId: this.orgId, acctId: this.accountId, channels: this.channelList, applications: this.applicationList},
      autoFocus: 'false'
    });

    // on close of dialog, refresh the listing page
    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug('The dialog was closed', result);
      if (result) {
        if (this.orgId && this.accountId) {
          this.channelSubscription = this.fetchChannels(this.orgId, this.accountId);
        } else {
          this.logger.error('Mandatory Parameters not available');
        }
      }
    });
  }

  disAssociate(): void {
    const dialogRef = this.matDialog.open(DelinkChannelAppComponent, {
      width: '6000px',
      maxHeight: '90vh',
      data: {orgId: this.orgId, acctId: this.accountId, channels: this.channelList, applications: this.applicationList},
      autoFocus: 'false'
    });

    // on close of dialog, refresh the listing page
    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug('The dialog was closed', result);
      if (result) {
        if (this.orgId && this.accountId) {
          this.channelSubscription = this.fetchChannels(this.orgId, this.accountId);
        } else {
          this.logger.error('Mandatory Parameters not available');
        }
      }
    });
  }

  /*
   * This method fetches the channels list from backend
  */
  private fetchChannels(orgId: number, accountId: number): Subscription {
    this.logger.debug('fetching channels for orgId:' + orgId + ' ; accountId:' + accountId);
    if (accountId) {
      if (orgId) {
        return this.channelService.getChannels(orgId, accountId)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (data: AdxChannel[]) => {
              // successfully received the data. remove the default channel and set the data in dataSource
              const filteredData: AdxChannel[] = data.filter(channel => channel.channelType !== AdxChannelType.DEFAULT);
              this.dataSource = new MatTableDataSource(filteredData);
              this.channelList = filteredData;
            }
          });
      } else {
        return of({}).subscribe();
      }
    } else {
      return of({}).subscribe();
    }
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchApplications(orgId: number, accountId: number): Subscription {
    this.logger.debug('fetching applications for orgId:' + orgId + ' ; appId:' + accountId);
    if (accountId && orgId) {
      return this.adxAppService.getApplications(orgId, accountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxApplication[]) => {
            // successfully received the data. so set it in variable
            this.applicationList = data;
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }
}
