import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {AdxImageLibraryType} from '../../../utils/adx-image-library-type';
import {MatDialog} from '@angular/material/dialog';
import {AdxImageLibraryComponent} from '../adx-image-library.component';
import {NGXLogger} from 'ngx-logger';
import {AdxImageLibraryItem} from '../../../../common/model/image-library/adx-image-library-item.model';

/**
 * Directive used to display the Image Library popup.
 * This will need ImageLibraryType and id as inputs that it will pass-on to the component.
 */
@Directive({
  selector: '[appAdxImageLibrary]'
})
export class AdxImageLibraryDirective {

  @Input() id: number | null = null;
  @Input() isIcon = true;
  @Input() type: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;
  @Input() isSelect = true;
  @Input() orgId : number | null = null;
  @Input() accountId : number | null = null;
  @Input() applnId : number | null = null;
  @Input() vpubId : number | null = null;
  @Input() moduleId : number | null = null;
  @Input() atomId : number | null = null;

  @Output() imgSelectEmitter = new EventEmitter<AdxImageLibraryItem>();

  constructor(private logger: NGXLogger, private dialog: MatDialog) {
    this.logger.debug('AdxImageLibraryDirective constructor');
  }

  /**
   * On click of the button/link in the element having the directive, this method is triggered.
   */
  @HostListener('click', ['$event']) onClick(){
    this.logger.debug(`AdxImageLibraryDirective id: ${this.id}`);
    this.logger.debug(`AdxImageLibraryDirective type: ${this.type}`);
    this.openDialog();
  }

  /*
   * Show popup having AdxImageLibraryComponent component
   */
  private openDialog(): void {
    const dialogRef = this.dialog.open(AdxImageLibraryComponent, {
      height: '100%',
      width: '100%',
      data: {
        isSelect: this.isSelect,
        libraryType: this.type,
        id: this.id,
        isIcon: this.isIcon,
        applnId : this.applnId,
        vpubId : this.vpubId,
        moduleId : this.moduleId,
        atomId : this.atomId,
        orgId : this.orgId,
        accountId : this.accountId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result != undefined) // if any image selected then emit the event.
      this.imgSelectEmitter?.emit(result || null);
    });
  }

}
