<div fxLayout="column" class="insert-image-container">
  <div fxFlex="100" class="external-image-container">
    <div fxLayout="column">
      <form class="insert-external-form" [formGroup]="insertExtrernalForm" autocomplete="off">      
        <div class="row">
          <div fxFlex="100">
            <h4 class="externalImageURL">
              <span i18N="@ext-img-header">External Image URL</span>
            </h4>
          </div>
        </div>     
        <div class="row">
          <div fxFlex="100" class="input-category-label">
          <mat-form-field class="input-form" appearance="fill">
            <mat-label>Image URL</mat-label>
            <input type="URL" matInput placeholder="https://my-image-url.com" formControlName="imageUrl">
          </mat-form-field>
        </div>
      </div>
      <div>
        <div fxLayout="row" fxLayoutGap="5px">
          <div fxFlex="15" class="email-label">
            <span>Dimensions</span>
          </div>
          <div fxFlex="12">
            <mat-form-field class="input-form" appearance="fill">
              <mat-label>Width</mat-label>
              <input type="text" matInput placeholder="Width" formControlName="imgWidth">
            </mat-form-field>
          </div>
          <div fxFlex="3" class="email-label">
            <span>*</span>
          </div>
          <div fxFlex="13">
            <mat-form-field class="input-form" appearance="fill">
              <mat-label>Height</mat-label>
              <input type="text" matInput placeholder="Height" formControlName="imgHeight">
            </mat-form-field>
          </div>
          <div fxFlex="34" class="email-label">
            <mat-checkbox formControlName="imgProportion" class="proportions-checkbox">Constrain proportions</mat-checkbox>
          </div>
          <div fxFlex="12" class="email-label common-locale-label">
            <button mat-raised-button i18n="@@editAccountForm.save" type="button" [disabled]="!(insertExtrernalForm.valid && insertExtrernalForm.dirty)" (click)="insertExternalImage()">Save</button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <div class="internal-image-container" *ngIf="typeId !== undefined && typeId !== null">
    <div class="row">
      <div fxFlex="100">
      <h4 class="imageLibrary container-fluid">
        <span i18N="@img-library-header">Image Library</span>
      </h4>
      </div>
    </div>
    <div>
      <button i18n="@@imgInputBtn" class="common-container" mat-stroked-button appAdxImageLibrary
              [id]="typeId" [type]="libraryType" [isSelect]="true" [isIcon]="false"
              (imgSelectEmitter)="imageSelected($event)" [orgId]="orgId" [accountId]="accountId" [applnId] ="applnId" [vpubId] ="vpubId"
              [moduleId] ="moduleId" [atomId]="atomId">Upload</button>
    </div>
  </div>
</div>
