<div class="common-container">
  <div class="flex-container" fxLayout="column">
    <mat-tab-group class="common-tab-group">
      <mat-tab label="Organizations">
        <ng-template matTabContent>
          <router-outlet></router-outlet>
        </ng-template>
      </mat-tab>
      <mat-tab label="Associations">
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
