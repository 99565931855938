<div class="adx-user-profile-edit-container">
  <div class="adx-user-profile-edit-search">
    <!--display the user search component-->
    <app-adx-user-search #search_component (searchResult)="updateSearchResult($event)"
      style="width: 95%;"></app-adx-user-search>
  </div>
  <div class="adx-user-profile-edit-content">
    <!--display user details component-->
    <app-adx-user-details [isDisabled]="isDisabled" [user]="userDetails"></app-adx-user-details>
  </div>
</div>
