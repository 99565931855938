import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AdxUserRole } from '../model/adx-user-role-model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { AdxUser } from '../model/adx-user-model';
import { AdxDialogService } from 'src/app/common/services/dialog/adx-dialog.service';
import { CustomMessageType } from 'src/app/core/service/notifier/custom-message-type';

/**
 * Component used to list the uer roles. It will take as input user details and applicable user roles
 *
 */
@Component({
  selector: 'app-adx-user-role-list',
  templateUrl: './adx-user-role-list.component.html',
  styleUrls: ['./adx-user-role-list.component.scss']
})
export class AdxUserRoleListComponent extends AdxBaseTemplate implements OnInit, OnChanges {

  @Input() selectedUser: AdxUser | null = null;
  @Input() userRoles: AdxUserRole[] = [];

  @Output() deleteUserRoles = new EventEmitter<AdxUserRole[]>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  displayedColumns: string[] = [
    'selection',
    'roletitle',
    'entityType',
    'entitytitle'
  ];
  dataSource = new MatTableDataSource<AdxUserRole>(); //used to display the roles as table
  isDisabled = true; // whether the delete button is disabled

  /* The selection for checklist */
  checklistSelection: SelectionModel<AdxUserRole>;
  allSelect = false; // indicates whether all check box selected

  constructor(private dialogService: AdxDialogService) {
    super();
    this.checklistSelection = new SelectionModel<AdxUserRole>(true, undefined, undefined, this.selectionCompareFn);
  }

  selectionCompareFn(o1: AdxUserRole, o2: AdxUserRole): boolean {
    const toRet = ((o1.role?.id === o2.role?.id) &&
            (o1.entityType === o2.entityType) &&
            (o1.organizationId === o2.organizationId) &&
            (o1.accountId === o2.accountId) &&
            (o1.applicationId === o2.applicationId) &&
            (o1.channelId === o2.channelId) &&
            (o1.vpubId === o2.vpubId) &&
            (o1.moduleId === o2.moduleId));
    return toRet;
  };

  ngOnInit(): void {
    // do nothing for now
    this.logger.debug(`UserRoleList onINit`);
  }

  /**
   * Whenever the input changes, this method is initiated
   */
  ngOnChanges(): void {
    this.logger.debug(`UserRoleList onChanges`);
    this.logger.debug(this.userRoles);
    this.dataSource.data = [];
    if (this.userRoles !== undefined && this.userRoles !== null
      && this.userRoles.length > 0) {
      this.dataSource.data = this.userRoles;
    }
    else {
      this.logger.debug(`User not found`);
    }
  }

  getCheckboxValue(userRole: AdxUserRole): string {
    const toRet = `${userRole.role?.id}#${this.getEntityTitle(userRole)}`;
    return toRet;
  }

  /**
   * callback from selectall checkbox
   */
  selectAll() {
    this.allSelect = !this.allSelect;
    this.dataSource.data.forEach((r, index) => {
      this.logger.debug(`index : ${index} :: ${this.dataSource.data[index]}`);
      if (this.allSelect) {
        this.checklistSelection.select(this.dataSource.data[index]);
        this.isDisabled = false;
      } else {
        this.checklistSelection.deselect(this.dataSource.data[index]);
        this.isDisabled = true;
      }
    });
  }

  /**
   * callback from checkbox
   *
   * @param element
   */
  selectionChange(element: AdxUserRole) {
    this.checklistSelection.toggle(element);
    if (!this.checklistSelection.isSelected(element)) {
      this.allSelect = false;//as one of the node is deselected, allSelect needs to be false
    }
    if (this.checklistSelection.isEmpty()) {
      this.isDisabled = true;
    }
    else {
      this.isDisabled = false;
    }
  }

  /**
   * Used to display the entity name in the user role table.
   *
   * @param userRole: role for which to get the title
   * @returns string: entity title
   */
  getEntityTitle(userRole: AdxUserRole): string {
    if (userRole) {
      if (userRole.moduleTitle !== undefined && userRole.moduleTitle !== null) {
        return userRole.moduleTitle;
      }
      else if (userRole.vpubTitle !== undefined && userRole.vpubTitle !== null) {
        return userRole.vpubTitle;
      }
      else if (userRole.channelTitle !== undefined && userRole.channelTitle !== null) {
        return userRole.channelTitle;
      }
      else if (userRole.applicationTitle !== undefined && userRole.applicationTitle !== null) {
        return userRole.applicationTitle;
      }
      else if (userRole.accountTitle !== undefined && userRole.accountTitle !== null) {
        return userRole.accountTitle;
      }
      else if (userRole.orgTitle !== undefined && userRole.orgTitle !== null) {
        return userRole.orgTitle;
      }
    }
    return '';
  }

  /**
   * need to indicate to parent that user role delete is called
   */
  onDelete(): void {
    // delete selected
    const selectedObjects = this.checklistSelection.selected;
        if (!selectedObjects || selectedObjects.length < 1) {
            this.alertDialog('Invalid Operation', 'Please select content to delete', null, CustomMessageType.ERROR);
            return;
        }
        this.confirmDialog('Please confirm whether you want to delete the selected content.').subscribe({
            next: (confirmed) => {
              if (confirmed) { //move ahead with deletion
                this.deleteUserRoles.emit(selectedObjects);
              }
            }
          });
  }

  private alertDialog(strTitle: string, msg: string, submsg: string | null, msgType: CustomMessageType): Observable<boolean> {
    return this.dialogService.alertDialog({
      title: strTitle,
      message: msg,
      submessage: submsg,
      type: msgType
    });
  }

  private confirmDialog(msg: string): Observable<boolean> {
    return this.dialogService.confirmDialog({
      title: 'Please confirm action',
      message: msg,
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    });
  }
}

