import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError} from 'rxjs/operators';
import {AdxApplicationService} from '../service/adx-application.service';
import {AdxApplication} from '../model/adx-application.model';

@Injectable({
  providedIn: 'root'
})
export class AdxApplicationResolver implements Resolve<AdxApplication | null> {

  constructor(private logger: NGXLogger, private applicationService: AdxApplicationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdxApplication | null> {
    this.logger.debug('In Application route resolve:' + route.paramMap.keys);
    const orgIdString: string | null | undefined = route.paramMap.get('orgId');
    this.logger.debug(orgIdString);
    const accountIdString: string | null = route.paramMap.get('acctId');
    this.logger.debug(accountIdString);
    const applicationIdString: string | null = route.paramMap.get('applnId');
    this.logger.debug(applicationIdString);

    if (orgIdString) {
      if (isNaN(+orgIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed org id is not a number: ${orgIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed org id is not a number: ${orgIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (accountIdString) {
      if (isNaN(+accountIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${accountIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${accountIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (applicationIdString) {
      if (isNaN(+applicationIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${applicationIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${applicationIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    const selectedOrgId: number = +orgIdString;
    const selectedAccountId: number = +accountIdString;
    const selectedApplicationId: number = +applicationIdString;
    this.logger.debug(`ids received: ${selectedOrgId}: ${selectedAccountId}`);
    return this.applicationService.getApplication(selectedOrgId, selectedAccountId, selectedApplicationId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of(null);
      })
    );
  }
}
