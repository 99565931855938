import {CustomMessageType} from './custom-message-type';

/**
 * Model class to hold the user notification.
 * Notification consists of type (enum) whether success, info, warn etc
 * and actual message to be displayed to user
 */
export class CustomNotification {

  type: CustomMessageType;
  message: string;

  constructor(msgType: CustomMessageType, msgContent: string){
    this.type = msgType;
    this.message = msgContent;
  }

}
