<div class="adx-user-role-list-container">
  <!-- header display's username and delete buttons-->
  <div class="adx-user-role-list-header">
    <div class="adx-user-role-list-title">
      <h3>{{ selectedUser?.firstName }} {{ selectedUser?.lastName }}</h3>
    </div>
    <div class="adx-user-role-list-action" *ngIf="(dataSource !== null && dataSource.data.length > 0)">
      <button mat-raised-button (click)="onDelete()" [disabled]="isDisabled">Delete</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <!-- content should display table containing the roles. Each table row should have a checkbox, role, applicable objectids-->
  <div class="adx-user-listing-content" *ngIf="(dataSource !== null && dataSource.data.length > 0); else NODATA">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="selection">
        <th mat-header-cell *matHeaderCellDef class="common-list-th common-title">
          <mat-checkbox class="selectAll-checkbox" id="input-vpubList-selectAllCheckbox" color="primary"
            (change)="selectAll()" [checked]="allSelect"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox class="select-checkbox" [value]="getCheckboxValue(element)" id="input-vpubList-selectCheckbox-{{element.role?.id}}-{{getEntityTitle(element)}}"
          [checked]="checklistSelection.isSelected(element)" (change)="selectionChange(element)"
            color="primary"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="roletitle">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Role Title</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.role.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="entityType">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Entity</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title" [matTooltip]="element.firstName">
          {{ element.entityType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="entitytitle">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Entity Title</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title" [matTooltip]="element.lastName">
          {{ getEntityTitle(element) }}
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>
  </div>
  <ng-template #NODATA>
    <span style="text-align: center;"> Data Not Found </span>
  </ng-template>
</div>
