import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdxModuleComponent} from './adx-module.component';
import {AddMultipleAdxModuleComponent} from './add-multiple-module/add-multiple-adx-module.component';
import {AdxModuleListComponent} from './module-list/adx-module-list.component';
import {AddAdxModuleGroupComponent} from './add-module-group/add-adx-module-group.component';
import {AdxEditModuleComponent} from './adx-edit-module/adx-edit-module.component';
import {AdxModuleResolver} from './resolver/adx-module.resolver';
import {ManageWebComponent} from './manage-web/manage-web.component';
import {ManageHtmlComponent} from './manage-html/manage-html.component';
import {AdxAddModuleComponent} from "./adx-add-module/adx-add-module/adx-add-module.component";
import {AccountResolver} from "../account/resolver/account.resolver";
import { AdxVpubResolver } from '../vPub/resolver/adx-vpub.resolver';

const moduleRoutes: Routes = [
  {
    path: '',
    component: AdxModuleComponent,
    resolve: {account: AccountResolver},
    children: [
      {
        path: '', component: AdxModuleListComponent,
        resolve: {account: AccountResolver, vpub: AdxVpubResolver}
      },
      {
        path: 'bulkAdd',
        component: AddMultipleAdxModuleComponent,
        resolve: {account: AccountResolver}
      },
      {
        path: 'add/ars',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/form',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/web',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/html',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/motor_axlenut_hubmount',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/motor_coolant',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/motor_truspeed',
        component: AdxAddModuleComponent
      },
      {
        path: 'add/group',
        component: AddAdxModuleGroupComponent
      },
      {
        path: 'edit/:modId',
        component: AdxEditModuleComponent,
        resolve: {
          modl: AdxModuleResolver
        }
      },
      {
        path: ':modId/web',
        component: ManageWebComponent,
        resolve: {
          modl: AdxModuleResolver
        }
      },
      {
        path: ':modId/html',
        component: ManageHtmlComponent,
        resolve: {
          modl: AdxModuleResolver
        }
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule]
})
export class AdxModuleRoutingModule { }
