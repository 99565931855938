<div class="content-report-container">
  <div class="content-report-page-header">

    <div style="flex: initial">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@status">Status</mat-label>
        <mat-select [formControl]="statusFilter">
          <mat-option value="">-- Select a Option --</mat-option>
          <mat-option value="SUBMITTED">SUBMITTED</mat-option>
          <mat-option value="PROCESSING">PROCESSING</mat-option>
          <mat-option value="SUCCESS">SUCCESS</mat-option>
          <mat-option value="FAILED">FAILED</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="action-button-container">
      <button i18n="@@contentReport-create" mat-raised-button color="primary" id="contentReport-add-Btn"
              (click)="createContentReport()" class="createContentReport">Create</button>
      <button mat-raised-button id="contentReport-reload-Btn" matTooltip="Reload" (click)="loadData()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="content-report-table">
    <table mat-table *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table">

      <ng-container matColumnDef="id">
        <th i18n="@@id" mat-header-cell *matHeaderCellDef class="common-list-th common-title">ID</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">User</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th">Type</th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          {{ element.reportName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th i18n="@@status" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Status</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title"
            matTooltip="{{element.statusDescription}}">
          <div *ngIf="element.statusDescription; else NODETAILS">
            <a role="button" (click)="showStatusDetails(element)" class="desc_link">{{ element.status }}</a>
          </div>
          <ng-template #NODETAILS>
            {{ element.status }}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th">Download</th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          <div *ngIf="element.status.toLowerCase() ==='success'">
            <a [attr.download]="element.fileName" [attr.href]="element.status.toLowerCase() ==='success' ? element.objectUrl : null" target="_blank">
              {{ element.fileName }}</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>

  </div>
</div>
