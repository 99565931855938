import { Component, Input, OnInit } from '@angular/core';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AdxUser } from '../model/adx-user-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { blankValidator } from 'src/app/common/utils/form-utility';
import { AdxUserService } from '../service/adx-user.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CustomNotifierService } from 'src/app/core/service/notifier/custom-notifier.service';
import { CustomMessageType } from 'src/app/core/service/notifier/custom-message-type';
import { AdxRole } from '../model/adx-role-model';
import { AdxUserRole } from '../model/adx-user-role-model';

/**
 * This component is used to add users.
 * This is a popup called from user listing page
 */
@Component({
  selector: 'app-adx-user-add',
  templateUrl: './adx-user-add.component.html',
  styleUrls: ['./adx-user-add.component.scss']
})
export class AdxUserAddComponent extends AdxBaseTemplate implements OnInit {

  @Input() orgId: number | null = null;
  @Input() accountId: number | null = null;

  destroy$: Subject<boolean> = new Subject<boolean>();
  searchUserForm: FormGroup;
  userDetails: AdxUser | null = null;
  private roles: AdxRole[] = [];

  constructor (private readonly service: AdxUserService,
    private readonly messageNotifier: CustomNotifierService) {

    super();

    this.searchUserForm = new FormGroup({
      searchUser: new FormControl<string>('', {
        updateOn: 'change', validators: [
          blankValidator,
          Validators.email,
          Validators.maxLength(100)
        ]
      })
    });
  }

  ngOnInit(): void {
    this.logger.debug(`orgId: ${this.orgId} :: accountId: ${this.accountId}`);
    if (this.orgId !== null) {
      if (this.accountId !== null) {
        this.fetchRoles(this.orgId, this.accountId);
      }
      else {
        this.fetchRoles(this.orgId, null);
      }
    }
    else {
      this.logger.error('orgId is NULL');
    }
  }

  /**
   * Called on getting result from search user child component
   */
  updateSearchResult(input: AdxUser | null): void {
    this.userDetails = input;
    this.logger.debug(this.userDetails);
    if (input === null) {
      this.messageNotifier.notify(CustomMessageType.ERROR, `User not found.`);
    }
  }

  /**
   * Used to enable or disable the buttons on UI
   *
   * @returns boolean: true if user has been found
   */
  isUserFound(): boolean {
    return (this.userDetails !== undefined && this.userDetails !== null
      && this.userDetails.id !== undefined && this.userDetails.id !== null);
  }

  /**
   * Callback on click of add.
   * Calls the backend to add user to either given organization or given account.
   */
  onAdd(): void {

    this.logger.debug(`${this.orgId} :: ${this.userDetails?.id}`);

    if (this.orgId !== undefined && this.orgId !== null &&
      this.userDetails && this.userDetails !== undefined && this.userDetails !== null) {
        this.userDetails.organizationId = this.orgId;
        if(this.accountId && this.accountId !== null && this.accountId !== undefined){ this.userDetails.accountId = this.accountId }

        if(this.userDetails.id !== null && this.userDetails.id !== undefined){ // for existing user we need to read roles if exixt
          //determine whether to add account role or organization role
          
          // let readonlyRole: AdxRole | null = null; //As we dont have to read and set READONLY role to any user this part is not required.
          // const fixedUserRole = new AdxUserRole(null);          
          // fixedUserRole.organizationId = this.orgId;
    
          if (this.accountId) {
            this.logger.debug(`Adding account level role:: ${this.accountId}`);
            
            // this below call was to read and set the accnt-read-only role to new user.
            // but it is not required now as server itself will set ACCESS role based on the level user is adding to. Hence from console we should not set/send any extra role while adding the user into any organization except existing roles for the user.
            // readonlyRole = this.filterAccountReadOnlyRole(this.roles);
            if (this.userDetails.accountRoles === undefined || this.userDetails.accountRoles === null) {
              this.userDetails.accountRoles = [];
            }
            
            this.userDetails.accountId = this.accountId;
          }
          else {
            this.logger.debug(`Adding org level role:: ${this.orgId}`);
            
             // this below call was to read and set the org-read-only role to new user.
             // but it is not required now as server itself will set ACCESS role based on the level user is adding to. Hence from console we should not set/send any extra role while adding the user into any organization except existing roles for the user.
            // readonlyRole = this.filterOrgReadOnlyRole(this.roles);
            
            if (this.userDetails.organizationRoles === undefined || this.userDetails.organizationRoles === null) {
              this.userDetails.organizationRoles = [];
            }
            
            this.userDetails.organizationId = this.orgId;
          }
        }else{
          //if we are adding a new user(not exist in the sysytem) to org/accout, then there are no roles assigned to the user, so need to send an empty array instead of null
          if (this.userDetails.organizationRoles === undefined || this.userDetails.organizationRoles === null) {
            this.userDetails.organizationRoles = [];
          }
          if (this.userDetails.accountRoles === undefined || this.userDetails.accountRoles === null) {
            this.userDetails.accountRoles = [];
          }
          if (this.userDetails.applicationRoles === undefined || this.userDetails.applicationRoles === null) {
            this.userDetails.applicationRoles = [];
          }
          if (this.userDetails.vpubRoles === undefined || this.userDetails.vpubRoles === null) {
            this.userDetails.vpubRoles = [];
          }
          if (this.userDetails.moduleRoles === undefined || this.userDetails.moduleRoles === null) {
            this.userDetails.moduleRoles = [];
          }
        }

        //api call to add new user either to org or to account
        this.service.addOrgorAccntUser(this.userDetails).pipe(takeUntil(this.destroy$)).subscribe({
          next: (data: AdxUser) => {
                  // set user variable
                  this.userDetails = data;
                  this.logger.debug(this.userDetails);
                },
                error: (err) => {
                  // navigate user to listing screen
                  this.messageNotifier.notify(CustomMessageType.ERROR, `${this.userDetails?.username} creation failed.`);
                },
                complete: () => {
                  // display success message
                  this.messageNotifier.notify(CustomMessageType.SUCCESS, `${this.userDetails?.username} created.`);
                }
        })   
    }
  }

  addNewUser(user: AdxUser | null) : void {
    console.log("user to add in add user page ==> ", user);
    if(!this.userDetails || this.userDetails == null || this.userDetails == undefined){//read the value from form only in case if the user is not exist in the system
      this.userDetails = user;
    }
    this.onAdd();
  }

  /*
  * makes backend call to fetch the user
  */
  private fetchRoles(orgIdInput: number, acctIdInput: number | null): void {
    this.service.getRoles(orgIdInput, acctIdInput).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxRole[]) => {
          // set user variable
          this.roles = data;
        }
      });
  }

  /*
   * used to fetch OrgReadonly role from given list of roles
   */
  private filterOrgReadOnlyRole(roles: AdxRole[]): AdxRole {
    const filteredRoles = roles.filter((role) => role.permissions[0].code === 'ORGANIZATION_READ_ONLY');
    return filteredRoles[0];
  }

  /*
   * used to fetch AccountReadonly role from given list of roles
   */
  private filterAccountReadOnlyRole(roles: AdxRole[]): AdxRole {
    const filteredRoles = roles.filter((role) => role.permissions[0].code === 'ACCOUNT_READ_ONLY');
    return filteredRoles[0];
  }

}
