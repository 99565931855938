import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {CustomNotification} from './custom-notification-model';
import {CustomMessageType} from './custom-message-type';

/**
 * The Notifier service used to notify messages to user.
 * This class acts as a publisher of notification. The subscriber is implemented in header under layout.
 * The actual rendering of the message is done using angular-notifier library.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomNotifierService {

  msgSubject = new Subject<CustomNotification>();

  constructor() { }

  /**
   * API to notify a message to user.
   *
   * @param msgType - enum
   * @param message - actual message
   */
  public notify(msgType: CustomMessageType, message: string): void {
    const notification: CustomNotification = new CustomNotification(msgType, message);
    this.msgSubject.next(notification);
  }
}
