import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AdxImageLibraryItem} from '../../../common/model/image-library/adx-image-library-item.model';
import {AdxImageLibraryType} from '../../utils/adx-image-library-type';
import {AdxImage} from '../../../common/model/adx-image.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * This component is used to enable image selection.
 * This will display image, title and 'select' button.
 * When select button is pressed, it pops-up the Image Library.
 * When user selects an image from image library, that image and title are displayed.
 * This component can be used as form field and it implements ControlValueAccessor
 * If there is a need to set image on initialization, can be done inside a ReactiveForm of caller.
 */
@Component({
  selector: 'app-adx-image-input',
  templateUrl: './adx-image-input.component.html',
  styleUrls: ['./adx-image-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdxImageInputComponent),
      multi: true
    }
  ]
})
export class AdxImageInputComponent implements OnInit, ControlValueAccessor {

  @Input() typeId: number | null = null; // Input. either organization or account id
  @Input() isIcon = true;
  @Input() libraryType: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB; // Input. either Organization or Account Image Library
  @Output() imageSelect = new EventEmitter<AdxImage>(); // The event that is output on selection of image from library.

  @Input() orgId : number | null = null; //need org and accounr id in image library to fetch applications
  @Input() accountId : number | null = null;
  @Input() applnId : number | null = null;
  @Input() vpubId : number | null = null;
  @Input() moduleId : number | null = null;
  @Input() atomId : number | null = null;

  imageTitle: string | null = null;
  imageUrl: string | null = null;

  imgObj: AdxImage | null = null;

  private touched = false; // to support dirty flag in the form field
  private disabled = false; // to support disabled flag in form field

  constructor() { }

  ngOnInit(): void {
    if (this.imgObj) {
      this.imageTitle = this.imgObj.title;
      if (this.imgObj.preSignedUrl) {
        this.imageUrl = this.imgObj.preSignedUrl;
      }
    }
  }

  onChange = (imgUrl: string | null) => {}; // call back called by parent, on data change in form field
  onTouched = () => {}; // call back called by parent, on field usage in form field

  imageSelected(imgLibItem: AdxImageLibraryItem): void {
    this.markAsTouched();
    if (!this.disabled) {
      if (imgLibItem) {
        this.onChange(imgLibItem.preSignedUrl);
        const selectedImage: AdxImage = new AdxImage(null, imgLibItem.id, imgLibItem.preSignedUrl, imgLibItem.title);
        this.imageUrl = imgLibItem.preSignedUrl;
        this.imageTitle = imgLibItem.title;
        this.imageSelect.emit(selectedImage);
      }
    }
  }

  writeValue(iconImg: AdxImage): void {
    this.imgObj = iconImg;
    if (this.imgObj) {
      this.imageTitle = this.imgObj.title;
      if (this.imgObj.preSignedUrl) {
        this.imageUrl = this.imgObj.preSignedUrl;
      }
    }
    else { // in case of reset
      this.imageTitle = null;
      this.imageUrl = null;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private markAsTouched(): void {
    if (!this.touched) { // if already set, no need to set flag again
      this.onTouched();
      this.touched = true;
    }
  }
}
