import {Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators, ControlValueAccessor } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';

@Component({
  selector: 'app-adx-insert-telephone-dialog',
  templateUrl: './adx-insert-telephone-dialog.component.html',
  styleUrls: ['./adx-insert-telephone-dialog.component.scss']
})
export class AdxInsertTelephoneDialogComponent implements OnInit, ControlValueAccessor {
  insertTelephoneForm: UntypedFormGroup;
  value: string;

  @Input() placeholder: string;
  @Output() changeEmitter: EventEmitter<string> = new EventEmitter<string>();
  title: any;
  telNum: any;
  constructor(@Inject(MAT_DIALOG_DATA) private data: {editorRef: any},
              private dialogRef: MatDialogRef<AdxInsertTelephoneDialogComponent>,
              private logger: NGXLogger) {
    this.insertTelephoneForm = new UntypedFormGroup({
      phone: new UntypedFormControl(),
      telLabel: new UntypedFormControl()
    });
    this.placeholder = '';
    this.value = '';
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propogateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propogateTouched = fn;
  }

  ngOnInit(): void {
  }

  save(): void {
    const telNum = this.insertTelephoneForm.get('phone')?.value;
    let title = this.insertTelephoneForm.get('telLabel')?.value;

    if(!title || title === '') {
        title = telNum;
    }

    this.logger.debug(`telNum:${telNum} title:${title}`);
    const htmlStart = '<a href="tel:' + telNum + '">';
    const htmlEnd = '</a>';
    const html = htmlStart + title + htmlEnd;

    this.data.editorRef.selection.restore();
    this.data.editorRef.html.insert(html);
    this.dialogRef.close();
  }

  /**
   * Triggered whenever keyboard key is pressed and released
   *
   * @param value
   */
   onKeyup(value: string): void {
    this.value = value;
    this.propogateChange(value);
    this.changeEmitter.emit(value);
  }

  /**
   * Triggered whenever input looses focus
   */
  onBlur(): void {
    this.propogateTouched();
  }

  private propogateChange: any = () => { };
  private propogateTouched: any = () => { };

}
