import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdxHtml} from '../../adx-module/model/adx-html-model';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AdxSimulatorService} from '../service/adx-simulator.service';

@Component({
  selector: 'app-html-module-simulator',
  templateUrl: './html-module-simulator.component.html',
  styleUrls: ['./html-module-simulator.component.scss']
})
export class HtmlModuleSimulatorComponent implements OnInit, OnDestroy {

  @Input() htmlModule: AdxHtml | null = null;
  changeSubscription: Subscription | undefined;

  constructor(private logger: NGXLogger, private simulatorService: AdxSimulatorService) { }

  ngOnInit(): void {
    this.changeSubscription = this.simulatorService.contentUpdated.subscribe((data: boolean) => {
      if (this.simulatorService.selectedModule.html) {
        this.htmlModule = this.simulatorService.selectedModule.html;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

}
