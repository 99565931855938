import { Component, OnDestroy, OnInit } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdxImage} from '../../../common/model/adx-image.model';
import {AdxImageLibraryType} from '../../../shared/utils/adx-image-library-type';
import {AdxModule} from '../../model/adx-module.model';
import {AdxModuleService} from '../../service/adx-module.service';
import {CustomNotifierService} from '../../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {blankValidator} from '../../../common/utils/form-utility';
import {CommonUtility} from '../../../common/utils/common-utils';
import {AdxInsertContentDialogData} from '../../../shared/utils/adx-insert-content-dialog-data';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../../core/service/notifier/custom-message-type';
import {AdxBaseEditorTemplate} from '../../../common/template/adx-base-editor-template';
import {AdxModuleType} from '../../model/adx-module-type';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

/**
 * This component is used to add individual modules.
 * Currently, to create ARS, Form, Web and HTML module, this same class is used.
 * In the router config, for different routes like 'add/ars', 'add/form' etc, this class will be invoked
 * This is done in purpose. If in future, we need to provide custom add for each type of modules,
 * then separate components can be created and replaced in router config, in place of this file.
 *
 * For now, for each module, only icon, title and description input fields are used.
 */
@Component({
  selector: 'app-adx-add-module',
  templateUrl: './adx-add-module.component.html',
  styleUrls: ['./adx-add-module.component.scss']
})
export class AdxAddModuleComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  addModuleForm: FormGroup;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.MODULE_IMG_LIB;
  typeId: number | null = null;
  moduleListUrl = '';
  applnId: number | null = null;
  vpubId: number | null = null;
  orgId: number | null = null;

  private readonly moduleTypeToAdd: AdxModuleType | null = null;
  private authSubscription: Subscription | undefined;

  constructor(private moduleService: AdxModuleService, private readonly authNotifier: AuthNotifierService,
              private readonly messageNotifier: CustomNotifierService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    const navigation = this.router.getCurrentNavigation();
    let state = null;
    if (navigation) {
      state = navigation.extras.state as {
        moduleType: AdxModuleType;
      };
    }
    if (state) {
      this.moduleTypeToAdd = state.moduleType;
      this.logger.debug(`moduleTypeToAdd from stateObj ${this.moduleTypeToAdd}`);
    }

    this.addModuleForm = new FormGroup({
      title: new FormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      }),
      icon: new FormControl(),
      desc: new FormControl(null, {
        updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(450)
        ]
      }),
      sortOrder: new FormControl<number | null>(null, {
        updateOn: 'change', validators: [
          Validators.max(9999999)
        ]
      })
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    this.typeId = accountId;
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
      this.orgId = orgId;
    
    //reading the hierarchy id values to pass the same to image library
    this.applnId = applnId; this.vpubId = vpubId;
    
    if (!orgId || !accountId || !vpubId) {
      this.logger.error('required params missing to fetch module');
      return;
    }

    if (accountId && orgId) {
      this.moduleListUrl = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
      this.logger.debug(`moduleListUrl: ${this.moduleListUrl}`);
    }

  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: CommonUtility.getId('vpubId', this.activatedRoute),
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.MODULE_IMG_LIB,
      editorRef: undefined,
      applicationId : CommonUtility.getId('applnId', this.activatedRoute),
      atomId : null
    };
    return toRet;
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  saveModule(): void {
    this.logger.debug(this.addModuleForm);
    if (!this.canAddModule()){
      return; // no need to proceed as current user does not have permission to add module
    }
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    if (!orgId || !accountId || !vpubId) {
      this.logger.error('required params missing to fetch module');
      return;
    }
    const moduleToAdd: AdxModule = new AdxModule(null);
    moduleToAdd.type = this.moduleTypeToAdd;
    moduleToAdd.title = this.addModuleForm.get('title')?.value;
    moduleToAdd.description = this.addModuleForm.get('desc')?.value;
    if (this.iconImage) {
      moduleToAdd.iconImage = this.iconImage;
    }
    const sortOrderControl = this.addModuleForm.get('sortOrder');
    if (sortOrderControl && sortOrderControl.dirty) {
      moduleToAdd.sortOrder = sortOrderControl.value;
    }

    this.addModule(orgId, accountId, vpubId, moduleToAdd);
  }

  /*
   * This is used to determine whether loggedin user can add module
  */
  private canAddModule(): boolean {
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (orgId !== undefined && orgId !== null
       && accountId !== undefined && accountId !== null
       && applnId !== undefined && applnId !== null
       && vpubId !== undefined && vpubId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserAddModule(orgId, accountId, applnId, vpubId, this.currentUser);
    }
    return false;
  }

  private addModule(orgId: number, accountId: number, vpubId: number, moduleToAdd: AdxModule): void {
    if (orgId && accountId && vpubId && moduleToAdd) {
      this.moduleService.addModule(orgId, accountId, vpubId, moduleToAdd)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Module Added Successfully.`);
          }
        });
    }
  }
}
