import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppChannelLinkComponent} from './app-channel-link.component';
import {MaterialModule} from '../common/material/material.module';
import { LinkChannelAppComponent } from './link-channel-app/link-channel-app.component';
import {ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import { DelinkChannelAppComponent } from './delink-channel-app/delink-channel-app.component';


@NgModule({
  declarations: [
    AppChannelLinkComponent,
    LinkChannelAppComponent,
    DelinkChannelAppComponent
  ],
  exports: [
    AppChannelLinkComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule
  ]
})
export class AppChannelLinkModule { }
