import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/*
* This is timezone model class
*/
export class AdxTimezone extends AdxResource {
  title: string;
  code: string;
  abbreviation: string;

  constructor(id: number, strTitle: string, strCode: string, strAbbreviation: string) {
    super(id);
    this.title = strTitle;
    this.code = strCode;
    this.abbreviation = strAbbreviation;
  }

  static buildObject(json: any): AdxTimezone {
    return new AdxTimezone(json.id, json.title, json.code, json.abbreviation);
  }
}
