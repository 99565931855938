import { Component, OnDestroy, OnInit } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {AdxModule} from '../model/adx-module.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AdxModuleService} from '../service/adx-module.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonUtility} from '../../common/utils/common-utils';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {AdxHtml} from '../model/adx-html-model';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxDialogService} from '../../common/services/dialog/adx-dialog.service';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Component({
  selector: 'app-manage-html',
  templateUrl: './manage-html.component.html',
  styleUrls: ['./manage-html.component.scss']
})
export class ManageHtmlComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedModule: AdxModule | null = null;
  moduleTitle: string | null = null;
  manageHtmlForm: UntypedFormGroup;
  moduleListUrl = '';
  isModulePublished  = false;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.MODULE_IMG_LIB;//on loading froala editor the value was not available like any other module so declred and initialized the same.

  private accountId: number | null = null;
  private orgId: number | null = null;
  private vpubId: number | null = null;
  private moduleId: number | null = null;
  private applnId: number | null = null;

  private authSubscription: Subscription | undefined;

    constructor(private moduleService: AdxModuleService, private readonly dialogService: AdxDialogService,
              private readonly messageNotifier: CustomNotifierService, private readonly authNotifier: AuthNotifierService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.manageHtmlForm = new UntypedFormGroup({
      desc: new UntypedFormControl(null, {
        updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(500000)
        ]
      })
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.accountId = CommonUtility.getId('acctId', this.activatedRoute);
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute);
    this.vpubId = CommonUtility.getId('vpubId', this.activatedRoute);
    this.moduleId = CommonUtility.getId('modId', this.activatedRoute);
    this.applnId = CommonUtility.getId('applnId', this.activatedRoute);
    if (!this.orgId || !this.accountId || !this.vpubId || !this.moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }

    this.selectedModule = this.activatedRoute.snapshot.data.modl;

    if (this.accountId && this.orgId) {
      this.moduleListUrl = `/orgs/${this.orgId}/accts/${this.accountId}/applns/${this.applnId}/vpubs/${this.vpubId}/modls`;
    }

    if (!this.selectedModule) {
      // error. Unable to display component
      this.logger.debug(`Unable to display component as module details not available`);
      return;
    }

    this.moduleTitle = this.selectedModule.title;
    if (this.selectedModule.html) {
      this.manageHtmlForm.patchValue({
        desc: this.selectedModule.html.description
      });
      this.isModulePublished = this.selectedModule.html.isActive
    }
    else {
      this.logger.error('ERROR: HTML Module Not Found.');
    }
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback that returns true if logged-in user has permission to save module
   *
   * @returns boolean: true if user has permissions to save current module
   */
  canUserSaveModule(): boolean {
    const accountId: number | null = CommonUtility.getId('acctId', this.activatedRoute);
    const orgId: number | null = CommonUtility.getId('orgId', this.activatedRoute);
    const vpubId: number | null = CommonUtility.getId('vpubId', this.activatedRoute);
    const moduleId: number | null = CommonUtility.getId('modId', this.activatedRoute);
    const applnId: number | null = CommonUtility.getId('applnId', this.activatedRoute);
    if (orgId !== undefined && orgId !== null
      && accountId !== undefined && accountId !== null
      && applnId !== undefined && applnId !== null
      && vpubId !== undefined && vpubId !== null
      && moduleId !== undefined && moduleId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserSaveModule(orgId, accountId, applnId, vpubId, moduleId, this.currentUser);
    }
    return false;
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: CommonUtility.getId('vpubId', this.activatedRoute),
      moduleId: CommonUtility.getId('modId', this.activatedRoute),
      typeId: CommonUtility.getId('acctId', this.activatedRoute),
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.MODULE_IMG_LIB,
      editorRef: undefined,
      applicationId : CommonUtility.getId('applnId', this.activatedRoute),
      atomId : null
    };
    return toRet;
  }

  save(): void {
    this.logger.debug(this.manageHtmlForm);
    if (!this.canUserSaveModule()) {
      this.logger.error('logged-in user does not have permission to save module');
      return;
    }
    if (!this.orgId || !this.accountId || !this.vpubId || !this.moduleId) {
      this.logger.error('required params missing to fetch module');
      return;
    }
    const moduleToUpdate: AdxModule = new AdxModule(this.moduleId);
    if (this.selectedModule && this.selectedModule.html) {
      moduleToUpdate.html = new AdxHtml(this.selectedModule.html.id);
    }
    else {
      moduleToUpdate.html = new AdxHtml(null);
    }

    if (this.manageHtmlForm.get('desc')?.dirty) {
      moduleToUpdate.html.description = this.manageHtmlForm.get('desc')?.value;
    }

    this.updateModule(moduleToUpdate);
  }

  publish() {
    this.logger.debug('In Publish');
    if (!this.canUserSaveModule()) {
      this.logger.error('logged-in user does not have permission to publish module');
      return;
    }
    this.confirmDialog('Please ensure that HTML Module is as required, before proceeding with Publish.').subscribe({
      next: (isConfirmed) => {
        if (isConfirmed) {
          if (this.moduleId !== null && this.moduleId !== undefined) {
            const moduleSelected: AdxModule = new AdxModule(this.moduleId);
            if (this.selectedModule && this.selectedModule.html) {
              moduleSelected.html = new AdxHtml(this.selectedModule.html.id);
            }
            else {
              moduleSelected.html = new AdxHtml(null);
            }

            // call publish
            this.publishModule(moduleSelected);
          }
        }
      }
    });
  }

  private updateModule(moduleToUpdate: AdxModule): void {
    if (this.orgId && this.accountId && this.vpubId && moduleToUpdate) {
      this.moduleService.updateModule(this.orgId, this.accountId, this.vpubId, moduleToUpdate)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Module updated Successfully.`);
          }
        });
    }
  }

  private confirmDialog(msg: string): Observable<boolean> {
    return this.dialogService.confirmDialog({
      title: 'Please confirm action',
      message: msg,
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    });
  }

  private publishModule(moduleToUpdate: AdxModule): void {
    if (this.orgId && this.accountId && this.vpubId && moduleToUpdate) {
      this.moduleService.publishHtmlModule(this.orgId, this.accountId, this.vpubId, moduleToUpdate)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.moduleListUrl) {
              this.router.navigateByUrl(this.moduleListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Module is Published.`);
          }
        });
    }
  }
}
