import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {AdxDataTransferService} from '../../../../common/services/data-transfer/adx-data-transfer.service';
import {AdxDataTransfer} from '../../../../common/model/data-transfer/adx-data-transfer-model';
import {AdxDisplayLevel} from '../../../../common/model/data-transfer/adx-display-level';
import {AdxDialogService} from '../../../../common/services/dialog/adx-dialog.service';
import {CustomMessageType} from '../../../../core/service/notifier/custom-message-type';
import {CommonUtility} from '../../../../common/utils/common-utils';

/**
 * This component will be used to display status of Dta Transfer operations.
 */
@Component({
  selector: 'app-adx-data-transfer',
  templateUrl: './adx-data-transfer.component.html',
  styleUrls: ['./adx-data-transfer.component.scss']
})
export class AdxDataTransferComponent implements OnInit {

  @Input() orgId: number | null = null; // need to be supplied by caller
  @Input() vpubId: number | null = null; // either one of vPubId or appId or accntId need to be specified
  @Input() applicationId: number | null = null;
  @Input() accountId: number | null = null;
  @Input() moduleId: number | null = null; // if called from within a module, this needs to be set
  @Input() displayLevel: AdxDisplayLevel  = AdxDisplayLevel.ARS_LEVEL;

  displayedColumns: string[] = [
    'id',
    'type',
    'status',
    'username',
    'vpubTitle',
    'moduleTitle'
  ];
  dataSource = new MatTableDataSource<AdxDataTransfer>();

  destroy$: Subject<boolean> = new Subject<boolean>();
  urlForAcctListing = '/orgs';
  statusFilter = new UntypedFormControl('');
  typeFilter = new UntypedFormControl('');
  filterValues: any = {
    status: '',
    type: ''
  };
  private statusFilterSubscription: Subscription | null = null;
  private typeFilterSubscription: Subscription | null = null;

  // the Direct Access Link base URL is read from environment specific file
  private readonly baseDalUrl: string = environment.dalBaseUrl;

  constructor(private logger: NGXLogger, private dataTransferService: AdxDataTransferService,
              private dialogService: AdxDialogService) { }

  ngOnInit(): void {
    this.loadData();
    if (this.isSystemDisplayLevel()) {
      this.displayedColumns = [
        'id',
        'type',
        'status',
        'username',
        'vpubTitle'
      ];
    }
  }

  /**
   * Callback that will display a popup dialog to display status details
   *
   * @param element: The object for which status description needs to be displayed
   */
  showStatusDetails(element: AdxDataTransfer) {
    if (element && element.statusDescription) {
      this.dialogService.alertDialog({
        title: 'Details',
        message: element.statusDescription,
        submessage: null,
        type: CustomMessageType.INFO
      });
    }
  }

  isArsDisplayLevel(): boolean {
    return this.displayLevel === AdxDisplayLevel.ARS_LEVEL;
  }

  isVpubDisplayLevel(): boolean {
    return this.displayLevel === AdxDisplayLevel.VPUB_LEVEL;
  }

  isSystemDisplayLevel(): boolean {
    return this.displayLevel === AdxDisplayLevel.SYSTEM_LEVEL;
  }

  protected loadData(): void {
    if (this.orgId === undefined || this.orgId === null) {
      this.logger.error('required params missing');
      return;
    }

    if (this.vpubId) {
      this.fetchDataTransferStatus(this.orgId, null, null, this.vpubId);
    }
    else if (this.applicationId) {
      this.fetchDataTransferStatus(this.orgId, null, this.applicationId, null);
    }
    else {
      this.fetchDataTransferStatus(this.orgId, this.accountId, null, null);
    }
    this.fieldListener();
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchDataTransferStatus(orgId: number, acctId: number | null,
                                  applicationId: number | null, vpubId: number | null): Subscription {

    this.logger.debug('fetching modules for orgId:' + orgId);
    return this.dataTransferService.getDataTransferStatus(orgId, acctId, applicationId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxDataTransfer[]) => {
          // successfully received the data. check if needs to be filtered
          if (this.moduleId) {
            data = this.filterOnModuleId(data);
          }
          // sort it by ID and set it in variable
          CommonUtility.sortByIdDescending(data);
          this.dataSource.data = data;
          this.dataSource.filterPredicate = this.createFilterFunction();
        }
      });
  }

  /*
   * While displaying the Transfer Status page at module level, we need to display just the entries for that module.
   * This function is used to filter the entries got back from db. The entries are filtered by moduleId
   */
  private filterOnModuleId(inputList: AdxDataTransfer[]): AdxDataTransfer[] {
    let toRet: AdxDataTransfer[] = [];
    if (inputList) {
      toRet = inputList.filter(entry => entry.moduleId === this.moduleId);
    }
    return toRet;
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createFilterFunction(): (dataTransferObj: AdxDataTransfer, filter: string) => boolean {
    const filterFunction = (dataTransferObj: AdxDataTransfer, filter: string): boolean => {

      const searchTerms = JSON.parse(filter);

      let statusValue = '';
      let statusFlag = -1;
      if (dataTransferObj && dataTransferObj.status) {
        statusValue = dataTransferObj.status;
        // there are values like SUCCESS and VALIDATION_SUCCESS. Hence, cannot just rely on indexOf
        if (searchTerms.status.length === 0 || (statusValue.length === searchTerms.status.length)) {
          statusFlag = (statusValue.toLowerCase().indexOf(searchTerms.status.toLowerCase()));
        }
      }

      let typeValue = '';
      let typeFlag = -1;
      if (dataTransferObj && dataTransferObj.type) {
        typeValue = dataTransferObj.type;
        typeFlag = (typeValue.toLowerCase().indexOf(searchTerms.type.toLowerCase()));
      }

      return (-1 !== typeFlag) && (-1 !== statusFlag);
    };

    return filterFunction;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.statusFilterSubscription = this.statusFilter.valueChanges
      .subscribe({
          next: status => {
            this.filterValues.status = status;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
    this.typeFilterSubscription = this.typeFilter.valueChanges
      .subscribe({
          next: value => {
            this.filterValues.type = value;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
  }
}
