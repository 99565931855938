import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxImage} from '../../common/model/adx-image.model';

export class AdxModuleGroupAddRequest extends AdxResource {

  title: string | null = null;
  description: string | null = null;
  iconImage: AdxImage | null = null;
  childrenIds: number[] | null = null;
  sortOrder: number | null = null;

  constructor(id: number | null) {
    super(id);
  }
}
