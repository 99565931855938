import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {AdxApplicationSerializer} from '../model/adx-application-serializer';
import {AdxApplication} from '../model/adx-application.model';
import {Observable, throwError} from 'rxjs';
import {AdxQueryParams} from '../../core/service/resource/adx-query-params.model';
import {catchError} from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

@Injectable({
  providedIn: 'root'
})
export class AdxApplicationService {

  private serializer: AdxApplicationSerializer = new AdxApplicationSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxApplication, AdxApplication>) { }

  /*
   * This API is used to fetch the list of Accounts from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getApplications(orgId: number, accountId: number): Observable<AdxApplication[]> {
    this.logger.debug('Getting applications for orgId:' + orgId + ' ; appId:' + accountId);
    return this.restService.list(`orgs/${orgId}/accts/${accountId}/apps`, new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the Account from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getApplication(orgId: number, accountId: number, id: number): Observable<AdxApplication> {

    return this.restService.read(`orgs/${orgId}/accts/${accountId}/apps`, id, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        }));
  }


  /*
   * This API is used to add Account to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addApplication(orgId: number, accountId: number, appln: AdxApplication): Observable<AdxApplication>{
    return this.restService.create(`orgs/${orgId}/accts/${accountId}/apps`, appln, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(errorMsg);
      }));
  }

  /*
   * This API is used to update Account in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateApplication(orgId: number, accountId: number, appln: AdxApplication): Observable<AdxApplication> {
    return this.restService.update(
      `orgs/${orgId}/accts/${accountId}/apps/${appln.id}`, appln, null, this.serializer).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error: ${error.message}`;
        }
        this.logger.error('Error while adding Account:' + errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }

  /**
   * Utility method get application ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organizationadmin, organization readonly, account admin or acount readonly
   * then he can view all applicationids. That need to be handled by the caller method.
   * This method will only return application ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of applications accessible by user
   */
  public getAccessiblApplicationIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleApplnIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleApplications !== undefined && accessibleEntities.accessibleApplications !== null) {
          userAccessibleApplnIds = accessibleEntities.accessibleApplications;
      }
    }
    return userAccessibleApplnIds;
  }

  /**
   * API used to determine if user has permission to add application
   *
   * @param orgId : number - organization id
   * @param acctId : number - account id
   * @param currentUser : logged in user
   * @returns boolean: true if logged in user can add application
   */
  public canUserAddApplication(orgId: number, acctId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    //check for org admin
    const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
    if (isOrgAdmin) {
      return true;
    }

    //check for account admin
    const isAcctAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
    if (isAcctAdmin) {
      return true;
    }

    //check for application add role
    const isApplicationAddRole = AdxAuthUtils.hasRoleForGivenId(acctId, 'APPLICATION_ADD', currentUser);
    if (isApplicationAddRole) {
      return true;
    }
    return false;
  }

  /**
   * API used to determine if user has permission to edit application
   *
   * @param orgId : number - organization id
   * @param acctId : number - account id
   * @param applnId : number - application id
   * @param currentUser : logged in user
   * @returns boolean: true if logged in user can edit application
   */
  public canUserEditApplication(orgId: number, acctId: number, applnId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for application admin
      const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
      if (isApplnAdmin) {
        return true;
      }

      //now check for application edit
      const isApplnEdit = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_EDIT', currentUser);
      if (isApplnEdit) {
        return true;
      }
    }
    return false;
  }
}
