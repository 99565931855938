<div fxLayout="row">
  <div *ngIf="showSimulator && style" class="simulator" fxLayout="column" fxFlex="20">
    <div class="web-client-frame-layout">
    <div class="web-client-frame" [style.background-color]="style.bodyBackgroundColor"
         [style.color]="style.bodyTextColor">
          <mat-toolbar class="simulator-top-bar"
                       [style.background-color]="style.topBarBackgroundColor"
                       [style.color]="style.topBarTextColor">
            <div class="container">
              <div class="flex-container" fxLayout="column">
                  <div fxLayout="row">
                      <div fxFlex="8">
                        <mat-icon>menu</mat-icon>
                      </div>
                      <div fxFlex="84" class="common-vpub-label">
                        <span class="spacer selectedModuleTitle">{{getSelectedModuleTitle()}}</span>
                      </div>
                      <div fxFlex="8">
                        <mat-icon>more_vert</mat-icon>
                      </div>
                  </div>
              </div>
            </div>
          </mat-toolbar>
          <div class="simulator-content">
            <ng-container *ngIf="selectedModule.type === getModuleType('group'); else ArsType">
              <app-module-group-simulator [moduleList]=getChildModuleList()></app-module-group-simulator>
            </ng-container>
            <ng-template #ArsType>
              <ng-container *ngIf="selectedModule.type === getModuleType('ars'); else HtmlType">
                <ng-container *ngIf="selectedAtom; else ArsListing">
                  <app-atom-simulator [atom]="selectedAtom"></app-atom-simulator>
                </ng-container>
                <ng-template #ArsListing>
                  <app-ars-simulator [atomList]=getAtomList()></app-ars-simulator>
                </ng-template>
              </ng-container>
            </ng-template>
            <ng-template #HtmlType>
              <ng-container *ngIf="selectedModule.type === getModuleType('html'); else WebType">
                <app-html-module-simulator [htmlModule]="selectedModule.html"></app-html-module-simulator>
              </ng-container>
            </ng-template>
            <ng-template #WebType>
              <ng-container *ngIf="selectedModule.type === getModuleType('web')">
                <app-web-module-simulator></app-web-module-simulator>
              </ng-container>
            </ng-template>
          </div>
          <mat-toolbar *ngIf="style.numberOfTabs" class="simulator-bottom-bar"
                       [style.background-color]="style.navigationBackgroundColor"
                       [style.color]="style.navigationTextColor">
            <div class="container">
                <div class="flex-container" fxLayout="column">
                  <div fxLayout="row">
                    <div class="display-mod-icon styleNumberOfTabs" *ngFor="let tab of [].constructor(style.numberOfTabs); let i = index">
                      <div class="styleNumberOfTabsIcon">
                        <img [src]="getIconUrl(i)"/>
                      </div>
                      <div class="styleNumberOfTitle">
                        <label class="styleNumberOfTabsTitle">{{getModuleTitle(i)}}</label>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </mat-toolbar>
  </div>
  </div>
  </div>
  <mat-icon *ngIf="showSimulator" class="simulator-toggle" (click)="toggle()">close_fullscreen</mat-icon>
  <mat-icon *ngIf="!showSimulator" class="simulator-toggle" (click)="toggle()">open_in_full</mat-icon>
</div>
