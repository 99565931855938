import {AdxModuleType} from '../../adx-module/model/adx-module-type';
import {AdxImage} from '../model/adx-image.model';

export class AdxModuleUtility {
  public static getAllModules(): {displayName: string; type: AdxModuleType; icon: AdxImage | null}[] {
    return [{displayName: 'ARS', type: AdxModuleType.ARS, icon: null},
      {displayName: 'Form', type: AdxModuleType.FORM, icon: null},
      {displayName: 'Web', type: AdxModuleType.WEB, icon: null},
      {displayName: 'HTML', type: AdxModuleType.HTML, icon: null},
      {displayName: 'MOTOR Axelnut Hubmount', type: AdxModuleType.MOTOR_AXLENUT_HUBMOUNT, icon: null},
      {displayName: 'MOTOR Coolant', type: AdxModuleType.MOTOR_COOLANT, icon: null},
      {displayName: 'MOTOR Truespeed', type: AdxModuleType.MOTOR_TRUSPEED, icon: null}];
  }
}
