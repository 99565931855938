
/**
 * This is the custom visibility type enum definition.
 * This enum is used with for changing object visibility to consumer
 */
export enum AdxVisibilityType {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED'
}
