import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AdxImageUploadDirective } from './adx-image-upload.directive';
import { AdxImageUploaderComponent } from './adx-image-uploader.component';
import { AdxUploadComponent } from './components/adx-upload/adx-upload.component';
import { AdxFileSizePipe } from './pipes/adx-file-size/adx-file-size.pipe';
import { AdxCropperComponent } from './components/adx-cropper/adx-cropper.component';
import { MatButtonModule } from '@angular/material/button';
import {CommonDirectiveModule} from '../../../common/directive/common-directive.module';
import {MaterialModule} from '../../../common/material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';




@NgModule({
  declarations: [
    AdxImageUploadDirective,
    AdxImageUploaderComponent,
    AdxUploadComponent,
    AdxFileSizePipe,
    AdxCropperComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        ImageCropperModule,
        CommonDirectiveModule,
        MaterialModule,
        FlexLayoutModule
    ],
  exports: [
    AdxImageUploadDirective
  ]
})
export class AdxImageUploaderModule { }
