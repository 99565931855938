import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdxButtonModule } from './button/adx_button.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdxButtonModule
  ],
  exports: [
    AdxButtonModule
  ]
})
export class AdxButtonsModule { }
