
<div class="dialog-header">
  <h2 mat-dialog-title>Update Image Details</h2>
  <!--  as we have cancel button along with update button close icon may not required -->
  <!-- <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button> -->
</div>

<div mat-dialog-content>
  <form [formGroup]="updateImageForm">
    <div class="flex-container" fxLayout="column">
      <div>
          <mat-form-field>
            <input matInput i18n="@@updateImage.name" #ImageFileName maxlength="50" placeholder="Name"
                   formControlName="name"/>
            <mat-hint align="end">{{ImageFileName.value.length}} / 50</mat-hint>
          </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput i18n="@@updateImage.desc" #ImageFileDesc maxlength="150" placeholder="Description"
                 formControlName="desc"/>
          <mat-hint align="end">{{ImageFileDesc.value.length}} / 150</mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-chip-list #tags>
            <mat-chip *ngFor="let tag of tagList" [selectable]="tagSelectable" [removable]="tagRemovable" (removed)="removeTag(tag)">
              {{tag}}
              <mat-icon matChipRemove *ngIf="tagRemovable">cancel</mat-icon>
            </mat-chip>
            <input i18n="@@updateImage.tags" #ImageTags id="image-tags" formControlName='imageTag' placeholder="Tags" [matChipInputFor]="tags" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="tagAddOnBlur" (matChipInputTokenEnd)="addTag($event)">
            <mat-hint align="end">{{tagList.join('').length}} / 500</mat-hint>
            <mat-error *ngIf="tagList.join('').length > 500">
              Please enter max of 500 characters
            </mat-error>
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>
  <div>
    <div fxLayout="column">
    <div class="display-cropper">
        <image-cropper [imageFile]="selImageFile" alignImage="left" [maintainAspectRatio]="isIcon"
                       format="png" (imageCropped)="imageCropped($event)" [disabled]="!checkbox.checked">
        </image-cropper>
    </div>
      <div class="checkbox-title">
        <mat-checkbox formControlName="cropImage" color="primary" #checkbox>Crop Image?</mat-checkbox>
      </div>
    </div>
  </div>
  </form>
</div>

<div mat-dialog-actions class="display-actions">
  <button mat-raised-button (click)="onCrop()" [disabled]="tagList.join('').length > 500" >Update</button>
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
</div>
