import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AdxResource } from './adx-resource.model';
import { AdxQueryParams } from './adx-query-params.model';
import { AdxBaseSerializer } from './adx-base-serializer';
import { NGXLogger } from 'ngx-logger';
import {FormUtility} from '../../../common/utils/form-utility';

/**
 * Base REST service that provides basic REST APIs like create, get, list, update and delete.
 * Service class in each feature needs to extend this Base class to provide REST calls.
 */
@Injectable({
  providedIn: 'root'
})
export class AdxRestService<IN extends AdxResource, OUT extends AdxResource> {

  // the baseUrl is read from environment specific file
  private readonly baseUrl = environment.apiUrl;

  constructor(private logger: NGXLogger, private httpClient: HttpClient) { }

    /**
     * API to be used to create data.
     *
     * @param resourceUrl : URL to be used
     * @param item : object with all information needed to create data.
     * @param headersObj : header
     * @param serializer : object serializer
     * @returns Observable<OUT>
     */
  public create(resourceUrl: string, item: IN, headersObj: HttpHeaders | null, serializer: AdxBaseSerializer): Observable<OUT> {
    const endPoint: string = this.resourceEndPoint(resourceUrl);

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    this.logger.debug(serializer.toJson(item));

    return this.httpClient
      .post<IN>(endPoint, serializer.toJson(item), {headers: headersObj})
      .pipe(map(data => serializer.fromJson(data) as OUT));
  }

  /**
   * API to be used to update data.
   *
   * @param resourceUrl : the URL to be used
   * @param item : The object with all information needed to update data.
   * @returns Observable<T>
   */
  public update(resourceUrl: string, item: IN, headersObj: HttpHeaders | null, serializer: AdxBaseSerializer): Observable<OUT> {

    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient
      .put<IN>(endPoint, serializer.toJson(item), {headers: headersObj})
      .pipe(map(data => serializer.fromJson(data) as OUT));
  }

  /**
   * API to be used to update data along with the files.
   *
   * @param resourceUrl : the URL to be used
   * @param item : The object with all information needed to update data.
   * @param headersObj : header
   * @param serializer : serializer
   * @returns Observable<T>
   */
  public updateFileAndImage(resourceUrl: string, item: IN, headersObj: HttpHeaders | null,
                            serializer: AdxBaseSerializer): Observable<OUT> {

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    const formData = FormUtility.getFormDataFromObj(item);

    return this.httpClient
      .put<IN>(endPoint, formData, {headers: headersObj})
      .pipe(map(data => serializer.fromJson(data) as OUT));
  }

  /**
   * API to be used to add image.
   *
   * @param resourceUrl : the URL to be used
   * @param item : The object with all information needed to update data.
   * @param headersObj : header
   * @param serializer : serializer
   * @returns Observable<T>
   */
  public addImages(resourceUrl: string, item: IN, headersObj: HttpHeaders | null,
                   serializer: AdxBaseSerializer): Observable<OUT[]> {

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    const formData = FormUtility.getFormDataFromObj(item);

    return this.httpClient
      .post<IN>(endPoint, formData, {headers: headersObj})
      .pipe(map((data: any) => this.convertData(data, serializer)));
  }

  /**
   * API used to get data by id
   *
   * @param resourceUrl : the URL to be used
   * @param id : id of the data to be fetched
   * @returns Observable<T>
   */
  public read(resourceUrl: string, id: number | null, headersObj: HttpHeaders | null,
              serializer: AdxBaseSerializer): Observable<OUT> {

    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    let url: string = this.resourceEndPoint(resourceUrl);
    if (id && (id !== null)) {
      url = url + '/' + id;
    }

    const endPoint: string = url;
    return this.httpClient
      .get(endPoint, {headers: headersObj})
      .pipe(map((data: any) => serializer.fromJson(data) as OUT));
  }

  /**
   * API to fetch all data
   *
   * @param resourceUrl : the URL to be used
   * @param queryOptions : The http query params to be used while fetching data
   * @returns Observable<T[]>
   */
  public list(resourceUrl: string, queryOptions: AdxQueryParams, headersObj: HttpHeaders | null,
              serializer: AdxBaseSerializer): Observable<OUT[]> {

    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient
      .get(endPoint, {headers: headersObj, params: queryOptions.getHttpParams()})
      .pipe(map((data: any) => this.convertData(data, serializer)));
  }

  /**
   * API used to delete the data identified by id
   *
   * @param resourceUrl : the URL to be used
   * @param id : id of the data to be deleted
   * @returns Observable<T>
   */
  public delete(resourceUrl: string, headersObj: HttpHeaders | null): Observable<any> {

    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient.delete(endPoint, {headers: headersObj});
  }

  /**
   * API used to delete the data identified by id
   *
   * @param resourceUrl : the URL to be used
   * @param id : id of the data to be deleted
   * @returns Observable<T>
   */
  public bulkDelete(resourceUrl: string, item: IN, headersObj: HttpHeaders | null,
                    serializer: AdxBaseSerializer): Observable<any> {

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient.delete(endPoint, {headers: headersObj, body: serializer.toJson(item)});
  }

  /**
   * API used to bulk delete objects from backend, provided a list if IDs to delete.
   *
   * @param resourceUrl : URL of resource
   * @param item : List of ids to be deleted
   * @param headersObj : additional headers to send to backend
   * @param serializer : Used to convert from obj to json and vice-versa
   */
  public bulkDeleteInputIds(resourceUrl: string, item: number[], headersObj: HttpHeaders | null,
                    serializer: AdxBaseSerializer): Observable<any> {

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient.delete(endPoint, {headers: headersObj, body: JSON.stringify(item)});
  }

  /**
   * API used to delete the data identified by id
   *
   * @param resourceUrl : the URL to be used
   * @param id : id of the data to be deleted
   * @returns Observable<T>
   */
  public bulkUpdate(resourceUrl: string, item: IN, headersObj: HttpHeaders | null,
                    serializer: AdxBaseSerializer): Observable<any> {

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    return this.httpClient.put(endPoint, serializer.toJson(item), {headers: headersObj});
  }

  /**
   * API to be used to create modules.
   *
   * @param resourceUrl : URL to be used
   * @param item : object with all information needed to create data.
   * @param headersObj : header
   * @param serializer : object serializer
   * @returns Observable<OUT[]>: list of modules created
   */
  public createModules(resourceUrl: string, item: IN,
                       headersObj: HttpHeaders | null, serializer: AdxBaseSerializer): Observable<OUT[]> {

    const endPoint: string = this.resourceEndPoint(resourceUrl);

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    this.logger.debug(serializer.toJson(item));

    return this.httpClient
      .post<IN>(endPoint, serializer.toJson(item), {headers: headersObj})
      .pipe(map((data: any) => this.convertData(data, serializer)));
  }

  /**
   * API to be used to create module group.
   *
   * @param resourceUrl : URL to be used
   * @param item : object with all information needed to create data.
   * @param headersObj : header
   * @param serializer : object serializer
   * @returns Observable<OUT>: Module created
   */
  public createModuleGroup(resourceUrl: string, item: IN,
                           headersObj: HttpHeaders | null, serializer: AdxBaseSerializer): Observable<OUT> {

    const endPoint: string = this.resourceEndPoint(resourceUrl);

    if (!headersObj) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    this.logger.debug(serializer.toJson(item));

    return this.httpClient
      .post<IN>(endPoint, serializer.toJson(item), {headers: headersObj})
      .pipe(map(data => serializer.fromJson(data) as OUT));
  }


  /*
   * private utility method to convert json data to array
  */
  private convertData(data: any, serializer: AdxBaseSerializer): OUT[] {
    if (data === undefined || data === null) {
      return [];
    }
    return data.map((item: any) => serializer.fromJson(item));
  }

  /*
   * private utility method to create the resource end point to be used for invoking
   * REST api by appending resourceUrl to the baseUrl
   *
   * @private
   * @param resourceUrl
   * @returns : the full url for REST call.
   * @memberof RestService
   */
  private resourceEndPoint(url: string): string {
    return `${this.baseUrl}/${url}`;
  }

}
