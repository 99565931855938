import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxImportProcessResult} from './adx-import-process-result';

/**
 * Model that represents the import process response
 */
export class AdxImportProcessResponse extends AdxResource {
  name: string | null = null;
  folderName: string | null = null;
  fileName: string | null = null;
  status: string | null = null;
  statusDescription: string | null = null;
  duration: string | null = null;
  results: AdxImportProcessResult[] | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any):  AdxImportProcessResponse {
    const toRet = new AdxImportProcessResponse(null);
    if (json) {
      if (json.name) {
        toRet.name = json.name;
      }
      if (json.folderName) {
        toRet.folderName = json.folderName;
      }
      if (json.fileName) {
        toRet.fileName = json.fileName;
      }
      if (json.status) {
        toRet.status = json.status;
      }
      if (json.statusDescription) {
        toRet.statusDescription = json.statusDescription;
      }
      if (json.duration) {
        toRet.duration = json.duration;
      }
      if (json.results) {
        toRet.results = [];
        json.results.forEach((processResult: any) => {
          const tempResultObj = (AdxImportProcessResult.buildObject(processResult));
          if (tempResultObj && toRet.results) {
            toRet.results.push(tempResultObj);
          }
        });
      }
    }
    return toRet;
  }
}
