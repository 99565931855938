import {AdxBaseSerializer} from '../../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxLocale} from '../../model/locale/adx-locale.model';

/**
 * Used to serialize from and deserialize to AdxLocale object
 */
export class AdxLocaleSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const locale: AdxLocale = AdxLocale.buildObject(json);
    return locale;
  }
  toJson(resource: AdxLocale): string {
    const json = JSON.stringify(resource);
    return json;
  }
}
