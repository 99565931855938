import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';

import { OrganizationResolver } from './organization/resolver/organization-resolver';
import { AdxImageLibraryComponent } from './shared/popups/adx-image-library/adx-image-library.component';
import {AccountResolver} from './account/resolver/account.resolver';
import {AdxApplicationResolver} from './application/resolver/adx-application.resolver';
import {AdxVpubResolver} from './vPub/resolver/adx-vpub.resolver';
import {AdxModuleResolver} from './adx-module/resolver/adx-module.resolver';

const routeConfig: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: true
};

const routes: Routes = [
  { path: 'auth', loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule)},
  { path: 'mahiti', loadChildren: () => import(`./adx-system-info/adx-system-info.module`).then(m => m.AdxSystemInfoModule)},
  { path: 'system', loadChildren: () => import(`./adx-system/adx-system.module`).then(m => m.AdxSystemModule)},
  { path: 'orgs', loadChildren: () => import(`./organization/organization.module`).then(m => m.OrganizationModule) },
  { path: 'orgs/systemImageLibrary', component: AdxImageLibraryComponent},
  {
    path: 'orgs/:orgId/accts',
    loadChildren: () =>
      import(`./account/account.module`).then((m) => m.AccountModule),
    resolve: {
      org: OrganizationResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/applns',
    loadChildren: () =>
      import(`./application/application.module`).then((m) => m.ApplicationModule),
    resolve: {
      org: OrganizationResolver,
      account: AccountResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/chnls',
    loadChildren: () =>
      import(`./channel/channel.module`).then((m) => m.ChannelModule),
    resolve: {
      org: OrganizationResolver,
      account: AccountResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/applns/:applnId/vpubs',
    loadChildren: () =>
      import(`./vPub/vpub.module`).then((m) => m.VpubModule),
    resolve: {
      org: OrganizationResolver,
      account: AccountResolver,
      appln: AdxApplicationResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/applns/:applnId/vpubs/:vpubId/modls',
    loadChildren: () =>
      import(`./adx-module/adx-module.module`).then((m) => m.AdxMdlModule),
    resolve: {
      org: OrganizationResolver,
      appln: AdxApplicationResolver,
      vpub: AdxVpubResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/applns/:applnId/vpubs/:vpubId/modls/:modId/ars',
    loadChildren: () =>
      import(`./adx-ars/adx-ars.module`).then((m) => m.AdxArsModule),
    resolve: {
      org: OrganizationResolver,
      appln: AdxApplicationResolver,
      modl: AdxModuleResolver
    }
  },
  {
    path: 'orgs/:orgId/accts/:acctId/applns/:applnId/vpubs/:vpubId/modls/:modId/form',
    loadChildren: () =>
      import(`./adx-form/adx-form.module`).then((m) => m.AdxFormModule),
    resolve: {
      org: OrganizationResolver,
      appln: AdxApplicationResolver,
      modl: AdxModuleResolver
    }
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routeConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
