import {AdxBaseSerializer} from '../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxArs} from './adx-ars-model';

/**
 * Serializer to convert json to and from AdxArs model class
 */
export class AdxArsSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxResource {
    const ars: AdxArs = AdxArs.buildObject(json);
    return ars;
  }

  toJson(resource: AdxResource): any {
    return JSON.stringify(resource);
  }

}
