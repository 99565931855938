/**
 * This is the custom Image Library type enum definition.
 * This enum is used with Image Library services, components etc
 */
export enum AdxImageLibraryType {
  ORG_IMG_LIB = 'org_img_lib',
  ACCOUNT_IMG_LIB = 'acc_img_lib',
  APPLICATION_IMG_LIB = 'app_img_lib',
  VPUB_IMG_LIB = 'vpub_img_lib',
  MODULE_IMG_LIB = 'mod_img_lib',
  ATOM_IMG_LIB = 'atom_img_lib'
}
