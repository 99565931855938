import {Component, Inject, OnInit} from '@angular/core';
import {of, Subject, Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {CustomNotifierService} from '../../../core/service/notifier/custom-notifier.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdxModuleService} from '../../../adx-module/service/adx-module.service';
import {AdxApplicationService} from '../../../application/service/adx-application.service';
import {AdxVpubService} from '../../../vPub/service/adx-vpub.service';
import {AdxDisplayLevel} from '../../../common/model/data-transfer/adx-display-level';
import {takeUntil} from 'rxjs/operators';
import {AdxApplication} from '../../../application/model/adx-application.model';
import {AdxVPub} from '../../../vPub/model/adx-vpub.model';
import {AdxModule} from '../../../adx-module/model/adx-module.model';
import {AdxModuleType} from '../../../adx-module/model/adx-module-type';
import {MatSelectChange} from '@angular/material/select';
import {AdxContentReportService} from '../../../common/services/adx-content-report/adx-content-report.service';
import {AdxContentReport} from '../../../common/model/adx-content-report/adx-content-report-model';
import {CustomMessageType} from '../../../core/service/notifier/custom-message-type';

/**
 * The popup used to help user to create the content report
 * This will use AdxContentReportService to send the request to backend to create content report
 */
@Component({
  selector: 'app-adx-content-report-create-dialog',
  templateUrl: './adx-content-report-create-dialog.component.html',
  styleUrls: ['./adx-content-report-create-dialog.component.scss']
})
export class AdxContentReportCreateDialogComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  createForm: FormGroup; // form
  applications: AdxApplication[] = []; // list of applications displayed in dropdown
  arsModules: AdxModule[] = []; // list of modules displayed in dropdown
  vpubs: AdxVPub[] = []; // list of vPubs displayed in dropdown
  reportTypes: {type: string; label: string} [] = [{type:'ACCOUNT_SUMMARY', label: 'Account Summary'},
    {type:'ARS_CONTENT', label: 'ARS'}];
  reportFormats: string [] = ['XML', 'MS EXCEL'];
  isSelARSType = false; // used to show/hide fields based on type selection

  constructor(private logger: NGXLogger, private moduleService: AdxModuleService,
              private applnService: AdxApplicationService, private vpubService: AdxVpubService,
              private readonly messageNotifier: CustomNotifierService,
              private readonly contentReportService: AdxContentReportService,
              public dialogRef: MatDialogRef<AdxContentReportCreateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public inputData:
                {displayLevel: AdxDisplayLevel; orgId: number; acctId: number; vpubId: number}) {
    this.createForm = new FormGroup({
      moduleControl: new FormControl<string>('', {}),
      appControl: new FormControl<string>('', {}),
      vpubControl: new FormControl<string>('', {}),
      typeControl: new FormControl<string>('', {}),
      formatControl: new FormControl<string>('',
        {updateOn: 'change', validators: [Validators.required]})
    });
  }

  ngOnInit(): void {
    this.logger.debug(`DIALOG displayLevel: ${this.inputData.displayLevel}`);
    // if account level, display list of applications
    if (this.inputData.displayLevel === AdxDisplayLevel.ACCOUNT_LEVEL) {
      this.fetchApplications(this.inputData.orgId, this.inputData.acctId);
    } // if vpub level display list of ARS modules
    else if (this.inputData.displayLevel === AdxDisplayLevel.VPUB_LEVEL) {
      this.fetchModules(this.inputData.orgId, this.inputData.acctId, this.inputData.vpubId);
    }

  }

  /**
   * Called on click of close button of popup
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * returns true if display is account level
   */
  isAccountDisplayLevel(): boolean {
    return this.inputData.displayLevel === AdxDisplayLevel.ACCOUNT_LEVEL;
  }

  /**
   * retruns true if display is vPub level
   */
  isVpubDisplayLevel(): boolean {
    return this.inputData.displayLevel === AdxDisplayLevel.VPUB_LEVEL;
  }

  /**
   * Called from application select box. This will fetch vPubs for the selected application and dispay in select box
   *
   * @param selectionEvent
   */
  loadVpubs(selectionEvent: MatSelectChange) {
    if (selectionEvent) {
      const selAppId = selectionEvent.value;
      if (selAppId) {
        const selVpubIdControl = this.createForm.get('vpubControl');
        if (selVpubIdControl) {
          selVpubIdControl.setValue(null);
        }
        this.fetchVpubs(this.inputData.orgId, this.inputData.acctId, selAppId);
      }
    }
  }

  /**
   * Called from reportType select box. Sets the isSelARSType flag accordingly
   *
   * @param selectionEvent
   */
  selectType(selectionEvent: MatSelectChange) {
    if (selectionEvent) {
      const selType = selectionEvent.value;
      if (selType && selType === 'ARS_CONTENT') {
        this.isSelARSType = true;
      }
      else {
        this.isSelARSType = false;
      }
    }
  }

  /**
   * Callback used to determine whether Submit button should be enabled or not.
   * If this function returns true, then Submit button is disabled
   */
  disableSubmitBtn(): boolean {
    if (!this.createForm.dirty) {
      return true; // if form is not changed, disable the btn
    }
    else {
      const selAppControl = this.createForm.get('appControl');
      const selVpubIdControl = this.createForm.get('vpubControl');
      const selModuleIdControl = this.createForm.get('moduleControl');
      const selectedReportFormatControl = this.createForm.get('formatControl');
      const selectedReportTypeControl = this.createForm.get('typeControl');
      if (!selectedReportFormatControl?.valid) {
        return true; // if format type not chosen, disable the btn
      }
      else {
        if (this.isVpubDisplayLevel()) {
          if (selModuleIdControl && selModuleIdControl.dirty) {
            const selModId = selModuleIdControl.value;
            if (selModId === undefined || selModId === null) {
              return true; // if vPub level and module is not chosen, disable btn
            }
          }
          else {
            return true; // if vPub level and module is not chosen, disable btn
          }
        }
        else { // account level
          if (selectedReportTypeControl && selectedReportTypeControl.dirty) {
            const selTypeVal = selectedReportTypeControl.value;
            if (selTypeVal === undefined || selTypeVal === null) {
              return true; // if account level and type is not chosen, disable btn
            }
          }
          else {
            return true; // if account level and type is not chosen, disable btn
          }
          const selType = selectedReportTypeControl.value;
          if (selType && selType === 'ARS_CONTENT') { // only if ARS content, check for applicationId and vPubId
            if (selAppControl && selAppControl.dirty) {
              const selAppId = selAppControl.value;
              this.logger.debug(`selAppId : ${selAppId}`);
              if (selAppId === undefined || selAppId === null) {
                return true; // if account level and application is not chosen, disable btn
              }
            }
            else {
              return true; // if account level and application is not chosen, disable btn
            }
            if (selVpubIdControl && selVpubIdControl.dirty) {
              const selVpubId = selVpubIdControl.value;
              this.logger.debug(`selVpubId : ${selVpubId}`);
              if (selVpubId === undefined || selVpubId === null || selVpubId === '') {
                return true; // if account level and vPub is not chosen, disable btn
              }
            }
            else {
              return true; // if account level and vPub is not chosen, disable btn
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * Callback on click of Submit btn. This will initiate creation of content report
   */
  createContentReport() {
    if (this.createForm.dirty) {
      const contentCreateReq = new AdxContentReport(null);
      contentCreateReq.accountId = this.inputData.acctId;
      const selVpubId = this.createForm.get('vpubControl')?.value;
      const selModuleId = this.createForm.get('moduleControl')?.value;
      const selectedReportFormat = this.createForm.get('formatControl')?.value;
      let selectedReportType = this.createForm.get('typeControl')?.value;
      if (this.isVpubDisplayLevel()) {
        selectedReportType = 'ARS_CONTENT'; //for vpub level, type is always ARS_CONTENT
      }
      if (selectedReportType && selectedReportType === 'ACCOUNT_SUMMARY') {
        if (selectedReportFormat) {
          if (selectedReportFormat === 'XML') {
            this.logger.debug(`template: ACCOUNT_SUMMARY_XML`);
            contentCreateReq.template = 'ACCOUNT_SUMMARY_XML';
          }
          else {
            this.logger.debug(`template: ACCOUNT_SUMMARY_XLSX`);
            contentCreateReq.template = 'ACCOUNT_SUMMARY_XLSX';
          }
        }
      }
      else if (selectedReportType && selectedReportType === 'ARS_CONTENT') {
        if (selectedReportFormat) {
          if (selectedReportFormat === 'XML') {
            this.logger.debug(`template: ARS_CONTENT_XML`);
            contentCreateReq.template = 'ARS_CONTENT_XML';
          }
          else {
            this.logger.debug(`template: ARS_CONTENT_XLSX`);
            contentCreateReq.template = 'ARS_CONTENT_XLSX';
          }
        }

        if (this.isVpubDisplayLevel()) { // if vpub level, the moduleIds need to be set
          contentCreateReq.moduleId = selModuleId;
          contentCreateReq.vpubId = this.inputData.vpubId;
        } else { // if account level, the vPubIds need to be set
          contentCreateReq.vpubId = selVpubId;
        }
      }
      this.logger.debug('request to create:');
      this.logger.debug(contentCreateReq);
      if (this.isVpubDisplayLevel()) {
        this.create(this.inputData.orgId, contentCreateReq);
      }
      else {
        this.create(this.inputData.orgId, contentCreateReq);
      }
    }
  }

  /*
   * Uses the service to initiate creation of content report
   */
  private create(orgId: number, request: AdxContentReport): void {
    if (orgId) {
      this.contentReportService.createContentReport(orgId, request)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            this.logger.debug(`createContentReport next`);
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Content Report Initiated Successfully.`);
          },
          error: () => {
            this.logger.debug(`createContentReport error`);
            this.dialogRef.close(false);
          },
          complete: () => {
            this.logger.debug(`createContentReport complete`);
            this.dialogRef.close(true);
          }
        });
    }
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchApplications(orgId: number, accountId: number): Subscription {
    this.logger.debug('fetching applications for orgId:' + orgId + ' ; appId:' + accountId);
    if (accountId && orgId) {
      return this.applnService.getApplications(orgId, accountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxApplication[]) => {
            // successfully received the data. so set it in variable
            this.applications = data;
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }

  /*
   * This method fetches the vPubs list from backend
  */
  private fetchVpubs(orgId: number, accountId: number, applnId: number): Subscription {
    this.logger.debug('fetching accounts for orgId:' + orgId + ' ; accountId:' + accountId + 'applnId:' + applnId);
    if (accountId && orgId && applnId) {
      return this.vpubService.getVpubs(orgId, accountId, applnId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxVPub[]) => {
            this.vpubs = data;
          }
        });
    }
    else {
      return of ({}).subscribe();
    }
  }

  /*
   * This method fetches the modules list from backend and filters ARS type and sets the variable
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for orgId:' + orgId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          this.filterArsModules(data);
        }
      });
  }

  /*
   * From given module list, filter ARS module.
   */
  private filterArsModules(modules: AdxModule[]) {
    this.logger.debug(modules);
    if (modules && modules.length > 0) {
      modules.forEach(module => {
        this.logger.debug(module);
        if (module) {
          if (module.type === AdxModuleType.ARS) {
            this.arsModules.push(module);
          }
          else if (module.type === AdxModuleType.GROUP) {
            this.filterArsModules(module.children);
          }
        }
      });
    }
  }
}
