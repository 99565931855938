import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxStyle} from '../../common/model/adx-style.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxModuleType} from './adx-module-type';
import {AdxArsSettings} from './adx-ars-settings-model';
import {AdxFormSettings} from './adx-form-settings-model';
import {AdxWeb} from './adx-web-model';
import {AdxHtml} from './adx-html-model';


export class AdxModule extends AdxResource {
  title: string | null = null;
  navigationBarTitle: string | null = null;
  type: AdxModuleType | null = null;
  code: number | null = null;
  description: string | null = null;
  summary: string | null = null;
  visibility: AdxVisibilityType | null = null;
  parentId: number | null = null;
  signinEnabled: string | null = null;
  isSharable: string | null = null;
  style: AdxStyle | null = null;
  iconImage: AdxImage | null = null;
  excludedModuleTypes: string[] = [];
  sortOrder: number | null = null;
  readOnly: boolean | null = null;
  ars: AdxArsSettings | null = null;
  form: AdxFormSettings | null = null;
  children: AdxModule[] = [];
  childrenIds: number[] | null = null;
  directAccessUrl: string | null = null;
  web: AdxWeb | null = null;
  html: AdxHtml | null = null;

  selectedIds: number[] = []; // used in bulk actions

  constructor(id: number | null) {
    super(id);
  }
}
