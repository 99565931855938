import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../common/material/material.module';
import { NgxColorsModule } from 'ngx-colors';

import { VpubListComponent } from './vpub-list/vpub-list.component';
import { AddVpubComponent } from './add-vpub/add-vpub.component';
import { EditVpubComponent } from './edit-vpub/edit-vpub.component';
import { VpubRoutingModule } from './vpub-routing.module';
import { VpubComponent } from './vpub.component';
import {AdxControlsModule} from '../shared';
import {CommonDirectiveModule} from '../common/directive/common-directive.module';
import {AdxPagesModule} from '../shared/pages';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        RouterModule,
        MaterialModule,
        NgxColorsModule,
        VpubRoutingModule,
        ReactiveFormsModule,
        AdxControlsModule,
        CommonDirectiveModule,
        AdxPagesModule,
        FroalaEditorModule
    ],
  exports: [VpubListComponent],
  providers: [
  ],
  declarations: [VpubListComponent, AddVpubComponent, EditVpubComponent, VpubComponent],
})
export class VpubModule {}
