import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 *  Class AboutUs used in Organization and Account classes
 */
export class AdxAboutUsInfo extends AdxResource {
  text: string | null;
  scope: string | null;

  constructor(id: number | null, txt: string | null, strScope: string | null) {
    super(id);
    this.text = txt;
    this.scope = strScope;
  }

  static buildObject(json: any): AdxAboutUsInfo {
    return new AdxAboutUsInfo(json.id, json.text, json.scope);
  }
}
