import { Component, Input, OnInit } from '@angular/core';
export type ButtonType = 'button' | 'submit';

@Component({
  selector: 'app-adx-button',
  templateUrl: './adx_button.component.html',
  styleUrls: ['./adx_button.component.scss']
})
export class AdxButtonComponent implements OnInit {

  @Input() type: ButtonType;

  constructor() {
    this.type = 'button';
   }

  ngOnInit(): void {
  }

}
