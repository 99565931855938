
/**
 * This is the custom module type enum definition.
 */
export enum AdxModuleType {
  GROUP = 'GROUP',
  FORM = 'FORM',
  CONTACTS = 'CONTACTS',
  LOCATIONS = 'LOCATIONS',
  ARS = 'ARS',
  WEB = 'WEB',
  HTML = 'HTML',
  MOTOR_AXLENUT_HUBMOUNT = 'MOTOR_AXLENUT_HUBMOUNT',
  MOTOR_COOLANT = 'MOTOR_COOLANT',
  MOTOR_TRUSPEED = 'MOTOR_TRUSPEED'
}
