import {AdxResource} from '../../core/service/resource/adx-resource.model';

export class AdxWeb extends AdxResource {

  endpointType: string | null = null;
  targetType: string | null = null;
  contentType: string | null = null;
  endpoint: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxWeb {
    const toRet: AdxWeb = new AdxWeb(json.id);

    if (json.endpointType) {
      toRet.endpointType = json.endpointType;
    }

    if (json.targetType) {
      toRet.targetType = json.targetType;
    }

    if (json.contentType) {
      toRet.contentType = json.contentType;
    }

    if (json.endpoint) {
      toRet.endpoint = json.endpoint;
    }
    return toRet;
  }
}
