<div class='form-field' [class.form-field_error]='hasError()' [class.form-field_inline]="isInline">
    <label class='form-field__label'>
      {{label}}
      <span class='form-field__req' *ngIf="required">*</span>
    </label>
    <div class='form-field__control'>

        <ng-content></ng-content>

        <div class='form-field__error'>
            <ng-container *ngIf="control && errorKey && control.errors && control.errors[errorKey]" [ngSwitch]="errorKey">
                <span *ngSwitchCase="'min'">Minimum {{control.errors[errorKey].min}}</span>
                <span *ngSwitchCase="'max'">Maximum {{control.errors[errorKey].min}}</span>
                <span *ngSwitchCase="'required'">This field is required</span>
                <span *ngSwitchCase="'requiredtrue'">This field is required</span>
                <span *ngSwitchCase="'minlength'">At least {{control.errors[errorKey].requiredLength}} characters</span>
                <span *ngSwitchCase="'maxlength'">No more than {{control.errors[errorKey].requiredLength}} characters</span>
                <span *ngSwitchCase="'pattern'"></span>
            </ng-container>

        </div>

    </div>
</div>