import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AdxTextInputModule } from './adx-text-input/adx-text-input.module';
import { AdxFormFieldModule } from './adx-form-field/adx-form-field.module';
import {AdxImageInputModule} from './adx-image-input/adx-image-input.module';
import { AdxInsertImageInputComponent } from './adx-insert-image-input/adx-insert-image-input.component';
import {MaterialModule} from '../../common/material/material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AdxImageLibraryModule} from '../popups';
import { AdxInsertEmbedInputComponent } from './adx-insert-embed-input/adx-insert-embed-input.component';
import { AdxInsertModuleInputComponent } from './adx-insert-module-input/adx-insert-module-input.component';
import { AdxInsertArsInputComponent } from './adx-insert-ars-input/adx-insert-ars-input.component';


@NgModule({
  declarations: [
    AdxInsertImageInputComponent,
    AdxInsertEmbedInputComponent,
    AdxInsertModuleInputComponent,
    AdxInsertArsInputComponent
  ],
  imports: [
    CommonModule,
    AdxTextInputModule,
    AdxImageInputModule,
    FlexLayoutModule,
    AdxFormFieldModule,
    MaterialModule,
    ReactiveFormsModule,
    AdxImageLibraryModule
  ],
    exports: [
        AdxTextInputModule,
        AdxImageInputModule,
        AdxFormFieldModule,
        AdxInsertImageInputComponent,
        AdxInsertEmbedInputComponent,
        AdxInsertModuleInputComponent,
        AdxInsertArsInputComponent
    ]
})
export class AdxControlsModule { }
