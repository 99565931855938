import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../common/material/material.module';
import { SimulatorComponent } from './adx-simulator/simulator.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ArsSimulatorComponent } from './ars-simulator/ars-simulator.component';
import { ModuleGroupSimulatorComponent } from './module-group-simulator/module-group-simulator.component';
import { WebModuleSimulatorComponent } from './web-module-simulator/web-module-simulator.component';
import { HtmlModuleSimulatorComponent } from './html-module-simulator/html-module-simulator.component';
import { AtomSimulatorComponent } from './atom-simulator/atom-simulator.component';
import { HtmlTranslateDirective } from './HtmlTranslate.directive';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, FlexLayoutModule ],
  exports: [SimulatorComponent, HtmlTranslateDirective ],
  declarations: [ SimulatorComponent, ArsSimulatorComponent, ModuleGroupSimulatorComponent, WebModuleSimulatorComponent, HtmlModuleSimulatorComponent, AtomSimulatorComponent, HtmlTranslateDirective ],
})
export class AdxSimulatorModule {}
