import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AdxBaseTemplate} from '../../../common/template/adx-base-template';
import {AdxInsertContentDialogData} from '../../utils/adx-insert-content-dialog-data';

@Component({
  selector: 'app-adx-insert-embed-input',
  templateUrl: './adx-insert-embed-input.component.html',
  styleUrls: ['./adx-insert-embed-input.component.scss']
})
export class AdxInsertEmbedInputComponent extends AdxBaseTemplate implements OnInit {

  @Input() data: AdxInsertContentDialogData | undefined;
  @Output() workDone = new EventEmitter<boolean>();

  insertEmbedForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.insertEmbedForm = this.formBuilder.group({
      embed: [null, {
          updateOn: 'blur', validators: [
            Validators.required
          ]
        }]
      }
    );
  }

  ngOnInit(): void {
  }

  insertEmbed(): void {
    if (!this.data || !this.data.editorRef) {
      this.logger.error(`insertEmbed:: Editor not initialized ${this.data} ${this.data?.editorRef}`);
      return;
    }
    this.logger.debug(`In insertEmbed ${this.data.editorRef}`);
    const html = this.insertEmbedForm.get('embed')?.value;
    this.data.editorRef.selection.restore();
    this.data.editorRef.html.insert(html);
    this.workDone.emit(true);
  }
}
