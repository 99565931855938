import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/*
* This is class for notification model
*/
export class AdxNotification extends AdxResource {
  title: string | null = null;
  content: string | null = null;
  deliveryType: string | null = null;
  organizationId: number | null = null;
  accountId: number | null = null;
  applicationId: number | null = null;
  vpubId: number | null = null;
  username: string | null = null;
  createdOn: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxNotification {
    const toRet = new AdxNotification(json.id);
    if (json.title) {
      toRet.title = json.title;
    }
    if (json.content) {
      toRet.content = json.content;
    }
    if (json.deliveryType) {
      toRet.deliveryType = json.deliveryType;
    }
    if (json.organizationId !== undefined && json.organizationId !== null) {
      toRet.organizationId = json.organizationId;
    }
    if (json.accountId !== undefined && json.accountId !== null) {
      toRet.accountId = json.accountId;
    }
    if (json.applicationId !== undefined && json.applicationId !== null) {
      toRet.applicationId = json.applicationId;
    }
    if (json.vpubId !== undefined && json.vpubId !== null) {
      toRet.vpubId = json.vpubId;
    }
    if (json.username) {
      toRet.username = json.username;
    }
    if (json.createdOn) {
      toRet.createdOn = json.createdOn;
    }
    return toRet;
  }
}
