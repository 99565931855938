import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdxBaseTemplate } from '../common/template/adx-base-template';
import { Organization } from '../organization/model/organization.model';
import { AdxDisplayLevel } from '../common/model/data-transfer/adx-display-level';

/**
 * Base component for User Management
 */
@Component({
  selector: 'app-adx-user-manage',
  templateUrl: './adx-user-manage.component.html',
  styleUrls: ['./adx-user-manage.component.scss']
})
export class AdxUserManageComponent extends AdxBaseTemplate implements OnInit {

  @Input() orgId: number | null = null;
  @Input() accountId: number | null = null;
  @Input() displayLevel = AdxDisplayLevel.ORG_LEVEL; // denoted at what level the usermgmt screen is displayed, whether ORG or ACCNT

  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {

  }

}
