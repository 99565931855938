import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxTimezone } from '../../model/adx-timezone.model';

/**
 * Serializer for Timezone data object
 */
export class AdxTimezoneSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const timezone: AdxTimezone = AdxTimezone.buildObject(json);
    return timezone;
  }
  toJson(resource: AdxTimezone): string {
    const json = JSON.stringify(resource);
    return json;
  }

}
