import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WorkaroundMatFormFieldAccessorDirective} from './mat-form-field-accessor/workaround-mat-form-field-accessor.directive';
import {AdxDropzoneDirective} from './adx-dropzone/adx-dropzone.directive';



@NgModule({
  declarations: [
    WorkaroundMatFormFieldAccessorDirective,
    AdxDropzoneDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WorkaroundMatFormFieldAccessorDirective,
    AdxDropzoneDirective
  ]
})
export class CommonDirectiveModule { }
