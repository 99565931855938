import {AdxBaseSerializer} from '../../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxContentReport} from '../../model/adx-content-report/adx-content-report-model';

/**
 * Used to serialize from and deserialize to AdxContentReport object
 */
export class AdxContentReportSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const locale: AdxContentReport = AdxContentReport.buildObject(json);
    return locale;
  }

  toJson(resource: AdxContentReport): string {
    const json = JSON.stringify(resource);
    return json;
  }
}
