/**
 * This is the custom error definition.
 * Within the console, if any error happens,
 * then new instance of this error object needs to be created and thrown.
 * The code is used to support i18n
 * This error will be caught by the Custom Error Handler and suitable error message will be displayed to user.
 */

export class AdxCustomError extends Error {

  errorMessage: string;
  constructor(public code: number){
    super();
    this.errorMessage = this.getMessage(code);
  }

  private getMessage(code: number): string {
    return '';
  }
}
