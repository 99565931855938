import {AdxImage} from "../../common/model/adx-image.model";

export class AdxArsSettings {
  navigationEnabled: boolean | null = null;
  backupEnabled: boolean | null = null;
  journalingEnabled: boolean | null = null;
  iconEnabled: boolean | null = null;
  defaultIconEnabled: boolean | null = null;
  footerDisplayEnabled: boolean | null = null;
  footerTitle: string | null = null;
  categoryTitleDisplayEnabled: boolean | null = null;
  sortColumn: string | null = null;
  sortDirection: string | null = null;
  defaultIconImage: AdxImage | null = null;

  constructor() {
  }

  static buildObject(json: any): AdxArsSettings {
    const toRet: AdxArsSettings = new AdxArsSettings();

    if (json.navigationEnabled !== null && json.navigationEnabled !== undefined) {
      toRet.navigationEnabled = json.navigationEnabled;
    }

    if (json.backupEnabled !== null && json.backupEnabled !== undefined) {
      toRet.backupEnabled = json.backupEnabled;
    }

    if (json.journalingEnabled !== null && json.journalingEnabled !== undefined) {
      toRet.journalingEnabled = json.journalingEnabled;
    }

    if (json.iconEnabled !== null && json.iconEnabled !== undefined) {
      toRet.iconEnabled = json.iconEnabled;
    }

    if (json.defaultIconEnabled !== null && json.defaultIconEnabled !== undefined) {
      toRet.defaultIconEnabled = json.defaultIconEnabled;
    }

    if (json.footerDisplayEnabled !== null && json.footerDisplayEnabled !== undefined) {
      toRet.footerDisplayEnabled = json.footerDisplayEnabled;
    }

    if (json.footerTitle !== null && json.footerTitle !== undefined) {
      toRet.footerTitle = json.footerTitle;
    }

    if (json.categoryTitleDisplayEnabled !== null && json.categoryTitleDisplayEnabled !== undefined) {
      toRet.categoryTitleDisplayEnabled = json.categoryTitleDisplayEnabled;
    }

    if (json.sortColumn !== null && json.sortColumn !== undefined) {
      toRet.sortColumn = json.sortColumn;
    }

    if (json.sortDirection !== null && json.sortDirection !== undefined) {
      toRet.sortDirection = json.sortDirection;
    }

    if (json.defaultIconImage !== null && json.defaultIconImage !== undefined) {
      toRet.defaultIconImage = AdxImage.buildObject(json.defaultIconImage);
    }
    return toRet;
  }
}
