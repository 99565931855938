import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {AdxImageLibraryItem} from '../../../common/model/image-library/adx-image-library-item.model';
import {AdxImageLibraryType} from '../../utils/adx-image-library-type';
import {NGXLogger} from 'ngx-logger';
import {AdxImageLibraryService} from '../../../common/services/image-library/adx-image-library.service';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {CustomMessageType} from '../../../core/service/notifier/custom-message-type';
import {CustomNotifierService} from '../../../core/service/notifier/custom-notifier.service';
import {MatTableDataSource} from '@angular/material/table';
import { AdxConfirmDialogComponent } from '../adx-confirm-dialog/adx-confirm-dialog.component';
import { CommonUtility } from 'src/app/common/utils/common-utils';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { AdxApplication } from 'src/app/application/model/adx-application.model';
import { AdxVPub } from 'src/app/vPub/model/adx-vpub.model';
import { AdxModule } from 'src/app/adx-module/model/adx-module.model';
import { AdxApplicationService } from 'src/app/application/service/adx-application.service';
import { AdxVpubService } from 'src/app/vPub/service/adx-vpub.service';
import { AdxModuleService } from 'src/app/adx-module/service/adx-module.service';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AdxMdlModule } from 'src/app/adx-module/adx-module.module';

/**
 * This the component used to display list of images from the image library.
 * This component has to be invoked with two inputs, libraryType and id
 * LibraryType identified whether organizationLibrary or Account Library
 * The id is of corresponding organization or account
 */
@Component({
  selector: 'app-adx-image-library',
  templateUrl: './adx-image-library.component.html',
  styleUrls: ['./adx-image-library.component.scss']
})
export class AdxImageLibraryComponent implements OnInit, OnDestroy {

  // selected library type
  @Input() libraryType: AdxImageLibraryType = AdxImageLibraryType.ORG_IMG_LIB;

  // id of the entity ( organization id or account id)
  @Input() id = 0;

  // whether image isIcon. If icon, then square image, else it can be rectangular
  @Input() isIcon = true;

  //additional info for saving an image to library.
  @Input() orgId : number;//org and account id is required to fetch the applications.
  @Input() accountId : number;
  @Input() applnId : number | null = null;
  @Input() vpubId : number | null = null;
  @Input() moduleId : number | null = null;
  @Input() atomId : number | null = null;

  // column titles
  displayedColumns: string[] = [];

  public dataSource = new MatTableDataSource<AdxImageLibraryItem>();

  // is used to decide whether to show 'select' button or 'delete' button
  isSelect = true;

  selectedView = 'catalog'; //used in toggle view

  private destroy$: Subject<boolean> = new Subject<boolean>();

  imageFilter = new UntypedFormControl('');
  applicationFilter = new UntypedFormControl(''); // new fields for image filter each for application, vpub and module.
  vpubFilter = new UntypedFormControl('');
  moduleFilter = new UntypedFormControl('');

  applications: AdxApplication[] = []; // array which holds the list of application
  vpubs: AdxVPub[] = []; // array which holds the list of vpub
  modules: AdxModule[] = []; // array which holds the list of module

  applnSelected = '';
  imageLibraryFilterForm: FormGroup<any>;
  showFilter = false;

  // organizations: Organization[] = [];
  // accounts: Account[] = [];
  // applications: AdxApplication[] = [];
  // channels: AdxChannel[] = [];
  // vpubs: AdxVPub[] = [];
  // modules: AdxModule[] = [];

  filterValues : any = {
    text : '',
    appId : '',
    vpubId : '',
    moduleId : '',
  };
  currentUser : AuthenticatedUser | null = null;
  private imageFilterSubscription: Subscription | null = null;
  private applicationFilterSubscription: Subscription | null = null;
  private vpubFilterSubscription: Subscription | null = null;
  private moduleFilterSubscription: Subscription | null = null;
  private authSubscription: Subscription | undefined;


  constructor(private logger: NGXLogger, private imgLibService: AdxImageLibraryService,
              private readonly messageNotifier: CustomNotifierService,
              public dialogRef: MatDialogRef<AdxImageLibraryComponent>,
              private matDialog: MatDialog,
              private readonly applnService: AdxApplicationService,
              private readonly vpubService: AdxVpubService,
              private readonly moduleService: AdxModuleService,
              private activatedRoute: ActivatedRoute, private router : Router,
              private authNotifier: AuthNotifierService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                                                      isSelect: boolean;
                                                      id: number;
                                                      isIcon: boolean;
                                                      libraryType: AdxImageLibraryType;
                                                      orgId :number; accountId:number;
                                                      applnId: number; vpubId: number; moduleId: number; atomId:number }) {

    this.logger.debug(data);
    this.isSelect = data.isSelect;
    this.id = data.id;
    this.isIcon = data.isIcon;
    this.libraryType = data.libraryType;
    this.applnId = data.applnId; //additional Id's for saving an image specific to app/vpub/module
    this.vpubId = data.vpubId;
    this.moduleId = data.moduleId;
    this.atomId = data.atomId;
    this.orgId = Number(data.orgId);
    this.accountId = Number(data.accountId);

    if (this.isSelect) {
      this.displayedColumns = [
        'icon',
        'name',
        'selectImage'
      ];
    }
    else {
      this.displayedColumns = [
        'icon',
        'name',
        'deleteImage'
      ];
    }

    //initialize the search and filter form 
    this.imageLibraryFilterForm = new FormGroup({
      imageFilter: new FormControl(),
      applicationFilter: new FormControl(),
      vpubFilter: new FormControl(),
      moduleFilter: new FormControl(),
    });

   }

  ngOnInit(): void {
    this.showFilter = this.libraryType != 'org_img_lib';
    
    this.logger.debug('libraryType:' + this.libraryType);
    this.logger.debug('id:' + this.id);
    this.fetchData();
    this.fieldListener();
    this.fetchApplications(this.orgId, this.accountId);

     // note change in user authentication status in order to get the permission of the user
     this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    this.logger.debug('OnDestroy of AdxImageLibraryComponent');
    if(this.imageFilterSubscription){
      this.imageFilterSubscription.unsubscribe();
    }
    if(this.applicationFilterSubscription){
      this.applicationFilterSubscription.unsubscribe();
    }
    if(this.vpubFilterSubscription){
      this.vpubFilterSubscription.unsubscribe();
    }
    if(this.moduleFilterSubscription){
      this.moduleFilterSubscription.unsubscribe();
    }

    if(this.authSubscription){this.authSubscription.unsubscribe()}
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onToggleChange(value: string) {
    this.selectedView = value;
  }

  /**
   * When dialog is closed, this method is called.
   * If dialog is closed by using close button, the imgUrl will not be set.
   * If image is uploaded successfully and dialog is closed using complete button, then the imgUrl will have the path.
   *
   * @param imgUrl: url of file, in case of upload
   */
  onImageUpload(imgUrl: string | string[]): void {
    if (imgUrl) {
      this.logger.debug(`Image Uploaded. Refresh list : ${imgUrl}`);
      this.fetchData();
    }
  }

  /**
   * On selecting the image, the dialog needs to be closed. The item selected is to be sent to parent.
   *
   * @param element: selected item
   */
  onImageSelect(element: AdxImageLibraryItem): void {
    this.logger.debug(`Image selected: ${element.title}`);
    this.dialogRef.close(element);
  }

  /**
   * Called when the user clicks on delete button.
   * The corresponding image needs to be deleted from backend using the service call.
   * The list is to be refreshed after deleting the image.
   *
   * @param element: the item selected to delete
   */
  onImageDelete(element: AdxImageLibraryItem): void {

    const dialogRef = this.matDialog.open(AdxConfirmDialogComponent, {
      width: '50vw',
      data: {title: 'Please confirm action', message: 'Please confirm whether you want to delete the selected image', confirmText: 'Confirm', cancelText: 'Cancel'},
      autoFocus: 'false'
    });

    // on close of dialog read the user action and process further,
    dialogRef.afterClosed().subscribe(confirm => {
      console.log('The dialog was closed', confirm);
      if(confirm){
        this.logger.debug(`Image to be deleted: ${element.title}`);
        if (element.id) {
          const tempList = this.dataSource.data;
          // identify the index of item to be deleted, within the local list used in display.
          const itemIndex = tempList.findIndex(obj => obj.id === element.id);
          this.imgLibService
            .deleteImageFromLibrary(element.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (data: any) => {
                tempList.splice(itemIndex, 1); // remove item from list
                this.logger.debug(`removed item with index ${itemIndex} from list`);
                this.dataSource.data = tempList;
              },
              complete: () => {
                this.messageNotifier.notify(CustomMessageType.SUCCESS, `Image Deleted Successfully.`);
              }
            });
        }
      }
    });
  }

  /*
   * Utility method to fetch list of images from backend by using the service call
   *
   */
  private fetchData(): void {
    this.imgLibService
      .getLibraryImagesByType(this.libraryType, this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: AdxImageLibraryItem[]) => {
        // successfully received the data. so set it in return variable
        // CommonUtility.sortByIdDescending(data); // sorting the image list by in descending order in order to list the latest uploaded image first in the image library 
        // this.dataSource.data = data;
        this.imageLibraryFilterForm.reset(); // reset the filters on uploading new image.
        this.handleImages(data);
      });
  }

  private handleImages (imageList : AdxImageLibraryItem[]){
    CommonUtility.sortByIdDescending(imageList); // sorting the image list by in descending order in order to list the latest uploaded image first in the image library 
    this.dataSource = new MatTableDataSource(imageList);    
    this.dataSource.filterPredicate = this.createFilterFunction();
}

/*
* This method creates function used to filter contents of data table.
* The returned function is used as filterPredicate.
* The returned function is called once for each row of the data table.
*/
  private createFilterFunction(): (image: AdxImageLibraryItem, filter: string) => boolean {    
  const filterFunction = (image: AdxImageLibraryItem, filter: string): boolean => {
    const searchTerms = JSON.parse(filter);    
    //checks if the image title matches with search text
    let titleValue = '';
    let titleFlag = -1;
    if (image && image.title) {
      titleValue = image.title.toLowerCase();
      titleFlag = (titleValue.indexOf(searchTerms.text.toLowerCase()));
    }

    //checks if the image tag matches with search text
    let tagFlag = -1;
      if (image && image.tags && image.tags.length > 0) {
        const imageTags = image.tags?.split(',');    //splitting the tags array by comma as this is in a string format which returns an array of tags   
        tagFlag =  imageTags.some((str : string) => str.includes(searchTerms.text.toLowerCase())) ? 0 : -1;// and checking each tag for searchText match      
      }

      //checks if the image applicationId matches with selected application from filter
      let applnvalue = '';
      let applnFlag = -1;
      if(image && image.applicationId && image.applicationId !== null && searchTerms.appId != ''){
        applnvalue = image.applicationId.toString();
        applnFlag = (applnvalue.indexOf(searchTerms.appId));
      }

      //checks if the image vpubId matches with selected vpub from filter
      let vpubvalue = '';
      let vpubFlag = -1;
      if(image && image.vpubId && image.vpubId !== null && searchTerms.vpubId != ''){
        vpubvalue = image.vpubId.toString();
        vpubFlag = (vpubvalue.indexOf(searchTerms.vpubId));        
      }

       //checks if the image module id matches with selected module from filter
       let modulevalue = '';
       let moduleFlag = -1;
       if(image && image.moduleId && image.moduleId !== null && searchTerms.moduleId != ''){
        modulevalue = image.moduleId.toString();
        moduleFlag = (modulevalue.indexOf(searchTerms.moduleId));        
       }


       //returns the filtered list which matches either title or tag or appln/vpub/module
       if(searchTerms.text != '' && searchTerms.appId == '' && searchTerms.vpubId == '' && searchTerms.moduleId == ''){ //if user enters only search text for filter then list should be filtered with title/tag        
        return -1 !== tagFlag || -1 !== titleFlag; 

      }else if(searchTerms.text != '' && searchTerms.appId != '' && searchTerms.vpubId =='' && searchTerms.moduleId == ''){ //if user select application along with search text then we should filter the list which matches both
        return (-1 !== tagFlag || -1 !== titleFlag) && -1 !== applnFlag; 

      }else if(searchTerms.text != '' && searchTerms.appId != '' && searchTerms.vpubId != '' && searchTerms.moduleId == ''){ //if user select application and vpub along with search text then we should filter the list which matches all
        return (-1 !== tagFlag || -1 !== titleFlag) && -1 !== applnFlag && -1 !== vpubFlag;
        
      }else if(searchTerms.text != '' && searchTerms.appId != '' && searchTerms.vpubId != '' && searchTerms.moduleId != ''){ //if user select application and vpub and module along with search text then we should filter the list which matches all
        return (-1 !== tagFlag || -1 !== titleFlag) && -1 !== applnFlag && -1 !== vpubFlag && -1 !== moduleFlag;
        
      }else if(searchTerms.appId != '' && searchTerms.text == '' && searchTerms.vpubId == ''&& searchTerms.moduleId == ''){ //if user select only application for filter then list should be filtered with application
        return -1 !== applnFlag; 

      }else if(searchTerms.appId != '' && searchTerms.text == '' && searchTerms.vpubId != '' && searchTerms.moduleId == ''){
        return -1 !== applnFlag && -1 !== vpubFlag; //returns the filtered list which matches appl and vpub

      }else if(searchTerms.appId != '' && searchTerms.text == '' && searchTerms.vpubId != '' && searchTerms.moduleId != ''){
        return -1 !== applnFlag && -1 !== vpubFlag && -1 !== moduleFlag; //returns the filtered list which matches appl and vpub and module

      }else{
        return -1 !== tagFlag || -1 !== titleFlag || 1 !== applnFlag || -1 !== vpubFlag; //returns the filtered list which matches either title ot tag
      }

  };

  return filterFunction;
  }


  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.imageFilterSubscription = this.imageFilter.valueChanges
      .subscribe({
        next: value => {
          this.filterValues.text = value;
          if (this.dataSource) {
            this.dataSource.filter = JSON.stringify(this.filterValues);            
          }
        }
      }
      );

      this.applicationFilterSubscription = this.applicationFilter.valueChanges
      .subscribe({
          next: selectedAppln => {            
            this.filterValues.appId = selectedAppln;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);              
            }
          }
        }
      );
      this.vpubFilterSubscription = this.vpubFilter.valueChanges
      .subscribe({
          next: selectedVpub => {            
            this.filterValues.vpubId = selectedVpub;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );

      this.moduleFilterSubscription = this.moduleFilter.valueChanges
      .subscribe({
          next: status => {
            this.filterValues.moduleId = status;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
  }


 /*
  * makes backend call to fetch the applications
  */
 private fetchApplications(orgId: number, acctId: number): void {

    this.applnService.getApplications(orgId, acctId).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxApplication[]) => {
          // set application variable
          if (data) {
            this.applications = data;
          }
        }
      });
}

/*
  * makes backend call to fetch the vpubs for the selected application
  */
private fetchVpubs(orgId: number, acctId: number, applnId: number): void {
  this.logger.debug(`fetching vpubs ${applnId}`);
  this.vpubService.getVpubs(orgId, acctId, applnId).pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data: AdxVPub[]) => {
        // set application variable
        if (data) {
          this.vpubs = data;
        }
      }
    });
}

/*
* makes backend call to fetch the modules for the selected vpub
*/
private fetchModules(orgId: number, acctId: number, vpubId: number): void {
  this.logger.debug(`fetching modules ${vpubId}`);
  this.moduleService.getModules(orgId, acctId, vpubId).pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data: AdxModule[]) => {
        // set application variable
        if (data) { //data will contain all the modules filter it to accessible module list
          this.modules = this.filterAccesibleModule(data);
        }
      }
    });
}


  onApplicationChange(appId: number | null, event: any): void {
    this.logger.debug(`onApplicationChange : ${appId}`);    
     // //reset other fields
     this.imageLibraryFilterForm.get('vpubFilter')?.reset();
     this.vpubs = [];
     this.modules = [];
     this.filterValues.vpubId ='';//clear the vpub and module selection on clearing the application
     this.filterValues.moduleId ='';

    // A selection change event is fired not only when an option is selected but also when it is deselected.
    if (event.isUserInput === false) {
      return; //only process user inputs/option selected
    }
    if (appId) {
        this.fetchVpubs(this.orgId, this.accountId, appId);
    }
  }  

  onVpubChange(vpubId: number | null, event: any): void {
    this.logger.debug(`onVpubChange : ${vpubId}`);

    //reset other fields
    this.modules = [];
    this.filterValues.moduleId =''; //clear the module selection on clearing the vpub

    // A selection change event is fired not only when an option is selected but also when it is deselected.
    // So when an option is selected, the event fires on it, and also on any option that is deselected.
    if (event.isUserInput === false) {
      return; //only process user inputs/option selected
    }
    if(vpubId){
      this.fetchModules(this.orgId, this.accountId, vpubId);
      
    }
  }

  //Method to filter the list of modules to accessible modules.
  filterAccesibleModule(modules : AdxModule[]) : AdxModule[]{
    let filteredModules : AdxModule[] = [];

    for(let mod of modules){
      if(mod.type == 'GROUP' && mod.children.length > 0){//if tht module type is group then display all its child module.
        filteredModules.push(...this.filterAccesibleModule(mod.children));
      }else{
        this.canAccessModule(mod.id) ? filteredModules.push(mod) : '';
      }
    }
    return filteredModules;
  }

  //method which returns boolean value based on the user permission.
  //If user has access to the module then returns true otherwise false.
  canAccessModule(moduleId: number | null): boolean {
    if ( this.orgId !== undefined && this.orgId !== null
      && this.accountId !== undefined && this.accountId !== null
      && this.filterValues.appId !== undefined && this.filterValues.appId !== null && this.filterValues.appId !== ''
      && this.filterValues.vpubId !== undefined && this.filterValues.vpubId !== null && this.filterValues.vpubId !== ''
      && moduleId !== undefined && moduleId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserViewModule(
        this.orgId, this.accountId, this.filterValues.appId, this.filterValues.vpubId.id, moduleId, this.currentUser);
    }
    return false;
  }
}
