import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxUserRole } from './adx-user-role-model';

/**
 * Model representing the user. Users are associated with UserRoles
 */
export class AdxUser extends AdxResource {
  username: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  middleName: string | null = null;
  primaryEmail: string | null = null;
  mobileNumber: string | null = null;
  lastLogin: string | null = null;
  systemRoles: AdxUserRole[] | null = null;
  organizationRoles: AdxUserRole[] | null = null;
  accountRoles: AdxUserRole[] | null = null;
  applicationRoles: AdxUserRole[] | null = null;
  channelRoles: AdxUserRole[] | null = null;
  vpubRoles: AdxUserRole[] | null = null;
  moduleRoles: AdxUserRole[] | null = null;
  organizationId : number | null = null;//adding additional info for adding new user
  accountId :  number | null = null;
  secondaryEmail : string | null = null;
  primaryNumber : string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxUser {
    const toRet: AdxUser = new AdxUser(null);
    if (!json) {
      return toRet;
    }

    if (json.id !== undefined && json.id !== null) {
      toRet.id = json.id;
    }

    if (json.username !== undefined && json.username !== null) {
      toRet.username = json.username;
    }

    if (json.firstName !== undefined && json.firstName !== null) {
      toRet.firstName = json.firstName;
    }

    if (json.lastName !== undefined && json.lastName !== null) {
      toRet.lastName = json.lastName;
    }

    if (json.middleName !== undefined && json.middleName !== null) {
      toRet.middleName = json.middleName;
    }

    if (json.primaryEmail !== undefined && json.primaryEmail !== null) {
      toRet.primaryEmail = json.primaryEmail;
    }

    if (json.mobileNumber !== undefined && json.mobileNumber !== null) {
      toRet.mobileNumber = json.mobileNumber;
    }

    if (json.lastLogin !== undefined && json.lastLogin !== null) {
      toRet.lastLogin = json.lastLogin;
    }

    if (json.primaryNumber !== undefined && json.primaryNumber !== null) {
      toRet.primaryNumber = json.primaryNumber;
    }

    if (json.secondaryEmail !== undefined && json.secondaryEmail !== null) {
      toRet.secondaryEmail = json.secondaryEmail;
    }

    if (json.systemRoles !== undefined && json.systemRoles !== null) {
      let tempObj: AdxUserRole;
      json.systemRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.systemRoles) {
            toRet.systemRoles = [];
          }
          toRet.systemRoles.push(tempObj);
        }
      });
    }

    if (json.organizationRoles !== undefined && json.organizationRoles !== null) {
      let tempObj: AdxUserRole;
      json.organizationRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.organizationRoles) {
            toRet.organizationRoles = [];
          }
          toRet.organizationRoles.push(tempObj);
        }
      });
    }

    if (json.accountRoles !== undefined && json.accountRoles !== null) {
      let tempObj: AdxUserRole;
      json.accountRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.accountRoles) {
            toRet.accountRoles = [];
          }
          toRet.accountRoles.push(tempObj);
        }
      });
    }

    if (json.applicationRoles !== undefined && json.applicationRoles !== null) {
      let tempObj: AdxUserRole;
      json.applicationRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.applicationRoles) {
            toRet.applicationRoles = [];
          }
          toRet.applicationRoles.push(tempObj);
        }
      });
    }

    if (json.channelRoles !== undefined && json.channelRoles !== null) {
      let tempObj: AdxUserRole;
      json.channelRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.channelRoles) {
            toRet.channelRoles = [];
          }
          toRet.channelRoles.push(tempObj);
        }
      });
    }

    if (json.vpubRoles !== undefined && json.vpubRoles !== null) {
      let tempObj: AdxUserRole;
      json.vpubRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.vpubRoles) {
            toRet.vpubRoles = [];
          }
          toRet.vpubRoles.push(tempObj);
        }
      });
    }

    if (json.moduleRoles !== undefined && json.moduleRoles !== null) {
      let tempObj: AdxUserRole;
      json.moduleRoles.forEach((role: any) => {
        tempObj = (AdxUserRole.buildObject(role));
        if (tempObj) {
          if (!toRet.moduleRoles) {
            toRet.moduleRoles = [];
          }
          toRet.moduleRoles.push(tempObj);
        }
      });
    }

    return toRet;
  }
}
