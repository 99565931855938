<div class="edit-module-group-container" *ngIf="canEditModule()">
  <form [formGroup]="editModuleForm">

    <div class="edit-module-class add-account">
      <div fxLayout="row">
        <div fxFlex="10" class="input-label associateCategories">
          <div class="vpubName-label">
            <mat-label i18n="@@editModule.iconUpload">Icon Upload</mat-label>
            <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                                 (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId" [applnId] ="applnId" [vpubId] ="vpubId" [moduleId]="moduleId"> </app-adx-image-input>
          </div>
        </div>
        <div fxFlex="90" class="common-input-head">
          <div class="moduleGroup-input">
            <mat-form-field class="input-form">
              <input matInput i18n="@@editModule.title" id="input-editModuleGroup-title" #moduleGroupTitle maxlength="100"
                placeholder="Title" matTooltip="Enter between 3 to 100 chars" formControlName="title" />
              <mat-hint align="end">{{moduleGroupTitle.value.length}} / 100</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="edit-module-field-common">
        <mat-form-field class="input-form">
          <input matInput i18n="@@editModule.navbarTitle" id="input-editModuleGroup-navbarTitle"
                 #moduleNavbarTitle maxlength="100" placeholder="Navigation-bar Title" formControlName="navbarTitle"/>
          <mat-hint align="end">{{moduleNavbarTitle.value.length}} / 100</mat-hint>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" class="edit-module-field-common">
        <div class="input-field">
          <mat-form-field class="input-form">
            <mat-label i18n="@@editModule.sort-order">Sort Order</mat-label>
            <input type='number' matInput i18n="@@editModule.sort-order" id="input-editModuleGroup-sortOrder"
                   min="0" placeholder="Sort Order" formControlName="sortOrder" required/>
          </mat-form-field>
        </div>
        <div class="input-visibility">
          <mat-form-field class="input-form">
            <mat-label i18n="@@editModule.visibility">Visibility</mat-label>
            <mat-select formControlName="visibility" id="input-editModuleGroup-visibility">
              <mat-option *ngFor="let type of visibilityTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="edit-module-field-common">
        <div>
            <ng-container *ngIf="isModuleGroup">
            <dual-list [source]="modules" [destination]="childModules" id="input-editModuleGroup-modules"
                       [key]="key" [display]="display" (destinationChange)="childrenChanged()">
            </dual-list>
            </ng-container>
          <mat-error *ngIf="childSelectionError">Minimum of one child module should be present</mat-error>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="edit-module-field-common">
        <mat-form-field class="common-desc-editor" fxLayout="column">
          <mat-label i18n="@@editModule.desc">Description<span class="required-asterisk">*</span></mat-label>
          <textarea placeholder='Description' id="input-editModuleGroup-description" formControlName="desc"
                    matInput [errorStateMatcher]="adxErrorStateMatcher" #moduleDescription
                    [froalaEditor]="froalaEditorOptions(450)" (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
          <mat-hint align="end">{{moduleDescription.value.length}} / 450</mat-hint>
          <mat-error *ngIf="editModuleForm.get('desc')?.hasError('maxlength')">
            {{moduleDescription.value.length}} exceeds max limit of 450 HTML characters
          </mat-error>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>
    </div>

    <div class="button-container">
      <button i18n="@@editModule.cancel" id="input-editModuleGroup-cancel-Btn" mat-raised-button routerLink="{{moduleListUrl}}"  class="button-cancel">Cancel</button>
      <button i18n="@@editModule.save" id="input-editModuleGroup-save-Btn" mat-raised-button [disabled]="!(editModuleForm.valid && editModuleForm.dirty)" type="submit" (click)="saveModule()">Save</button>
    </div>
  </form>
</div>
