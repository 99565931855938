<div class="editOrgFormGroup" *ngIf="canUserEditThisOrg()">
            <form class="edit-org-form" [formGroup]="editOrgForm" autocomplete="off">
                <div class="flex-container edit-org" fxLayout="column">
                  <div fxLayout="row">
                    <div fxFlex="10" class="container-label">
                        <div class="orgName-label">
                            <h4 i18n="@@editOrgForm.orgImageUpload">Image Upload</h4>
                            <app-adx-image-input [libraryType]="imgLibType" id="input-edit-organization-icon" [typeId]="typeId"
                                            formControlName="orgIcon"
                                            (imageSelect)="onIconSelected($event)"></app-adx-image-input>
                        </div>
                    </div>
                    <div fxFlex="80" class="common-input-head">
                        <div class="orgName-input">
                            <mat-form-field class="input-form">
                                <input matInput i18n="@@editOrgForm.orgName" id="input-edit-organization-name" #orgName maxlength="150" placeholder="Organization Name" formControlName="orgName" />
                                <mat-error>
                                    This field is required
                                  </mat-error>
                              </mat-form-field>
                              <mat-hint class="hintend">{{orgName.value.length}} / 150</mat-hint>
                        </div>
                    </div>
                    <div fxFlex="10">
                    </div>
                  </div>

                    <mat-divider></mat-divider>

                          <mat-form-field fxLayout="column" class="common-desc-editor">
                            <h4 i18n="@@editOrgForm.aboutScreen">About Us</h4>
                            <textarea [froalaEditor]="options" formControlName="orgAbout" #orgAbout
                                      matInput [errorStateMatcher]="adxErrorStateMatcher"></textarea>
                            <mat-hint align="end">{{orgAbout.value.length}} / 10000</mat-hint>
                            <mat-error *ngIf="editOrgForm.get('orgAbout')?.hasError('maxlength')">
                            {{orgAbout.value.length}} exceeds max limit of 10000 HTML characters
                            </mat-error>
                          </mat-form-field>
                  <mat-divider></mat-divider>
                          <mat-form-field class="common-desc-editor">
                            <h4 i18n="@@editOrgForm.helpScreen">Help Screen</h4>
                            <textarea [froalaEditor]="options" formControlName="orgHelp" #orgHelp
                                      matInput [errorStateMatcher]="adxErrorStateMatcher" required></textarea>
                            <mat-hint align="end">{{orgHelp.value.length}} / 10000</mat-hint>
                            <mat-error *ngIf="editOrgForm.get('orgHelp')?.hasError('maxlength')">
                                {{orgHelp.value.length}} exceeds max limit of 10000 HTML characters
                            </mat-error>
                          </mat-form-field>

                    <mat-divider></mat-divider>

                  <!-- Verify Consumer Signup Credentials -->
                  <div class="verify-cred-class">
                    <div appearance="outline" fxFlex="100" class="pr-4">
                      <mat-slide-toggle i18n="@@editOrgForm.verifyCredentials" labelPosition="before"
                                        id="input-edit-organization-verifyCredentials"
                                        formControlName="verifySignupCredentials">Verify Consumer Signup Credentials
                      </mat-slide-toggle>
                    </div>
                  </div>
                  <mat-divider></mat-divider>

                  <div class="domain_details_container" formGroupName="orgEmailDomain">
                    <div>
                      <h4 i18n="@@editOrgForm.emailDomain">Email Domains</h4>
                    </div>
                    <div class="domain_details">
                        <div class="domain_restrict">
                          <mat-form-field class="">
                            <mat-label i18n="@@editOrgForm.domain">Select Domain Restriction</mat-label>
                            <mat-select formControlName="emailDomainOption" placeholder='Domains' id="input-edit-org-domain">
                              <mat-option value="ALLOW_ALL">Allow all domains</mat-option>
                              <mat-option value="ALLOW">Allow only these domains</mat-option>
                              <mat-option value="RESTRICT">Restrict these domains</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="domain_list">
                          <mat-form-field class="domain_details_field">
                                <mat-chip-list #domainTags formControlName="emailDomainList">
                                  <mat-chip *ngFor="let tag of domainTagList" [selectable]="tagSelectable" [removable]="tagRemovable" (removed)="removeTag(tag)">
                                    {{tag}}
                                    <mat-icon matChipRemove *ngIf="tagRemovable">cancel</mat-icon>
                                  </mat-chip>
                                  <input matInput i18n="@@editOrgForm.tags" id="domain-tags" formControlName='emailDomains' placeholder="Enter Domains" [matChipInputFor]="domainTags"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="tagAddOnBlur" (matChipInputTokenEnd)="addTag($event)">
                                   <mat-error *ngIf="domainTagList.join('').length > 250"> Please enter max of 250 characters</mat-error>
                                    <mat-error *ngIf="!isValidDomain">  Please enter valid domain.(example.com) </mat-error>
                                </mat-chip-list>
                            </mat-form-field>
                        </div>
                    </div>
                  </div>

              <mat-divider></mat-divider>

                        <div class="details_group" formGroupName="orgEmail">
                          <div>
                            <h4 i18n="@@editOrgForm.emailDetails">Email Details</h4>
                          </div>
                          <div class="details_sec">
                            <mat-form-field class="details_field">
                                <input matInput placeholder="SMTP Host" id="input-edit-organization-smtpHost" formControlName="smtpHost" autocomplete="off"/>
                            </mat-form-field>

                            <mat-form-field class="details_field">
                                <input matInput type="number" placeholder="SMTP Port" id="input-edit-organization-smtpPort" formControlName="smtpPort" autocomplete="off"/>
                            </mat-form-field>

                            <div class="details_field"></div>
                          </div>
                          <div class="details_sec">
                            <mat-form-field class="details_field">
                                <input matInput placeholder="SMTP Username" id="input-edit-organization-smtpUsername" formControlName="smtpUsername" name="smtpUsername" autocomplete="nope"/>
                            </mat-form-field>

                            <mat-form-field class="details_field">
                                <input matInput placeholder="SMTP Password" id="input-edit-organization-smtpPsswd" formControlName="smtpPsswd" name="smtpPsswd" type="password" autocomplete="nope"/>
                            </mat-form-field>

                            <mat-form-field class="details_field">
                                <input matInput placeholder="Email Address" id="input-edit-organization-smtpEmail" formControlName="smtpEmail" autocomplete="off"/>
                            </mat-form-field>
                          </div>
                        </div>

                    <mat-divider></mat-divider>

                    <div class="details_group" formGroupName="orgPush">
                      <div>
                        <h4 i18n="@@editOrgForm.pushDetails">PUSH Notification Details</h4>
                      </div>
                      <div class="details_sec">
                          <mat-form-field class="details_field">
                              <input matInput placeholder="PUSH API Key" id="input-edit-organization-pushApiKey" formControlName="pushApiKey" autocomplete="off"/>
                          </mat-form-field>

                          <mat-form-field class="details_field">
                              <input matInput placeholder="PUSH Auth Domain" id="input-edit-organization-pushAuthDomain" formControlName="pushAuthDomain" autocomplete="off"/>
                          </mat-form-field>

                          <mat-form-field class="details_field">
                            <input matInput placeholder="PUSH APP Id" id="input-edit-organization-pushAppId" formControlName="pushAppId" autocomplete="off"/>
                          </mat-form-field>

                      </div>
                      <div class="details_sec">

                          <mat-form-field class="details_field">
                            <input matInput placeholder="PUSH Project Name" id="input-edit-organization-pushProjName" formControlName="pushProjName" autocomplete="off"/>
                          </mat-form-field>

                          <mat-form-field class="details_field">
                              <input matInput placeholder="PUSH Project Id" id="input-edit-organization-pushProjId" formControlName="pushProjId" autocomplete="nope"/>
                          </mat-form-field>

                          <mat-form-field class="details_field">
                              <input matInput placeholder="PUSH Messaging Sender Id" id="input-edit-organization-pushMsgSendId" formControlName="pushMsgSendId" autocomplete="off"/>
                          </mat-form-field>
                      </div>

                      <div class="details_sec">

                        <mat-form-field class="details_field">
                          <input matInput placeholder="PUSH Storage Bucket" id="input-edit-organization-pushStorageBkt" formControlName="pushStorageBkt" autocomplete="nope"/>
                        </mat-form-field>

                        <div style="display: flex; flex-direction: column; align-items: center; min-width: 14rem;">
                          <h5>PUSH Notification Project File</h5>
                          <input id="input-edit-organization-pushProjFile" type="file" style="display: none;" accept="application/json" (change)="onFileChange($event)" #fileUpload>
                          <div class="file-upload">
                              {{uploadedFcmFileName || "No file uploaded yet."}}
                              <button mat-mini-fab color="link" class="upload-btn" (click)="fileUpload.click()"><mat-icon>attach_file</mat-icon></button>
                          </div>
                        </div>

                        <div class="details_field"></div>

                      </div>
                    </div>

              <mat-divider></mat-divider>


                    <div class="edit-style-container" formGroupName="orgStyle">
                        <div class="orgStyle-input">
                            <h4 i18n="@@addOrgForm.style">Style</h4>
                            <table>
                                <tr>
                                    <th class="numOfTabs">
                                        <h4 i18n="@@addOrgForm.numOfTabs">Number Of Tabs *</h4>
                                    </th>
                                    <th class="org-style-numOfTabs">
                                        <mat-form-field class="style-numOfTabs-input">
                                            <input matInput type="number" i18n="@@addOrgForm.numOfTabs"
                                                    id="input-add-organization-styleNumOfTabs"
                                                    formControlName="numOfTabs" min="1" max="4" required>
                                          </mat-form-field>
                                        </th>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td class='commonTitletd'>Top bar Background color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-organization-topBarBackgroundColor" formControlName="topBarBackgroundColor">
                                        </ngx-colors>
                                    </td>
                                    <td rowspan="4" class='common-td simulatorLookNFeel'>
                                        <div class="flex-container" fxLayout="column" fxLayout.lt-md="column">
                                            <div class="simulator-topbar" fxLayout="row" [style.background-color]="topBarBackgroundColor" [style.color]="topBarTextColor">
                                                <div class="simulator-topbar-text" fxFlex="100">
                                                    Top Bar
                                                </div>
                                            </div>
                                            <div class="simulator-contentbar" fxLayout="row">
                                                Conveyer
                                            </div>
                                            <div class="simulator-navbar" fxLayout="column" [style.background-color]="backgroundColor" [style.color]="backgroundTextColor">
                                                <div class="simulator-navbar-icon" fxFlex="100">
                                                    <img class="iconImageSimulator" src="assets/images/app-icon.png" alt="image"/>
                                                </div>
                                                <div class="simulator-navbar-text" fxFlex="100">
                                                    Navigation
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Top bar Text color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-organization-topBarTextColor" formControlName="topBarTextColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Navigation bar Background color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-organization-styleBackgroundColor" formControlName="backgroundColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Navigation bar Text color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-edit-organization-styleBackgroundTextColor" formControlName="backgroundTextColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                            <div class="common-address-editor" formGroupName='orgAddress'>
                                <h4 i18n="@@editOrgForm.address">Address</h4>
                                <mat-form-field class="street1">
                                    <input matInput i18n="@@editOrgForm.street1" id="input-edit-organization-street1" #street1 maxlength="80" formControlName="street1" placeholder="Street1">
                                    <mat-hint align="end">{{street1.value.length}} / 80</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="street2">
                                    <input matInput i18n="@@editOrgForm.street2" id="input-edit-organization-street2" #street2 maxlength="80" formControlName="street2" placeholder="Street2">
                                    <mat-hint align="end">{{street2.value.length}} / 80</mat-hint>
                                </mat-form-field>
                                <div fxLayout="column" class=" city-state-details">
                                    <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                                        <mat-form-field class="city">
                                            <input matInput i18n="@@editOrgForm.city" id="input-edit-organization-city" #city maxlength="50" formControlName="city" placeholder="City">
                                            <mat-hint align="end">{{city.value.length}} / 50</mat-hint>
                                        </mat-form-field>
                                        <mat-form-field class="state">
                                            <input matInput i18n="@@editOrgForm.state" id="input-edit-organization-state" #state maxlength="50" formControlName="state" placeholder="State">
                                            <mat-hint align="end">{{state.value.length}} / 50</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayoutAlign="space-between center zipcode-country-details" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                                        <mat-form-field class="zipcode">
                                            <input matInput i18n="@@editOrgForm.zipcode" id="input-edit-organization-zipcode" #zipcode maxlength="6" formControlName="zipcode" placeholder="Zipcode">
                                            <mat-hint align="end">{{zipcode.value.length}} / 6</mat-hint>
                                        </mat-form-field>
                                        <mat-form-field class="country">
                                            <mat-select formControlName="country" placeholder='Country' id="input-edit-organization-country">
                                                <mat-option *ngFor="let cnt of countries" id="input-edit-organization-country-{{cnt.id}}" [value]="cnt.id">{{cnt.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="selectTimezone">
                                            <mat-select formControlName="orgTimezone" placeholder='Timezone' id="input-edit-organization-timezones">
                                                <mat-option *ngFor="let tz of timezones" id="input-edit-organization-timezones-{{tz.id}}" [value]="tz.id">
                                                    {{tz.abbreviation}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>

                    <mat-divider></mat-divider>
                  <div class="edit-style-container">
                    <app-adx-locale-input [locales]="locales" (defaultUpdate)="onDefaultLocaleChange($event)"
                                          (selectionUpdate)="onSelectedLocaleUpdate($event)" formControlName="orgLocale">
                    </app-adx-locale-input>
                  </div>

                  <mat-divider></mat-divider>

                  <mat-form-field class="common-desc-editor">
                    <h4 i18n="@@editOrgForm.description">Description</h4>
                    <textarea [froalaEditor]="options" formControlName="orgDescription" #orgDescription
                              matInput [errorStateMatcher]="adxErrorStateMatcher"></textarea>
                    <mat-hint align="end">{{orgDescription.value.length}} / 450</mat-hint>
                    <mat-error *ngIf="editOrgForm.get('orgDescription')?.hasError('maxlength')">
                        {{orgDescription.value.length}} exceeds max limit of 450 HTML characters
                    </mat-error>
                  </mat-form-field>
                  <mat-divider></mat-divider>
                  <div>
                    <h4 i18n="@@editOrgForm.excludeModule">Include / Exclude Modules for this Organization</h4>
                    <dual-list [sort]="keepSorted" [source]="allModules" [format]="format"
                               (destinationChange)="onModuleExclude($event)"
                               [(destination)]="excludedModules" height="180px">
                    </dual-list>
                  </div>
                </div>

                <div class="button-container">
                    <button mat-raised-button i18n="@@editOrgForm.cancel" type="button" class="button-cancel" routerLink="/orgs" id="input-edit-organization-cancel-Btn">Cancel</button>
                    <button mat-raised-button i18n="@@editOrgForm.save" type="button"
                            [disabled]="!(editOrgForm.valid && (editOrgForm.dirty || this.excludeModuleDualBoxChanged))"
                            id="input-edit-organization-save-Btn" (click)="update()">Save</button>
                </div>
            </form>
</div>
