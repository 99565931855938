import { Component, OnDestroy, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {Organization} from '../../organization/model/organization.model';
import {Account} from '../../account/model/account.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxAccessType} from '../../common/model/access/adx-access-type';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxChannel} from '../model/adx-channel.model';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {AdxChannelService} from '../service/adx-channel.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {CommonUtility} from '../../common/utils/common-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

@Component({
  selector: 'app-add-channel',
  templateUrl: './add-channel.component.html',
  styleUrls: ['./add-channel.component.scss']
})
export class AddChannelComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg?: Organization;
  selectedAccount?: Account;
  defaultLocale: AdxLocale | null = null;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.ACCOUNT_IMG_LIB;
  typeId: number | null = null;
  visibilityTypes: string[] = [AdxVisibilityType.DISABLED.toString(),
    AdxVisibilityType.VISIBLE.toString(), AdxVisibilityType.HIDDEN.toString()];
  accessTypes: string[] = [AdxAccessType.REQUIRE_APPROVAL.toString(), AdxAccessType.UNLOCKED.toString()];

  addChannelForm: UntypedFormGroup;
  channelListUrl: string | null = null;

  private authSubscription: Subscription | undefined;

  constructor(private channelService: AdxChannelService,
              private readonly messageNotifier: CustomNotifierService,
              private authNotifier: AuthNotifierService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.addChannelForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(150)
        ]}),
      icon: new UntypedFormControl(),
      desc: new UntypedFormControl(null, {updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(450)
        ]}),
      summary: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          Validators.maxLength(150)
        ]}),
      sortOrder: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          Validators.min(0)
        ]}),
      visibility: new UntypedFormControl(null, {updateOn: 'blur', validators: [
        Validators.required
      ]})
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.logger.debug('selectedAccount:' + this.selectedAccount);
    this.logger.debug('selectedOrg:' + this.selectedOrg);

    if (this.selectedAccount && this.selectedOrg) {
      this.typeId = this.selectedAccount?.id;
      this.channelListUrl = `/orgs/${this.selectedOrg.id}/accts/${this.selectedAccount?.id}/chnls`;
    }
    this.logger.debug(`channelListUrl: ${(this.channelListUrl)}`);

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('In AdxHamburgerMenuComponent authSubscription');
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: null,
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.ACCOUNT_IMG_LIB,
      editorRef: undefined,
      applicationId : null,
      atomId : null
    };
    return toRet;
  }

  save(): void {
    if (!this.canAddChannel()){
      return; // no need to proceed as current user does not have permission to add channel
    }
    const strTitle = this.addChannelForm.get('title')?.value.trim(); // title is mandatory field
    if (this.selectedAccount && strTitle) {
      const channelToAdd: AdxChannel = new AdxChannel(null, this.selectedAccount?.id, null);
      channelToAdd.title = strTitle;
      channelToAdd.createdByUser = 1;
      if (this.addChannelForm.get('desc')?.dirty) {
        channelToAdd.description = this.addChannelForm.get('desc')?.value;
      }
      if (this.addChannelForm.get('summary')?.dirty) {
        channelToAdd.summary = this.addChannelForm.get('summary')?.value.trim();
      }
      if (this.addChannelForm.get('icon')?.dirty) {
        channelToAdd.iconImage = this.iconImage;
      }
      if (this.addChannelForm.get('sortOrder')?.dirty) {
        channelToAdd.sortOrder = this.addChannelForm.get('sortOrder')?.value;
      }
      else {
        channelToAdd.sortOrder = 1;
      }
      if (this.addChannelForm.get('visibility')?.dirty) {
        channelToAdd.visibility = this.addChannelForm.get('visibility')?.value;
      }
      this.addChannel(channelToAdd);
    }
    else {
      this.logger.error('Title & Account Details not specified!! Unable to add Channel');
    }
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  /*
   * This is used to determine whether loggedin user can add channel
  */
  private canAddChannel(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
       && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
       && this.selectedAccount !== undefined && this.selectedAccount !== null
       && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.channelService.canUserAddChannel(this.selectedOrg.id, this.selectedAccount.id, this.currentUser);
    }
    return false;
  }

  private addChannel(channelToAdd: AdxChannel): void {
    if (!this.canAddChannel()){
      return; // no need to proceed as current user does not have permission to add channel
    }
    if (this.selectedOrg && this.selectedOrg.id
      && this.selectedAccount && this.selectedAccount.id && channelToAdd) {
      this.channelService.addChannel(this.selectedOrg.id, this.selectedAccount.id, channelToAdd)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.channelListUrl) {
              this.router.navigateByUrl(this.channelListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.channelListUrl) {
              this.router.navigateByUrl(this.channelListUrl).then(r => {});
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `${channelToAdd.title} created Successfully.`);
          }
        });
    }
  }
}
