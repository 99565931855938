<table mat-table [dataSource]="dataSource" class="display-data">
  <ng-container matColumnDef="icon">
    <td *matCellDef="let element" class="common-list-td td-img-simulator">
      <img class="sim-icon" [src]="element.iconImage && element.iconImage.preSignedUrl?
                          element.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <td mat-cell *matCellDef="let element" fxFlex="90"  class="row">
      <div fxFlex="90" class="col">
      <div>
      {{ element.title }}
      </div>
      <div>
        {{ element.summary }}
      </div>
      </div>
      <div fxFlex="10" class="col">
        <mat-icon matSuffix>keyboard_arrow_right</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </td>
  </ng-container>


  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
