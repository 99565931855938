import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxTimezone} from '../../common/model/adx-timezone.model';
import {Account} from '../model/account.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxTimezoneService} from '../../common/services/timezone/adx-timezone.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../service/account.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {AdxCountryUtility} from '../../common/utils/adx-country-utility';
import {AdxCountry} from '../../common/model/country/adx-country.model';
import {Address} from '../../common/model/address.model';
import {AdxStyle} from '../../common/model/adx-style.model';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {Subject, Subscription} from 'rxjs';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {Moment} from 'moment';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {CommonUtility} from '../../common/utils/common-utils';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import {OrganizationUtility} from '../../organization/utils/organization-utility';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },

  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class EditAccountComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  editAccountForm: UntypedFormGroup;
  selectedAccount: Account | null = null;

  selectedOrgId: number | null | undefined = null;
  selectedAccountId: number | null | undefined = null;

  timezones: AdxTimezone[] = [];
  countries?: Array<AdxCountry>;
  accountIconImage: AdxImage | null = null;

  searchPlaceholder = '';
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.ACCOUNT_IMG_LIB;
  typeId: number | null = null;

  tagList: string[] = [];
  visible = true;
  tagSelectable = true;
  tagRemovable = true;
  tagAddOnBlur = true;
  searchPlaceholderSubscription: Subscription | undefined;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  keepSorted = true;
  excludedModules: string[] = [];
  includedModules: string[] = [];
  allModules: string[] = [];
  format: any = { add: 'Exclude Module', remove: 'Include Module', all: 'Select All', none: 'Unselect All',
    direction: 'left-to-right', draggable: true, locale: undefined };
  display: any;
  filter = true;
  excludeModuleDualBoxChanged = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  orgId : number | null = null;
  protected readonly options: object = OrganizationUtility.options;

  private authSubscription: Subscription | undefined;

  constructor(private accountService: AccountService,
              private formBuilder: UntypedFormBuilder,
              private readonly authNotifier: AuthNotifierService,
              private readonly messageNotifier: CustomNotifierService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.editAccountForm = this.formBuilder.group({
      accountTitle: [],
      accountIcon: [],
      accountDescription: [null, {
        updateOn: 'change', validators: [
        Validators.maxLength(450)
      ]}],
      summary: [null, {
        updateOn: 'blur', validators: [
          Validators.maxLength(150)
        ]
      }],
      accountName: [null, {
        updateOn: 'blur', validators: [
          Validators.maxLength(50)
        ]
      }],
      accountNumber: [null, {
        updateOn: 'blur', validators: [
          Validators.maxLength(50)
        ]
      }],
      accountNotes: [null, {
        updateOn: 'blur', validators: [
          Validators.maxLength(50)
        ]
      }],
      startDate: [],
      endDate: [],
      websiteUrl: [],
      accountTag: [],
      accountPlaceholder: [],
      accountStyle: this.formBuilder.group({
        numOfTabs: [],
        backgroundColor: [],
        backgroundTextColor: [],
        topBarBackgroundColor: [],
        topBarTextColor: [],
        bodyBackgroundColor: [],
        bannerBackgroundColor: [],
        bannerTextColor: [],
        buttonBackgroundColor: [],
        buttonTextColor: [],
        bodyTextColor: []
      }),
      accountAddress: this.formBuilder.group( {
        street1: [],
        street2: [],
        city: [],
        state: [],
        zipcode: [],
        country: [],
        accountTimezone: [],
      }),
      aboutUs: [null, {
        updateOn: 'change', validators: [
          Validators.maxLength(10000)
        ]
      }],
      helpInfo: [null, {
        updateOn: 'change', validators: [
          Validators.maxLength(10000)
        ]
      }]
    });
  }

  get backgroundColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('backgroundColor')?.value;
  }

  get backgroundTextColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('backgroundTextColor')?.value;
  }

  get topBarBackgroundColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('topBarBackgroundColor')?.value;
  }
  get topBarTextColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('topBarTextColor')?.value;
  }
  get bannerBackgroundColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('bannerBackgroundColor')?.value;
  }
  get bannerTextColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('bannerTextColor')?.value;
  }
  get buttonBackgroundColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('buttonBackgroundColor')?.value;
  }
  get buttonTextColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('buttonTextColor')?.value;
  }

  get bodyBackgroundColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('bodyBackgroundColor')?.value;
  }

  get bodyTextColor(): string {
    return this.editAccountForm?.get('accountStyle')?.get('bodyTextColor')?.value;
  }

  ngOnDestroy(): void {
    if (this.searchPlaceholderSubscription) {
      this.searchPlaceholderSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  ngOnInit(): void {

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    this.countries = AdxCountryUtility.getSupportedCountries();
    this.timezones = this.activatedRoute.snapshot.data.timezones;
    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedAccountId = this.selectedAccount?.id;
    this.logger.debug(this.activatedRoute.parent?.snapshot.params.orgId);
    this.selectedOrgId = this.activatedRoute.parent?.snapshot.params.orgId;
    this.logger.debug(`selectedOrgId: ${this.selectedOrgId} ; selectedAccountId:${this.selectedAccountId}`);
    if (this.selectedAccount) {
      this.typeId = this.selectedAccount.id;
      this.accountIconImage = this.selectedAccount.iconImage;
      this.logger.debug(`image:${this.accountIconImage?.title}`);
    }
    this.logger.debug(`accountId: ${this.typeId}`);
    this.logger.debug(`country: ${this.selectedAccount?.address?.country}`);

    this.editAccountForm.patchValue({
      accountTitle: this.selectedAccount?.title,
      accountIcon: this.selectedAccount?.iconImage,
      accountDescription: this.selectedAccount?.description,
      summary: this.selectedAccount?.summary,
      accountName: this.selectedAccount?.accountName,
      accountNumber: this.selectedAccount?.accountNumber,
      accountNotes: this.selectedAccount?.notes,
      startDate: this.selectedAccount?.startDate,
      endDate: this.selectedAccount?.endDate,
      websiteUrl: this.selectedAccount?.websiteUrl,
      accountTag: this.selectedAccount?.tags,
      accountPlaceholder: [],
      accountStyle: ({
        numOfTabs: this.selectedAccount?.style?.numberOfTabs,
        backgroundColor: this.selectedAccount?.style?.navigationBackgroundColor,
        backgroundTextColor: this.selectedAccount?.style?.navigationTextColor,
        topBarBackgroundColor: this.selectedAccount?.style?.topBarBackgroundColor,
        topBarTextColor: this.selectedAccount?.style?.topBarTextColor,
        bodyBackgroundColor: this.selectedAccount?.style?.bodyBackgroundColor,
        bannerBackgroundColor: this.selectedAccount?.style?.bannerBackgroundColor,
        bannerTextColor: this.selectedAccount?.style?.bannerTextColor,
        buttonBackgroundColor: this.selectedAccount?.style?.buttonBackgroundColor,
        buttonTextColor: this.selectedAccount?.style?.buttonTextColor,
        bodyTextColor: this.selectedAccount?.style?.bodyTextColor
      }),
      accountAddress: ( {
        street1: this.selectedAccount?.address?.street1,
        street2: this.selectedAccount?.address?.street2,
        city: this.selectedAccount?.address?.city,
        state: this.selectedAccount?.address?.state,
        zipcode: this.selectedAccount?.address?.zipCode,
        country: this.selectedAccount?.address?.country,
        accountTimezone: this.selectedAccount?.timeZone?.id,
      }),
      aboutUs: this.selectedAccount?.aboutUsInfo?.text,
      helpInfo: this.selectedAccount?.helpInfo?.text
    });

    // to display user entered test in the readonly search bar
    this.searchPlaceholderSubscription = this.editAccountForm.get('accountPlaceholder')?.valueChanges.subscribe(
        (change) => this.searchPlaceholder = change);

    this.logger.debug(this.selectedAccount);
    this.logger.debug(`this.selectedAccount?.excludedModuleTypes: ${this.selectedAccount?.excludedModuleTypes}`);

    if (this.selectedAccount && this.selectedAccount?.excludedModuleTypes) {
      this.excludedModules = this.selectedAccount?.excludedModuleTypes?.split(',');
      if (!this.excludedModules && this.selectedAccount.excludedModuleTypes
        && this.selectedAccount.excludedModuleTypes?.length > 0) {
        this.excludedModules = [];
        this.excludedModules.push(this.selectedAccount.excludedModuleTypes);
      }
    }
    if (this.selectedAccount) {
      const objAllAvailableModules = this.selectedAccount.allModules;
      this.logger.debug(`objAllAvailableModules: ${objAllAvailableModules}`);
      for (const module of objAllAvailableModules) {
        this.allModules.push(module.type.toString());
      }
      this.logger.debug(`allModules: ${this.allModules}`);
    }
    this.orgId = Number(this.selectedOrgId);
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: null,
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.ACCOUNT_IMG_LIB,
      editorRef: undefined,
      applicationId : null,
      atomId : null
    };
    return toRet;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tagList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tagList.indexOf(tag);

    if (index >= 0) {
      this.tagList.splice(index, 1);
    }
  }

  /**
   * Called when the dual list box is updated
   *
   * @param $event
   */
  onModuleExclude($event: any) {
    this.excludeModuleDualBoxChanged = true;
  }

  editAccount(): void {
    this.logger.debug(this.editAccountForm);

    if (!this.selectedAccount) {
      this.logger.error('No account selected');
      return;
    }
    else if (!this.selectedAccount.id || !this.selectedAccount.title) {
      this.logger.error('No id or title mentioned for selected account');
      return;
    }
    const accountToSave: Account = new Account(this.selectedAccount?.id, this.selectedAccount?.title);

    if (this.editAccountForm.get('accountTitle')?.dirty) {
      accountToSave.title = this.editAccountForm.get('accountTitle')?.value.trim();
    }
    accountToSave.sortOrder = 1;
    accountToSave.createdByUser = 1;
    if (this.editAccountForm.get('accountDescription')?.dirty) {
      accountToSave.description = this.editAccountForm.get('accountDescription')?.value;
    }
    if (this.editAccountForm.get('accountIcon')?.dirty) {
      accountToSave.iconImage = this.accountIconImage;
    }
    if (this.editAccountForm.get('summary')?.dirty) {
      accountToSave.summary = this.editAccountForm.get('summary')?.value.trim();
    }
    if (this.editAccountForm.get('accountName')?.dirty) {
      accountToSave.accountName = this.editAccountForm.get('accountName')?.value.trim();
    }
    if (this.editAccountForm.get('accountNumber')?.dirty) {
      accountToSave.accountNumber = this.editAccountForm.get('accountNumber')?.value.trim();
    }
    if (this.editAccountForm.get('accountNotes')?.dirty) {
      accountToSave.notes = this.editAccountForm.get('accountNotes')?.value.trim();
    }
    if (this.editAccountForm.get('startDate')?.dirty) {
      const startDateInput: Moment = this.editAccountForm.get('startDate')?.value;
      if (startDateInput) {
        accountToSave.startDate = startDateInput.format('MM/DD/YYYY');
      }
      else {
        // if blank, then we need to delete the value
        accountToSave.startDate = '';
      }

    }
    if (this.editAccountForm.get('endDate')?.dirty) {
      const endDateInput: Moment = this.editAccountForm.get('endDate')?.value;
      if (endDateInput) {
        accountToSave.endDate = endDateInput.format('MM/DD/YYYY');
      }
      else {
        // if blank, then we need to delete the value
        accountToSave.endDate = '';
      }
    }
    if (this.editAccountForm.get('websiteUrl')?.dirty) {
      accountToSave.websiteUrl = this.editAccountForm.get('websiteUrl')?.value;
    }
    if (this.tagList) {
      accountToSave.tags = this.tagList.join(',');
    }

    if (this.editAccountForm.get('aboutUs')?.dirty) {
      accountToSave.aboutUsInfo = new AdxAboutUsInfo(null, null, null);
      if (this.selectedAccount.aboutUsInfo?.id && this.selectedAccount.aboutUsInfo?.scope === 'ACCOUNT') {
        accountToSave.aboutUsInfo.id = this.selectedAccount.aboutUsInfo?.id;
      }
      accountToSave.aboutUsInfo.text = this.editAccountForm.get('aboutUs')?.value;
      accountToSave.aboutUsInfo.scope = 'ACCOUNT';
    }
    if (this.editAccountForm.get('helpInfo')?.dirty) {
      accountToSave.helpInfo = new AdxHelpInfo(null, null, null);
      if (this.selectedAccount.helpInfo?.id && this.selectedAccount.aboutUsInfo?.scope === 'ACCOUNT') {
        accountToSave.helpInfo.id = this.selectedAccount.helpInfo?.id;
      }
      accountToSave.helpInfo.text = this.editAccountForm.get('helpInfo')?.value;
      accountToSave.helpInfo.scope = 'ACCOUNT';
    }

    // add address
    if (this.editAccountForm.get('accountAddress')?.dirty) { // not same as org address
      const strStreet1: string = this.editAccountForm.get('accountAddress.street1')?.value.trim();
      const strStreet2: string = this.editAccountForm.get('accountAddress.street2')?.value.trim();
      const strCity: string = this.editAccountForm.get('accountAddress.city')?.value.trim();
      const strState: string = this.editAccountForm.get('accountAddress.state')?.value.trim();
      const strZip: string = this.editAccountForm.get('accountAddress.zipcode')?.value;
      const strCountry: string = this.editAccountForm.get('accountAddress.country')?.value;
      const addr = new Address(
        NaN,
        strStreet1,
        strStreet2,
        strCity,
        strState,
        '',
        strCountry,
        strZip,
        'ACCOUNT'
      );

      // TO-DO Set the logged in user here
      addr.createdByUser = 1;
      accountToSave.address = addr;
      accountToSave.timeZoneId = this.editAccountForm.get('accountAddress.timezone')?.value;
      this.logger.debug(`Address Changed: ${accountToSave.timeZoneId}`);
    } else {
      accountToSave.address = this.selectedAccount?.address;
      if (this.selectedAccount && this.selectedAccount?.timeZone) {
        accountToSave.timeZoneId = this.selectedAccount?.timeZone.id;
      }
    }

    // add style
    if (this.editAccountForm?.get('accountStyle')?.dirty) { // not same as org style
      const numOfTabs = this.editAccountForm?.get('accountStyle')?.get('numOfTabs')?.value;
      const strBackgroundColor = this.backgroundColor;
      const strBackgroundTextColor = this.backgroundTextColor;
      const strTopBarColor = this.topBarBackgroundColor;
      const strTopBarTextColor = this.topBarTextColor;
      const strBannerColor = this.bannerBackgroundColor;
      const strBannerTextColor = this.bannerTextColor;
      const strButtonColor = this.buttonBackgroundColor;
      const strButtonTextColor = this.buttonTextColor;
      const strBodyColor = this.bodyBackgroundColor;
      const strBodyTextColor = this.bodyTextColor;

      const style: AdxStyle = new AdxStyle(
        NaN,
        numOfTabs,
        strBackgroundColor,
        strBackgroundTextColor,
        ' ',
        strTopBarColor,
        strTopBarTextColor,
        strBodyColor,
        strBodyTextColor,
        strBannerColor,
        strBannerTextColor,
        strButtonColor,
        strButtonTextColor,
        9,
        'ACCOUNT'
      );
      // TO-DO Set the logged in user here
      style.createdByUser = 1;
      accountToSave.style = style;
    } else {
      // is not modified. so add organization style
      accountToSave.style = this.selectedAccount?.style;
    }

    // update excluded modules
    if (this.excludeModuleDualBoxChanged) {
      if (this.excludedModules && this.excludedModules.length > 0){
        this.logger.debug(`this.excludedModules: ${this.excludedModules}`);
        accountToSave.excludedModuleTypes = String(this.excludedModules);
      }
      else {
        this.logger.debug(`ELSE this.excludedModules: ${this.excludedModules}`);
        accountToSave.excludedModuleTypes = '';
      }
    }
    this.logger.debug(accountToSave);
    this.saveAccount(accountToSave);
  }

  onIconSelected(selImage: AdxImage): void {
    this.logger.debug('Image Selected:' + selImage.title);
    this.accountIconImage = selImage;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit account
   *
   * @param acctId
   * @returns boolean: true if user can edit account identified by given id
   */
  canUserEditAccount(): boolean {
    if (this.selectedOrgId !== undefined && this.selectedOrgId !== null
       && this.selectedAccountId !== undefined && this.selectedAccountId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
          //here the selectedOrgId type is string hence the comparision was failing in utils method. using Number() to convert it to number.
          let orgId = Number(this.selectedOrgId);
      return this.accountService.canUserEditAccount(orgId, this.selectedAccountId, this.currentUser);
    }
    return false;
  }

  private saveAccount(accountToSave: Account): void {
    if (this.selectedOrgId && accountToSave) {
      this.accountService
        .updateAccount(this.selectedOrgId, accountToSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            this.logger.debug('Updated account');
            this.logger.debug(data);
            // navigate user to listing screen
            this.router.navigateByUrl(`/orgs/${this.selectedOrgId}/accts`).then(r => {});
          },
          error: () => {
            // navigate user to listing screen
            this.router.navigateByUrl(`/orgs/${this.selectedOrgId}/accts`).then(r => {});
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `${accountToSave.title} updated Successfully.`);
          }
        });
    }
  }
}
