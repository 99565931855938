import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {AdxInsertContentDialogData} from '../../utils/adx-insert-content-dialog-data';

@Component({
  selector: 'app-adx-insert-content-dialog',
  templateUrl: './adx-insert-content-dialog.component.html',
  styleUrls: ['./adx-insert-content-dialog.component.scss']
})
export class AdxInsertContentDialogComponent implements OnInit {

  inputData: AdxInsertContentDialogData;

  constructor(@Inject(MAT_DIALOG_DATA) private data: AdxInsertContentDialogData,
              private dialogRef: MatDialogRef<AdxInsertContentDialogComponent>,
              private logger: NGXLogger) {
    this.logger.debug(data);
    this.inputData = data;
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
