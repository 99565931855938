import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxStyle} from '../../common/model/adx-style.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxAccessType} from '../../common/model/access/adx-access-type';
import {AdxVPub} from '../../vPub/model/adx-vpub.model';
import {AdxAuthor} from './adx-author.model';
import {AdxChannel} from '../../channel/model/adx-channel.model';
import {AdxImage} from '../../common/model/adx-image.model';

export class AdxApplication extends AdxResource {

  passkey: string | null = null;
  title: string | null = null;
  code: string | null = null;
  description: string | null = null;
  summary: string | null = null;
  sortOrder: number | null = null;
  isDraft: boolean | null = null;
  visibility: AdxVisibilityType | null = null;
  accessType: AdxAccessType | null = null;
  style: AdxStyle | null = null;
  approverEmail: string | null = null;
  signinEnabled: boolean | null = null;
  vpubs: AdxVPub[] = [];
  authors: AdxAuthor[] = [];
  channels: AdxChannel[] = [];
  channelIds: number[] = [];
  isCreated: boolean | null = null;

  iconImage: AdxImage | null = null;
  defaultLocale: AdxLocale | null | undefined = null; // used in the listing and edit page
  defaultLocaleId: number | null = null; // used to send data while add

  createdByUser: number | null = null;
  updatedByUser: number | null = null;

  constructor(id: number | null, passkey: string | null) {
    super(id);
    this.passkey = passkey;
  }

  static buildObject(json: any): AdxApplication {
    const toRet: AdxApplication = new AdxApplication(json.id, json.passkey);
    if (json.title) {
      toRet.title = json.title;
    }
    if (json.code) {
      toRet.code = json.code;
    }
    if (json.description) {
      toRet.description = json.description;
    }
    if (json.summary) {
      toRet.summary = json.summary;
    }
    if (json.sortOrder) {
      toRet.sortOrder = json.sortOrder;
    }
    if (json.isDraft !== undefined && json.isDraft !== null) {
      toRet.isDraft = json.isDraft;
    }
    if (json.visibility) {
      toRet.visibility = json.visibility;
    }
    if (json.accessType) {
      toRet.accessType = json.accessType;
    }
    if (json.style) {
      toRet.style = AdxStyle.buildObject(json.style);
    }
    if (json.approverEmail ) {
      toRet.approverEmail = json.approverEmail;
    }
    if (json.signinEnabled !== undefined && json.signinEnabled !== null) {
      toRet.signinEnabled = json.signinEnabled;
    }
    if (json.isCreated !== undefined && json.isCreated !== null) {
      toRet.isCreated = json.isCreated;
    }
    if (json.iconImage) {
      toRet.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.defaultLocale) {
      toRet.defaultLocale = AdxLocale.buildObject(json.defaultLocale);
    }
    if (json.vpubs) {
      let tempVpubObj: AdxVPub;
      json.vpubs.forEach((vpub: any) => {
        tempVpubObj = (AdxVPub.buildObject(vpub));
        if (tempVpubObj) {
          if (!toRet.vpubs) {
            toRet.vpubs = [];
          }
          toRet.vpubs.push(tempVpubObj);
        }
      });
    }
    if (json.authors) {
      let tempObj: AdxAuthor;
      json.authors.forEach((objStr: any) => {
        tempObj = (AdxAuthor.buildObject(objStr));
        if (tempObj) {
          if (!toRet.authors) {
            toRet.authors = [];
          }
          toRet.authors.push(tempObj);
        }
      });
    }
    if (json.channels) {
      let tempObj: AdxChannel;
      json.channels.forEach((objStr: any) => {
        tempObj = (AdxChannel.buildObject(objStr));
        if (tempObj) {
          if (!toRet.channels) {
            toRet.channels = [];
          }
          toRet.channels.push(tempObj);
        }
      });
    }
    if (json.channelIds) {
      let tempObj: number;
      json.channelIds.forEach((objStr: any) => {
        if (!isNaN(+objStr)) {
          tempObj = (+(objStr));
          if (tempObj) {
            if (!toRet.channelIds) {
              toRet.channelIds = [];
            }
            toRet.channelIds.push(tempObj);
          }
        }
      });
    }
    if (json.style) {
      toRet.style = AdxStyle.buildObject(json.style);
    }
    if (json.createdByUser) {
      toRet.createdByUser = json.createdByUser;
    }
    if (json.updatedByUser) {
      toRet.updatedByUser = json.updatedByUser;
    }
    return toRet;
  }
}
