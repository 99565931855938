<div class="add-vpub-container">
  <form class="add-vpub-form" [formGroup]="addVpubForm">
    <div class="flex-container add-vpub" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="10" class="input-label associateCategories">
          <div class="vpubName-label">
            <mat-label i18n="@@addvpubForm.iconUpload">Icon Upload</mat-label>
            <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                                 (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId" [applnId] ="applnId"> </app-adx-image-input>
          </div>
        </div>
        <div fxFlex="90" class="common-input-head">
          <div class="vpubName-input">
            <mat-form-field class="input-form">
              <input matInput i18n="@@addvpubForm.orgName" id="input-add-vpub-title" #vpubName maxlength="150" placeholder="Title"
                     formControlName="title"/>
              <mat-hint align="end">{{vpubName.value.length}} / 150</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="90">
          <mat-form-field class="input-form">
            <mat-label i18n="@@addvpubForm.summary">Summary</mat-label>
            <input type='text' matInput i18n="@@addvpubForm.summary" id="input-add-vpub-summary" #addvpubSummary maxlength="150" placeholder="Summary" formControlName="summary" />
            <mat-hint align="end">{{addvpubSummary.value.length}} / 150</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="common-locale-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column">
          <div *ngIf="availableLocales !== undefined && availableLocales !== null && availableLocales.length !== 0;
           else NO_AVAILABLE_LOCALE">
          <mat-form-field class="common-locale-label">
            <mat-label i18n="@@addApplnForm.sort-order">Select Locale</mat-label>
            <mat-select formControlName="locale" placeholder='Locale' id="input-add-vpub-locale">
              <mat-option *ngFor="let locale of availableLocales" [value]="locale.id">
                {{locale.displayName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <ng-template #NO_AVAILABLE_LOCALE>
            <span style="font-style: italic; color: #99aa00">No Locales Available to create vPub. Contact Admin</span>
          </ng-template>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="container-label" fxFlex="10">
        </div>
        <mat-form-field class="common-desc-editor" fxLayout="column" fxFlex="90">
          <mat-label i18n="@@addvpubForm.desc">Description</mat-label>
          <textarea placeholder='Description' id="input-add-vpub-description" formControlName="desc"
                    [froalaEditor]="froalaEditorOptions(250)" #vpubDescription
                    matInput [errorStateMatcher]="adxErrorStateMatcher"
                    (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
          <mat-hint align="end">{{vpubDescription.value.length}} / 250</mat-hint>
          <mat-error *ngIf="addVpubForm.get('desc')?.hasError('maxlength')">
            {{vpubDescription.value.length}} exceeds max limit of 250 HTML characters
          </mat-error>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div class="button-container">
        <button i18n="@@addvpubForm.cancel" mat-raised-button routerLink="{{vpubListUrl}}" id="input-add-vpub-cancel-Btn" class="button-cancel">Cancel</button>
        <button i18n="@@addvpubForm.save" mat-raised-button [disabled]="!(addVpubForm.valid && addVpubForm.dirty)" id="input-add-vpub-save-Btn" type="submit" (click)="save()">Save</button>
      </div>

    </div>
  </form>
</div>
