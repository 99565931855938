import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxImage} from '../../common/model/adx-image.model';

export class AdxVPub extends AdxResource {
  applicationId: number | null = null;
  locale: AdxLocale | null = null;
  localeId: number | null = null;
  isDefault: boolean | null = null;
  title: string | null = null;
  description: string | null = null;
  summary: string | null = null;
  isDraft: boolean | null = null;
  iconImage: AdxImage | null = null;
  tags: string | null = null;

  createdByUser: number | null = null;
  updatedByUser: number | null = null;

  constructor(id: number | null, applicationId: number | null) {
    super(id);
    this.applicationId = applicationId;
  }

  static buildObject(json: any): AdxVPub {
    const tempVpub = new AdxVPub(json.id, json.applicationId);
    if (json.title) {
      tempVpub.title = json.title;
    }
    if (json.isDraft) {
      tempVpub.isDraft = json.isDraft;
    }
    if (json.isDefault) {
      tempVpub.isDefault = json.isDefault;
    }
    if (json.tags) {
      tempVpub.tags = json.tags;
    }
    if (json.description) {
      tempVpub.description = json.description;
    }
    if (json.summary) {
      tempVpub.summary = json.summary;
    }
    if (json.iconImage) {
      tempVpub.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.locale) {
      tempVpub.locale = AdxLocale.buildObject(json.locale);
    }
    if (json.localeId) {
      tempVpub.localeId = json.localeId;
    }
    if (json.createdByUser) {
      tempVpub.createdByUser = json.createdByUser;
    }
    if (json.updatedByUser) {
      tempVpub.updatedByUser = json.updatedByUser;
    }
    return tempVpub;
  }
}
