import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Account} from '../model/account.model';
import {Subject, Subscription} from 'rxjs';
import {AccountService} from '../service/account.service';
import {takeUntil} from 'rxjs/operators';
import {Organization} from '../../organization/model/organization.model';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {AdxImageLibraryItem} from '../../common/model/image-library/adx-image-library-item.model';
import {UntypedFormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {AdxBaseTemplate} from '../../common/template/adx-base-template';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';
import { OrganizationService } from 'src/app/organization/service/organization.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  displayedColumns: string[] = [
    'icon',
    'accountname',
    'visible',
    'authentication',
    'accountActions'
  ];
  dataSource: MatTableDataSource<Account> | null = null;
  dataFromBackend: Account[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg?: Organization;
  libraryType: AdxImageLibraryType = AdxImageLibraryType.ACCOUNT_IMG_LIB;

  titleFilter = new UntypedFormControl('');
  statusFilter = new UntypedFormControl('');
  signinFilter = new UntypedFormControl('');
  filterValues: any = {
    title: '',
    visibility: '',
    signinEnabled: ''
  };
  hasOrgLevelAccess: boolean = false;
  orgId : number | null = null;
  private authSubscription: Subscription | undefined;
  private dataFetchSubscription?: Subscription;
  private statusFilterSubscription: Subscription | null = null;
  private signinFilterSubscription: Subscription | null = null;
  private titleFilterSubscription: Subscription | null = null;

  constructor(private router: Router, private authNotifier: AuthNotifierService, private orgService: OrganizationService,
              private accountService: AccountService, private activatedRoute: ActivatedRoute) {
    super();
    this.logger.debug('In constructor of AccountListComponent');
  }

  ngOnInit(): void {
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.logger.debug(this.selectedOrg);
    this.orgId = Number(this.selectedOrg?.id); // need this in image library
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    if (this.selectedOrg && this.selectedOrg.id) {
      this.dataFetchSubscription = this.fetchAccounts(this.selectedOrg.id);
    } else {
      this.logger.error('Either selected Object or its id is null');
    }
    this.fieldListener();
  }

  ngOnDestroy(): void {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
    if (this.statusFilterSubscription) {
      this.statusFilterSubscription.unsubscribe();
    }
    if (this.signinFilterSubscription) {
      this.signinFilterSubscription.unsubscribe();
    }
    if (this.titleFilterSubscription) {
      this.titleFilterSubscription.unsubscribe();
    }
    this.authSubscription?.unsubscribe();
  }

  addAccount(): void {
    if (!this.canAddAccount()) {
      return;
    }
    this.router.navigate(['/accts/add']).then(r => {});
  }

  /*
   * Here image library is used to just add the images to library.
   * No use of image selection. Just ignore image selection
   */
  onImageSelect(imgItem: AdxImageLibraryItem): void {
    if (imgItem) {
      this.logger.debug(`In Org Listing; selected Image is ${imgItem.title}`);
    }
  }

  /**
   * Callback that returns true if logged-in user has permission to add new account
   *
   * @returns boolean. true if current user can add new org
   */
  canAddAccount(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
       && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.accountService.canUserAddAccount(this.selectedOrg.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit account
   *
   * @param acctId
   * @returns boolean: true if user can edit account identified by given id
   */
  canEditAccount(acctId: number | null): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
       && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
       && acctId !== undefined && acctId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.accountService.canUserEditAccount(this.selectedOrg.id, acctId, this.currentUser);
    }
    return false;
  }

  private filterAccessibleAccountIds(accountsRetrieved: Account[]) {
    if (accountsRetrieved === undefined || accountsRetrieved === null) {
      return accountsRetrieved;
    }

    if (this.selectedOrg === undefined || this.selectedOrg === null
       || this.selectedOrg.id === undefined || this.selectedOrg.id === null) {
      const temp: Account[] = [];
      return temp;
    }

    if (this.currentUser === undefined || this.currentUser === null) {
      const temp: Account[] = [];
      return temp;
    }

    const userPermissions = this.currentUser?.userPermissions;
    if (userPermissions === undefined || userPermissions === null) {
      const temp: Account[] = [];
      return temp;
    }

    if (AdxAuthUtils.isSystemOrInstanceAdmin(this.currentUser)) {
      return accountsRetrieved; //no filtering as user is system/instance admin
    }

    if (this.orgService.hasUserOrgAdminAccess(this.selectedOrg?.id, this.currentUser)) {
      return accountsRetrieved; //no filtering as user is org admin
    }

    if (this.orgService.hasUserOrgReadOnlyAccess(this.selectedOrg?.id, this.currentUser)) {
      return accountsRetrieved; //no filtering as user has org readonly permission
    }

    const accessbileAccounts = this.accountService.getAccessiblAccountIdsForUser(this.currentUser);

    const toRet: Account[] = []; // if nothing matches, then empty list is returned

    if (accountsRetrieved.length > 0) {
      //verify whether accessibleOrgs suppiled or not
      if (accessbileAccounts !== undefined && accessbileAccounts !== null && accessbileAccounts.length > 0) {
        for (const accountId of accessbileAccounts) {
          const foundAccount = accountsRetrieved.find(org => org.id === accountId);
          //if org is present in accessible list, add it to list to be returned
          if (foundAccount !== undefined && foundAccount !== null) {
            toRet.push(foundAccount);
          }
        }
      }
    }

    return toRet;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.statusFilterSubscription = this.statusFilter.valueChanges
      .subscribe({
          next: status => {
            this.filterValues.visibility = status;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
    this.signinFilterSubscription = this.signinFilter.valueChanges
      .subscribe({
          next: value => {
            this.filterValues.signinEnabled = value;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
    this.titleFilterSubscription = this.titleFilter.valueChanges
      .subscribe({
          next: value => {
            this.filterValues.title = value;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
  }

  /*
   * once data is fetched from backend, set the datasource and filtering function
   */
  private handleAccounts(accounts: Account[]): void {
    if (this.currentUser === undefined || this.currentUser === null) {
      return;
    }
    //filter list based on user role based access
    const hasSystemAccess = AdxAuthUtils.hasSystemAccess(this.currentUser);
    let accessibleAccounts = accounts; //if system access, then user will be displayed all accounts

    //condition to check if the user has access at organization level if so no need to filter the account and user will be displayed all accounts
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null &&
      (AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_ADMIN', this.currentUser) ||
      AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_READ_ONLY', this.currentUser)) ){               
          this.hasOrgLevelAccess = true;
      } 

    if (!hasSystemAccess && !this.hasOrgLevelAccess) {
      //get accessible list from storage and filter the input orgs
      const accessibleAcctIds = this.accountService.getUserAccessibleAccountIds(this.currentUser);
      accessibleAccounts = this.filterAccessibleAccounts(accounts, accessibleAcctIds);
    }
    this.dataSource = new MatTableDataSource(accessibleAccounts);
    this.dataSource.filterPredicate = this.createFilterFunction();
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createFilterFunction(): (account: Account, filter: string) => boolean {
    const filterFunction = (account: Account, filter: string): boolean => {

      const searchTerms = JSON.parse(filter);
      let visibilityValue = '';
      let visibilityFlag = -1;
      if (account && account.visibility) {
        visibilityValue = account.visibility.toLowerCase();
        visibilityFlag = (visibilityValue.indexOf(searchTerms.visibility.toLowerCase()));
      }
      let titleValue = '';
      let titleFlag = -1;
      if (account && account.title) {
        titleValue = account.title.toLowerCase();
        titleFlag = (titleValue.indexOf(searchTerms.title.toLowerCase()));
      }
      let signinValue = false;
      let signinFlag = -1;
      if (account && account.signinEnabled !== undefined && account.signinEnabled !== null) {
        signinValue = account.signinEnabled;
        signinFlag = (signinValue.toString().toLowerCase().indexOf(searchTerms.signinEnabled.toLowerCase()));
      }
      return -1 !== visibilityFlag && -1 !== signinFlag && -1 !== titleFlag;
    };

    return filterFunction;
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchAccounts(orgId: number): Subscription {
    this.logger.debug('fetching accounts for orgId:' + orgId);
    return this.accountService.getAccounts(orgId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: Account[]) => {
          // successfully received the data. so set it in variable
          this.handleAccounts(data);
        }
      });
  }

  /*
   * Utility to filter accounts based o accessibility
  */
  private filterAccessibleAccounts(acctList: Account[], accessibleAccts: number[]): Account[] {
    const toRet: Account[] = []; // if nothing matches, then empty list is returned

    if (acctList !== undefined && acctList !== null && acctList.length > 0) {
      //verify whether accessibleAccts suppiled or not
      if (accessibleAccts !== undefined && accessibleAccts !== null && accessibleAccts.length > 0) {
        for (const acctId of accessibleAccts) {
          const foundAcct = acctList.find(org => org.id === acctId);
          //if account is present in accessible list, add it to list to be returned
          if (foundAcct !== undefined && foundAcct !== null) {
            toRet.push(foundAcct);
          }
        }
      }
    }

    return toRet;
  }

}

