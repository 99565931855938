import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdxChannel} from '../../channel/model/adx-channel.model';
import {AdxApplication} from '../../application/model/adx-application.model';
import {NGXLogger} from 'ngx-logger';
import {UntypedFormArray, FormControl, UntypedFormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {BulkActionService} from '../../core/service/resource/bulk-action.service';
import {LinkChannelApplicationRequest} from '../model/link-channel-application-request';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {Subject} from 'rxjs';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';

@Component({
  selector: 'app-link-channel-app',
  templateUrl: './link-channel-app.component.html',
  styleUrls: ['./link-channel-app.component.scss']
})
export class LinkChannelAppComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  linkAppChannelForm: UntypedFormGroup;
  channels: AdxChannel[] = [];
  applications: AdxApplication[] = [];
  selectedChannelIds: number[] = [];
  selectedApplnIds: number[] = [];

  constructor(private logger: NGXLogger, private bulkService: BulkActionService,
              private readonly messageNotifier: CustomNotifierService,
              public dialogRef: MatDialogRef<LinkChannelAppComponent>,
              @Inject(MAT_DIALOG_DATA) public inputData:
                {orgId: number; acctId: number; channels: AdxChannel[]; applications: AdxApplication[]}) {
    this.linkAppChannelForm = new UntypedFormGroup({
      channels: new UntypedFormArray([]),
      applications: new UntypedFormArray([])
    });
  }

  ngOnInit(): void {
    this.logger.debug('channels:' + this.inputData.channels.length);
    this.channels = this.inputData.channels;
    this.logger.debug('applications:' + this.inputData.applications.length);
    this.applications = this.inputData.applications;
  }

  onApplSelectionChange(applnId: number | null, event: MatCheckboxChange): void {
    if (applnId) {
      this.logger.debug(`event.checked: ${event.checked}`);
      if (event.checked) {
        this.selectedApplnIds.push(applnId);
      } else {
        this.selectedApplnIds.forEach((element, index) => {
          if (element === applnId) { this.selectedApplnIds.splice(index,1); }
        });
      }
      this.logger.debug(this.selectedApplnIds);
    }
  }

  onChannelSelectionChange(channelId: number | null, event: MatCheckboxChange): void {
    if (channelId) {
      this.logger.debug(`event.checked: ${event.checked}`);
      if (event.checked) {
        this.selectedChannelIds.push(channelId);
      } else {
        this.selectedChannelIds.forEach((element, index) => {
          if (element === channelId) { this.selectedChannelIds.splice(index,1); }
        });
      }
      this.logger.debug(this.selectedChannelIds);
    }
  }

  associate(): void {
    this.logger.debug(`selectedChannelIds: ${this.selectedChannelIds} ; selectedApplnIds: ${this.selectedApplnIds}`);
    const requestObj: LinkChannelApplicationRequest = new LinkChannelApplicationRequest(
      this.inputData.orgId, this.inputData.acctId);
    requestObj.channelIds = this.selectedChannelIds;
    requestObj.applicationIds = this.selectedApplnIds;
    this.bulkService.linkChannelApplication(requestObj).pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Associated Successfully.`);
          this.dialogRef.close(true);
        }
      });
  }

  isDisabled(): boolean {
    if (this.selectedChannelIds && this.selectedChannelIds.length> 0 &&
    this.selectedApplnIds && this.selectedApplnIds.length> 0) {
      return false;
    }
    return true;
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
