import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/*
* This is class for address model
*/
export class Address extends AdxResource {
    street1: string;
    street2: string;
    city: string;
    state: string;
    region: string;
    country: string;
    zipCode: string;
    public createdByUser: number | null = null;
    scope: string;

    constructor(id: number, strStreet1: string, strStreet2: string, strCity: string,
                strState: string, strRegion: string, strCountry: string, strZipCode: string, strScope: string){
        super(id);
        this.street1 = strStreet1;
        this.street2 = strStreet2;
        this.city = strCity;
        this.state = strState;
        this.region = strRegion;
        this.country = strCountry;
        this.zipCode = strZipCode;
        this.scope = strScope;
    }

    static buildObject(json: any): Address {
      return new Address(json.id, json.street1, json.street2, json.city, json.state, json.region, json.country, json.zipCode, json.scope);
    }
}
