<div class="insert-module-container" fxLayout="column">
  <div class="row">
    <div fxFlex="100">
    <h4 class="moduleContent">
      <span>Select a module to link to</span>
    </h4>
  </div>
  </div>
    
  <div class="container-fluid moduleList">
    <div class="row">
     <div fxFlex="68">Title</div>
      <div fxFlex="8">Type</div>
      <div fxFlex="25"></div>
    </div>
  </div> 
   
  <div class="container-fluid">
  <div class="row moduleListFluid">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"> 
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node class="moduleListMatTreeNodeToggle" *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <div class="container-fluid">
          <div class="row">
            <div fxFlex="70" class="input-label">
              <p class="moduleListMatTreeNodeTitle" matTooltip="{{node.title}}">{{node.title}}</p>              
            </div>
            <div fxFlex="10" class="input-label">{{node.type}}
            </div>
            <div fxFlex="20">
              <button class="accountDalLink" i18n="@@account-dalLink" (click)="addLink(node.id, node.title)"
                      mat-stroked-button>Add Link</button>
            </div>
          </div>
        </div>
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
     <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node ">
          <div class="container-fluid">
            <div class="row">
              <div fxFlex="70" class="input-label">
                <p class="moduleListMatTreeNodeTitle" matTooltip="{{node.title}}">{{node.title}}</p>
              </div>
              <div fxFlex="10" class="input-label">{{node.type}}</div>
              <div fxFlex="20">
                <button class="accountDalLink" i18n="@@account-dalLink" (click)="addLink(node.id, node.title)"
                        mat-stroked-button>Add Link</button>

              </div>
            </div>
          </div>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
  </div>
  </div>
</div>
