import { Component, OnInit } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ActivatedRoute} from '@angular/router';
import {AdxVPub} from './model/adx-vpub.model';
import {Account} from '../account/model/account.model';
import {CommonUtility} from '../common/utils/common-utils';
import {AdxDisplayLevel} from '../common/model/data-transfer/adx-display-level';

@Component({
  selector: 'app-vpub',
  templateUrl: './vpub.component.html',
  styleUrls: ['./vpub.component.scss']
})
export class VpubComponent implements OnInit {

  orgId: number | null = null;
  applnId: number | null = null;
  accountTitle: string | null = null;
  vpubLevelDisplay = AdxDisplayLevel.VPUB_LEVEL;

  constructor(private logger: NGXLogger,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute);
    this.applnId = CommonUtility.getId('applnId', this.activatedRoute);
    const account: Account = this.activatedRoute.snapshot.data.account;
    this.logger.debug(account);
    this.accountTitle = account.title;
  }

}
