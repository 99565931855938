import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AccountService} from '../service/account.service';
import {catchError} from 'rxjs/operators';
import {Account} from '../model/account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountResolver implements Resolve<Account | null> {

  constructor(private logger: NGXLogger, private accountService: AccountService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Account | null> {
    this.logger.debug('In Account route resolve:' + route.paramMap.keys);
    const orgIdString: string | null | undefined = route.paramMap.get('orgId');
    this.logger.debug(orgIdString);
    const accountIdString: string | null = route.paramMap.get('acctId');
    this.logger.debug(accountIdString);

    if (orgIdString) {
      if (isNaN(+orgIdString)) {
        const message =
          'Something went wrong while fetching the Account. Please try again';
        this.logger.error(`Passed org id is not a number: ${orgIdString}`);
        throwError(message);
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Account. Please try again';
      this.logger.error(`Passed org id is not a number: ${orgIdString}`);
      throwError(message);
      return of(null);
    }

    if (accountIdString) {
      if (isNaN(+accountIdString)) {
        const message =
          'Something went wrong while fetching the Account. Please try again';
        this.logger.error(`Passed account id is not a number: ${accountIdString}`);
        throwError(message);
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Account. Please try again';
      this.logger.error(`Passed account id is not a number: ${accountIdString}`);
      throwError(message);
      return of(null);
    }

    const selectedOrgId: number = +orgIdString;
    const selectedAccountId: number = +accountIdString;
    this.logger.debug(`ids received: ${selectedOrgId}: ${selectedAccountId}`);
    return this.accountService.getAccount(selectedOrgId, selectedAccountId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of(null);
      })
    );
  }
}
