<div
  class="add-manage-html-container"
  style="height: 100%; background-color: #eee"
>
  <div class="flex-container" fxLayout="column">
    <!-- <div class="fixed-bar">
      <mat-toolbar fxLayoutAlign="center center" class="common-vpub-label">
        <span>{{ moduleTitle }}</span>
      </mat-toolbar>
    </div> -->
    <form class="manage-web-form" [formGroup]="manageHtmlForm">
      <div class="title-container">
        <div class="title-html">{{ moduleTitle }}</div>
        <div class="button-container-right">
          <button
            i18n="@@cancel"
            mat-raised-button
            routerLink="{{ moduleListUrl }}"
            class="button-cancel"
          >
            Cancel
          </button>
          <button *ngIf="canUserSaveModule()"
            i18n="@@save"
            mat-raised-button
            [disabled]="!(manageHtmlForm.valid && manageHtmlForm.dirty)"
            type="submit"
            color="primary"
            (click)="save()"
          >
            Save
          </button>
          <div [matTooltip]="'Module already published.'" [matTooltipDisabled]="!isModulePublished">
            <button *ngIf="canUserSaveModule()"
              i18n="@@save"
              class="publish-button"
              mat-raised-button
              [disabled]="manageHtmlForm.dirty || isModulePublished"
              type="submit"
              (click)="publish()"
            >
              Publish
            </button>
          </div>
        </div>
      </div>
      <div
        class="manage-html-content add-account"
        style="padding: 1rem; background-color: #eee; border-radius: 0"
      >
        <mat-form-field>
          <mat-label i18n="@@manageHtml.content">Content</mat-label>
          <textarea
            formControlName="desc"
            [froalaEditor]="froalaEditorOptions(500000)"
            matInput
            [errorStateMatcher]="adxErrorStateMatcher"
            #htmlDescription
            (froalaInit)="initializeFroalaEditor($event)"
          >
          </textarea>
          <mat-hint align="end"
            >{{ htmlDescription.value.length }} / 500k</mat-hint
          >
          <mat-error *ngIf="manageHtmlForm.get('desc')?.hasError('maxlength')">
            {{ htmlDescription.value.length }} exceeds max limit of 500k HTML
            characters
          </mat-error>
        </mat-form-field>
        <mat-divider></mat-divider>
      </div>
    </form>
  </div>
</div>
