import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AdxUser } from '../model/adx-user-model';
import { AdxUserSerializer } from '../serializer/adx-user-serializer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { AdxQueryParams } from 'src/app/core/service/resource/adx-query-params.model';
import { AdxRoleSerializer } from '../serializer/adx-role-serializer';
import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';

/**
 * Service class used by User Management functionality.
 * Interacts with backend using get/put/post REST API calls
 */
@Injectable({
  providedIn: 'root'
})
export class AdxUserService {

  // the baseUrl is read from environment specific file
  private readonly baseUrl = environment.apiUrl;

  private userSerializer: AdxUserSerializer = new AdxUserSerializer();
  private roleSerializer: AdxRoleSerializer = new AdxRoleSerializer();

  constructor(private logger: NGXLogger, private httpClient: HttpClient) { }

  /*
   * This API is used to fetch the User from backend, given the username, from which tab we are calling and org/account id
   * Returns an observable. Throws exception in case of error.
  */
  getUser(userName: string, isFromUserAddTab : boolean,orgId: number | null, accountId: number | null): Observable<AdxUser> {

    let headersObj: HttpHeaders  = new HttpHeaders();
    let url : string;
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    // dont send org/accout id to search user we we call search from user add tab else send org/accntId based on the page from where we are searching.
    if(isFromUserAddTab){ 
      url = `${this.baseUrl}/users/${userName}`;
    }else if(accountId && accountId !== null){ // if account id is not null means the service is called from account level hence passing account id
      url = `${this.baseUrl}/users/${userName}?acctId=${accountId}`;
    }else{      // if account id is null means the service is called from org level hence passing org id
      url = `${this.baseUrl}/users/${userName}?orgId=${orgId}`;
    }

    const endPoint: string = url;
    return this.httpClient
      .get(endPoint, {headers: headersObj})
      .pipe(map((data: any) => this.userSerializer.fromJson(data) as AdxUser));
  }

  /*
   * This API is used to fetch Roles from backend, given the organizationId and pagenumber
   * Returns an observable. Throws exception in case of error.
  */
  getUsers(orgId: number, accountId: number | null, pageNum: number): Observable<{moreRecords: boolean; users: AdxUser[]}> {
    let headersObj: HttpHeaders  = new HttpHeaders();

    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const url = `${this.baseUrl}/users`;
    const queryOptions: AdxQueryParams = new AdxQueryParams();
    if (accountId) {
      queryOptions.addQueryParam('acctId', accountId.toString());
    }
    else {
      queryOptions.addQueryParam('orgId', orgId.toString());
    }
    queryOptions.addQueryParam('page', pageNum.toString());
    queryOptions.addQueryParam('pageSize', '50');

    const endPoint: string = url;
    return this.httpClient
      .get(endPoint, {headers: headersObj, params: queryOptions.getHttpParams()})
      .pipe(map((data: any) => this.convertGetUsersData(data, this.userSerializer)));
  }


 /**
  * This API is used to fetch Roles from backend for given either organization id or accountId
  *
  * @param orgId : id of organization
  * @param accountId : id of account or null if org level roles
  * @returns Returns an observable. Throws exception in case of error.
  */
  getRoles(orgId: number, accountId: number | null): Observable<any> {
    let headersObj: HttpHeaders  = new HttpHeaders();

    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const url = `${this.baseUrl}/roles`;
    const queryOptions: AdxQueryParams = new AdxQueryParams();
    if (accountId !== null) {
      queryOptions.addQueryParam('acctId', accountId.toString());
    }
    else {
      queryOptions.addQueryParam('orgId', orgId.toString());
    }

    const endPoint: string = url;
    return this.httpClient
      .get(endPoint, {headers: headersObj, params: queryOptions.getHttpParams()})
      .pipe(map((data: any) => this.convertData(data, this.roleSerializer)));
  }

  /**
   * API to be used to update user role.
   *
   * @returns Observable<AdxUser>
   */
  public updateUserRole(user: AdxUser): Observable<AdxUser> {

    let headersObj: HttpHeaders  = new HttpHeaders();
    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const url = `${this.baseUrl}/users/${user.id}/roles`;
    const endPoint: string = url;
    return this.httpClient
      .put(endPoint, this.userSerializer.toJson(user), {headers: headersObj})
      .pipe(map(data => this.userSerializer.fromJson(data) as AdxUser));
  }

  /*
   * private utility method to convert json data to array
  */
  private convertData(data: any, serializer: AdxBaseSerializer): [] {
    if (data === undefined || data === null) {
      return [];
    }
    return data.map((item: any) => serializer.fromJson(item));
  }

  /*
   * private utility method to convert json data to array
  */
  private convertGetUsersData(data: any, serializer: AdxBaseSerializer): {moreRecords: boolean; users: AdxUser[]} {
    if (data === undefined || data === null) {
      return {moreRecords: false, users:[]};
    }
    return {moreRecords: data.moreRecords, users: data.users.map((item: any) => serializer.fromJson(item))};
  }

  /**
   * API to be used to add new user to the system at org/account level.
   *
   * @returns Observable<AdxUser>
   */
  public addOrgorAccntUser(user: AdxUser): Observable<AdxUser> {
    let headersObj: HttpHeaders  = new HttpHeaders();
    if (!headersObj || headersObj === null) {
      headersObj = new HttpHeaders();
    }
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');
    
    const url = `${this.baseUrl}/users`;
    const endPoint: string = url;
    return this.httpClient
      .post(endPoint, this.userSerializer.toJson(user), {headers: headersObj})
      .pipe(map(data => this.userSerializer.fromJson(data) as AdxUser));
  }

}
