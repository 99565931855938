import { AdxBaseSerializer } from 'src/app/core/service/resource/adx-base-serializer';
import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxNotification } from '../../model/notification/adx-notification.model';

/**
 * Serializer for AdxNotification model
 */
export class AdxNotificationSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const locale: AdxNotification = AdxNotification.buildObject(json);
    return locale;
  }
  toJson(resource: AdxNotification): string {
    const json = JSON.stringify(resource);
    return json;
  }
}
