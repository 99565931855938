<div class="account-container" fxLayout="column">
    <div class="account-option">
        <div class="button-container">
            <button mat-raised-button i18n="@@acctList.back" type="button" id="input-accountList-back-Btn" routerLink='/orgs'>
              <mat-icon>keyboard_arrow_left</mat-icon> Organizations
            </button>
          </div>
          <div class="button-container">
          <mat-form-field appearance="fill" class="accountListSigninFilter">
            <mat-label i18n="@@authRequired">Sign-in</mat-label>
            <mat-select [formControl]="signinFilter" id="input-accountList-signinFilter">
              <mat-option value="">-- Select a Option --</mat-option>
              <mat-option value="true">Enabled</mat-option>
              <mat-option value="false">Disabled</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="button-container">
          <mat-form-field appearance="fill" class="accountListStatusFilter">
            <mat-label i18n="@@status">Status</mat-label>
            <mat-select [formControl]="statusFilter" id="input-accountList-statusFilter">
              <mat-option value="">-- Select a Option --</mat-option>
              <mat-option value="visible">Visible</mat-option>
              <mat-option value="hidden">Hidden</mat-option>
              <mat-option value="disable">Disable</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="button-container">
            <mat-form-field class="common-search-input">
                <input i18n="@@serachAccount" id="input-accountList-titleFilter" matInput placeholder="Search Accounts" [formControl]="titleFilter">
            </mat-form-field>
            </div>
            <div class="button-container" *ngIf="canAddAccount()">
              <button i18n="@@addAccount" mat-raised-button color="primary" id="input-accountList-addAccount-Btn" routerLink='add' [state]=selectedOrg>Add Account</button>
            </div>
    </div>

    <div class="account-list-table">
        <table mat-table *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table commonOrgAccountAppl">
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef class="common-list-th"></th>
                <td mat-cell *matCellDef="let element" class="common-list-td td-img">
                  <img class="iconImage" [src]="element.iconImage && element.iconImage.preSignedUrl?
                          element.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="accountname">
                <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th">Title</th>
                <td i18n="@@name-val" mat-cell *matCellDef="let element" class="common-list-td" matTooltip="{{element.title}}">
                    {{ element.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="visible">
                <th mat-header-cell *matHeaderCellDef class="common-list-th">Visibility Status</th>
                <td mat-cell *matCellDef="let element" class="common-list-td">
                    <ng-container *ngIf="element.visibility.toLowerCase() ==='visible';  else AccountHidden" mat-cell>
                        <mat-icon matTooltip="Content is visible">visibility</mat-icon>
                    </ng-container>
                    <ng-template #AccountHidden>
                        <ng-container *ngIf="element.visibility.toLowerCase() ==='hidden';  else AccountDisable">
                            <mat-icon matTooltip="Content is hidden, but can be reached using internal hyper-link">visibility_off</mat-icon>
                        </ng-container>
                    </ng-template>
                    <ng-template #AccountDisable>
                        <mat-icon matTooltip="Content is hidden and unreachable">hide_source</mat-icon>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="authentication">
                <th mat-header-cell *matHeaderCellDef class="common-list-th">Authentication Required?</th>
                <td mat-cell *matCellDef="let element" class="common-list-td">
                    <ng-container *ngIf="element.signinEnabled;  else AccountUnlock" mat-cell>
                      <mat-icon matTooltip="User needs to authenticate to view content">check</mat-icon>
                    </ng-container>
                    <ng-template #AccountUnlock>
                        <mat-icon matTooltip="User authentication not needed to view content">close</mat-icon>
                    </ng-template>
                </td>
            </ng-container>

          <ng-container matColumnDef="accountActions">
            <th mat-header-cell *matHeaderCellDef class="common-list-th"></th>
            <td mat-cell *matCellDef="let element" class="common-list-td" (click)="$event.stopPropagation()">
              <div class="orgAccountsApplicationBtns">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="edit/{{element.id}}" i18n="@@account-edit" *ngIf="canEditAccount(element.id)"
                          id="input-accountList-edit-Btn-{{element.id}}">Edit</button>
                  <button mat-menu-item routerLink="{{element.id}}/chnls" i18n="@@acctList.channel"
                          id="input-accountList-channel-Btn-{{element.id}}">Channel</button>
                  <button mat-menu-item i18n="@@accountImageLib" appAdxImageLibrary
                          [id]="element.id" [type]="libraryType" [isSelect]="false" [isIcon]="false"
                          (imgSelectEmitter)="onImageSelect($event)" [orgId]="orgId" [accountId]="element.id">Image Library</button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" routerLink="{{row.id}}/applns"></tr>
        </table>
    </div>

</div>
