<div>
  <h3>Send Notification</h3>
  <form [formGroup]="sendNotificationForm" class="send-notification-container">
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label i18n="@@send-notification.title">Title</mat-label>
        <input matInput formControlName="title" placeholder='Title' #sendNotificationTitle maxlength="100"
          id="input-send-notification-title" required />
      </mat-form-field>
      <mat-hint class="hintend">{{sendNotificationTitle.value.length}} / 100</mat-hint>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label i18n="@@send-notification.content">Content</mat-label>
        <textarea matInput formControlName="content" placeholder='Content' #sendNotificationContent maxlength="100"
          id="input-send-notification-content" required>
        </textarea>
      </mat-form-field>
      <mat-hint class="hintend">{{sendNotificationContent.value.length}} / 100</mat-hint>
    </div>
    <div style="text-align: center;">
      <mat-form-field>
        <mat-label i18n="@@createReportForm.deliveryType">Select Delivery Type</mat-label>
        <mat-select formControlName="deliveryType" id="input-send-notification-deliverType">
          <mat-option *ngFor="let delType of deliveryTypes" [value]="delType.value"
            id="input-createReport-vPub-{{delType}}">
            {{delType.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button mat-raised-button (click)="close()" class="button-cancel"
        id="input-send-notification-Close-Btn">Close</button>
      <button mat-raised-button id="input-send-notification-submit-Btn" [disabled]="disableSendBtn()" color="primary"
        (click)="sendNotification()">Send</button>
    </div>
  </form>
</div>
