<div class="edit-vpub-container" *ngIf="canEditVpub()">
  <form class="edit-vpub-form" [formGroup]="editVpubForm">
    <div class="flex-container edit-vpub" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="10" class="input-label associateCategories">
          <div class="vpubName-label">
            <mat-label i18n="@@editvpubForm.iconUpload">Icon Upload</mat-label>
            <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                                 (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId" [applnId] ="applnId" [vpubId] ="vpubId"> </app-adx-image-input>
          </div>
        </div>
        <div fxFlex="90" class="common-input-head">
          <div class="vpubName-input">
            <mat-form-field class="input-form">
              <input matInput i18n="@@editvpubForm.orgName" id="input-edit-vpub-title" #vpubName maxlength="150" placeholder="Title"
                     formControlName="title"/>
              <mat-hint align="end">{{vpubName.value.length}} / 150</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="input-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="90">
          <mat-form-field class="input-form">
            <input type='text' matInput i18n="@@editvpubForm.summary" id="input-edit-vpub-summary" #editvpubSummary maxlength="150" placeholder="Summary" formControlName="summary" />
            <mat-hint align="end">{{editvpubSummary.value.length}} / 150</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row">
        <div class="common-locale-label" fxFlex="10">
        </div>
        <div class="input-field" fxLayout="column" fxFlex="40">
          <mat-form-field class="common-locale-label">
            <mat-label i18n="@@editApplnForm.sort-order">Select Locale</mat-label>
            <mat-select formControlName="locale" placeholder='Locale' id="input-edit-vpub-locale" [disabled]="true">
              <ng-container *ngIf="selectedVpub && !selectedVpub.isDefault; else defaultVal">
              <mat-option *ngFor="let locale of availableLocales" [value]="locale.id">
                {{locale.displayName}}
              </mat-option>
              </ng-container>
              <ng-template #defaultVal>
                <ng-container *ngIf="selectedVpub && selectedVpub.locale">
                <mat-option [value]="selectedVpub.locale.id">
                  {{selectedVpub.locale.displayName}}
                </mat-option>
                </ng-container>
              </ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="6">
        </div>
        <div class="common-locale-label" fxFlex="4">
          <mat-label i18n="@@editvpubForm.isDraft">is Draft?</mat-label>
        </div>
        <div class="common-locale-label" fxFlex="5">
          <mat-checkbox formControlName="isDraft" id="input-edit-vpub-isDraft" workaroundMatFormFieldAccessor></mat-checkbox>
        </div>
        <div fxFlex="35">
        </div>
      </div>

      <mat-divider></mat-divider>

      <mat-form-field style="padding-left: 10%; padding-right: 1rem">
          <mat-label i18n="@@editvpubForm.desc">Description</mat-label>
          <textarea placeholder='Description' id="input-edit-vpub-description" formControlName="desc"
                    matInput [errorStateMatcher]="adxErrorStateMatcher" #vpubDescription
                    [froalaEditor]="froalaEditorOptions(250)" (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
          <mat-hint align="end">{{vpubDescription.value.length}} / 250</mat-hint>
          <mat-error *ngIf="editVpubForm.get('desc')?.hasError('maxlength')">
            {{vpubDescription.value.length}} exceeds max limit of 250 HTML characters
          </mat-error>
      </mat-form-field>

      <mat-divider></mat-divider>

      <div class="button-container">
        <button i18n="@@editvpubForm.cancel" mat-raised-button routerLink="{{vpubListUrl}}" id="input-edit-vpub-cancel-Btn" class="button-cancel">Cancel</button>
        <button i18n="@@editvpubForm.save" mat-raised-button [disabled]="!(editVpubForm.valid && editVpubForm.dirty)" id="input-edit-vpub-save-Btn" type="submit" (click)="save()">Save</button>
      </div>

    </div>
  </form>
</div>
