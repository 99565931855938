import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountListComponent } from './account-list/account-list.component';
import { AccountComponent } from './account.component';
import { AddAccountComponent } from './add-account/add-account.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import {AdxTimezoneResolver} from '../common/resolvers/adx-timezone-resolver';
import {AdxLocaleResolver} from '../common/resolvers/locale/adx-locale.resolver';
import {AccountResolver} from './resolver/account.resolver';

const accountRoutes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      { path: '', component: AccountListComponent },
      {
        path: 'add',
        component: AddAccountComponent,
        resolve: { timezones: AdxTimezoneResolver, locales: AdxLocaleResolver }
      },
      {
        path: 'edit/:acctId',
        component: EditAccountComponent,
        resolve: { account: AccountResolver, timezones: AdxTimezoneResolver, locales: AdxLocaleResolver }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(accountRoutes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
