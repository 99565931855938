<div class="dialog-header">
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
</div>

<div mat-dialog-content class="dialog-content">
  <div class="files-upload">
    <div class="files-upload__content">
      <div class="dropzone" appAdxDropzone (dropped)="onDrop($event)" (hovered)="toggleHover($event)"
           [class.dropzone_hovered]="isHovering">
        <span class="dropzone__label">Drop files here or</span>
        <div class="dropzone__file">

          <label class="file">
            <input class="file__input" [multiple]="multiple" type="file" [accept]="acceptedTypes"
                   (change)="onChange($event)">
            <span class="file__label">choose</span>
          </label>

        </div>
      </div>
    </div>
    <div class="error" *ngIf="isError">
      {{errorMsg}}
    </div>
  </div>
</div>

  <div mat-dialog-actions class="files-upload__footer">
    <button mat-raised-button [mat-dialog-close]="false">Close</button>
  </div>
