import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 * The model used for content report. It has some parameters used in the create request as well.
 */
export class AdxContentReport extends AdxResource {

  accountId: number | null = null;
  code: string | null = null;
  applicationId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;
  template: string | null = null;
  reportName: string | null = null;
  reportType: string | null = null;
  description: string | null = null;
  executionTime: string | null = null;
  status: string | null = null;
  statusDescription: string | null = null;
  fileName: string | null = null;
  objectKey: string | null = null;
  objectUrl: string | null = null;
  mimeType: string | null = null;
  username: number | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxContentReport {
    if (!json) {
      return new AdxContentReport(null);
    }
    const contentReport = new AdxContentReport(json.id);
    if (json.code) {
      contentReport.code = json.code;
    }
    if (json.accountId) {
      contentReport.accountId = json.accountId;
    }
    if (json.applicationId) {
      contentReport.applicationId = json.applicationId;
    }
    if (json.vpubId) {
      contentReport.vpubId = json.vpubId;
    }
    if (json.moduleId) {
      contentReport.moduleId = json.moduleId;
    }
    if (json.template) {
      contentReport.template = json.template;
    }
    if (json.reportName) {
      contentReport.reportName = json.reportName;
    }
    if (json.reportType) {
      contentReport.reportType = json.reportType;
    }
    if (json.description) {
      contentReport.description = json.description;
    }
    if (json.executionTime) {
      contentReport.executionTime = json.executionTime;
    }
    if (json.status) {
      contentReport.status = json.status;
    }
    if (json.statusDescription) {
      contentReport.statusDescription = json.statusDescription;
    }
    if (json.fileName) {
      contentReport.fileName = json.fileName;
    }
    if (json.objectKey) {
      contentReport.objectKey = json.objectKey;
    }
    if (json.objectUrl) {
      contentReport.objectUrl = json.objectUrl;
    }
    if (json.mimeType) {
      contentReport.mimeType = json.mimeType;
    }
    if (json.username) {
      contentReport.username = json.username;
    }
    return contentReport;
  }
}
