import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticatedUser} from '../../common/model/adx-auth/authenticated-user.model';
import {Subject, Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AuthNotifierService} from '../../auth/service/auth-notifier.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {Router, Event as NavigationEvent, NavigationEnd, ActivatedRoute, ParamMap} from '@angular/router';
import {AdxSettingsMenuItem} from '../model/adx-settings-menuitem.model';
import {filter, map} from 'rxjs/operators';

/**
 * This component displays the settings menu.
 * This component builds the settings menu dynamically based on the route.
 * When menu items are clicked settings options are displayed in popup.
 */
@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  errorMessage: string | null = null;
  isUserAuthenticated = false;
  authenticatedUser: AuthenticatedUser | null = null;
  menuItems: AdxSettingsMenuItem[] = [];

  orgId = -1;
  accountId = -1;

  authSubscription: Subscription | undefined;
  routerSubscription: Subscription | undefined;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private logger: NGXLogger,
    private cd: ChangeDetectorRef,
    private authNotifier: AuthNotifierService,
    private readonly messageNotifier: CustomNotifierService,
    private router: Router, private route: ActivatedRoute) {}

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.logger.debug('In noOnInit of SettingsComponent');
  }

  /*
   * Based on the current route, the setting menu is generated.
   * For e.g. if within an organization then 'Organization Settings' menu is displayed.
   * If within an account, the 'Account Settings' menu is displayed.
   */
  private generateSettingsMenu(url: string, paramMap: ParamMap): void {
    if (!this.authenticatedUser) {
      this.logger.debug('User is not authenticated');
      // if user is not authenticated, do not display settings.
      return;
    }
    this.logger.debug(`settings menu: ${url}`);
    let orgMenuItemToBeAdded = false;
    let orgMenuItemToBeDeleted = false;
    let accountMenuItemToBeAdded = false;
    let accountMenuItemToBeDeleted = false;
    const orgMatchUrl = AdxSettingsMenuItem.ORGANIZATION.match_url;
    const orgIgnoreUrl = orgMatchUrl + 'add';
    const accountMatchUrl = AdxSettingsMenuItem.ACCOUNT.match_url;
    const accountIgnoreUrl = accountMatchUrl + 'add';

    // if no prior menus added
    if (this.menuItems.length === 0) {
      if (url.indexOf(accountMatchUrl) !== -1 && url.indexOf(accountIgnoreUrl) === -1) {
        // url matches account, add account menu item
        accountMenuItemToBeAdded = true;
      }
      if (url.indexOf(orgMatchUrl) !== -1 && url.indexOf(orgIgnoreUrl) === -1) {
        // url matches organization, add organization menu item
        orgMenuItemToBeAdded = true;
      }
    } else {
      // if menu items are already present
      if (url.indexOf(accountMatchUrl) !== -1 && url.indexOf(accountIgnoreUrl) === -1) {
        // url matches account, check whether account menu already displayed
        accountMenuItemToBeAdded = true;
        this.menuItems.forEach(menuItem => {
          if (menuItem.code.indexOf(AdxSettingsMenuItem.ACCOUNT.code) !== -1) {
            accountMenuItemToBeAdded = false;
          }
        });
      } else {
        // url does not match account, remove menu if present
        accountMenuItemToBeDeleted = true;
      }
      if (url.indexOf(orgMatchUrl) !== -1 && url.indexOf(orgIgnoreUrl) === -1) {
        // url matches organization, add organization menu item
        orgMenuItemToBeAdded = true;
        this.menuItems.forEach(menuItem => {
          if (menuItem.code.indexOf(AdxSettingsMenuItem.ORGANIZATION.code) !== -1) {
            orgMenuItemToBeAdded = false;
          }
        });
      } else {
        // url does not match organization, remove menu if present
        orgMenuItemToBeDeleted = true;
      }
    }
    // based on the flag, add/remove menu items
    if (orgMenuItemToBeAdded) {
      this.addOrgMenuItem(url, paramMap);
    } else if (orgMenuItemToBeDeleted) {
      // identify the index of menuItem from the list
      const objIndex = this.menuItems.findIndex(menu => (-1 !== menu.code.indexOf(AdxSettingsMenuItem.ORGANIZATION.code)));
      // remove if index is present
      if (objIndex > -1) {
        this.menuItems.splice(objIndex, 1);
      }
    }
    if (accountMenuItemToBeAdded) {
      this.addAccountMenuItem(url, paramMap);
    } else if (accountMenuItemToBeDeleted) {
      // identify the index of menuItem from the list
      const objIndex = this.menuItems.findIndex(menu => (-1 !== menu.code.indexOf(AdxSettingsMenuItem.ACCOUNT.code)));
      // remove if index is present
      if (objIndex > -1) {
        this.menuItems.splice(objIndex, 1);
      }
    }
  }

  /*
   * Add Account Menu Item
   */
  private addAccountMenuItem(url: string, paramMap: ParamMap): void {
    if (url.indexOf(AdxSettingsMenuItem.ACCOUNT.match_url) !== -1) {
      const strOrgId = paramMap.get('orgId');
      if (strOrgId !== null) {
        this.orgId = +strOrgId;
      }
      const strId = paramMap.get('acctId');
      if (strId !== null) {
        this.accountId = +strId;
      }
      this.menuItems.push(AdxSettingsMenuItem.ACCOUNT);
    }
  }

  /*
   * Add Organization Menu Item
   */
  private addOrgMenuItem(url: string, paramMap: ParamMap): void {
    if (url.indexOf(AdxSettingsMenuItem.ORGANIZATION.match_url) !== -1) {
      const strId = paramMap.get('orgId');
      if (strId !== null) {
        this.orgId = +strId;
      }
      this.menuItems.push(AdxSettingsMenuItem.ORGANIZATION);
    }
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
