import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/**
 * Model representing the Permissions that can be associated with the Role
 */
export class AdxRolePermission extends AdxResource {
  code: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxRolePermission {
    const toRet: AdxRolePermission = new AdxRolePermission(null);
    if (!json) {
      return toRet;
    }

    if (json.id !== undefined && json.id !== null) {
      toRet.id = json.id;
    }

    if (json.code !== undefined && json.code !== null) {
      toRet.code = json.code;
    }

    return toRet;
  }
}
