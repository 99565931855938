import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SpinnerOverlayService} from '../service/spinner-overlay.service';
import {NGXLogger} from 'ngx-logger';

/**
 * Interceptor will display the spinner overlay when the REST API gets called
 * and hides the spinner on completion of REST API call.
 */
@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(private readonly logger: NGXLogger, private readonly spinnerOverlayService: SpinnerOverlayService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.debug('In SpinnerInterceptor');
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
    return next.handle(request).pipe(finalize(() => spinnerSubscription.unsubscribe()));
  }
}
