import {NGXLogger} from 'ngx-logger';
import {AdxInjector} from '../utils/adx-injector';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';
import { AuthenticatedUser } from '../model/adx-auth/authenticated-user.model';

/**
 * This is the base class for all the components.
 * Any code that needs to be executed by all the components needs to be put here.
 */
export abstract class AdxBaseTemplate {

  protected currentUser: AuthenticatedUser | null = null;
  protected readonly logger: NGXLogger;

  private readonly localStorageService: LocalStorageService;

  protected constructor() {
    const injector = AdxInjector.getInjector();

    this.logger = injector.get(NGXLogger); // DI
    this.localStorageService = injector.get(LocalStorageService); // DI

    this.currentUser = this.localStorageService.get('auth_user'); //initialize from localstorage and then keep updated using AuthNotifier
  }
}
