import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../common/material/material.module';
import { NgxColorsModule } from 'ngx-colors';

import { AccountListComponent } from './account-list/account-list.component';
import { AddAccountComponent } from './add-account/add-account.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { AccountComponent } from './account.component';
import { AccountRoutingModule } from './account.routing';
import { CoreModule } from '../core/core.module';
import {AdxControlsModule, AdxImageLibraryModule, AdxLocaleInputModule} from '../shared';
import {OrganizationSettingComponent} from './organization-setting/organization-setting.component';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import { AdxUserManageModule } from '../adx-user-manage/adx-user-manage.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        NgxColorsModule,
        CoreModule,
        AccountRoutingModule,
        AdxLocaleInputModule,
        AdxImageLibraryModule,
        AdxControlsModule,
        AdxUserManageModule,
        FroalaEditorModule,
        AngularDualListBoxModule
    ],
    exports: [],
  providers: [
  ],
  declarations: [
    AccountComponent,
    AccountListComponent,
    AddAccountComponent,
    EditAccountComponent,
    OrganizationSettingComponent
  ],
})
export class AccountModule {}
