import { Component, OnDestroy, OnInit } from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AdxModule} from '../model/adx-module.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdxModuleGroupAddService} from '../service/adx-module-group-add.service';
import {AdxModuleGroupAddRequest} from '../model/adx-module-group-add-request';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {Subject, Subscription} from 'rxjs';
import {AdxModuleService} from '../service/adx-module.service';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {CommonUtility} from '../../common/utils/common-utils';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

@Component({
  selector: 'app-add-adx-module-group',
  templateUrl: './add-adx-module-group.component.html',
  styleUrls: ['./add-adx-module-group.component.scss'],
})
export class AddAdxModuleGroupComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  addModuleGroupForm: UntypedFormGroup;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.MODULE_IMG_LIB;
  typeId: number | null = null;
  modules: AdxModule[] = [];
  selectedModules: AdxModule[] = [];
  key = 'id';
  display = 'title';

  moduleListUrl = '';
  applnId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;
  orgId : number | null = null;

  private authSubscription: Subscription | undefined;

  constructor(private addModuleGroupService: AdxModuleGroupAddService, private readonly authNotifier: AuthNotifierService,
              private moduleService: AdxModuleService, private readonly messageNotifier: CustomNotifierService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.addModuleGroupForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]}),
      icon: new UntypedFormControl(),
      desc: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.required,
        Validators.maxLength(450)
      ]}),
      sortOrder: new FormControl<number | null>(null, {
        updateOn: 'change', validators: [
          Validators.max(9999999)
        ]
      })
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    const accountId: number | null = this.getId('acctId');
    this.typeId = accountId;
    const orgId: number | null = this.getId('orgId');
    const vpubId: number | null = this.getId('vpubId');
    const applnId: number | null = this.getId('applnId');
    if (!orgId || !accountId || !vpubId) {
      this.logger.error('required params missing');
      return;
    }
    this.orgId = orgId;
    //reading the hierarchy id values to pass the same to image library
    this.applnId = applnId; this.vpubId = vpubId;
    if (accountId && orgId && applnId) {
      this.moduleListUrl = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );

    this.fetchModules(orgId, accountId, vpubId);
  }

  ngOnDestroy(): void {
    //on destroy unsubscribe from all the subscription
    this.authSubscription?.unsubscribe();
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: CommonUtility.getId('vpubId', this.activatedRoute),
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.MODULE_IMG_LIB,
      editorRef: undefined,
      applicationId : CommonUtility.getId('applnId', this.activatedRoute),
      atomId : null
    };
    return toRet;
  }

  addModuleGroup(): void {
    this.logger.debug(this.addModuleGroupForm);
    if (!this.canAddModule()){
      return; // no need to proceed as current user does not have permission to add module
    }
    const title: string = this.addModuleGroupForm.get('title')?.value;
    if (!title || title.trim().length < 1) {
      this.logger.error('Text is mandatory field');
      return;
    }
    const orgId: number | null = this.getId('orgId');
    const accountId: number | null = this.getId('acctId');
    const applnId: number | null = this.getId('applnId');
    const vpubId: number | null = this.getId('vpubId');
    if (!orgId || !accountId || !applnId || !vpubId) {
      this.logger.error('Mandatory field missing');
      return;
    }
    const addReq: AdxModuleGroupAddRequest = new AdxModuleGroupAddRequest(null);
    addReq.title = title;
    if (this.addModuleGroupForm.get('desc')?.dirty) {
      const desc: string = this.addModuleGroupForm.get('desc')?.value;
      addReq.description = desc;
    }
    if (this.addModuleGroupForm.get('icon')?.dirty) {
      addReq.iconImage = this.iconImage;
    }
    if (this.selectedModules && this.selectedModules.length > 0) {
      const ids: number[] = [];
      this.selectedModules.forEach(item => {
        if (item && item.id && item.id != null) {
            ids.push(item.id);
        }
      });
      addReq.childrenIds = ids;
    }

    const sortOrderControl = this.addModuleGroupForm.get('sortOrder');
    if (sortOrderControl && sortOrderControl.dirty) {
      addReq.sortOrder = sortOrderControl.value;
    }

    const url = `/orgs/${orgId}/accts/${accountId}/applns/${applnId}/vpubs/${vpubId}/modls`;
    this.addModuleGroupService.addModuleGroup(orgId, accountId, vpubId, addReq)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // navigate user to listing screen
          this.router.navigateByUrl(url).then(r => {});
        },
        error: () => {
          // navigate user to listing screen
          this.router.navigateByUrl(url).then(r => {});
        },
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `${addReq.title} created Successfully.`);
        }
      });
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  /*
   * This is used to determine whether loggedin user can add module
  */
  private canAddModule(): boolean {
    const accountId: number | null = this.getId('acctId');
    const orgId: number | null = this.getId('orgId');
    const vpubId: number | null = this.getId('vpubId');
    const applnId: number | null = this.getId('applnId');
    if (orgId !== undefined && orgId !== null
       && accountId !== undefined && accountId !== null
       && applnId !== undefined && applnId !== null
       && vpubId !== undefined && vpubId !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.moduleService.canUserAddModule(orgId, accountId, applnId, vpubId, this.currentUser);
    }
    return false;
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for orgId:' + orgId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          data.forEach(item => {
            if (item && item.id && item.id != null) {
              const isEligible: boolean = this.canBeChild(item, data);
              this.logger.debug(`${item.title} is eligible ${isEligible}`);
              if (isEligible) {
                this.modules.push(item);
              }
            }
          });
        }
      });
  }

  private getId(strId: string): number | null {
    const idString: string | null | undefined = this.activatedRoute.snapshot.paramMap.get(strId);
    this.logger.debug(idString);

    if (idString) {
      if (isNaN(+idString)) {
        return null;
      }
    }
    else {
      return null;
    }
    return +idString;
  }

  private canBeChild(currentNode: AdxModule, nodeList: AdxModule[]): boolean {
    if (currentNode && currentNode.id) {
      // only 2 level of modules supported. Hence shortlist items who do not have:
      // grandparent OR grandchildren OR parent & Children both
      if (currentNode.parentId && currentNode.children && currentNode.children.length > 0) {
        this.logger.debug(`current node ${currentNode.title} has father and child`);
        return false; // has both parent and child
      }
      else if (currentNode.parentId) {
        // get parent and check whether parent has a parent
        const parent = nodeList.find(item => item.id === currentNode.parentId);
        if (parent && parent.parentId) {
          this.logger.debug(`current node ${currentNode.title} has grandfather`);
          return false; // parent has a parent
        }
      }
      else if (currentNode.children && currentNode.children.length > 0) {
        // get children and check whether child has children
        let hasGrandChild = false;
        currentNode.children.forEach(child => {
          if (child.children && child.children.length > 0) {
            hasGrandChild = true; // child has child
          }
        });
        if (hasGrandChild) {
          this.logger.debug(`current node ${currentNode.title} has grandchild`);
          return false;
        }
      }
    }
    return true;
  }
}
