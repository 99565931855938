<div fxLayout="row">
  <div class="image-input-url">
    <ng-container *ngIf="imageUrl && imageUrl.length > 0">
      <img mat-card-sm-image [src]="imageUrl"/>
      <ng-container *ngIf="imageTitle && imageTitle.length > 0">
        <span class="image-input-title">{{imageTitle}}</span>
      </ng-container>
    </ng-container>
    <button i18n="@@imgInputBtn" mat-stroked-button appAdxImageLibrary
            [id]="typeId" [type]="libraryType" [isSelect]="true" [isIcon]="isIcon"
            (imgSelectEmitter)="imageSelected($event)" [orgId]="orgId" [accountId]="accountId" [applnId]="applnId" [vpubId]="vpubId" [moduleId]="moduleId" [atomId]="atomId"><mat-icon>photo_library</mat-icon></button>
  </div>
</div>
