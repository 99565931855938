<div class="container">
  <div class="flex-container" fxLayout="column">
    <mat-toolbar fxlayoutalign="center center" class="common-vpub-label">
      <span>{{moduleTitle}}</span>
    </mat-toolbar>
  </div>
  <form class="manage-web-form" [formGroup]="manageWebForm">
      <div class="flex-container add-account" fxLayout="column" fxLayoutGap="30px">
          <div fxLayout="row">
            <div fxFlex="10" class="container-label">
              <div class="manageWeb-label">
                <mat-label i18n="@@manageWeb.url">URL<span class="required-asterisk">*</span></mat-label>
              </div>
            </div>
            <div fxFlex="80" class="common-input-head">
              <div class="manageWeb-input">
                <mat-form-field class="input-form">
                  <textarea matInput #manageWebUrl maxlength="250" placeholder="URL"
                            id="input-manageWeb-url" formControlName="url" style="overflow:auto;"></textarea>
                  <mat-hint align="end">{{manageWebUrl.value.length}} / 250</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
      </div>
      <div class="button-container">
          <button i18n="@@cancel" mat-raised-button routerLink="{{moduleListUrl}}" id="input-manageWeb-cancel-Btn" class="button-cancel">Cancel</button>
          <button i18n="@@save" mat-raised-button id="input-manageWeb-save-Btn" *ngIf="canUserSaveModule()"
          [disabled]="!(manageWebForm.valid && manageWebForm.dirty)" type="submit" (click)="save()">Save</button>
      </div>
  </form>
</div>
