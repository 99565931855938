import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AdxTimezone } from '../model/adx-timezone.model';
import { AdxTimezoneService } from '../services/timezone/adx-timezone.service';

/**
 * The timezone resolver is used to fetch timezone data from backend.
 */
@Injectable({
  providedIn: 'root',
})
export class AdxTimezoneResolver implements Resolve<AdxTimezone[]> {

  constructor(private logger: NGXLogger, private timezoneService: AdxTimezoneService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AdxTimezone[] | Observable<AdxTimezone[]> | Promise<AdxTimezone[]> {

    this.logger.debug('Timezone resolve called');
    return this.timezoneService.getTimezones().pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of([]);
      })
    );
  }
}
