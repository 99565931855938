import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChannelComponent} from './channel.component';
import {ChannelListComponent} from './channel-list/channel-list.component';
import {AddChannelComponent} from './add-channel/add-channel.component';
import {EditChannelComponent} from './edit-channel/edit-channel.component';
import {AdxChannelResolver} from './resolver/adx-channel.resolver';

const channelRoutes: Routes = [
  {
    path: '',
    component: ChannelComponent,
    children: [
      { path: '', component: ChannelListComponent },
      {
        path: 'add',
        component: AddChannelComponent
      },
      {
        path: 'edit/:chnlId',
        component: EditChannelComponent,
        resolve: { channel: AdxChannelResolver }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(channelRoutes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule { }
