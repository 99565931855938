import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AdxUserAddComponent } from '../adx-user-add/adx-user-add.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AdxUser } from '../model/adx-user-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AdxUserService } from '../service/adx-user.service';
import { Subject, takeUntil } from 'rxjs';
import { AdxUserSearchComponent } from '../adx-user-search/adx-user-search.component';

/**
 * User Listing page
 * It will display list of users and also provide ability to add user
 */
@Component({
  selector: 'app-adx-user-listing',
  templateUrl: './adx-user-listing.component.html',
  styleUrls: ['./adx-user-listing.component.scss']
})
export class AdxUserListingComponent extends AdxBaseTemplate implements OnInit, AfterViewInit {

  @Input() orgId: number | null = null;
  @Input() accountId: number | null = null;

  @ViewChild('paginator') paginator: MatPaginator | null = null; //pagination for table. Fixed page size of 50
  @ViewChild('search_component') searchComponent: AdxUserSearchComponent | undefined;

  destroy$: Subject<boolean> = new Subject<boolean>();

  displayedColumns: string[] = [
    'username',
    'firstname',
    'lastname',
    'actions'
  ];
  dataSource = new MatTableDataSource<AdxUser>();
  isPrevDisabled = true;
  isNextDisabled = true;
  currentPageNumber = 1;

  constructor(
    private readonly service: AdxUserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly route: Router,
    private dialog: MatDialog) {

    super();
   }

  ngOnInit(): void {

    this.fetchUsers(this.currentPageNumber);
  }

  ngAfterViewInit() {
    this.logger.debug(this.paginator);
    this.dataSource.paginator = this.paginator;

  }

  /**
   * Called on getting result from search user child component
   */
  updateSearchResult(input: AdxUser | null): void {
    this.logger.debug(input);
    const userArray: AdxUser[] = [];
    if (input) {
      userArray.push(input);
      this.logger.debug(`added ${input.username} to the list`);
    }
    this.dataSource.data = userArray;
  }


  /**
   * Callback to display adduser popup
   */
  onAdd() {
    this.dialog.open(AdxUserAddComponent, {
      data: {orgId: this.orgId}
    });
  }

  /**
   * Callback to refresh the user list
   */
  onRefresh() {
    if (this.searchComponent) {
      this.searchComponent.searchUserForm.reset();
    }
    this.fetchUsers(this.currentPageNumber);
  }

  /**
   * Callback to edit the details for given user
   *
   * @param userId number. Uniquely identifies the user
   */
  onEdit(userId: number) {
    this.logger.debug(`edting userid ${userId}`);
    const editUrl = `/orgs/${this.orgId}/user/manage/edit`;
    this.route.navigateByUrl(editUrl).then(r => { });
  }

  nextPage(): void {
    this.currentPageNumber++;
    this.fetchUsers(this.currentPageNumber);
  }

  prevPage(): void {
    this.currentPageNumber--;
    this.fetchUsers(this.currentPageNumber);
  }

  /*
   * fetch the list of users from backend and populate the datasource object
  */
  private fetchUsers(pageNumber: number): void {
    if(this.orgId === undefined || this.orgId === null) {
      return;
    }
    this.service.getUsers(this.orgId, this.accountId, pageNumber).pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data: {moreRecords: boolean; users: AdxUser[]}) => {
        // set user variable
        this.dataSource.data = data.users;
        if (data.moreRecords === true) {
          this.isNextDisabled = false;
        }
        else {
          this.isNextDisabled = true;
        }
        this.logger.debug(data);
      },
      complete: () => {
        this.logger.debug(this.currentPageNumber);
        if (this.currentPageNumber === 1) {
          this.isPrevDisabled = true;
        }
        else {
          this.isPrevDisabled = false;
        }
      }
    });
  }
}
