import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LinkChannelApplicationRequest} from '../../../app-channel-link/model/link-channel-application-request';
import {FormUtility} from '../../../common/utils/form-utility';
import {map} from 'rxjs/operators';
import {AdxBulkLinkAtomsRequest} from '../../../adx-ars/model/adx-bulk-link-atoms-request';
import {AdxImportResponse} from '../../../common/model/data-transfer/adx-import-response';
import {AdxBulkUnlinkAtomsRequest} from '../../../adx-ars/model/adx-bulk-unlink-atoms-request';
import {AdxDataTransferRequest} from '../../../common/model/data-transfer/adx-data-transfer-request';
import {AdxBulkLinkCategoriesRequest} from '../../../adx-ars/model/adx-bulk-link-categories-request';
import {AdxBulkUnlinkCategoriesRequest} from '../../../adx-ars/model/adx-bulk-unlink-categories-request';

/**
 * Service that works with HTTPClient to make REST calls to backend.
 * This service is mainly used to perform bulk actions
 */
@Injectable({
  providedIn: 'root'
})
export class BulkActionService {

  // the baseUrl is read from environment specific file
  private readonly baseUrl = environment.apiUrl;

  constructor(private logger: NGXLogger, private httpClient: HttpClient) { }

  public linkChannelApplication(requestObj: LinkChannelApplicationRequest): Observable<any> {

    this.logger.debug('in linkChannelApplication');
    const reqUrl = `orgs/${requestObj.orgId}/accts/${requestObj.acctId}/chnls/app-associations`;
    const endPoint: string = this.resourceEndPoint(reqUrl);

    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    return this.httpClient.post(endPoint, JSON.stringify(requestObj), {headers: headersObj});
  }

  /**
   * This API is used to upload ARS xml file to backend for import of ars data.
   *
   * @param resourceUrl: url of the upload request
   * @param requestObj: Object containing the uploaded file and other details.
   */
  public uploadXml(resourceUrl: string, requestObj: AdxDataTransferRequest): Observable<AdxImportResponse> {

    let  headersObj = new HttpHeaders();
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);
    const formData = FormUtility.getFormDataFromObj(requestObj);

    return this.httpClient.post(endPoint, formData, {headers: headersObj})
      .pipe(map(data => AdxImportResponse.buildObject(data) as AdxImportResponse));
  }

  /**
   * This API is used to export the ARS data.
   *
   * @param resourceUrl: url of the upload request
   * @param requestObj: Object containing the uploaded file and other details.
   */
  public exportXml(resourceUrl: string, requestObj: AdxDataTransferRequest): Observable<any> {

    let  headersObj = new HttpHeaders();
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);

    const formData = FormUtility.getFormDataFromObj(requestObj);
    return this.httpClient.post(endPoint, formData, {headers: headersObj});
  }

  /**
   * API used to initiate the Import processing
   *
   * @param resourceUrl
   * @param requestObj
   */
  public processImport(resourceUrl: string, requestObj: {documentId: number; aceProcessToRun: string}): Observable<AdxImportResponse> {

    let  headersObj = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    const endPoint: string = this.resourceEndPoint(resourceUrl);

    return this.httpClient.post(endPoint, JSON.stringify(requestObj), {headers: headersObj})
      .pipe(map(data => AdxImportResponse.buildObject(data) as AdxImportResponse));
  }

  /**
   * API used for bulk associating category and atoms
   *
   * @param reqObj: Request object containing all required input data
   */
  public linkCategoryAtoms(reqObj: AdxBulkLinkAtomsRequest): Observable<any> {

    this.logger.debug('in linkCategoryAtoms');
    let reqUrl = `orgs/${reqObj.orgId}/accts/${reqObj.acctId}/vpubs/${reqObj.vpubId}`;
    reqUrl = reqUrl + `/mdls/${reqObj.moduleId}/atms/associations`;
    const endPoint: string = this.resourceEndPoint(reqUrl);

    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    return this.httpClient.put(endPoint, JSON.stringify(reqObj), {headers: headersObj});
  }

  /**
   * API used for bulk disassociating category and atoms
   *
   * @param unlinkReq: Request object containing all required input data
   */
  public unlinkCategoryAtoms(unlinkReq: AdxBulkUnlinkAtomsRequest): Observable<any> {

    this.logger.debug('in UnLinkCategoryAtoms');
    let reqUrl = `orgs/${unlinkReq.orgId}/accts/${unlinkReq.accountId}/vpubs/${unlinkReq.vpubId}`;
    reqUrl = reqUrl + `/mdls/${unlinkReq.moduleId}/atms/associations`;
    const endPoint: string = this.resourceEndPoint(reqUrl);

    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    return this.httpClient.delete(endPoint, {headers: headersObj, body: JSON.stringify(unlinkReq)});
  }

  /**
   * API used for bulk associating category and child categories
   *
   * @param reqObj: Request object containing all required input data
   */
  public linkCategorySubCategory(reqObj: AdxBulkLinkCategoriesRequest): Observable<any> {

    this.logger.debug('in linkCategorySubCategory');
    let reqUrl = `orgs/${reqObj.orgId}/accts/${reqObj.acctId}/vpubs/${reqObj.vpubId}`;
    reqUrl = reqUrl + `/mdls/${reqObj.moduleId}/ctgs/associations`;
    const endPoint: string = this.resourceEndPoint(reqUrl);

    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    return this.httpClient.put(endPoint, JSON.stringify(reqObj), {headers: headersObj});
  }

  /**
   * API used for bulk disassociating category and child categories
   *
   * @param unlinkReq: Request object containing all required input data
   */
  public unlinkCategorySubCategory(unlinkReq: AdxBulkUnlinkCategoriesRequest): Observable<any> {

    this.logger.debug('in unlinkCategorySubCategory');
    let reqUrl = `orgs/${unlinkReq.orgId}/accts/${unlinkReq.acctId}/vpubs/${unlinkReq.vpubId}`;
    reqUrl = reqUrl + `/mdls/${unlinkReq.moduleId}/ctgs/associations`;
    const endPoint: string = this.resourceEndPoint(reqUrl);

    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('content-type', 'application/json');
    headersObj = headersObj.set('Access-Control-Allow-Origin', '*');

    return this.httpClient.delete(endPoint, {headers: headersObj, body: JSON.stringify(unlinkReq)});
  }

  private resourceEndPoint(url: string): string {
    return `${this.baseUrl}/${url}`;
  }
}
