import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AdxUser } from '../model/adx-user-model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { blankValidator } from 'src/app/common/utils/form-utility';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';

/**
 * Component to display User Details
 * Also provides ability to update user details
 */
@Component({
  selector: 'app-adx-user-details',
  templateUrl: './adx-user-details.component.html',
  styleUrls: ['./adx-user-details.component.scss']
})
export class AdxUserDetailsComponent extends AdxBaseTemplate implements OnChanges {

  @Input() user: AdxUser | null = null; // user input revieved
  @Input() isDisabled = true; //flag indicating whether the inputs need to be disabled or enabled

  @Output() userDetail = new EventEmitter<AdxUser | null>();
  userDetailsForm: FormGroup<any>; //reactiveform for user details
  private readonly PHONE_NUMBER = "^[0-9]{10}$";

  constructor() {
    super();
    this.userDetailsForm = new FormGroup({
      firstName: new FormControl<string>('', {
        updateOn: 'blur', validators: [
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]
      }),
      middleName: new FormControl<string>('', {
        updateOn: 'change', validators: [
          Validators.maxLength(50)
        ]
      }),
      lastName: new FormControl<string>('', {
        updateOn: 'change', validators: [
          blankValidator,
          Validators.maxLength(50)
        ]
      }),
      userName: new FormControl<string>('', {
        updateOn: 'change', validators: [
          blankValidator,
          Validators.email,
          Validators.maxLength(254)
        ]
      }),
      altEmail: new FormControl<string>('', {
        updateOn: 'change', validators: [
          Validators.email,
          Validators.maxLength(254)
        ]
      }),
      mobileNumber: new FormControl<string>('', {
        updateOn: 'change', validators: [
          Validators.minLength(10),
          Validators.pattern(this.PHONE_NUMBER),
          Validators.maxLength(10)
        ]
      })
    });
  }

  /**
   * If input data changes, then this method will update the same in UI
   */
  ngOnChanges(): void {   
    this.userDetailsForm.reset();
    this.userDetailsForm.enable();
    if (this.user) {
      this.userDetailsForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        userName: this.user.username,
        middleName: (this.user.middleName && this.user.middleName != null) ? this.user.middleName : '',
        altEmail: (this.user.secondaryEmail && this.user.secondaryEmail != null) ? this.user.secondaryEmail : '',
        mobileNumber: (this.user.primaryNumber && this.user.primaryNumber != null) ? this.user.primaryNumber : ''
      });
      this.userDetailsForm.get('firstName')?.disable();
      this.userDetailsForm.get('lastName')?.disable();
      this.userDetailsForm.get('middleName')?.disable();
      this.userDetailsForm.get('userName')?.disable();
      this.userDetailsForm.get('altEmail')?.disable();
      this.userDetailsForm.get('mobileNumber')?.disable();
    }
  }

  /**
   * Callback to update user details
   */
  onUpdate(): void {
    let newUser = new AdxUser(null);
    
      const firstNameControl = this.getFormControl('firstName');
      let firstNameValue: string | null = null;
      if (firstNameControl && firstNameControl.dirty) {
        firstNameValue = firstNameControl.value;
        newUser.firstName = firstNameValue;
      }
      const middleNameControl = this.getFormControl('middleName');
      let middleNameValue: string | null = null;
      if (middleNameControl && middleNameControl.dirty) {
        middleNameValue = middleNameControl.value;
        newUser.middleName = middleNameValue;
      }
      const lastNameControl = this.getFormControl('lastName');
      let lastNameValue: string | null = null;
      if (lastNameControl && lastNameControl.dirty) {
        lastNameValue = lastNameControl.value;
        newUser.lastName = lastNameValue;
      }
      const userNameControl = this.getFormControl('userName');
      let userNameValue: string | null = null;
      if (userNameControl && userNameControl.dirty) {
        userNameValue = userNameControl.value;
        newUser.username = userNameValue;
      }
      const altEmailControl = this.getFormControl('altEmail');
      let altEmailValue: string | null = null;
      if (altEmailControl && altEmailControl.dirty) {
        altEmailValue = altEmailControl.value;
        newUser.secondaryEmail = altEmailValue;
      }
      const mobileNumberControl = this.getFormControl('mobileNumber');
      let mobileNumberValue: string | null = null;
      if (mobileNumberControl && mobileNumberControl.dirty) {
        mobileNumberValue = mobileNumberControl.value;
        newUser.primaryNumber = mobileNumberValue;
      }

      //now make the call to backend with updated value      
      this.userDetail.emit(newUser);

  }

  /**
   * Callback to reset the form
   */
  onCancel(): void {
    this.logger.debug(`cancel called`);
    this.userDetailsForm.reset();
  }

  /**
   * decides whether add button should be disabled
   *
   * @returns boolean - true if isDisabled flag is true Or if form is not dirty and form is invalid
   */
  canDisable(): boolean {
    return this.isDisabled || !(this.userDetailsForm.dirty && this.userDetailsForm.valid);
  }

  /*
   * utility function to get abstract control for given key
  */
  private getFormControl(name: string): AbstractControl | null {
    return this.userDetailsForm.get(name);
  }
}
