import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {AdxImageLibraryType} from '../../utils/adx-image-library-type';
import {AdxImageLibraryItem} from '../../../common/model/image-library/adx-image-library-item.model';
import {Subscription} from 'rxjs';
import {AdxInsertContentDialogData} from '../../utils/adx-insert-content-dialog-data';

@Component({
  selector: 'app-adx-insert-image-input',
  templateUrl: './adx-insert-image-input.component.html',
  styleUrls: ['./adx-insert-image-input.component.scss']
})
export class AdxInsertImageInputComponent implements OnInit, OnDestroy {

  @Input() data: AdxInsertContentDialogData | undefined;

  @Output() workDone = new EventEmitter<boolean>();

  typeId: number | undefined;
  libraryType: AdxImageLibraryType = AdxImageLibraryType.ACCOUNT_IMG_LIB;
  insertExtrernalForm: UntypedFormGroup;
  orgId : number | null = null; // need these id's in image library for saving an image.
  accountId : number | null = null;
  applnId : number | null = null;
  vpubId : number | null = null;
  moduleId : number | null = null;
  atomId : number | null = null;
  imgUrlSubscription: Subscription | undefined;

  constructor(private logger: NGXLogger, private formBuilder: UntypedFormBuilder) {
    if (this.data && this.data.typeId) {
      this.typeId = this.data.typeId;
    }
    this.insertExtrernalForm = this.formBuilder.group({
        imageUrl: [null, {
          updateOn: 'blur', validators: [
            Validators.required
          ]
        }],
        imgWidth: [],
        imgHeight: [],
        imgProportion: [true]
      }
    );
  }

  ngOnInit(): void {
    if (this.data && this.data.typeId) {
      this.typeId = this.data.typeId;
      this.libraryType = this.data.imgLibraryType;
      this.orgId = this.data.orgId;
      this.accountId = this.data.accountId;
      this.applnId = this.data.applicationId;
      this.vpubId = this.data.vpubId;
      this.moduleId = this.data.moduleId;
      this.atomId = this.data.atomId;
    }
    // to display user entered test in the readonly search bar
    this.imgUrlSubscription = this.insertExtrernalForm.get('imageUrl')?.valueChanges.subscribe(
      (change) => {
        this.logger.debug(`ImgUrl changed ${change}`);
        this.getImageDimension(change);
      });
  }

  ngOnDestroy(): void {
    if (this.imgUrlSubscription) {
      this.imgUrlSubscription.unsubscribe();
    }
  }

  insertExternalImage(): void {
    if (!this.data || !this.data.editorRef) {
      this.logger.error(`insertExternalImage:: Editor not initialized ${this.data} ${this.data?.editorRef}`);
      return;
    }
    const imgUrl: string = this.getControlValue('imageUrl');
    const imgWidth: string = this.getControlValue('imgWidth');
    const imgHeight: string = this.getControlValue('imgHeight');
    const imgProportion: string = this.getControlValue('imgProportion');
    this.logger.debug(`In imageSelected ${this.data.editorRef}`);
    let html = '<a href="' + imgUrl + '" target="_blank"><img src="' + imgUrl + '" ';

    if (!imgWidth) {
      html += 'width="' + imgWidth + '" ';
    }

    if (!imgHeight) {
      html += 'height="' + imgHeight + '" ';
    }

    html += '/></a>';
    this.data.editorRef.selection.restore();
    this.data.editorRef.html.insert(html);
    this.workDone.emit(true);
  }

  imageSelected(imgLibItemt: AdxImageLibraryItem): void {
    if (!this.data || !this.data.editorRef) {
      this.logger.error(`imageSelected:: Editor not initialized ${this.data} ${this.data?.editorRef}`);
      return;
    }
    this.logger.debug(`In insertExternalImage ${this.data.editorRef}`);
    const html = '<a href="' + imgLibItemt.preSignedUrl + '" target="_blank"><img src="' + imgLibItemt.preSignedUrl + '" /></a>';
    this.data.editorRef.selection.restore();
    this.data.editorRef.html.insert(html);
    this.workDone.emit(true);
  }

  private getControlValue(controlName: string): any {
    const control: AbstractControl | null = this.insertExtrernalForm.get(controlName);
    if (control) {
      return control.value;
    }
    return undefined;
  }

  private getImageDimension(imgUrl: string): void {

    const img = new Image();

    img.src = imgUrl;
    img.onload = event => {
      const loadedImage = event.currentTarget as HTMLImageElement;
      if (loadedImage) {
        const width = loadedImage.width;
        const height = loadedImage.height;
        this.insertExtrernalForm.patchValue({
          imgProportion: [true],
          imgWidth: [width],
          imgHeight: [height]
        });
        this.logger.debug('height: ' + height);
        this.logger.debug('width: ' + width);
      }
    };
  }

}
