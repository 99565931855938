<div class="add-module-group-container">
  <form [formGroup]="addModuleGroupForm">

    <div class="add-module-group">
        <div class="input-label associateCategories">
          <div class="vpubName-label">
            <mat-label i18n="@@addModuleGroup.iconUpload">Icon Upload</mat-label>
            <app-adx-image-input [libraryType]="imgLibType" [typeId]="typeId" formControlName="icon"
                                 (imageSelect)="onIconSelected($event)" [orgId]="orgId" [accountId]="typeId" [applnId] ="applnId" [vpubId] ="vpubId"
                                 > </app-adx-image-input>
          </div>
        </div>
        <div class="common-input-head">
          <div class="moduleGroup-input">
            <mat-form-field class="input-form">
              <input matInput i18n="@@addModuleGroup.orgName" id="input-addModuleGroup-title" #moduleGroupTitle maxlength="100" placeholder="Title"
                     formControlName="title"/>
              <mat-hint align="end">{{moduleGroupTitle.value.length}} / 100</mat-hint>
            </mat-form-field>
          </div>
        </div>

      <mat-divider></mat-divider>

      <div>
        <mat-form-field>
          <mat-label i18n="@@addModuleGroup.sort-order">Sort Order</mat-label>
          <input type='number' matInput i18n="@@addModuleGroup.sort-order" id="input-addModuleGroup-sortOrder"
                 min="0" placeholder="Sort Order" formControlName="sortOrder"/>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <div>
          <dual-list [source]="modules" [(destination)]="selectedModules" id="input-addModuleGroup-modules"
                 [key]="key" [display]="display">
          </dual-list>
        </div>

      <mat-divider></mat-divider>


      <mat-form-field class="common-desc-editor">
          <mat-label i18n="@@addModuleGroup.desc">Description<span class="required-asterisk">*</span></mat-label>
          <textarea placeholder='Description' id="input-addModuleGroup-description" formControlName="desc"
                    matInput [errorStateMatcher]="adxErrorStateMatcher"
                    [froalaEditor]="froalaEditorOptions(450)" (froalaInit)="initializeFroalaEditor($event)">
          </textarea>
        <mat-hint>Maximum number of HTML characters is 450</mat-hint>
        <mat-error *ngIf="addModuleGroupForm.get('desc')?.hasError('maxlength')">
          Exceeded max limit of 450 HTML characters
        </mat-error>
        </mat-form-field>

      <mat-divider></mat-divider>

      <div class="add-module-group-button-container">
      <button i18n="@@addModuleForm.cancel" id="input-addModuleGroup-cancel-Btn" mat-raised-button routerLink="{{moduleListUrl}}"  class="button-cancel">Cancel</button>
      <button i18n="@@addModuleForm.save" id="input-addModuleGroup-save-Btn" class="button-cancel" mat-raised-button [disabled]="!(addModuleGroupForm.valid && addModuleGroupForm.dirty)" type="submit" (click)="addModuleGroup()">Save</button>
    </div>
    </div>
  </form>
</div>
