import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { of, Subject, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Account } from '../../account/model/account.model';
import { Organization } from '../../organization/model/organization.model';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AdxApplication } from '../../application/model/adx-application.model';
import { AdxVpubService } from '../service/adx-vpub.service';
import { AdxVPub } from '../model/adx-vpub.model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

@Component({
  selector: 'app-vpub-list',
  templateUrl: './vpub-list.component.html',
  styleUrls: ['./vpub-list.component.scss'],
})
export class VpubListComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['checkbox', 'icon', 'vpubtitle', 'locale', 'isDefault', 'vpubisDraft', 'edit'];
  dataSource: MatTableDataSource<AdxVPub> | null = null;
  selectedAccount: Account | null = null;
  selectedOrg: Organization | null = null;
  selectedApplication: AdxApplication | null = null;
  urlForApplnListing = '';
  dataFetchSubscription: Subscription | null = null;
  titleFilterSubscription: Subscription | null = null;
  titleFilter: UntypedFormControl = new UntypedFormControl('');
  private filterValues: any = {
    title: '',
    visibility: ''
  };

  private authSubscription: Subscription | undefined;

  constructor(private authNotifier: AuthNotifierService, private router: Router,
    private vPubService: AdxVpubService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.selectedApplication = this.activatedRoute.snapshot.data.appln;

    this.logger.debug(this.selectedAccount);
    this.logger.debug(this.selectedOrg);
    this.urlForApplnListing = `/orgs/${this.selectedOrg?.id}/accts/${this.selectedAccount?.id}/applns`;

    if (this.selectedAccount && this.selectedAccount.id && this.selectedOrg && this.selectedOrg.id
      && this.selectedApplication && this.selectedApplication.id) {
      this.dataFetchSubscription = this.fetchVpubs(this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id);
    } else {
      this.logger.error('Either selected Object or its id is null');
    }
    this.fieldListener();

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
    if (this.titleFilterSubscription) {
      this.titleFilterSubscription.unsubscribe();
    }

    this.authSubscription?.unsubscribe();
  }

  async navigateToAdd() {
    if (!this.canAddVpub()) {
      return; //if user does not have permission to add vpub, return.
    }
    await this.router.navigate(['add'],
      { relativeTo: this.activatedRoute, state: { vPubList: this.dataSource?.data } });
  }

  /**
   * Callback that returns true if logged-in user has permission to add new vpub
   *
   * @returns boolean. true if current user can add new vpub
   */
  canAddVpub(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.vPubService.canUserAddVpub(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to delete vpub
   *
   * @returns boolean. true if current user can delete vpub
   */
  canDeleteVpub(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.vPubService.canUserDeleteVpub(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedApplication.id, this.currentUser);
    }
    return false;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit vpub
   *
   * @param acctId
   * @returns boolean: true if user can edit vpub identified by given id
   */
  canEditVpub(acctId: number | null): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedApplication !== undefined && this.selectedApplication !== null
      && this.selectedApplication.id !== undefined && this.selectedApplication.id !== null
      && acctId !== undefined && acctId !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.vPubService.canUserEditVpub(this.selectedOrg.id,
        this.selectedAccount.id, this.selectedApplication.id, acctId, this.currentUser);
    }
    return false;
  }


  private filterAccessibleVpubIds(vpubsRetrieved: AdxVPub[]) {
    if (vpubsRetrieved === undefined || vpubsRetrieved === null) {
      return vpubsRetrieved;
    }

    this.logger.debug(vpubsRetrieved);

    if (this.selectedOrg === undefined || this.selectedOrg === null
      || this.selectedOrg.id === undefined || this.selectedOrg.id === null) {
      const temp: AdxVPub[] = [];
      return temp;
    }

    if (this.selectedAccount === undefined || this.selectedAccount === null
      || this.selectedAccount.id === undefined || this.selectedAccount.id === null) {
      const temp: AdxVPub[] = [];
      return temp;
    }

    if (this.selectedApplication === undefined || this.selectedApplication === null
      || this.selectedApplication.id === undefined || this.selectedApplication.id === null) {
      const temp: AdxVPub[] = [];
      return temp;
    }

    if (this.currentUser === undefined || this.currentUser === null) {
      const temp: AdxVPub[] = [];
      return temp;
    }

    const userPermissions = this.currentUser?.userPermissions;
    if (userPermissions === undefined || userPermissions === null) {
      const temp: AdxVPub[] = [];
      return temp;
    }

    if (AdxAuthUtils.isSystemOrInstanceAdmin(this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is system/instance admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_ADMIN', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is org admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedOrg?.id, 'ORGANIZATION_READ_ONLY', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user has org readonly permission
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedAccount.id, 'ACCOUNT_ADMIN', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is account admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedApplication.id, 'APPLICATION_ADMIN', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is application admin
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedApplication.id, 'APPLICATION_READ_ONLY', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is application readonly
    }

    if (AdxAuthUtils.hasRoleForGivenId(this.selectedAccount.id, 'ACCOUNT_READ_ONLY', this.currentUser)) {
      return vpubsRetrieved; //no filtering as user is account readonly
    }

    const accessbileVpubs = this.vPubService.getAccessiblVpubIdsForUser(this.currentUser);

    const toRet: AdxVPub[] = []; // if nothing matches, then empty list is returned

    if (vpubsRetrieved.length > 0) {
      //verify whether accessbileVpubs suppiled or not
      if (accessbileVpubs !== undefined && accessbileVpubs !== null && accessbileVpubs.length > 0) {
        for (const vpubId of accessbileVpubs) {
          const foundVpub = vpubsRetrieved.find(vpub => vpub.id === vpubId);
          //if vpub is present in accessible list, add it to list to be returned
          if (foundVpub !== undefined && foundVpub !== null) {
            toRet.push(foundVpub);
          }
        }
      }
    }

    this.logger.debug(toRet);
    return toRet;
  }

  /*
   * This method fetches the vpubs list from backend
  */
  private fetchVpubs(orgId: number, accountId: number, applnId: number): Subscription {
    this.logger.debug('fetching vpubs for orgId:' + orgId + ' ; accountId:' + accountId + 'applnId:' + applnId);
    if (accountId && orgId && applnId) {
      return this.vPubService.getVpubs(orgId, accountId, applnId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxVPub[]) => {
            // successfully received the data. so set it in variable
            this.processResponse(data);
          }
        });
    }
    else {
      return of({}).subscribe();
    }
  }

  /*
   * Utility to set accessible applications in the DataSource
   */
  private processResponse(data: AdxVPub[]) {
    if (this.currentUser === undefined || this.currentUser === null) {
      return;
    }
    //filter list based on user role based access
    const hasSystemAccess = AdxAuthUtils.hasSystemAccess(this.currentUser);
    let accessibleVpubs = data; //if system access, then user will be displayed all vpubs
    if (!hasSystemAccess) {
      //get accessible list from storage and filter the input vpubs
      accessibleVpubs = this.filterAccessibleVpubIds(data);
    }
    this.dataSource = new MatTableDataSource(accessibleVpubs);
    this.dataSource.filterPredicate = this.createFilterFunction();
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createFilterFunction(): (vPub: AdxVPub, filter: string) => boolean {
    const filterFunction = (adxVPub: AdxVPub, filter: string): boolean => {

      this.logger.debug('filter: ' + filter);
      const searchTerms = JSON.parse(filter);

      let titleValue = '';
      let titleFlag = -1;
      if (adxVPub && adxVPub.title) {
        titleValue = adxVPub.title.toLowerCase();
        titleFlag = (titleValue.indexOf(searchTerms.title.toLowerCase()));
      }
      return -1 !== titleFlag;
    };

    return filterFunction;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {

    this.titleFilterSubscription = this.titleFilter.valueChanges
      .subscribe({
        next: value => {
          this.filterValues.title = value;
          if (this.dataSource) {
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        }
      }
      );
  }
}
