<div class="common-container channelContainer" fxLayout="column">
  <div class="channel-option">
    <mat-toolbar class="account-name" fxLayoutAlign="center center">
      <span i18n="@@accountName" matTooltip="{{accountName}}">{{accountName}}</span>
    </mat-toolbar>
  </div>

  <mat-tab-group class="common-tab-group">
    <mat-tab label="Channel Listing">
      <ng-template matTabContent>
      <router-outlet></router-outlet>
      </ng-template>
    </mat-tab>

    <mat-tab label="Channel Application Bulk Association" *ngIf="isUserAccountAdmin()">
      <ng-template matTabContent>
      <app-app-channel-link></app-app-channel-link>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>
