import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AdxQueryParams } from 'src/app/core/service/resource/adx-query-params.model';
import { AdxRestService } from 'src/app/core/service/resource/adx-rest-service.service';
import { AdxTimezone } from '../../model/adx-timezone.model';
import { AdxTimezoneSerializer } from '../../serializer/timezone/adx-timezone-serializer';

/**
 * Timezone data is static.
 * Using this service to fetch the timezone data from backend.
 */
@Injectable({
  providedIn: 'root',
})
export class AdxTimezoneService {

  private timezones: AdxTimezone[];
  private serializer: AdxTimezoneSerializer = new AdxTimezoneSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxTimezone, AdxTimezone>) {
    this.timezones = [];
  }

  /*
   * This API is used to fetch the list of timezones from backend.
   * Returns an observable. Throws exception in case of error.
   */
  getTimezones(): Observable<AdxTimezone[]> {

    if (this.timezones.length > 0) {
      this.logger.debug('data already fetched. No need to fetch again');
      return of(this.timezones);
    }

    this.logger.debug('fetching timezones in getTimezones');
    return this.restService.list('timezones', new AdxQueryParams(), null, this.serializer)
      .pipe(
        tap(data => this.timezones = data),
        catchError((error) => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
        })
      );
  }
}
