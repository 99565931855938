import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxRole } from './adx-role-model';

/**
 * Model associating user and role
 */
export class AdxUserRole extends AdxResource {
  role: AdxRole | null = null;
  organizationId: number | null = null;
  accountId: number | null = null;
  applicationId: number | null = null;
  channelId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;

  // only used by UI
  orgTitle: string | null = null;
  accountTitle: string | null = null;
  applicationTitle: string | null = null;
  channelTitle: string | null = null;
  vpubTitle: string | null = null;
  moduleTitle: string | null = null;
  entityType: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxUserRole {
    const toRet: AdxUserRole = new AdxUserRole(null);
    if (!json) {
      return toRet;
    }

    if (json.id !== undefined && json.id !== null) {
      toRet.id = json.id;
    }

    if (json.role !== undefined && json.role !== null) {
      toRet.role = AdxRole.buildObject(json.role);
    }

    if (json.organizationId !== undefined && json.organizationId !== null) {
      toRet.organizationId = json.organizationId;
    }

    if (json.accountId !== undefined && json.accountId !== null) {
      toRet.accountId = json.accountId;
    }

    if (json.applicationId !== undefined && json.applicationId !== null) {
      toRet.applicationId = json.applicationId;
    }

    if (json.channelId !== undefined && json.channelId !== null) {
      toRet.channelId = json.channelId;
    }

    if (json.vpubId !== undefined && json.vpubId !== null) {
      toRet.vpubId = json.vpubId;
    }

    if (json.moduleId !== undefined && json.moduleId !== null) {
      toRet.moduleId = json.moduleId;
    }

    return toRet;
  }
}
