import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { AdxImageLibraryItem } from '../../../common/model/image-library/adx-image-library-item.model';
import { AdxImageLibraryType } from '../../utils/adx-image-library-type';
import { AdxImageLibraryRequestFile } from '../../../common/model/image-library/adx-image-library-request-file';
import { AdxImageLibraryRequest } from '../../../common/model/image-library/adx-image-library-request';
import { AdxImageLibraryService } from '../../../common/services/image-library/adx-image-library.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { CustomMessageType } from '../../../core/service/notifier/custom-message-type';
import { CustomNotifierService } from '../../../core/service/notifier/custom-notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AdxCropperComponent } from './components/adx-cropper/adx-cropper.component';
import { FormUtility } from '../../../common/utils/form-utility';
import { DOC_ORIENTATION, DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { CommonUtility } from 'src/app/common/utils/common-utils';


export interface DialogData {
  multiple: boolean;
  crop: boolean;
  id: number;
  libraryType: AdxImageLibraryType;
  isIcon: boolean;
  applicationId : number | null;
  vpubId : number | null;
  moduleId : number | null;
  atomId : number | null;
}

export class InternalDataFile {
  position = 0;
  file: File | undefined;
  url = '';
  name = '';
  size = 0;
  description = '';
  tags: string = '';
  // applicationId : number | null = null;
  // vpubId : number | null = null;
  // moduleId : number | null = null;
  // atomId : number | null = null;
}

/**
 * This component is used to add image to library.
 * This component has to be invoked with two inputs, libraryType and id
 * LibraryType identified whether organizationLibrary or Account Library
 * The id is of corresponding organization or account.
 * It supports drag and drop, and crop of image
 */
@Component({
  selector: 'app-adx-image-uploader',
  templateUrl: './adx-image-uploader.component.html',
  styleUrls: ['./adx-image-uploader.component.scss'],
})
export class AdxImageUploaderComponent implements OnInit {

  isHovering = false;
  imageFiles: InternalDataFile[] = [];
  imageFile: File | null = null;
  isUploadComplete = false;
  isError = false;

  filesURLs: string[] = [];
  dataSource = new MatTableDataSource<InternalDataFile>();
  displayedColumns = ['checkbox', 'icon', 'name', 'size', 'type'];

  readonly id: number;
  readonly libType: AdxImageLibraryType;
  readonly isIcon: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly SUPPORTED_IMAGE_MIME_TYPE = 'image/bmp,image/jpeg,image/png,image/webp';

  /* The selection for checklist */
  checklistSelection = new SelectionModel<InternalDataFile>(true /* multiple */);
  select = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private errorMsg?: string;

  //additional info for saving an image to library.
  readonly applnId : number | null = null;
  readonly vpubId : number | null = null;
  readonly moduleId : number | null = null;
  readonly atomId : number | null = null;

  constructor (
    private logger: NGXLogger,
    private imgLibService: AdxImageLibraryService,
    private imageCompress: NgxImageCompressService,
    private readonly messageNotifier: CustomNotifierService,
    private router: Router, private editDialog: MatDialog,
    private dialogRef: MatDialogRef<AdxImageUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.libType = data.libraryType;
    this.id = data.id;
    this.isIcon = data.isIcon;
    this.applnId = data.applicationId;
    this.vpubId = data.vpubId;
    this.moduleId = data.moduleId;
    this.atomId = data.atomId;
  }

  ngOnInit(): void { }

  toggleHover(input: boolean): void {
    this.isHovering = input;
  }

  /**
   * Callback for close operation
   */
  onClose(): void {
    this.dialogRef.close();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.checklistSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.checklistSelection.clear();
    }
    else {
      this.dataSource.data.forEach(row => this.checklistSelection.select(row));
    }
  }

  /**
   * Delete the selected images
   */
  async deleteSelected(): Promise<any> {
    const selectedImages: InternalDataFile[] = this.checklistSelection.selected; // gives in ascending order
    // Sort the selection in descending order of the position.
    // incase multiple entries to be deleted, delete from end, for splice to work properly
    selectedImages.sort((a, b) => b.position - a.position);
    this.logger.debug(`delete selected`);
    for (const selectedImage of selectedImages) {
      this.logger.debug(`delete selected ${selectedImage.position}`);
      this.imageFiles.splice(selectedImage.position, 1);
    }
    // re-initialize the position field with actual position in changed array
    for (let idx = 0; idx < this.imageFiles.length; idx++) {
      this.imageFiles[idx].position = idx;
    }
    this.dataSource.data = this.imageFiles;
    this.checklistSelection.clear();
  }

  /**
   * Callback when images are dropped
   *
   * @param inputFileList: List of files uploaded
   */
  async imagesDropped(inputFileList: FileList): Promise<any> {
    if (inputFileList !== null) {
      // validation
      for (let i = 0; i < inputFileList.length; i++) {
        const f: File | null = inputFileList.item(i);
        if (f != null && this.SUPPORTED_IMAGE_MIME_TYPE.indexOf(f.type.toString()) === -1) {
          this.errorMsg = `The file type not supported for ${f.name}`;
          return;
        }
      }

      for (let i = 0; i < inputFileList.length; i++) {
        const f: File | null = inputFileList.item(i);
        if (f != null) {
          this.addImageToList(f, i);
        }
      }
    }
    const images = await this.transformImages();
    this.dataSource.data = images;
  }

  /**
   * Callback when user uploads one or more images
   *
   * @param event: files uploaded
   */
  async imagesSelected(event: any): Promise<any> {
    const files: FileList | null = (event.currentTarget as HTMLInputElement).files;
    this.logger.debug(files);
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const f: File | null = files.item(i);
        if (f != null) {
          this.addImageToList(f, i);
        }
      }
    }
    const images = await this.transformImages();
    this.dataSource.data = images;
    // all images' base64encoded data will be available as array in images
  }

  /**
   * Save the uploaded files to backend
   */
  saveImages(): void {
    const imgLibRequest: AdxImageLibraryRequest = new AdxImageLibraryRequest();
    if (this.libType === AdxImageLibraryType.ORG_IMG_LIB) {    
      imgLibRequest.organizationId = this.id;
    }else  if(this.libType === AdxImageLibraryType.ACCOUNT_IMG_LIB) {  
      imgLibRequest.accountId = this.id;
    }else  if(this.libType === AdxImageLibraryType.APPLICATION_IMG_LIB) {// adding additional info in save image payload which helps filtering the image by application/vpub/module
      imgLibRequest.accountId = this.id;
      imgLibRequest.applicationId = (this.applnId && this.applnId != undefined ) ? this.applnId : null;
    }else  if(this.libType === AdxImageLibraryType.VPUB_IMG_LIB) {
      imgLibRequest.accountId = this.id;
      imgLibRequest.applicationId = this.applnId;
      imgLibRequest.vpubId = this.vpubId;
    }else  if(this.libType === AdxImageLibraryType.MODULE_IMG_LIB) {
      imgLibRequest.accountId = this.id;
      imgLibRequest.applicationId = this.applnId;
      imgLibRequest.vpubId = this.vpubId;
      imgLibRequest.moduleId = this.moduleId;
    }else  if(this.libType === AdxImageLibraryType.ATOM_IMG_LIB) {// in each level, send the current level id and it's parent id's
      imgLibRequest.accountId = this.id;
      imgLibRequest.applicationId = this.applnId;
      imgLibRequest.vpubId = this.vpubId;
      imgLibRequest.moduleId = this.moduleId;
      imgLibRequest.atomId = this.atomId;
    }
    for (const fileToSave of this.imageFiles) {
      const imgLibReqFile: AdxImageLibraryRequestFile = new AdxImageLibraryRequestFile(null,
        fileToSave.name, fileToSave.description, false, null, fileToSave.tags);
      if (fileToSave.file) {
        imgLibReqFile.file = fileToSave.file;
      }
      imgLibRequest.images.push(imgLibReqFile);
    }
    this.imgLibService.addImageToOrgLibrary(this.libType, imgLibRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (data instanceof Array) {
            data.forEach((item: AdxImageLibraryItem) => {
              if (item.preSignedUrl != null) {
                this.filesURLs.push(item.preSignedUrl);
              }
            });
          }
        },
        error: () => {
          this.dialogRef.close();
        },
        complete: () => {
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `Images created successfully`);
          this.dialogRef.close(this.filesURLs);
        }
      });
  }

  /**
   * callback used to edit the image. This will popup a dialog where user can change details and crop file.
   *
   * @param element: The file selected for editing
   */
  editImage(element: InternalDataFile): void {
    const dialogRef = this.editDialog.open(AdxCropperComponent, {
      height: '75vh',
      width: '50vh',
      data: {
        selectedDataFile: element,
        isIcon: this.isIcon
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result instanceof InternalDataFile) {
        this.logger.debug(`name: ${result.name};; size: ${result.size};; pos:${result.position};; desc: ${result.description}`);
        this.imageFiles[result.position] = result;
        this.dataSource.data = this.imageFiles;
      }
    });
  }

  private onDrop(files: FileList): void {
    this.logger.debug('onDrop');
    this.isError = false;

    if (files !== null && this.data.crop && files.length === 1) {
      const f: File | null = files.item(0);
      if (f !== null && f.type.split('/')[0] === 'image') {
        this.imageFile = f;
      }
      return;
    }

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const f: File | null = files.item(i);
        if (f != null) {
          this.addImageToList(f, i);
        }
      }
    }

    this.logger.debug(files);
  }

  /*
  * Utility to get image as compressed base64 string
  */
  private async transformImages() {
    let images = await Promise.all(this.imageFiles.map((file) => this.readAsDataURL(file)));
    this.logger.debug(`files uploaded: ${images}`);
    images = await Promise.all(images.map((image) => this.compressImage(image)));
    return images;
  }

  /*
   * Utility to get base64 string for the image file
   */
  private readAsDataURL(dataFile: InternalDataFile): Promise<InternalDataFile> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        dataFile.url = fileReader.result as string;
        resolve(dataFile);
      };
      if (dataFile.file) {
        try {
          fileReader.readAsDataURL(dataFile.file);
        } catch (e) {
          reject(`error while converting image file to dataURL: ${e}`);
        }
      }
    });
  }

  /*
   * Utility function to compress the image.
   * If maxWidth and maxHeight is specified, the image is resized to that height and width
  */
  private compressImage(dataFile: InternalDataFile): Promise<InternalDataFile> {
    this.logger.debug(`original size of image: ${dataFile.size} :: ${dataFile.url.length}`);
    return this.imageCompress.compressFile(dataFile.url, DOC_ORIENTATION.Default)
      .then((result: DataUrl) => {
        dataFile.url = result;
        this.logger.debug(`size of compressed image: ${dataFile.size} :: ${dataFile.url.length}`);
        return dataFile;
      });
  }

  /*
   * Utility to get file title without the file extension
   */
  private getFileTitle(name: string): string {
    if (name && name.lastIndexOf('.') !== -1) {
      return name.substring(0, name.lastIndexOf('.'));
    }
    return name;
  }

  /*
   * Utility to create object of type InternalDataFile from input data
   */
  private createInputDataFile(i: number, f: File, fileName: string): InternalDataFile {
    const imgFile = new InternalDataFile();
    imgFile.position = i;
    imgFile.file = f;
    imgFile.name = this.getFileTitle(fileName);
    imgFile.size = f.size;
    return imgFile;
  }

  /*
   * Validate file name. If any special characters replace with underscore.
   * Then create input-data-file and add to file-list.
   */
  private addImageToList(f: File, i: number) {
    // get the file name without the extension
    const fileNameSansExtension = f.name.substring(0, f.name.lastIndexOf('.'));
    // in the file name, replace special characters with underscores
    const changedFileName = FormUtility.replaceSpecialCharsWithUnderscore(fileNameSansExtension);
    const imgFile = this.createInputDataFile(i, f, changedFileName);
    this.imageFiles.push(imgFile);
  }
}
