<div class="img-lib">
  <div class="adx-imagelib-actions">
    <mat-toolbar>
        <span class='img-library-header'>System Image Library</span>
        <button mat-raised-button appAdxImageUpload [id]="id" [isIcon]="isIcon" style="margin-right: 1rem"
                [type]="libraryType" (imgUploadEmitter)="onImageUpload($event)" [orgId]="orgId" [accountId]="accountId" [applnId] ="applnId" [vpubId] ="vpubId"
                [moduleId] ="moduleId" [atomId]="atomId">Add Image</button>
      <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedView" (change)="onToggleChange(group.value)">
        <mat-button-toggle value="list" aria-label="List">
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="catalog" aria-label="Catalog">
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-toolbar>
    <button mat-icon-button (click)="onCloseClick()" style="margin-left: 0.25rem;" matTooltip="Close"><mat-icon>close</mat-icon></button>
  </div>
  <div class="adx-imagelib-content">

      <form class="adx-image-library-filter-select" [formGroup]="imageLibraryFilterForm">
  
        <mat-form-field>
          <input matInput placeholder="Search Image by Title/Tag" [formControl]="imageFilter">
        </mat-form-field>
  
        <div *ngIf="showFilter" style="display: contents;">
          <!--application list-->
         <mat-form-field class="adx-image-library-appln-filter">
         <mat-select [formControl]="applicationFilter" placeholder='Application' id="adx-image-library-appln-appln" [(value)]="filterValues.appId">
           <mat-option value=''>Select an Application</mat-option>
           <mat-option *ngFor="let obj of applications" matTooltip="{{obj.title}}" (onSelectionChange)="onApplicationChange(obj.id, $event)"
            [value]="obj.id" id="adx-image-library-appln-appln-{{obj.id}}">{{obj.title}}</mat-option>
         </mat-select>
       </mat-form-field>
   
       <!--vpub list-->
         <mat-form-field class="adx-image-library-add-vpub">
         <mat-select [formControl]="vpubFilter" placeholder='vPub' id="adx-image-library-vpub" [(value)]="filterValues.vpubId">
           <mat-option value=''>Select a vpub</mat-option>
           <mat-option *ngFor="let obj of vpubs" matTooltip="{{obj.title}}" (onSelectionChange)="onVpubChange(obj.id, $event)"
            [value]="obj.id" id="adx-image-library-vpub-{{obj.id}}">{{obj.title}}</mat-option>
         </mat-select>
       </mat-form-field>
   
       <!--module list-->
         <mat-form-field class="adx-image-library-add-module">
         <mat-select [formControl]="moduleFilter" placeholder='Module' id="adx-image-library-module" [(value)]="filterValues.moduleId">
           <mat-option value=''>Select a Module</mat-option>
           <mat-option *ngFor="let obj of modules" matTooltip="{{obj.title}}"
            [value]="obj.id" id="adx-image-library-module-{{obj.id}}">{{obj.title}}</mat-option>
         </mat-select>
       </mat-form-field> 
        </div>
      </form>

  <div *ngIf="selectedView === 'list'">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <img [src]="element.preSignedUrl" alt="image" />
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell i18n="@@name" *matHeaderCellDef>Image/Folder Name</mat-header-cell>
            <mat-cell i18n="@@name-val" *matCellDef="let element">
                {{ element.title }}
            </mat-cell>
        </ng-container>

      <ng-container matColumnDef="selectImage" *ngIf="isSelect">
        <mat-header-cell  *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button i18n="@@selectImage" mat-stroked-button (click)="onImageSelect(element)">Select</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deleteImage" *ngIf="!isSelect">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button i18n="@@deleteImage" mat-stroked-button (click)="onImageDelete(element)">Delete</button>
        </mat-cell>
      </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="selectedView !== 'list'" class="card-list">
    <div *ngFor="let item of dataSource.filteredData" class="card-view">
      <div class="card-title" [matTooltip]="item.title">{{item.title}}</div>
      <img class="card-image" mat-card-image src="{{item.preSignedUrl}}" alt="{{item.title}}"/>
      <div>
        <button *ngIf="isSelect" i18n="@@selectImage" mat-stroked-button (click)="onImageSelect(item)">Select</button>
        <button *ngIf="!isSelect" i18n="@@deleteImage" mat-stroked-button (click)="onImageDelete(item)">Delete</button>
      </div>
    </div>
  </div>
</div>
  <div mat-dialog-actions class="button-close">
    <button mat-raised-button type="button" (click)="onCloseClick()">Close</button>
  </div>
</div>
