
<div class="common-container">
  <div class="module-list-container" fxLayout="column" fxFlex="100">
    <div>
      <mat-toolbar class="vpub-name" fxLayoutAlign="center center">
        <span i18n="@@orgName" matTooltip="{{orgTitle}}">{{orgTitle}}</span>
      </mat-toolbar>
    </div>

    <div class="common-container">
      <mat-tab-group class="common-tab-group" [selectedIndex]="0">

        <mat-tab i18n="@@accounts-label" label="Accounts">
          <ng-template matTabContent>
          <router-outlet></router-outlet>
          </ng-template>
        </mat-tab>

        <mat-tab label="User Management" *ngIf="hasPermissionToUserManagement()">
          <ng-template matTabContent>
            <app-adx-user-manage [orgId]="orgId"></app-adx-user-manage>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>

  </div>

</div>


