import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AdxModule} from '../../adx-module/model/adx-module.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxArs} from '../../adx-ars/model/adx-ars-model';

@Injectable({
  providedIn: 'root'
})
export class AdxSimulatorService {

  selectedModule: AdxModule = new AdxModule(-1);
  moduleList: AdxModule[] = [];
  atomList: AdxArs[] = [];
  selectedAtom: AdxArs | null = null;
  contentUpdated: Subject<boolean> = new Subject();

  constructor() { }

  updateSelectedModule(selMod: AdxModule): void {
    if (selMod.visibility === AdxVisibilityType.VISIBLE) {
      this.selectedModule = (selMod);
      this.contentUpdated.next(true);
    }
  }

  updateModuleList(modList: AdxModule[]): void {
    const visibleModules = modList.filter(mod => mod.visibility === AdxVisibilityType.VISIBLE);
    this.moduleList = (visibleModules);
    if (visibleModules.length > 0) {
      this.updateSelectedModule(visibleModules[0]);
    }
    this.contentUpdated.next(true);
  }

  updateAtomList(atomList: AdxArs[]): void {
    const visibleAtoms = atomList.filter(atom => atom.visibility === AdxVisibilityType.VISIBLE);
    this.atomList = (visibleAtoms);
    this.contentUpdated.next(true);
  }

  updateAtom(atom: AdxArs | null): void {
    this.selectedAtom = atom;
    this.contentUpdated.next(true);
  }
}
