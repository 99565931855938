
export class DelinkChannelApplicationRequest {
  channelId: number;
  applicationIds: number[] = [];

  constructor(channelId: number) {
    this.channelId = channelId;
  }

  /**
   * API to add application id to the list of applicationIds that need to be delinked from the channelId
   *
   * @param applnId: number
   */
  addApplicationId(applnId: number): void {
    if (!this.applicationIds) {
      this.applicationIds = [];
    }
    this.applicationIds.push(applnId);
  }
}
