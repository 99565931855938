import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdxArs} from '../../adx-ars/model/adx-ars-model';
import {NGXLogger} from 'ngx-logger';
import {AdxSimulatorService} from '../service/adx-simulator.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-atom-simulator',
  templateUrl: './atom-simulator.component.html',
  styleUrls: ['./atom-simulator.component.scss']
})
export class AtomSimulatorComponent implements OnInit, OnDestroy {

  @Input() atom: AdxArs | null = null;
  changeSubscription: Subscription | undefined;

  constructor(private logger: NGXLogger, private simulatorService: AdxSimulatorService) { }

  ngOnInit(): void {
    this.changeSubscription = this.simulatorService.contentUpdated.subscribe((data: boolean) => {
      this.logger.debug('ArsSimulatorComponent Refresh received');
      this.atom = this.simulatorService.selectedAtom;
    });
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

}
