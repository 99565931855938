import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CustomErrorNotifierService } from './core/service/error-handler/custom-error-notifier.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ADX console';

  showHeader = true;
  showSidebar = true;
  showFooter = true;

  errorMessage?: string | null;

  private errorSubscription: Subscription | undefined;

  constructor(private logger: NGXLogger, private router: Router,
              private activatedRoute: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private errorBar: MatSnackBar,
              private errorNotifier: CustomErrorNotifierService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.logger.debug(event.url);
        this.showHeader = !(event.url.startsWith('/info'));
        this.logger.debug(`this.showHeader : ${this.showHeader}`);
      }
    }).then(r => {});
  }

  ngOnInit(): void {
    this.errorSubscription = this.errorNotifier.errorSubject.subscribe(
      (msg: string) => {
        this.logger.debug('received error event' + msg);
        this.openSnackBar(msg, 'Dismiss'); // display error bar at the top of the screen
      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.activatedRoute.firstChild != null) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
        }
        else {
          this.logger.debug('error!! activatedRoute not initialized correctly');
        }
      }
    });
  }


  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe();
  }

  private openSnackBar(message: string, action: string): void {
    this.errorBar.open(message, action, {
      duration: 6000, // time in milliseconds to close the snackbar
      verticalPosition: 'top', // display bar on the top
      horizontalPosition: 'end',
      panelClass: ['red-snackbar'] // css in global.scss to display with reb background
    });
  }
}
