import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {

  production: true,
  appName: require('../../package.json').name,
  version: require('../../package.json').version,
  environment: 'P1US1',
  dalBaseUrl: 'https://p1us1.conveyerweb.com/gadgetweb', // gadgetone_direct_access DAL
  apiUrl: 'https://p1us1.conveyerweb.com/producer', // producer
  logApiUrl: 'https://p1us1.conveyerweb.com/producer', // producer
  logLevel: NgxLoggerLevel.FATAL,
  serverLogLevel: NgxLoggerLevel.OFF,
  infoSecret: '635f727f-00f8-4b93-afb6-1b8c62367da3',

  recaptcha: {
    siteKey: '6LcxYBgoAAAAADwMR6f24xcdpt3WBQ9a505E0LXw',
  },
};
