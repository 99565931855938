import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VpubComponent} from './vpub.component';
import {VpubListComponent} from './vpub-list/vpub-list.component';
import {AddVpubComponent} from './add-vpub/add-vpub.component';
import {EditVpubComponent} from './edit-vpub/edit-vpub.component';
import {AdxVpubResolver} from './resolver/adx-vpub.resolver';


const vpubRoutes: Routes = [
  {
    path: '',
    component: VpubComponent,
    children: [
      { path: '', component: VpubListComponent },
      {
        path: 'add',
        component: AddVpubComponent
      },
      {
        path: 'edit/:vpubId',
        component: EditVpubComponent,
        resolve: { vpub: AdxVpubResolver }
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(vpubRoutes)],
  exports: [RouterModule]
})
export class VpubRoutingModule { }
