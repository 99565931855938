import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { Address } from 'src/app/common/model/address.model';
import { AdxStyle } from 'src/app/common/model/adx-style.model';
import { AdxTimezone } from 'src/app/common/model/adx-timezone.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxModuleType} from '../../adx-module/model/adx-module-type';
import {AdxModuleUtility} from '../../common/utils/adx-module-utility';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';

/**
 * This is Organization model class
 */
export class Organization extends AdxResource {

  public title: string | null = null;
  public secret: string;
  public description: string | null = null;
  public iconImage: AdxImage | null = null;
  public helpInfo: AdxHelpInfo | null = null;
  public aboutUsInfo: AdxAboutUsInfo | null = null;
  public timeZone: AdxTimezone | null = null;
  public timeZoneId: number | null = null;
  public defaultLocale: AdxLocale | null = null;
  public locales: AdxLocale[] | null = null;
  public style: AdxStyle | null = null;
  public address: Address | null = null;
  public excludedModuleTypes: string | null = null;
  public accountName: string | null = null;
  public accountNumber: string | null = null;
  public notes: string | null = null;
  public startDate: string | null = null;
  public endDate: string | null = null;
  public websiteUrl: string | null = null;
  public loginOptions: any[] | null = null;
  public emailDomainRestriction: string | null = null;
  public isEmailConfigured = false;
  public verifyConsumerSignup: boolean | null = null;
  public createdByUser: number | null = null;
  public updatedByUser: number | null = null;
  public emailDomainOption: string | null = "";
  public emailDomains: string | null = "";

  smtpHost: string | null = null;
  smtpPort: string | null = null;
  smtpUsername: string | null = null;
  smtpPassword: string | null = null;
  senderEmail: string | null = null;

  pushNotificationProjectName: string | null = null;
  pushNotificationProjectFile: File | undefined;
  pushNotifProjectFileName: string | null = null;
  pushNotifApiKey: string | null = null;
  pushNotifAppId: string | null = null;
  pushNotifAuthDomain: string | null = null;
  pushNotifProjectId: string | null = null;
  pushNotifMessagingSenderId: string | null = null;
  pushNotifStorageBucket: string | null = null;

  constructor(
    id: number | null,
    secret: string
  ) {
    super(id);
    this.secret = secret;
  }

  public get orgAvailableModules(): { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] {
    if (this.excludedModuleTypes === undefined || this.excludedModuleTypes === null
      || this.excludedModuleTypes?.trim().length === 0) {
      return this.getAllModules();
    } else {
      const includedModules: { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] = [];
      // remove the excluded modules from available modules
      let excludedModules: string[] | undefined = this.excludedModuleTypes?.split(',');
      if (!excludedModules && this.excludedModuleTypes && this.excludedModuleTypes?.length > 0) {
        excludedModules = [];
        excludedModules.push(this.excludedModuleTypes);
      }

      for (const module of this.getAllModules()) {
        let isExcluded = false;
        for (const excludedModule of excludedModules) {
          if (module.type.toString().toLowerCase() === (excludedModule.toLowerCase())) {
            isExcluded = true;
            break;
          }
        }
        if (isExcluded === false) {
          includedModules.push(module);
        }
      }
      return includedModules;
    }
  }

  public getAllModules(): {displayName: string; type: AdxModuleType; icon: AdxImage | null}[] {
    return AdxModuleUtility.getAllModules();
  }
}
