import {AdxResource} from '../../core/service/resource/adx-resource.model';

export class AdxHtml extends AdxResource {

  title: string | null = null;
  description: string | null = null;
  isActive: boolean  = false;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxHtml {
    const toRet: AdxHtml = new AdxHtml(json.id);

    if (json.title) {
      toRet.title = json.title;
    }

    if (json.description) {
      toRet.description = json.description;
    }

    //adding isActive key, in order to read whether the module is published or not
    if (json.isActive) {
      toRet.isActive = json.isActive;
    }
    return toRet;
  }
}
