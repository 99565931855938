import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../common/material/material.module';
import { NgxColorsModule } from 'ngx-colors';

import { TreeTableModule } from 'primeng/treetable';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';

import { AdxModuleListComponent } from './module-list/adx-module-list.component';
import { AddMultipleAdxModuleComponent } from './add-multiple-module/add-multiple-adx-module.component';
import { AddAdxModuleGroupComponent } from './add-module-group/add-adx-module-group.component';
import { AdxSimulatorModule } from '../adx-simulator/adx-simulator.module';
import { AdxModuleComponent } from './adx-module.component';
import { AdxModuleRoutingModule } from './adx-module-routing.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AdxControlsModule} from '../shared';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import { AdxEditModuleComponent } from './adx-edit-module/adx-edit-module.component';
import { ManageWebComponent } from './manage-web/manage-web.component';
import { ManageHtmlComponent } from './manage-html/manage-html.component';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import { AdxAddModuleComponent } from './adx-add-module/adx-add-module/adx-add-module.component';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';
import {AdxPagesModule} from '../shared/pages';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        RouterModule,
        MatFormFieldModule,
        MaterialModule,
        NgxColorsModule,
        AdxSimulatorModule,
        TreeTableModule,
        ButtonModule,
        DialogModule,
        MultiSelectModule,
        InputTextModule,
        ToastModule,
        ContextMenuModule,
        AdxModuleRoutingModule,
        ReactiveFormsModule,
        AdxControlsModule,
        AngularDualListBoxModule,
        AngularStickyThingsModule,
        FroalaEditorModule,
        AdxPagesModule
    ],
  exports: [],
  providers: [
  ],
  declarations: [
    AdxModuleListComponent,
    AddMultipleAdxModuleComponent,
    AddAdxModuleGroupComponent,
    AdxModuleComponent,
    AdxEditModuleComponent,
    ManageWebComponent,
    ManageHtmlComponent,
    AdxAddModuleComponent,
  ],
})
export class AdxMdlModule {}
