import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  isassociationsTableOpen: any;
  isassociationsDivOpen: any;
  associatedata: Map<string, Array<string>> = new Map([
    ['Organization One', ['Account One', 'Account Two']],
    ['Organization Two', []],
    ['Organization Three', ['Account One']],
    [
      'Organization Four',
      ['Account One', 'Account Two', 'Account Three', 'Account Four'],
    ],
  ]);

  dataSourceAssociate: any;
  displayedColumnsAssociate: any;
  isBulkDisassociationOpen: any;
  isBulkAssociationOpen: any;
  orgchecked: any;

  constructor() { }

  ngOnInit(): void {
  }

  openBulkDisassociationDialog(): void {

  }

  openBulkAssociationDialog(): void {

  }

  closeBulkAssociationDialog(): void {

  }

  closeBulkDisassociationDialog(): void {

  }

  disassociate(f: NgForm): void {

  }
}
