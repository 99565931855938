import {AdxImageLibraryRequestFile} from './adx-image-library-request-file';
import {AdxResource} from '../../../core/service/resource/adx-resource.model';

/**
 * The request object used in add image to image library
 */
export class AdxImageLibraryRequest extends AdxResource {
  organizationId: number | null = null;
  accountId: number | null = null;
  applicationId: number | null = null; // adding additional info in save image payload which helps filtering the image by level.
  vpubId: number | null = null;
  moduleId: number | null = null;
  atomId: number | null = null;
  images: AdxImageLibraryRequestFile[] = [];
  ids: number[] = [];

  constructor() {
    super(NaN);
  }

}
