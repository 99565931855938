import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AdxLocaleSelection} from '../../../common/model/locale/adx-locale-selection';
import {AdxLocale} from '../../../common/model/locale/adx-locale.model';
import {MatSelectChange} from '@angular/material/select';
import {NGXLogger} from 'ngx-logger';

/**
 * This component is used to for locale selection.
 * This will display default locale (combo box), and list of available locales (dual list).
 * When user changes the selected locale list, selectionUpdate event is emitted.
 * When user changes the default Locale defaultUpdate event is emitted.
 * This component can be used as form field, and it implements ControlValueAccessor
 * If there is a need to set image on initialization, can be done inside a ReactiveForm of caller.
 */
@Component({
  selector: 'app-adx-locale-input',
  templateUrl: './adx-locale-input.component.html',
  styleUrls: ['./adx-locale-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdxLocaleInputComponent),
      multi: true
    }
  ]
})
export class AdxLocaleInputComponent implements OnInit, ControlValueAccessor {

  @Input() locales: AdxLocale[] = []; // all locales
  @Output() selectionUpdate = new EventEmitter<AdxLocale[]>(); // output selected locales
  @Output() defaultUpdate = new EventEmitter<AdxLocale>(); // output default locale

  defaultLocale: AdxLocale | null = null; // default locale selected
  selectedLocales: AdxLocale[] = []; // selected list of locales
  localeOptions: AdxLocale[] = []; // list of locales to be displayed in combobox
  key = 'id';
  display = 'displayName';

  localeSelection: AdxLocaleSelection | null = null; // used to initialize component with data

  private touched = false; // to support dirty flag in the form field
  private disabled = false; // to support disabled flag in form field

  constructor(private logger: NGXLogger) { }

  ngOnInit(): void {
    if (this.localeSelection) {
      this.defaultLocale = this.localeSelection?.defaultLocale;
      if (this.localeSelection?.locales) {
        this.selectedLocales = this.localeSelection?.locales;
        this.localeOptions = this.selectedLocales.filter(i => i.id !== this.defaultLocale?.id);
      }
    }
  }

  onChange = (selectedLocales: AdxLocale[] | null) => {}; // call back called by parent, on data change in form field
  onTouched = () => {}; // call back called by parent, on field usage in form field

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: AdxLocaleSelection): void {
    if (obj) {
      this.localeSelection = obj;
      this.defaultLocale = this.localeSelection.defaultLocale;
      if (this.localeSelection.locales) {
        this.selectedLocales = this.localeSelection.locales;
        this.localeOptions = this.selectedLocales.filter(i => i.id !== this.defaultLocale?.id);
      }
      else {
        if (this.defaultLocale instanceof AdxLocale) {
          this.selectedLocales.push(this.defaultLocale);
        }
      }
    }
  }

  /**
   * As the select box displays list of objects, this is used for comparing objects of the option list.
   *
   * @param option
   * @param value
   */
  objectComparisonFunction(option: AdxLocale, value: AdxLocale): boolean {
    return option.id === value.id;
  }

  /**
   * Select box change handler
   *
   * @param $event
   */
  onDefaultChange($event: MatSelectChange): void {
    this.logger.debug('onDefaultChange Select box modified');
    this.markAsTouched();
    if (!this.disabled) {
      if (this.defaultLocale) {
        this.localeOptions = this.selectedLocales.filter(i => i.id !== this.defaultLocale?.id);
        this.onChange(this.selectedLocales);
        this.defaultUpdate.emit(this.defaultLocale);
      }
    }
  }

  /**
   * Dual box change handler
   *
   * @param $event
   */
  onSelectionChange($event: any): void {
    this.logger.debug('onSelectionChange Dual box modified');
    this.markAsTouched();
    if (!this.disabled) {
      if (this.selectedLocales) {
        this.localeOptions = this.selectedLocales.filter(i => i.id !== this.defaultLocale?.id);
        this.onChange(this.selectedLocales);
        this.selectionUpdate.emit(this.selectedLocales);
      }
    }
  }

  private markAsTouched(): void {
    if (!this.touched) { // if already set, no need to set flag again
      this.onTouched();
      this.touched = true;
    }
  }
}
