import {Component, Input, OnInit} from '@angular/core';
import {AdxDisplayLevel} from '../../../common/model/data-transfer/adx-display-level';
import {MatTableDataSource} from '@angular/material/table';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {AdxDialogService} from '../../../common/services/dialog/adx-dialog.service';
import {CustomMessageType} from '../../../core/service/notifier/custom-message-type';
import {takeUntil} from 'rxjs/operators';
import {AdxContentReport} from '../../../common/model/adx-content-report/adx-content-report-model';
import {AdxContentReportService} from '../../../common/services/adx-content-report/adx-content-report.service';
import {
  AdxContentReportCreateDialogComponent
} from '../../popups/adx-content-report-create-dialog/adx-content-report-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CommonUtility} from '../../../common/utils/common-utils';

/**
 * Displays the content report list retrieved from backend. Also, provides ability for users to Create Content.
 * Users can filter the contents and refresh the page.
 */
@Component({
  selector: 'app-adx-content-report',
  templateUrl: './adx-content-report.component.html',
  styleUrls: ['./adx-content-report.component.scss']
})
export class AdxContentReportComponent implements OnInit {

  @Input() orgId: number | null = null; // need to be supplied by caller
  @Input() accountId: number | null = null;
  @Input() vpubId: number | null = null; // either one of vPubId or accntId need to be specified
  @Input() displayLevel: AdxDisplayLevel = AdxDisplayLevel.ACCOUNT_LEVEL;

  displayedColumns: string[] = [
    'id',
    'type',
    'status',
    'username',
    'download'
  ];
  dataSource = new MatTableDataSource<AdxContentReport>();

  destroy$: Subject<boolean> = new Subject<boolean>();
  urlForAcctListing = '/orgs';
  statusFilter = new UntypedFormControl('');
  typeFilter = new UntypedFormControl('');
  filterValues: any = {
    status: '',
    type: ''
  };
  private statusFilterSubscription: Subscription | null = null;
  private typeFilterSubscription: Subscription | null = null;

  constructor(private logger: NGXLogger, private matDialog: MatDialog,
              private contentReportService: AdxContentReportService,
              private dialogService: AdxDialogService) {
  }

  ngOnInit(): void {
    this.loadData();
    this.logger.debug(`displayLevel: ${this.displayLevel}`);
  }

  /**
   * Callback that will display a popup dialog to display status details
   *
   * @param element: The object for which status description needs to be displayed
   */
  showStatusDetails(element: AdxContentReport) {
    if (element && element.statusDescription) {
      this.dialogService.alertDialog({
        title: 'Details',
        message: element.statusDescription,
        submessage: null,
        type: CustomMessageType.INFO
      });
    }
  }

  /**
   * returns true if display level is account level
   */
  isAccountDisplayLevel(): boolean {
    return this.displayLevel === AdxDisplayLevel.ACCOUNT_LEVEL;
  }

  /**
   * returns true if display level is vPub level
   */
  isVpubDisplayLevel(): boolean {
    return this.displayLevel === AdxDisplayLevel.VPUB_LEVEL;
  }

  /**
   * Used to fetch content report list from backend. Called from init and refresh
   */
  loadData(): void {
    if (this.orgId === undefined || this.orgId === null) {
      this.logger.error('required params missing');
      return;
    }

    if (this.isVpubDisplayLevel()) {
      this.fetchContentReportStatus(this.orgId, null, this.vpubId);
    } else {
      this.fetchContentReportStatus(this.orgId, this.accountId, null);
    }
    this.fieldListener();
  }

  /**
   * Callback to display content report creation popup
   */
  createContentReport() {
    const dialogRef = this.matDialog.open(AdxContentReportCreateDialogComponent, {
      width: '6000px',
      maxHeight: '90vh',
      data: {displayLevel: this.displayLevel, orgId: this.orgId, acctId: this.accountId, vpubId: this.vpubId},
      autoFocus: 'false'
    });

    // on close of dialog, refresh the listing page
    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug(`The dialog was closed: ${result}`);
      if (result) {
        this.loadData();
      }
    });
  }

  /*
   * This method fetches the content report list from backend
  */
  private fetchContentReportStatus(orgId: number, acctId: number | null,
                                   vpubId: number | null): Subscription {

    this.logger.debug('fetching content report for orgId:' + orgId);
    return this.contentReportService.getContentReportStatus(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxContentReport[]) => {
          // sort it by ID and set it in variable
          CommonUtility.sortByIdDescending(data);
          this.dataSource.data = data;
          this.dataSource.filterPredicate = this.createFilterFunction();
        }
      });
  }

  /*
   * This method creates function used to filter contents of data table.
   * The returned function is used as filterPredicate.
   * The returned function is called once for each row of the data table.
   */
  private createFilterFunction(): (contentReport: AdxContentReport, filter: string) => boolean {
    const filterFunction = (contentReportObj: AdxContentReport, filter: string): boolean => {

      const searchTerms = JSON.parse(filter);

      let statusValue = '';
      let statusFlag = -1;
      if (contentReportObj && contentReportObj.status) {
        statusValue = contentReportObj.status;
        // there are values like SUCCESS and VALIDATION_SUCCESS. Hence, cannot just rely on indexOf
        if (searchTerms.status.length === 0 || (statusValue.length === searchTerms.status.length)) {
          statusFlag = (statusValue.toLowerCase().indexOf(searchTerms.status.toLowerCase()));
        }
      }

      let typeValue = '';
      let typeFlag = -1;
      if (contentReportObj && contentReportObj.reportType) {
        typeValue = contentReportObj.reportType;
        typeFlag = (typeValue.toLowerCase().indexOf(searchTerms.type.toLowerCase()));
      }

      return (-1 !== typeFlag) && (-1 !== statusFlag);
    };

    return filterFunction;
  }

  /*
   * The filter fields are form controls.
   * Here value changes listeners are defined for these controls.
   */
  private fieldListener(): void {
    this.statusFilterSubscription = this.statusFilter.valueChanges
      .subscribe({
          next: status => {
            this.filterValues.status = status;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
    this.typeFilterSubscription = this.typeFilter.valueChanges
      .subscribe({
          next: value => {
            this.filterValues.type = value;
            if (this.dataSource) {
              this.dataSource.filter = JSON.stringify(this.filterValues);
            }
          }
        }
      );
  }
}
