import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';

/**
 * Model storing the AuthPermissions as got in the authenticated token
 */
/* eslint-disable @typescript-eslint/naming-convention */
export class AdxAuthUserPermission extends AdxResource {

  ORGANIZATION_READ_ONLY: number[] | null = null;
  ORGANIZATION_EDIT: number[] | null = null;
  ORGANIZATION_ADD: number[] | null = null;
  ORGANIZATION_ACCESS: number[] | null = null;
  ORGANIZATION_ADMIN: number[] | null = null;
  ORGANIZATION_DELETE: number[] | null = null;

  ACCOUNT_ADMIN: number[] | null = null;
  ACCOUNT_ADD: number[] | null = null;
  ACCOUNT_READ_ONLY: number[] | null = null;
  ACCOUNT_EDIT: number[] | null = null;
  ACCOUNT_ACCESS: number[] | null = null;

  APPLICATION_DELETE: number[] | null = null;
  APPLICATION_EDIT: number[] | null = null;
  APPLICATION_ACCESS: number[] | null = null;
  APPLICATION_READ_ONLY: number[] | null = null;
  APPLICATION_ADMIN: number[] | null = null;
  APPLICATION_ADD: number[] | null = null;

  CHANNEL_DELETE: number[] | null = null;
  CHANNEL_ADD: number[] | null = null;
  CHANNEL_EDIT: number[] | null = null;
  CHANNEL_READ_ONLY: number[] | null = null;
  CHANNEL_ADMIN: number[] | null = null;

  SYSTEM_READ_ONLY: number[] | null = null;
  SYSTEM_ADMIN: number[] | null = null;
  INSTANCE_ADMIN: number[] | null = null;

  VPUB_EDIT: number[] | null = null;
  VPUB_ADMIN: number[] | null = null;
  VPUB_DELETE: number[] | null = null;
  VPUB_READ_ONLY: number[] | null = null;
  VPUB_ACCESS: number[] | null = null;
  VPUB_ADD: number[] | null = null;

  MODULE_DELETE: number[] | null = null;
  MODULE_ACCESS: number[] | null = null;
  MODULE_EDIT: number[] | null = null;
  MODULE_ADMIN: number[] | null = null;
  MODULE_ADD: number[] | null = null;
  MODULE_READ_ONLY: number[] | null = null;

  FORM_EDIT: number[] | null = null;
  FORM_READ_ONLY: number[] | null = null;

  ATOM_READ_ONLY: number[] | null = null;
  ACCOUNT_DELETE: number[] | null = null;
  ATOM_DELETE: number[] | null = null;
  ATOM_EDIT: number[] | null = null;
  ATOM_ADD: number[] | null = null;


  constructor(id: number | null) {
    super(id);
  }

  /**
   * Builds the AdxAuthUserPermission object from given json
   * @param json
   * @returns AdxAuthUserPermission object
   */
  static buildObject(json: any): AdxAuthUserPermission {
    const userPermissions: AdxAuthUserPermission = new AdxAuthUserPermission(null);
    if (json) {
      if (json.id !== undefined && json.id !== null) {
        userPermissions.id = json.id;
      }
      if (json.ACCOUNT_ACCESS !== undefined && json.ACCOUNT_ACCESS !== null) {
        userPermissions.ACCOUNT_ACCESS = json.ACCOUNT_ACCESS;
      }
      if (json.ACCOUNT_ADD !== undefined && json.ACCOUNT_ADD !== null) {
        userPermissions.ACCOUNT_ADD = json.ACCOUNT_ADD;
      }
      if (json.ACCOUNT_ADMIN !== undefined && json.ACCOUNT_ADMIN !== null) {
        userPermissions.ACCOUNT_ADMIN = json.ACCOUNT_ADMIN;
      }
      if (json.ACCOUNT_DELETE !== undefined && json.ACCOUNT_DELETE !== null) {
        userPermissions.ACCOUNT_DELETE = json.ACCOUNT_DELETE;
      }
      if (json.ACCOUNT_EDIT !== undefined && json.ACCOUNT_EDIT !== null) {
        userPermissions.ACCOUNT_EDIT = json.ACCOUNT_EDIT;
      }
      if (json.ACCOUNT_READ_ONLY !== undefined && json.ACCOUNT_READ_ONLY !== null) {
        userPermissions.ACCOUNT_READ_ONLY = json.ACCOUNT_READ_ONLY;
      }
      if (json.APPLICATION_ACCESS !== undefined && json.APPLICATION_ACCESS !== null) {
        userPermissions.APPLICATION_ACCESS = json.APPLICATION_ACCESS;
      }
      if (json.APPLICATION_ADD !== undefined && json.APPLICATION_ADD !== null) {
        userPermissions.APPLICATION_ADD = json.APPLICATION_ADD;
      }
      if (json.APPLICATION_ADMIN !== undefined && json.APPLICATION_ADMIN !== null) {
        userPermissions.APPLICATION_ADMIN = json.APPLICATION_ADMIN;
      }
      if (json.APPLICATION_EDIT !== undefined && json.APPLICATION_EDIT !== null) {
        userPermissions.APPLICATION_EDIT = json.APPLICATION_EDIT;
      }
      if (json.APPLICATION_DELETE !== undefined && json.APPLICATION_DELETE !== null) {
        userPermissions.APPLICATION_DELETE = json.APPLICATION_DELETE;
      }
      if (json.APPLICATION_READ_ONLY !== undefined && json.APPLICATION_READ_ONLY !== null) {
        userPermissions.APPLICATION_READ_ONLY = json.APPLICATION_READ_ONLY;
      }
      if (json.ATOM_ADD !== undefined && json.ATOM_ADD !== null) {
        userPermissions.ATOM_ADD = json.ATOM_ADD;
      }
      if (json.ATOM_DELETE !== undefined && json.ATOM_DELETE !== null) {
        userPermissions.ATOM_DELETE = json.ATOM_DELETE;
      }
      if (json.ATOM_EDIT !== undefined && json.ATOM_EDIT !== null) {
        userPermissions.ATOM_EDIT = json.ATOM_EDIT;
      }
      if (json.ATOM_READ_ONLY !== undefined && json.ATOM_READ_ONLY !== null) {
        userPermissions.ATOM_READ_ONLY = json.ATOM_READ_ONLY;
      }
      if (json.CHANNEL_ADD !== undefined && json.CHANNEL_ADD !== null) {
        userPermissions.CHANNEL_ADD = json.CHANNEL_ADD;
      }
      if (json.CHANNEL_ADMIN !== undefined && json.CHANNEL_ADMIN !== null) {
        userPermissions.CHANNEL_ADMIN = json.CHANNEL_ADMIN;
      }
      if (json.CHANNEL_DELETE !== undefined && json.CHANNEL_DELETE !== null) {
        userPermissions.CHANNEL_DELETE = json.CHANNEL_DELETE;
      }
      if (json.CHANNEL_EDIT !== undefined && json.CHANNEL_EDIT !== null) {
        userPermissions.CHANNEL_EDIT = json.CHANNEL_EDIT;
      }
      if (json.CHANNEL_READ_ONLY !== undefined && json.CHANNEL_READ_ONLY !== null) {
        userPermissions.CHANNEL_READ_ONLY = json.CHANNEL_READ_ONLY;
      }
      if (json.FORM_EDIT !== undefined && json.FORM_EDIT !== null) {
        userPermissions.FORM_EDIT = json.FORM_EDIT;
      }
      if (json.FORM_READ_ONLY !== undefined && json.FORM_READ_ONLY !== null) {
        userPermissions.FORM_READ_ONLY = json.FORM_READ_ONLY;
      }
      if (json.INSTANCE_ADMIN !== undefined && json.INSTANCE_ADMIN !== null) {
        userPermissions.INSTANCE_ADMIN = json.INSTANCE_ADMIN;
      }
      if (json.MODULE_ACCESS !== undefined && json.MODULE_ACCESS !== null) {
        userPermissions.MODULE_ACCESS = json.MODULE_ACCESS;
      }
      if (json.MODULE_ADD !== undefined && json.MODULE_ADD !== null) {
        userPermissions.MODULE_ADD = json.MODULE_ADD;
      }
      if (json.MODULE_ADMIN !== undefined && json.MODULE_ADMIN !== null) {
        userPermissions.MODULE_ADMIN = json.MODULE_ADMIN;
      }
      if (json.MODULE_DELETE !== undefined && json.MODULE_DELETE !== null) {
        userPermissions.MODULE_DELETE = json.MODULE_DELETE;
      }
      if (json.MODULE_EDIT !== undefined && json.MODULE_EDIT !== null) {
        userPermissions.MODULE_EDIT = json.MODULE_EDIT;
      }
      if (json.MODULE_READ_ONLY !== undefined && json.MODULE_READ_ONLY !== null) {
        userPermissions.MODULE_READ_ONLY = json.MODULE_READ_ONLY;
      }
      if (json.ORGANIZATION_ACCESS !== undefined && json.ORGANIZATION_ACCESS !== null) {
        userPermissions.ORGANIZATION_ACCESS = json.ORGANIZATION_ACCESS;
      }
      if (json.ORGANIZATION_ADD !== undefined && json.ORGANIZATION_ADD !== null) {
        userPermissions.ORGANIZATION_ADD = json.ORGANIZATION_ADD;
      }
      if (json.ORGANIZATION_ADMIN !== undefined && json.ORGANIZATION_ADMIN !== null) {
        userPermissions.ORGANIZATION_ADMIN = json.ORGANIZATION_ADMIN;
      }
      if (json.ORGANIZATION_DELETE !== undefined && json.ORGANIZATION_DELETE !== null) {
        userPermissions.ORGANIZATION_DELETE = json.ORGANIZATION_DELETE;
      }
      if (json.ORGANIZATION_EDIT !== undefined && json.ORGANIZATION_EDIT !== null) {
        userPermissions.ORGANIZATION_EDIT = json.ORGANIZATION_EDIT;
      }
      if (json.ORGANIZATION_READ_ONLY !== undefined && json.ORGANIZATION_READ_ONLY !== null) {
        userPermissions.ORGANIZATION_READ_ONLY = json.ORGANIZATION_READ_ONLY;
      }
      if (json.SYSTEM_READ_ONLY !== undefined && json.SYSTEM_READ_ONLY !== null) {
        userPermissions.SYSTEM_READ_ONLY = json.SYSTEM_READ_ONLY;
      }
      if (json.VPUB_ACCESS !== undefined && json.VPUB_ACCESS !== null) {
        userPermissions.VPUB_ACCESS = json.VPUB_ACCESS;
      }
      if (json.VPUB_ADD !== undefined && json.VPUB_ADD !== null) {
        userPermissions.VPUB_ADD = json.VPUB_ADD;
      }
      if (json.VPUB_ADMIN !== undefined && json.VPUB_ADMIN !== null) {
        userPermissions.VPUB_ADMIN = json.VPUB_ADMIN;
      }
      if (json.VPUB_DELETE !== undefined && json.VPUB_DELETE !== null) {
        userPermissions.VPUB_DELETE = json.VPUB_DELETE;
      }
      if (json.VPUB_EDIT !== undefined && json.VPUB_EDIT !== null) {
        userPermissions.VPUB_EDIT = json.VPUB_EDIT;
      }
      if (json.VPUB_READ_ONLY !== undefined && json.VPUB_READ_ONLY !== null) {
        userPermissions.VPUB_READ_ONLY = json.VPUB_READ_ONLY;
      }
      if (json.SYSTEM_ADMIN !== undefined && json.SYSTEM_ADMIN !== null) {
        userPermissions.SYSTEM_ADMIN = json.SYSTEM_ADMIN;
      }
    }
    return userPermissions;
  }
}
