import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AdxRestService} from '../../core/service/resource/adx-rest-service.service';
import {Observable, throwError} from 'rxjs';
import {AdxQueryParams} from '../../core/service/resource/adx-query-params.model';
import {catchError} from 'rxjs/operators';
import {AdxModule} from '../model/adx-module.model';
import {AdxModuleSerializer} from '../model/adx-module-serializer';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

@Injectable({
  providedIn: 'root'
})
export class AdxModuleService {

  private serializer: AdxModuleSerializer = new AdxModuleSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<AdxModule, AdxModule>) {
  }

  /*
   * This API is used to fetch the list of Modules from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getModules(orgId: number, accountId: number, vpubId: number): Observable<AdxModule[]> {
    this.logger.debug('Getting modules for orgId:' + orgId + ' ; appId:' + accountId);
    const fetchModulesUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls`;
    return this.restService.list(fetchModulesUrl, new AdxQueryParams(), null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          this.logger.debug(errorMsg);
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to fetch the Module from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getModule(orgId: number, accountId: number, vpubId: number, id: number): Observable<AdxModule> {
    const fetchModuleUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls`;
    return this.restService.read(fetchModuleUrl, id, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }

  /**
   * This API is used to add one module at a time.
   * @param orgId
   * @param accountId
   * @param vpubId
   * @param moduleToAdd
   */
  addModule(orgId: number, accountId: number, vpubId: number, moduleToAdd: AdxModule): Observable<AdxModule> {
    const addUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls`;
    return this.restService.create(addUrl, moduleToAdd, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }

  /*
   * This API is used to update the Module in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateModule(orgId: number, accountId: number, vpubId: number, moduleToUpdate: AdxModule): Observable<AdxModule> {
    const updateUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${moduleToUpdate.id}`;
    return this.restService.update(updateUrl, moduleToUpdate, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }

  /**
   * API used to Publish HTML Module
   *
   * @param orgId: Organization ID
   * @param accountId: Account ID
   * @param vpubId: vPub ID
   * @param moduleToUpdate: AdxModule data that needs to be updated
   */
  publishHtmlModule(orgId: number, accountId: number, vpubId: number, moduleToUpdate: AdxModule): Observable<AdxModule> {
    const updateUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/${moduleToUpdate.id}/html-publish`;
    return this.restService.update(updateUrl, moduleToUpdate, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }

  /**
   * API used to bulk delete modules
   *
   * @param orgId: org id
   * @param accountId: account id
   * @param vpubId: vpub id
   * @param delObj: object to be deleted
   */
  deleteModules(orgId: number, accountId: number, vpubId: number, delObj: AdxModule): Observable<AdxModule> {
    const fetchModuleUrl = `orgs/${orgId}/accts/${accountId}/vpubs/${vpubId}/mdls/delete-selected`;
    return this.restService.bulkDelete(fetchModuleUrl, delObj, null, this.serializer)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error: ${error.message}`;
          }
          return throwError(() => new Error(errorMsg));
        }));
  }

  /**
   * API that verifies whether the user has permission to view the module.
   *
   * @param orgId : number
   * @param acctId : number
   * @param applnId : number
   * @param vpubId : number
   * @param moduleId : number
   * @param currentUser : AthenticatedUser
   * @returns boolean - true if user has permission to view module
   */
  public canUserViewModule(orgId: number, acctId: number, applnId: number, vpubId: number,
    moduleId: number, currentUser: AuthenticatedUser): boolean {
   //if not logged-in send false
   if (currentUser === undefined || currentUser === null) {
     return false;
   }
   //if system or instance admin, return true
   if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
     this.logger.debug('sys/inst admin user, allowed to edit');
     return true;
   }
   if (currentUser !== undefined && currentUser !== null
     && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
     //first check for org admin
     const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
     if (isOrgAdmin) {
       return true;
     }

     //now check for account admin
     const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
     if (isAccountAdmin) {
       return true;
     }

     //now check for application admin
     const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
     if (isApplnAdmin) {
       return true;
     }

     //now check for vpub admin
     const isVpubAdmin = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser);
     if (isVpubAdmin) {
       return true;
     }

     //now check for module admin
     const isModuleAdmin = AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser);
     if (isModuleAdmin) {
      this.logger.debug(`ModuleId ${moduleId} in modue admin list, returning true`);
       return true;
     }

     //now check for whether module id in accessible list
     const accessibleModuleIds = this.getAccessiblModuleIdsForUser(currentUser);
     if (accessibleModuleIds !== undefined && accessibleModuleIds !== null && accessibleModuleIds.length > 0) {
      const foundModuleId = accessibleModuleIds.find(modId => modId === moduleId);
      if (foundModuleId !== undefined && foundModuleId !== null) {
        this.logger.debug(`ModuleId ${moduleId} in accessible list, returning true`);
        return true;
      }
     }
   }
   return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to add module
   * user with org/account/application/vpub admin or module add permission will be able to add module.
   * If user has system admin or instance admin role, then he will be able to add module
   *
   * @param orgId organization id
   * @param accountId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit account
   */
  public canUserAddModule(orgId: number, accountId: number, applnId: number, vpubId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub admin
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser)) {
        //means input vpubid is present in vpub-admin permission list for user. return true
        this.logger.debug('user has vpub admin permission');
        return true;
      }

      //now check for module add
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'MODULE_ADD', currentUser)) {
        //means input vpubId is present in module-add permission list for user. return true
        this.logger.debug('user has module add permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has permission to save module
   * user with org/account/application/vpub/module admin will be able to save module.
   * If user has system admin or instance admin role, then he will be able to save module
   *
   * @param orgId organization id
   * @param accountId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param moduleId module id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit account
   */
  public canUserSaveModule(orgId: number, accountId: number, applnId: number, vpubId: number,
    moduleId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to save');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub admin
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser)) {
        //means input vpubid is present in vpub-admin permission list for user. return true
        this.logger.debug('user has vpub admin permission');
        return true;
      }

      //now check for module admin
      if (AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser)) {
        //means input vpubId is present in module-admin permission list for user. return true
        this.logger.debug('user has module admin permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to delete module
   * user with org/account/application/vpub admin or module delete permission will be able to delete module.
   * If user has system admin or instance admin role, then he will be able to delete module
   *
   * @param orgId organization id
   * @param accountId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit vpub
   */
  public canUserDeleteModule(orgId: number, accountId: number, applnId: number, vpubId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account admin
      if (AdxAuthUtils.hasRoleForGivenId(accountId, 'ACCOUNT_ADMIN', currentUser)) {
        //means input accountid is present in account-admin permission list for user. return true
        this.logger.debug('user has account admin permission');
        return true;
      }

      //now check for application admin
      if (AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser)) {
        //means input applicationid is present in application-admin permission list for user. return true
        this.logger.debug('user has application admin permission');
        return true;
      }

      //now check for vpub admin
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser)) {
        //means input vpubid is present in vpub-admin permission list for user. return true
        this.logger.debug('user has vpub admin permission');
        return true;
      }

      //now check for module delete
      if (AdxAuthUtils.hasRoleForGivenId(vpubId, 'MODULE_DELETE', currentUser)) {
        //means input vpubId is present in module-delete permission list for user. return true
        this.logger.debug('user has module delete permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to edit module
   * user with org admin or account admin or application admin or vpub admin or module admin or module edit permission
   * will be able to edit module.If user has system admin or instance admin role, then he will be able to edit module
   *
   * @param orgId organization id
   * @param acctId account id
   * @param applnId application id
   * @param vpubId vpub id
   * @param moduleId module id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit vpub
   */
  public canUserEditModule(orgId: number, acctId: number, applnId: number, vpubId: number,
     moduleId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for application admin
      const isApplnAdmin = AdxAuthUtils.hasRoleForGivenId(applnId, 'APPLICATION_ADMIN', currentUser);
      if (isApplnAdmin) {
        return true;
      }

      //now check for vpub admin
      const isVpubAdmin = AdxAuthUtils.hasRoleForGivenId(vpubId, 'VPUB_ADMIN', currentUser);
      if (isVpubAdmin) {
        return true;
      }

      //now check for module admin
      const isModuleAdmin = AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_ADMIN', currentUser);
      if (isModuleAdmin) {
        return true;
      }

      //now check for vpub edit
      const isModuleEdit = AdxAuthUtils.hasRoleForGivenId(moduleId, 'MODULE_EDIT', currentUser);
      if (isModuleEdit) {
        return true;
      }
    }
    return false;
  }

  /*
   * Utility method get module ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organization/account/application/vpub admin or
   * organization/account/application/vpub readonly, then he can view all modules. That need to be handled by the caller method.
   * This method will only return module ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of modules accessible by user
   */
  private getAccessiblModuleIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleModuleIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleModules !== undefined && accessibleEntities.accessibleModules !== null) {
        userAccessibleModuleIds = accessibleEntities.accessibleModules;
      }
    }
    this.logger.debug(userAccessibleModuleIds);
    return userAccessibleModuleIds;
  }
}
