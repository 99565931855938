import { AdxResource } from '../../../core/service/resource/adx-resource.model';

export class AdxDataTransfer extends AdxResource {

  accountId: number | null = null;
  applicationId: number | null = null;
  vpubId: number | null = null;
  moduleId: number | null = null;
  refNo1: number | null = null;
  refNo2: number | null = null;
  refNo3: number | null = null;
  type: string | null = null;
  dataType: string | null = null;
  template: string | null = null;
  fileName: string | null = null;
  mimeType: string | null = null;
  objectUrl: string | null = null;
  folderName: string | null = null;
  inputFileName: string | null = null;
  zipFileName: string | null = null;
  zipFileUrl: string | null = null;
  timeTakenForProcessing: string | null = null;
  timeTakenForImageExtraction: string | null = null;
  timeTakenForTableExtraction: string | null = null;
  aceProcessToRun: string | null = null;
  availableProcesses: string | null = null;
  totalNumberOfPages: number | null = null;
  executionTime: string | null = null;
  status: string | null = null;
  statusDescription: string | null = null;
  vpubTitle: string | null = null;
  moduleTitle: number | null = null;
  username: number | null = null;
  createdOn: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxDataTransfer {
    const tempDataTransfer = new AdxDataTransfer(null);
    if (json) {
      if (json.id) {
        tempDataTransfer.id = json.id;
      }
      if (json.accountId) {
        tempDataTransfer.accountId = json.accountId;
      }
      if (json.applicationId) {
        tempDataTransfer.applicationId = json.applicationId;
      }
      if (json.vpubId) {
        tempDataTransfer.vpubId = json.vpubId;
      }
      if (json.moduleId) {
        tempDataTransfer.moduleId = json.moduleId;
      }
      if (json.refNo1) {
        tempDataTransfer.refNo1 = json.refNo1;
      }
      if (json.refNo2) {
        tempDataTransfer.refNo2 = json.refNo2;
      }
      if (json.refNo3) {
        tempDataTransfer.refNo3 = json.refNo3;
      }
      if (json.type) {
        tempDataTransfer.type = json.type;
      }
      if (json.dataType) {
        tempDataTransfer.dataType = json.dataType;
      }
      if (json.template) {
        tempDataTransfer.template = json.template;
      }
      if (json.fileName) {
        tempDataTransfer.fileName = json.fileName;
      }
      if (json.mimeType) {
        tempDataTransfer.mimeType = json.mimeType;
      }
      if (json.objectUrl) {
        tempDataTransfer.objectUrl = json.objectUrl;
      }
      if (json.folderName) {
        tempDataTransfer.folderName = json.folderName;
      }
      if (json.inputFileName) {
        tempDataTransfer.inputFileName = json.inputFileName;
      }
      if (json.zipFileName) {
        tempDataTransfer.zipFileName = json.zipFileName;
      }
      if (json.zipFileUrl) {
        tempDataTransfer.zipFileUrl = json.zipFileUrl;
      }
      if (json.timeTakenForProcessing) {
        tempDataTransfer.timeTakenForProcessing = json.timeTakenForProcessing;
      }
      if (json.timeTakenForImageExtraction) {
        tempDataTransfer.timeTakenForImageExtraction = json.timeTakenForImageExtraction;
      }
      if (json.timeTakenForTableExtraction) {
        tempDataTransfer.timeTakenForTableExtraction = json.timeTakenForTableExtraction;
      }
      if (json.aceProcessToRun) {
        tempDataTransfer.aceProcessToRun = json.aceProcessToRun;
      }
      if (json.availableProcesses) {
        tempDataTransfer.availableProcesses = json.availableProcesses;
      }
      if (json.totalNumberOfPages) {
        tempDataTransfer.totalNumberOfPages = json.totalNumberOfPages;
      }
      if (json.executionTime) {
        tempDataTransfer.executionTime = json.executionTime;
      }
      if (json.status) {
        tempDataTransfer.status = json.status;
      }
      if (json.statusDescription) {
        tempDataTransfer.statusDescription = json.statusDescription;
      }
      if (json.vpubTitle) {
        tempDataTransfer.vpubTitle = json.vpubTitle;
      }
      if (json.moduleTitle) {
        tempDataTransfer.moduleTitle = json.moduleTitle;
      }
      if (json.username) {
        tempDataTransfer.username = json.username;
      }
      if (json.createdOn) {
        tempDataTransfer.createdOn = json.createdOn;
      }
    }

    return tempDataTransfer;
  }
}
