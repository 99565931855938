import {AdxResource} from '../../core/service/resource/adx-resource.model';

/**
 * Account property model class
 */
export class AdxAccountProperty extends AdxResource {
  accountId: number | null = null;
  value: string | null = null;

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxAccountProperty {
    const toRet = new AdxAccountProperty(json.id);
    if (json.accountId !== undefined && json.accountId !== null) {
      toRet.accountId = json.accountId;
    }
    if (json.value) {
      toRet.value = json.value;
    }
    return toRet;
  }
}
