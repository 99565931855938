import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticatedUser} from '../../common/model/adx-auth/authenticated-user.model';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AuthNotifierService} from '../../auth/service/auth-notifier.service';

@Component({
  selector: 'app-adx-hamburger-menu',
  templateUrl: './adx-hamburger-menu.component.html',
  styleUrls: ['./adx-hamburger-menu.component.scss']
})
export class AdxHamburgerMenuComponent implements OnInit, OnDestroy {

  errorMessage: string | null = null;
  isUserAuthenticated = false;
  authenticatedUser: AuthenticatedUser | null = null;
  authSubscription: Subscription | undefined;

  constructor(
    private logger: NGXLogger,
    private cd: ChangeDetectorRef,
    private authNotifier: AuthNotifierService
  ) {}

  //clear the error message displayed on ui
  onCloseError() {
    this.errorMessage = null;
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('In AdxHamburgerMenuComponent authSubscription');
        this.isUserAuthenticated = (user !== null? true: false);
        this.authenticatedUser = user;
        this.cd.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

}
