import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {AdxModule} from '../../adx-module/model/adx-module.model';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {AdxArs} from '../../adx-ars/model/adx-ars-model';
import {NGXLogger} from 'ngx-logger';
import {AdxSimulatorService} from '../service/adx-simulator.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-ars-simulator',
  templateUrl: './ars-simulator.component.html',
  styleUrls: ['./ars-simulator.component.scss']
})
export class ArsSimulatorComponent implements OnInit, OnDestroy {

  @Input() atomList: AdxArs[] = [];
  treeControl = new NestedTreeControl<AdxArs>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AdxArs>();
  changeSubscription: Subscription;

  constructor(private logger: NGXLogger, private simulatorService: AdxSimulatorService) {
    this.changeSubscription = this.simulatorService.contentUpdated.subscribe((data: boolean) => {
      this.logger.debug('ArsSimulatorComponent Refresh received');
      this.atomList = this.simulatorService.atomList;
      this.dataSource.data = this.atomList;
    });
  }

  ngOnInit(): void {
    this.logger.debug(`In ngOnInit ArsSimulatorComponent ${this.atomList}`);
    if (this.atomList) {
      this.dataSource.data = this.atomList;
    }
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

  hasChild = (_: number, node: AdxModule) => !!node.children && node.children.length > 0;

}
