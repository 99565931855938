<div class="adx-user-details-container">
  <form [formGroup]="userDetailsForm">
    <div class="adx-user-details-name">
      <mat-form-field class="adx-user-details-name-first">
        <input matInput placeholder="First Name" formControlName="firstName" required maxlength="50"/>
        <mat-error *ngIf="userDetailsForm.get('firstName')?.hasError('required')">
          This is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="adx-user-details-name-middle">
        <input matInput placeholder="Middle Name" formControlName="middleName" maxlength="50"/>
      </mat-form-field>
      <mat-form-field class="adx-user-details-name-last">
        <input matInput placeholder="Last Name" formControlName="lastName" required maxlength="50"/>
        <mat-error *ngIf="userDetailsForm.get('lastName')?.hasError('required')">
          This is a required field!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="adx-user-details-contact">
      <mat-form-field class="adx-user-details-contact-username">
        <input matInput placeholder="User Name" formControlName="userName" required type="email" maxlength="254"/>
        <mat-error *ngIf="userDetailsForm.get('userName')?.hasError('required')">
          This is a required field!
        </mat-error>
        <mat-error *ngIf="userDetailsForm.get('userName')?.hasError('email')">
          Please enter valid email address!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="adx-user-details-contact-altemail">
        <input matInput placeholder="Secondary Email" formControlName="altEmail" type="email" maxlength="254"/>
        <mat-error *ngIf="userDetailsForm.get('altEmail')?.hasError('email')">
          Please enter valid email address!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="adx-user-details-contact-mobile">
        <input matInput placeholder="Mobile Number" formControlName="mobileNumber" type="number"/>
        <mat-error *ngIf="userDetailsForm.get('mobileNumber')?.hasError('pattern')">
          Please enter valid 10 digit mobile number
        </mat-error>
      </mat-form-field>
    </div>

    <div class="adx-user-add-action">
      <button mat-raised-button color="primary" type="submit" [disabled]="!((userDetailsForm.valid && userDetailsForm.dirty) || user)" (click)="onUpdate()">Add</button>
      <!-- <button mat-raised-button color="primary" type="submit" [disabled]="canDisable()" (click)="onUpdate()">Update</button>
      <button mat-raised-button [disabled]="canDisable()" (click)="onCancel()" style="margin-left: 1rem;">Reset</button> -->
    </div>
  </form>
</div>
