import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { Organization } from '../../organization/model/organization.model';
import { AdxTimezone } from '../../common/model/adx-timezone.model';
import { Address } from '../../common/model/address.model';
import { AdxStyle } from '../../common/model/adx-style.model';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxAccountProperty} from './adx-account-property.model';
import {AdxPopupInfo} from '../../common/model/adx-popup-info.model';
import {AdxModuleType} from "../../adx-module/model/adx-module-type";
import {AdxModuleUtility} from "../../common/utils/adx-module-utility";
import {AdxHelpInfo} from "../../common/model/help-info/adx-help-info.model";
import {AdxAboutUsInfo} from "../../common/model/aboutus-info/adx-aboutus-info.model";

/**
 * This is Organization model class
 */
export class Account extends AdxResource {
  title: string | null;
  passkey: string | null = null;
  description: string | null = null;
  summary: string | null = null;
  excludedModuleTypes: string | null = null;
  accountName: string | null = null;
  accountNumber: string | null = null;
  notes: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  websiteUrl: string | null = null;
  iconImage: AdxImage | null = null;
  helpInfo: AdxHelpInfo | null = null;
  aboutUsInfo: AdxAboutUsInfo | null = null;
  visibility: AdxVisibilityType | null = null;
  tier: string | null = null;
  tags: string | null = null;
  signinEnabled: boolean | null = null;
  weblinkShareEnabled = false;
  organization: Organization | null = null;
  timeZone: AdxTimezone | null = null;
  timeZoneId: number| null | undefined = null;
  address: Address | null | undefined = null;
  style: AdxStyle | null | undefined = null;
  defaultLocale: AdxLocale | null | undefined = null;
  locales: AdxLocale[] | null | undefined = null;
  validEmailDomains: AdxAccountProperty | null = null;
  simulatorDisplayProp: AdxAccountProperty | null = null;
  popupInfo: AdxPopupInfo | null = null;

  createdByUser: number | null = null;
  updatedByUser: number | null = null;
  sortOrder: number | null = null;

  allModules = AdxModuleUtility.getAllModules();

  constructor(id: number | null, strTitle: string | null) {
                super(id);
                this.title = strTitle;
  }

  public get accountAvailableModules(): { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] {
    if (this.excludedModuleTypes === undefined || this.excludedModuleTypes === null
      || this.excludedModuleTypes?.trim().length === 0) {
      return this.allModules;
    } else {
      const includedModules: { displayName: string; type: AdxModuleType; icon: AdxImage | null }[] = [];
      // remove the excluded modules from available modules
      let excludedModules: string[] | undefined = this.excludedModuleTypes?.split(',');
      if (!excludedModules && this.excludedModuleTypes && this.excludedModuleTypes?.length > 0) {
        excludedModules = [];
        excludedModules.push(this.excludedModuleTypes);
      }

      for (let counter = 0; counter < this.allModules.length; counter++) {
        const module = this.allModules[counter];
        let isExcluded = false;
        for (const excludedModule of excludedModules) {
          if (module.type.toString().toLowerCase() === (excludedModule.toLowerCase())) {
            isExcluded = true;
            break;
          }
        }
        if (isExcluded === false) {
          includedModules.push(module);
        }
      }
      return includedModules;
    }
  }
}
