/**
 * The model class used for setting menu item.
 * The match_url field is used to match the current path and if there is a match then the menu item is displayed.
 * Any new menu item, needs to be pre-created here as static readonly fields.
 */
export class AdxSettingsMenuItem {

  static readonly ORGANIZATION = new AdxSettingsMenuItem('ORG','/orgs/', 'Organization Setting', '/orgs/');
  static readonly ACCOUNT = new AdxSettingsMenuItem('ACCT', '/accts/', 'Account Setting', 'accounts');

  code: string;
  match_url: string;
  path: string;
  title: string;
  icon?: string;

  constructor(code: string, match_url: string, title: string, path: string) {
    this.code = code;
    this.match_url = match_url;
    this.title = title;
    this.path = path;
  }
}
