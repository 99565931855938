import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxChannelType} from './adx-channel-type';
import {AdxApplication} from '../../application/model/adx-application.model';

export class AdxChannel extends AdxResource {

  title: string | null = null;

  summary: string | null = null;

  description: string | null = null;

  sortOrder: number | null = null;

  channelType: AdxChannelType = AdxChannelType.OTHER;

  accountId: number | null = null;

  passkey: string | null = null;

  visibility: AdxVisibilityType | null = null;

  iconImage: AdxImage | null = null;

  applications: AdxApplication[] | null = null;

  createdByUser: number | null = null;
  updatedByUser: number | null = null;

  constructor(id: number | null, accntId: number | null, passkey: string | null) {
    super(id);
    this.accountId = accntId;
    this.passkey = passkey;
  }

  static buildObject(json: any): AdxChannel {
    const toRet: AdxChannel = new AdxChannel(json.id, json.applicationId, json.passkey);
    if (json.title) {
      toRet.title = json.title;
    }
    if (json.summary) {
      toRet.summary = json.summary;
    }
    if (json.description) {
      toRet.description = json.description;
    }
    if (json.sortOrder) {
      toRet.sortOrder = json.sortOrder;
    }
    if (json.channelType) {
      toRet.channelType = json.channelType;
    }
    if (json.visibility) {
      toRet.visibility = json.visibility;
    }
    if (json.iconImage) {
      toRet.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.applications) {
        let tempObj: AdxApplication;
        json.applications.forEach((objStr: any) => {
          tempObj = (AdxApplication.buildObject(objStr));
          if (tempObj) {
            if (!toRet.applications) {
              toRet.applications = [];
            }
            toRet.applications.push(tempObj);
          }
        });
    }
    if (json.createdByUser) {
      toRet.createdByUser = json.createdByUser;
    }
    if (json.updatedByUser) {
      toRet.updatedByUser = json.updatedByUser;
    }
    return toRet;
  }
}
