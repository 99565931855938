import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Organization } from '../model/organization.model';
import { OrganizationService } from '../service/organization.service';

/**
 * This resolver is used to fetch all required data from backend before displaying the Organization edit screen.
 */
@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<Organization | null> {
  constructor(private logger: NGXLogger, private orgService: OrganizationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization | null> {

    this.logger.debug('In route resolve:' + route.paramMap);
    const orgIdString: string | null = route.paramMap.get('orgId');

    if (orgIdString === null || isNaN(+orgIdString)) {
      const message =
        'Something went wrong while fetching the organization. Please try again';
      this.logger.error(`Passed id is not a number: ${orgIdString}`);
      throwError(message);
      return of(null);
    }
    const selectedOrgId: number = +orgIdString;
    this.logger.debug('id received:' + selectedOrgId);
    return this.orgService.getOrganization(selectedOrgId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of(null);
      })
    );
  }
}


