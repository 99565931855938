import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/service/authentication.service';
import { AdxAuthUserPermission } from 'src/app/common/model/adx-auth/adx-auth-user-permission.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';

/**
 * Auth guard for Organization listing screen. Only allows access to authenticated users
 */
@Injectable({
  providedIn: 'root'
})
export class AdxOrgAuthGuard implements CanActivate {

  constructor(private logger: NGXLogger, private localStorageService: LocalStorageService,
     private router: Router, private readonly authService: AuthenticationService) { }

     /**
      * Method to override to use canActivate guard to determine whether given route is accessible by user or not
      *
      * @param route
      * @param state
      * @returns boolean
      */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.logger.debug(`In CanActivate`);
      const url: string = state.url;
      this.logger.debug(`url : ${url}`);
      const authUser: AuthenticatedUser = this.localStorageService.get('auth_user');
      if (authUser !== undefined && authUser !== null && authUser.userPermissions !== undefined && authUser.userPermissions !== null) {
        const authUserPermissions: AdxAuthUserPermission = authUser.userPermissions;
        //if authenticated user, allow, else block
        return true;
      }
    return false;
  }

}
