import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdxTextInputComponent } from './adx-text-input.component';



@NgModule({
  declarations: [
    AdxTextInputComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AdxTextInputComponent
  ]
})
export class AdxTextInputModule { }
