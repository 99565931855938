/**
 * Enum that is used to indicate at which level, the Transfer/ContentReport Page is being displayed.
 */
export enum AdxDisplayLevel {
  ARS_LEVEL = 'ARS_LEVEL',
  VPUB_LEVEL = 'VPUB_LEVEL',
  ACCOUNT_LEVEL = 'ACCOUNT_LEVEL',
  ORG_LEVEL = 'ORG_LEVEL',
  SYSTEM_LEVEL = 'SYSTEM_LEVEL'
}
