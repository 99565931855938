import {HttpParams} from '@angular/common/http';


/**
 * This class is utility class to be used when HttpParams need to be sent along with REST call.
 */
export class AdxQueryParams {
  private params: HttpParams | undefined = undefined;

  constructor(){}

  /**
   * API to add a parameter that is to be sent to server in the REST request.
   * If same key is added again, the existing value is overwritten
   *
   * @param key : the param key/name
   * @param value : the param value
   */
  public addQueryParam(key: string, value: string): void {
    if (!this.params) {
      if (key && value) {
        this.params = new HttpParams();
      }
    }
    if (this.params && key && value) {
      this.params = this.params.set(key, value);
    }
  }

  /**
   * API retuns the HttpParams representation of Query parameters.
   *
   * @returns : Returns HttpParams if valid params are added to QueryParams object.
   *            Returns empty HttpParams if no parameters added to QueryParams object.
   */
  public getHttpParams(): HttpParams {

    let toRet: HttpParams = new HttpParams();
    // return emply object if there are no params
    if (!this.params) {
      return toRet;
    }
    else {
      toRet = this.params;
    }
    return toRet;
  }
}
