        <div class="addOrgFormGroup" *ngIf="canAddAccount()">
            <form class="add-org-form" [formGroup]="addOrgForm">
                <div class="flex-container add-org" fxLayout="column" fxLayout.lt-md="column">

                    <div class="new-org-name" fxLayout="row" fxLayout.lt-md="column">
                        <div class="input-label" fxFlex="10">
                        </div>
                        <div class="orgName-input" fxFlex="70">
                            <mat-form-field class="input-form">
                                <input matInput i18n="@@addOrgForm.orgName" id="input-add-organization-name" #orgName maxlength="150" formControlName="orgName" class="name-input" placeholder="Organization Name" required> <!-- [formControl]="orgName"-->
                                <mat-error>
                                    This field is required
                                </mat-error>
                            </mat-form-field>
                            <mat-hint class="hintend">{{orgName.value.length}} / 150</mat-hint>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="org-address" fxLayout="row" fxLayout.lt-md="column">
                        <div class="common-label" fxFlex="10">
                        </div>
                        <div class="common-address-editor" formGroupName='orgAddress' fxLayout="column" fxFlex="90">
                            <mat-label i18n="@@addOrgForm.address">Address</mat-label>
                            <mat-form-field>
                                <input matInput i18n="@@addOrgForm.street1" id="input-add-organization-street1" #street1 maxlength="80" formControlName="street1" class="street1" placeholder="Street1">
                                <mat-hint align="end">{{street1.value.length}} / 80</mat-hint>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput i18n="@@addOrgForm.street2" id="input-add-organization-street2" #street2 maxlength="80" formControlName="street2" class="street2" placeholder="Street2">
                                <mat-hint align="end">{{street2.value.length}} / 80</mat-hint>
                            </mat-form-field>
                            <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between stretch">
                                <mat-form-field class="city">
                                    <input matInput i18n="@@addOrgForm.city" id="input-add-organization-city" #city maxlength="50" formControlName="city" placeholder="City">
                                    <mat-hint align="end">{{city.value.length}} / 50</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="state">
                                    <input matInput i18n="@@addOrgForm.state" id="input-add-organization-state" #state maxlength="50" formControlName="state" placeholder="State">
                                    <mat-hint align="end">{{state.value.length}} / 50</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="zipcode">
                                    <input matInput i18n="@@addOrgForm.zipcode" id="input-add-organization-zipcode" #zipcode maxlength="6" formControlName="zipcode" placeholder="Zipcode">
                                    <mat-hint align="end">{{zipcode.value.length}} / 6</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="country">
                                    <mat-select formControlName="country" placeholder='Country' id="input-add-organization-country">
                                        <mat-option *ngFor="let cnt of countries" id="input-add-organization-country-{{cnt.id}}" value="{{cnt.id}}">{{cnt.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="timezone" fxLayout="row" fxLayout.lt-md="column">
                        <div class="input-label" fxFlex="10">
                        </div>
                        <div class="timezone-input" fxFlex="25">
                            <mat-form-field class="input-form selectTimezone">
                                <mat-label i18n="@@addOrgForm.timezone">Timezone</mat-label>
                                <mat-select formControlName="orgTimezone" id="input-add-organization-timezones" required>
                                    <mat-option *ngFor="let tz of timezones" id="input-add-organization-timezones-{{tz.id}}" value="{{tz.id}}">
                                        {{tz.abbreviation}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="65">
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="org-style-container" fxLayout="row" fxLayout.lt-md="row" formGroupName="orgStyle">
                        <div class="common-label" fxFlex="10" fxLayout="column">
                        </div>
                        <div class="orgStyle-input" fxLayout="column" fxFlex="80">
                            <mat-label i18n="@@addOrgForm.style">Style</mat-label>
                            <table>
                                <tr>
                                    <th class="numOfTabs">
                                        <mat-label i18n="@@addOrgForm.numOfTabs">Number Of Tabs *</mat-label>
                                    </th>
                                    <th class="org-style-numOfTabs">
                                        <mat-form-field fxFlex="10" class="style-numOfTabs-input">
                                            <input matInput type="number" i18n="@@addOrgForm.numOfTabs"
                                                    id="input-add-organization-styleNumOfTabs"
                                                    formControlName="numOfTabs" min="1" max="4" required>
                                          </mat-form-field>
                                        </th>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td class='commonTitletd'>Top bar Background color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' formControlName="topBarColor">
                                        </ngx-colors>
                                    </td>
                                    <td rowspan="4" class='common-td simulatorLookNFeel'>
                                        <div class="flex-container" fxLayout="column" fxLayout.lt-md="column">
                                            <div class="simulator-topbar" fxLayout="row" [style.background-color]="topBarColor" [style.color]="topBarTextColor">
                                                <div class="simulator-topbar-text" fxFlex="100">
                                                    Top Bar
                                                </div>
                                            </div>
                                            <div class="simulator-contentbar" fxLayout="row">
                                                Conveyer
                                            </div>
                                            <div class="simulator-navbar" fxLayout="column" [style.background-color]="backgroundColor" [style.color]="backgroundTextColor">
                                                <div class="simulator-navbar-icon" fxFlex="100">
                                                    <img class="iconImageSimulator" src="assets/images/app-icon.png" alt="image"/>
                                                </div>
                                                <div class="simulator-navbar-text" fxFlex="100">
                                                    Navigation
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Top bar Text color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' formControlName="topBarTextColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Navigation bar Background color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-organization-styleBackgroundColor" formControlName="backgroundColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='commonTitletd'>Navigation bar Text color</td>
                                    <td class='common-td'>
                                        <ngx-colors ngx-colors-trigger [hideColorPicker]='true' id="input-add-organization-styleBackgroundTextColor" formControlName="backgroundTextColor">
                                        </ngx-colors>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="about-screen" fxLayout="row" fxLayout.lt-md="column">
                        <div class="common-label" fxFlex="10">
                        </div>
                        <mat-form-field class="common-desc-editor" fxFlex="90">
                            <mat-label i18n="@@addOrgForm.about-screen">About Us</mat-label>
                          <textarea id="input-add-organization-aboutScreen" formControlName="orgAbout"
                                    matInput [errorStateMatcher]="adxErrorStateMatcher" #orgAbout
                                    [froalaEditor]="options"></textarea>
                          <mat-hint align="end">{{orgAbout.value.length}} / 10000</mat-hint>
                          <mat-error *ngIf="addOrgForm.get('orgAbout')?.hasError('maxlength')">
                            {{orgAbout.value.length}} exceeds max limit of 10000 HTML characters
                          </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="help-screens" fxLayout="row" fxLayout.lt-md="column">
                        <div class="common-label" fxFlex="10">
                        </div>
                        <mat-form-field class="common-desc-editor" fxFlex="90">
                            <mat-label i18n="@@addOrgForm.help-screen">Help Screen</mat-label>
                          <textarea id="input-add-organization-homeScreen" formControlName="orgHelp"
                                    matInput [errorStateMatcher]="adxErrorStateMatcher" #orgHelp
                                    [froalaEditor]="options" (froalaInit)="initializeHelpFroalaEditor($event)" required></textarea>
                          <mat-hint align="end">{{orgHelp.value.length}} / 10000</mat-hint>
                          <mat-error *ngIf="addOrgForm.get('orgHelp')?.hasError('maxlength')">
                            {{orgHelp.value.length}} exceeds max limit of 10000 HTML characters
                          </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="description-screen" fxLayout="row" fxLayout.lt-md="column">
                        <div class="common-label" fxFlex="10">
                        </div>
                        <mat-form-field class="common-desc-editor" fxFlex="90">
                            <mat-label i18n="@@addOrgForm.description">Description</mat-label>
                          <textarea id="input-add-organization-description" formControlName="orgDescription"
                                    matInput [errorStateMatcher]="adxErrorStateMatcher" #orgDescription
                                    [froalaEditor]="options"></textarea>
                          <mat-hint align="end">{{orgDescription.value.length}} / 450</mat-hint>
                          <mat-error *ngIf="addOrgForm.get('orgDescription')?.hasError('maxlength')">
                            {{orgDescription.value.length}} exceeds max limit of 450 HTML characters
                          </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-divider></mat-divider>
                </div>

                <div class="button-container">
                    <button mat-raised-button i18n="@@addOrgForm.cancel" type="button" routerLink='/orgs' class="button-cancel" id="input-add-organization-cancel-Btn">Cancel</button>
                    <button mat-raised-button i18n="@@addOrgForm.save" [disabled]="!(addOrgForm.valid && addOrgForm.dirty)" type="button" id="input-add-organization-save-Btn" (click)="save()">Save</button>
                </div>
            </form>
        </div>

