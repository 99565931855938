import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError} from 'rxjs/operators';
import {AdxModule} from '../model/adx-module.model';
import {AdxModuleService} from '../service/adx-module.service';

@Injectable({
  providedIn: 'root'
})
export class AdxModuleResolver implements Resolve<AdxModule | null> {
  constructor(private logger: NGXLogger, private adxModuleService: AdxModuleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdxModule | null> {
    this.logger.debug('In Module route resolve:' + route.paramMap.keys);
    const orgIdString: string | null | undefined = route.paramMap.get('orgId');
    this.logger.debug(orgIdString);
    const accountIdString: string | null = route.paramMap.get('acctId');
    this.logger.debug(accountIdString);
    const vPubIdString: string | null = route.paramMap.get('vpubId');
    this.logger.debug(vPubIdString);
    const moduleIdString: string | null = route.paramMap.get('modId');
    this.logger.debug(moduleIdString);

    if (orgIdString) {
      if (isNaN(+orgIdString)) {
        const message =
          'Something went wrong while fetching the Module. Please try again';
        this.logger.error(`Passed org id is not a number: ${orgIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Module. Please try again';
      this.logger.error(`Passed org id is not a number: ${orgIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (accountIdString) {
      if (isNaN(+accountIdString)) {
        const message =
          'Something went wrong while fetching the Module. Please try again';
        this.logger.error(`Passed account id is not a number: ${accountIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Module. Please try again';
      this.logger.error(`Passed account id is not a number: ${accountIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (vPubIdString) {
      if (isNaN(+vPubIdString)) {
        const message =
          'Something went wrong while fetching the Module. Please try again';
        this.logger.error(`Passed vPub id is not a number: ${vPubIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Module. Please try again';
      this.logger.error(`Passed account id is not a number: ${vPubIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (moduleIdString) {
      if (isNaN(+moduleIdString)) {
        const message =
          'Something went wrong while fetching the Module. Please try again';
        this.logger.error(`Passed account id is not a number: ${moduleIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Module. Please try again';
      this.logger.error(`Passed account id is not a number: ${moduleIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }
    const selectedOrgId: number = +orgIdString;
    const selectedAccountId: number = +accountIdString;
    const selectedVpubId: number = +vPubIdString;
    const moduleId: number = +moduleIdString;
    this.logger.debug(`ids received: ${selectedOrgId}: ${selectedAccountId}`);
    return this.adxModuleService.getModule(selectedOrgId, selectedAccountId, selectedVpubId, moduleId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        this.logger.error(message);
        return of(null);
      })
    );
  }
}
