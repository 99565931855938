import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerOverlayComponent } from './components/spinner-overlay.component';
import { MaterialModule } from '../../common/material/material.module';

/**
 * Module responsible for displaying of Spinner.
 * The spinner is automatically displayed when REST API is made till response is go back from server.
 * Uses mat-progress-spinner from material to actually display the spinner.
 * Reference: https://medium.com/show-loader-during-http-request-handeling/angular-display-loader-on-every-http-request-handeling-concurrency-with-rxjs-6b2515abe52a
 */
@NgModule({
  declarations: [
    SpinnerOverlayComponent
  ],
  exports: [
    SpinnerOverlayComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class SpinnerOverlayModule { }
