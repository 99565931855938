import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {AdxSelectDialogData} from '../../utils/adx-select-dialog-data';

@Component({
  selector: 'app-adx-select-dialog',
  templateUrl: './adx-select-dialog.component.html',
  styleUrls: ['./adx-select-dialog.component.scss']
})
export class AdxSelectDialogComponent implements OnInit {

  selectControl = new UntypedFormControl('visibility');
  selectedValue = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: AdxSelectDialogData, private logger: NGXLogger) {
    this.selectControl.valueChanges.subscribe(value => {
      this.selectedValue = value;
    });
  }

  ngOnInit(): void {
  }

}
