import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Organization } from '../../organization/model/organization.model';
import { Account } from '../../account/model/account.model';
import { AdxLocale } from '../../common/model/locale/adx-locale.model';
import { AdxImage } from '../../common/model/adx-image.model';
import { AdxImageLibraryType } from '../../shared/utils/adx-image-library-type';
import { AdxVisibilityType } from '../../common/model/visibility/adx-visibility-type';
import { AdxAccessType } from '../../common/model/access/adx-access-type';
import { AdxChannelService } from '../service/adx-channel.service';
import { CustomNotifierService } from '../../core/service/notifier/custom-notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdxChannel } from '../model/adx-channel.model';
import { takeUntil } from 'rxjs/operators';
import { CustomMessageType } from '../../core/service/notifier/custom-message-type';
import { blankValidator } from '../../common/utils/form-utility';
import { AdxBaseEditorTemplate } from '../../common/template/adx-base-editor-template';
import { AdxInsertContentDialogData } from '../../shared/utils/adx-insert-content-dialog-data';
import { CommonUtility } from '../../common/utils/common-utils';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';

@Component({
  selector: 'app-edit-channel',
  templateUrl: './edit-channel.component.html',
  styleUrls: ['./edit-channel.component.scss']
})
export class EditChannelComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg?: Organization;
  selectedAccount?: Account;
  selectedChannel?: AdxChannel;
  defaultLocale: AdxLocale | null = null;
  iconImage: AdxImage | null = null;
  imgLibType: AdxImageLibraryType = AdxImageLibraryType.ACCOUNT_IMG_LIB;
  typeId: number | null = null;
  visibilityTypes: string[] = [AdxVisibilityType.DISABLED.toString(),
  AdxVisibilityType.VISIBLE.toString(), AdxVisibilityType.HIDDEN.toString()];
  accessTypes: string[] = [AdxAccessType.REQUIRE_APPROVAL.toString(), AdxAccessType.UNLOCKED.toString()];

  editChannelForm: UntypedFormGroup;
  channelListUrl: string | null = null;

  private authSubscription: Subscription | undefined;

  constructor (private channelService: AdxChannelService,
    private readonly messageNotifier: CustomNotifierService,
    private readonly authNotifier: AuthNotifierService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this.editChannelForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3),
          Validators.maxLength(150)
        ]
      }),
      icon: new UntypedFormControl(),
      desc: new UntypedFormControl(null, {
        updateOn: 'change', validators: [
          Validators.required,
          Validators.maxLength(450)
        ]
      }),
      summary: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          Validators.maxLength(150)
        ]
      }),
      sortOrder: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.required,
          Validators.min(0)
        ]
      }),
      visibility: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    this.selectedAccount = this.activatedRoute.snapshot.data.account;
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.selectedChannel = this.activatedRoute.snapshot.data.channel;

    this.logger.debug('selectedAccount:' + this.selectedAccount);
    this.logger.debug('selectedOrg:' + this.selectedOrg);

    if (this.selectedAccount && this.selectedOrg) {
      this.typeId = this.selectedAccount?.id;
      this.channelListUrl = `/orgs/${this.selectedOrg.id}/accts/${this.selectedAccount?.id}/chnls`;
    }
    this.logger.debug(`channelListUrl: ${(this.channelListUrl)}`);

    if (this.selectedChannel) {
      this.editChannelForm.patchValue({
        title: this.selectedChannel.title,
        sortOrder: this.selectedChannel.sortOrder,
        icon: this.selectedChannel.iconImage,
        desc: this.selectedChannel.description,
        summary: this.selectedChannel.summary,
        visibility: this.selectedChannel.visibility
      });
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: CommonUtility.getId('acctId', this.activatedRoute),
      vpubId: null,
      moduleId: null,
      typeId: this.typeId,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.ACCOUNT_IMG_LIB,
      editorRef: undefined,
      applicationId : null,
      atomId : null
    };
    return toRet;
  }

  /**
   * Callback that returns true if logged-in user has permission to edit channel
   *
   * @param number: channel id
   * @returns boolean: true if user can edit channel identified by given id
   */
  canEditChannel(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
      && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
      && this.selectedAccount !== undefined && this.selectedAccount !== null
      && this.selectedAccount.id !== undefined && this.selectedAccount.id !== null
      && this.selectedChannel !== undefined && this.selectedChannel !== null
      && this.selectedChannel.id !== undefined && this.selectedChannel.id !== null
      && this.currentUser !== undefined && this.currentUser !== null) {
      return this.channelService.canUserEditChannel(
        this.selectedOrg.id, this.selectedAccount.id, this.selectedChannel.id, this.currentUser);
    }
    return false;
  }

  save(): void {
    this.logger.debug(this.editChannelForm);
    const strTitle = this.editChannelForm.get('title')?.value;
    if (this.selectedAccount && this.selectedChannel) {
      const channelToAdd: AdxChannel = new AdxChannel(this.selectedChannel?.id, this.selectedAccount?.id, this.selectedChannel.passkey);
      if (this.editChannelForm.get('title')?.dirty) {
        channelToAdd.title = this.editChannelForm.get('title')?.value.trim();
      }
      if (this.editChannelForm.get('desc')?.dirty) {
        channelToAdd.description = this.editChannelForm.get('desc')?.value;
      }
      if (this.editChannelForm.get('summary')?.dirty) {
        channelToAdd.summary = this.editChannelForm.get('summary')?.value.trim();
      }
      if (this.editChannelForm.get('icon')?.dirty) {
        channelToAdd.iconImage = this.iconImage;
      }
      if (this.editChannelForm.get('sortOrder')?.dirty) {
        channelToAdd.sortOrder = this.editChannelForm.get('sortOrder')?.value;
      }
      if (this.editChannelForm.get('visibility')?.dirty) {
        channelToAdd.visibility = this.editChannelForm.get('visibility')?.value;
      }
      this.editChannel(channelToAdd);
    }
    else {
      this.logger.error('Title & Account Details not specified!! Unable to add Channel');
    }
  }

  onIconSelected(icon: AdxImage): void {
    this.iconImage = icon;
  }

  private editChannel(channelToEdit: AdxChannel): void {
    if (this.selectedOrg && this.selectedOrg.id
      && this.selectedAccount && this.selectedAccount.id && channelToEdit) {
      this.channelService.updateChannel(this.selectedOrg.id, this.selectedAccount.id, channelToEdit)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            if (this.channelListUrl) {
              this.router.navigateByUrl(this.channelListUrl).then(r => { });
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          error: () => {
            // navigate user to listing screen
            if (this.channelListUrl) {
              this.router.navigateByUrl(this.channelListUrl).then(r => { });
            }
            else {
              this.logger.error('URL is not set, cannot navigate');
            }
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `Channel updated Successfully.`);
          }
        });
    }
  }
}
