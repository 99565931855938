import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {AdxImageLibraryType} from '../../../../utils/adx-image-library-type';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * This component is used to take inputs for the upload. Like title, description etc
 */
@Component({
  selector: 'app-adx-upload',
  templateUrl: './adx-upload.component.html',
  styleUrls: ['./adx-upload.component.scss']
})
export class AdxUploadComponent implements OnInit, OnDestroy {

  @Input() file: File | null = null;
  @Input() imgLibraryType: AdxImageLibraryType | undefined;
  @Input() id: number | undefined;
  @Output() imgDetailsRcvd = new EventEmitter<{title: string; description: string}>();

  percentage$: Observable<number> | null = null;
  snapshot$: Observable<any> | null = null;
  downloadURL = '';

  url = '';
  imageUploadForm!: UntypedFormGroup;

  private destroy = new Subject<void>();

  constructor(private logger: NGXLogger, private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<AdxUploadComponent>) { }

  ngOnInit(): void {
    this.buildImageUploadForm();
    this.logger.debug(this.file);
    const reader = new FileReader();

    reader.onload = (event: any) => {
      this.url = event.target.result;
    };

    reader.onerror = (event: any) => {
      this.logger.debug('File could not be read: ' + event.target.error.code);
    };

    if (this.file) {
      reader.readAsDataURL(this.file);
    }
  }

  ngOnDestroy(): void {
    // this.destroy.next();
    this.destroy.complete();
  }

  startUpload(): void {
    if (this.file != null) {
      const path = `${this.file.type.split('/')[0]}/${Date.now()}_${this.file.name}`;
    }
  }

  submitForm(): void {
    const title: string = this.imageUploadForm.get('name')?.value;
    const description: string = this.imageUploadForm.get('description')?.value;
    this.logger.debug('selected File:' + this.file?.name + '; title:' + title + '; desc:' + description);
    this.logger.debug('id:' + this.id + ' ; libType:' + this.imgLibraryType + ' ; ');
    if (AdxImageLibraryType.ORG_IMG_LIB === this.imgLibraryType) {
      this.logger.debug('Organization id: ' + this.id);
    }
    else {
      this.logger.debug('Account id: ' + this.id);
    }
    this.imgDetailsRcvd.emit({title, description});
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private buildImageUploadForm(): void {
    this.imageUploadForm = this.fb.group({
      name: [''],
      description: ['']
    });
  }

}
