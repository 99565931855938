import { AdxAuthUserPermission } from 'src/app/common/model/adx-auth/adx-auth-user-permission.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';

/**
 * Utility method used for authentication related functionality
 */
export class AdxAuthUtils {

  /**
   * Utility method to getermine whether input user has system access
   *
   * @param user AuthenticatedUser object
   * @returns boolean true if input user has system access
   */
  public static hasSystemAccess(user: AuthenticatedUser): boolean {
    if(!this.hasValidPermissionObject){
      return false;
    }
    const userPerms = user.userPermissions;
    if (userPerms?.SYSTEM_ADMIN !== undefined && userPerms.SYSTEM_ADMIN !== null) {
      return true;
    }
    else if (userPerms?.SYSTEM_READ_ONLY !== undefined && userPerms.SYSTEM_READ_ONLY !== null) {
      return true;
    }
    else if (userPerms?.INSTANCE_ADMIN !== undefined && userPerms.INSTANCE_ADMIN !== null) {
      return true;
    }
    else if (userPerms?.ORGANIZATION_DELETE !== undefined && userPerms.ORGANIZATION_DELETE !== null) {
      //ORGANIZATION_DELETE is also system level role, hence the check is been added
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Utility method to determine whether input user has System or Instance admin role
   *
   * @param user AuthenticatedUser object
   * @returns boolean true if input user has role of System or Instance Admin
   */
  public static isSystemOrInstanceAdmin(user: AuthenticatedUser): boolean {
    if(!this.hasValidPermissionObject){
      return false;
    }
    const userPerms = user.userPermissions;
    if (userPerms?.SYSTEM_ADMIN !== undefined && userPerms.SYSTEM_ADMIN !== null) {
      return true;
    }
    else if (userPerms?.INSTANCE_ADMIN !== undefined && userPerms.INSTANCE_ADMIN !== null) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Utility Method to check whether given user has given role for given id
   *
   * @param inputId : id
   * @param roleTitle: role
   * @param user : logged in user
   * @returns boolean: true if logged in user has role for input org id
   */
  public static hasRoleForGivenId(inputId: number, roleTitle: string, user: AuthenticatedUser): boolean {

    if(!this.hasValidPermissionObject){
      return false;
    }
    const userPerms = user.userPermissions;
    if (userPerms === undefined || userPerms === null) {
      return false;
    }
    //check for role
    const permissionArray: number[] = this.getPermArrayForRole(roleTitle, user);
    if (permissionArray !== undefined && permissionArray !== null) {
      const selId = permissionArray.find(id => id === inputId);
      if (selId !== undefined && selId !== null) {
        //means input inputId is present in permission list for user. return true
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method that builds AdxAccessibleEntites object for input user permissions
   * @param userPermissions AdxAuthUserPermission object containing all permissions
   * @returns AdxAccessibleEntites
   */
  public static getAccessibleEntites(userPermissions: AdxAuthUserPermission): AdxAccessibleEntites {
    const toRet = new AdxAccessibleEntites();
    if (userPermissions !== undefined && userPermissions !== null) {
      if (userPermissions.ORGANIZATION_ACCESS !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ORGANIZATION_ACCESS);
      }
      if (userPermissions.ORGANIZATION_ADMIN !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ORGANIZATION_ADMIN);
      }
      if (userPermissions.ORGANIZATION_EDIT !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ORGANIZATION_EDIT);
      }
      if (userPermissions.ORGANIZATION_READ_ONLY !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ORGANIZATION_READ_ONLY);
      }

      //if user logs in to console with only ORGANIZATION_DELETE permission,
      // user should be listed with all the organizations sent from server.
      if (userPermissions.ORGANIZATION_DELETE !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ORGANIZATION_DELETE);
      }

      //we need to display the organization list to the user with ACCOUNT_ADD/ACCOUNT_DELETE permission as well
      if (userPermissions.ACCOUNT_ADD !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ACCOUNT_ADD);
      }

      if (userPermissions.ACCOUNT_DELETE !== null) {
        if (toRet.accessibleOrganizations === null) {
          toRet.accessibleOrganizations = [];
        }
        toRet.accessibleOrganizations.push(...userPermissions.ACCOUNT_DELETE);
      }


      if (toRet.accessibleOrganizations !== undefined && toRet.accessibleOrganizations !== null
        && toRet.accessibleOrganizations.length > 0) {
        toRet.accessibleOrganizations = [...new Set(toRet.accessibleOrganizations)];
      }

      if (userPermissions.ACCOUNT_ACCESS !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.ACCOUNT_ACCESS);
      }
      if (userPermissions.ACCOUNT_ADMIN !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.ACCOUNT_ADMIN);
      }
      if (userPermissions.ACCOUNT_EDIT !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.ACCOUNT_EDIT);
      }
      if (userPermissions.ACCOUNT_READ_ONLY !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.ACCOUNT_READ_ONLY);
      }

      if (userPermissions.APPLICATION_ADD !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.APPLICATION_ADD);
      }

      if (userPermissions.APPLICATION_DELETE !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.APPLICATION_DELETE);
      }

      if (userPermissions.CHANNEL_ADD !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.CHANNEL_ADD);
      }

      if (userPermissions.CHANNEL_DELETE !== null) {
        if (toRet.accessibleAccounts === null) {
          toRet.accessibleAccounts = [];
        }
        toRet.accessibleAccounts.push(...userPermissions.CHANNEL_DELETE);
      }

      if (toRet.accessibleAccounts !== undefined && toRet.accessibleAccounts !== null
        && toRet.accessibleAccounts.length > 0) {
        toRet.accessibleAccounts = [...new Set(toRet.accessibleAccounts)];
      }

      if (userPermissions.APPLICATION_ACCESS !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.APPLICATION_ACCESS);
      }
      if (userPermissions.APPLICATION_ADMIN !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.APPLICATION_ADMIN);
      }
      if (userPermissions.APPLICATION_READ_ONLY !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.APPLICATION_READ_ONLY);
      }
      if (userPermissions.APPLICATION_EDIT !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.APPLICATION_EDIT);
      }
      if (userPermissions.VPUB_ADD !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.VPUB_ADD);
      }
      if (userPermissions.VPUB_DELETE !== null) {
        if (toRet.accessibleApplications === null) {
          toRet.accessibleApplications = [];
        }
        toRet.accessibleApplications.push(...userPermissions.VPUB_DELETE);
      }
      if (toRet.accessibleApplications !== undefined && toRet.accessibleApplications !== null
        && toRet.accessibleApplications.length > 0) {
        toRet.accessibleApplications = [...new Set(toRet.accessibleApplications)];
      }

      if (userPermissions.CHANNEL_ADMIN !== null) {
        if (toRet.accessibleChannels === null) {
          toRet.accessibleChannels = [];
        }
        toRet.accessibleChannels.push(...userPermissions.CHANNEL_ADMIN);
      }
      if (userPermissions.CHANNEL_EDIT !== null) {
        if (toRet.accessibleChannels === null) {
          toRet.accessibleChannels = [];
        }
        toRet.accessibleChannels.push(...userPermissions.CHANNEL_EDIT);
      }
      if (userPermissions.CHANNEL_READ_ONLY !== null) {
        if (toRet.accessibleChannels === null) {
          toRet.accessibleChannels = [];
        }
        toRet.accessibleChannels.push(...userPermissions.CHANNEL_READ_ONLY);
      }
      if (toRet.accessibleChannels !== undefined && toRet.accessibleChannels !== null
        && toRet.accessibleChannels.length > 0) {
        toRet.accessibleChannels = [...new Set(toRet.accessibleChannels)];
      }

      if (userPermissions.VPUB_ACCESS !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.VPUB_ACCESS);
      }
      if (userPermissions.VPUB_ADMIN !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.VPUB_ADMIN);
      }
      if (userPermissions.VPUB_EDIT !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.VPUB_EDIT);
      }
      if (userPermissions.VPUB_READ_ONLY !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.VPUB_READ_ONLY);
      }
      if (userPermissions.MODULE_DELETE !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.MODULE_DELETE);
      }
      if (userPermissions.MODULE_ADD !== null) {
        if (toRet.accessibleVpubs === null) {
          toRet.accessibleVpubs = [];
        }
        toRet.accessibleVpubs.push(...userPermissions.MODULE_ADD);
      }
      if (toRet.accessibleVpubs !== undefined && toRet.accessibleVpubs !== null
        && toRet.accessibleVpubs.length > 0) {
        toRet.accessibleVpubs = [...new Set(toRet.accessibleVpubs)];
      }

      if (userPermissions.MODULE_ACCESS !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.MODULE_ACCESS);
      }
      if (userPermissions.MODULE_ADMIN !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.MODULE_ADMIN);
      }
      if (userPermissions.MODULE_READ_ONLY !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.MODULE_READ_ONLY);
      }
      if (userPermissions.MODULE_EDIT !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.MODULE_EDIT);
      }
      if (userPermissions.FORM_EDIT !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.FORM_EDIT);
      }
      if (userPermissions.FORM_READ_ONLY !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.FORM_READ_ONLY);
      }
      if (userPermissions.ATOM_DELETE !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.ATOM_DELETE);
      }
      if (userPermissions.ATOM_ADD !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.ATOM_ADD);
      }
      if (userPermissions.ATOM_EDIT !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.ATOM_EDIT);
      }
      if (userPermissions.ATOM_READ_ONLY !== null) {
        if (toRet.accessibleModules === null) {
          toRet.accessibleModules = [];
        }
        toRet.accessibleModules.push(...userPermissions.ATOM_READ_ONLY);
      }
      if (toRet.accessibleModules !== undefined && toRet.accessibleModules !== null
        && toRet.accessibleModules.length > 0) {
        toRet.accessibleModules = [...new Set(toRet.accessibleModules)];
      }
    }
    return toRet;
  }

  /*
   * determines whether user permissions object is valid (not null) or not
  */
  private static hasValidPermissionObject(user: AuthenticatedUser): boolean {

    if (user === undefined || user === null) {
      return false;
    }
    if (user.userPermissions === undefined || user.userPermissions === null) {
      return false;
    }
    return true;
  }

  private static getPermArrayForRole(roleTitle: string, user: AuthenticatedUser): number[] {
    if(!this.hasValidPermissionObject){
      return [];
    }
    const userPerms = user.userPermissions;
    if (userPerms === undefined || userPerms === null) {
      return [];
    }
    if (roleTitle === undefined || roleTitle === null) {
      return [];
    }
    if (roleTitle === 'ACCOUNT_ACCESS') {
      if(userPerms.ACCOUNT_ACCESS === undefined || userPerms.ACCOUNT_ACCESS === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_ACCESS;
      }
    }
    else if (roleTitle === 'ACCOUNT_ADD') {
      if(userPerms.ACCOUNT_ADD === undefined || userPerms.ACCOUNT_ADD === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_ADD;
      }
    }
    else if (roleTitle === 'ACCOUNT_ADMIN') {
      if(userPerms.ACCOUNT_ADMIN === undefined || userPerms.ACCOUNT_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_ADMIN;
      }
    }
    else if (roleTitle === 'ACCOUNT_DELETE') {
      if(userPerms.ACCOUNT_DELETE === undefined || userPerms.ACCOUNT_DELETE === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_DELETE;
      }
    }
    else if (roleTitle === 'ACCOUNT_EDIT') {
      if(userPerms.ACCOUNT_EDIT === undefined || userPerms.ACCOUNT_EDIT === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_EDIT;
      }
    }
    else if (roleTitle === 'ACCOUNT_READ_ONLY') {
      if(userPerms.ACCOUNT_READ_ONLY === undefined || userPerms.ACCOUNT_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.ACCOUNT_READ_ONLY;
      }
    }
    else if (roleTitle === 'APPLICATION_ACCESS') {
      if(userPerms.APPLICATION_ACCESS === undefined || userPerms.APPLICATION_ACCESS === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_ACCESS;
      }
    }
    else if (roleTitle === 'APPLICATION_ADD') {
      if(userPerms.APPLICATION_ADD === undefined || userPerms.APPLICATION_ADD === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_ADD;
      }
    }
    else if (roleTitle === 'APPLICATION_ADMIN') {
      if(userPerms.APPLICATION_ADMIN === undefined || userPerms.APPLICATION_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_ADMIN;
      }
    }
    else if (roleTitle === 'APPLICATION_DELETE') {
      if(userPerms.APPLICATION_DELETE === undefined || userPerms.APPLICATION_DELETE === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_DELETE;
      }
    }
    else if (roleTitle === 'APPLICATION_EDIT') {
      if(userPerms.APPLICATION_EDIT === undefined || userPerms.APPLICATION_EDIT === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_EDIT;
      }
    }
    else if (roleTitle === 'APPLICATION_READ_ONLY') {
      if(userPerms.APPLICATION_READ_ONLY === undefined || userPerms.APPLICATION_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.APPLICATION_READ_ONLY;
      }
    }
    else if (roleTitle === 'ATOM_ADD') {
      if(userPerms.ATOM_ADD === undefined || userPerms.ATOM_ADD === null) {
        return [];
      }
      else {
        return userPerms.ATOM_ADD;
      }
    }
    else if (roleTitle === 'ATOM_DELETE') {
      if(userPerms.ATOM_DELETE === undefined || userPerms.ATOM_DELETE === null) {
        return [];
      }
      else {
        return userPerms.ATOM_DELETE;
      }
    }
    else if (roleTitle === 'ATOM_EDIT') {
      if(userPerms.ATOM_EDIT === undefined || userPerms.ATOM_EDIT === null) {
        return [];
      }
      else {
        return userPerms.ATOM_EDIT;
      }
    }
    else if (roleTitle === 'ATOM_READ_ONLY') {
      if(userPerms.ATOM_READ_ONLY === undefined || userPerms.ATOM_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.ATOM_READ_ONLY;
      }
    }
    else if (roleTitle === 'CHANNEL_ADD') {
      if(userPerms.CHANNEL_ADD === undefined || userPerms.CHANNEL_ADD === null) {
        return [];
      }
      else {
        return userPerms.CHANNEL_ADD;
      }
    }
    else if (roleTitle === 'CHANNEL_ADMIN') {
      if(userPerms.CHANNEL_ADMIN === undefined || userPerms.CHANNEL_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.CHANNEL_ADMIN;
      }
    }
    else if (roleTitle === 'CHANNEL_DELETE') {
      if(userPerms.CHANNEL_DELETE === undefined || userPerms.CHANNEL_DELETE === null) {
        return [];
      }
      else {
        return userPerms.CHANNEL_DELETE;
      }
    }
    else if (roleTitle === 'CHANNEL_EDIT') {
      if(userPerms.CHANNEL_EDIT === undefined || userPerms.CHANNEL_EDIT === null) {
        return [];
      }
      else {
        return userPerms.CHANNEL_EDIT;
      }
    }
    else if (roleTitle === 'CHANNEL_READ_ONLY') {
      if(userPerms.CHANNEL_READ_ONLY === undefined || userPerms.CHANNEL_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.CHANNEL_READ_ONLY;
      }
    }
    else if (roleTitle === 'FORM_EDIT') {
      if(userPerms.FORM_EDIT === undefined || userPerms.FORM_EDIT === null) {
        return [];
      }
      else {
        return userPerms.FORM_EDIT;
      }
    }
    else if (roleTitle === 'FORM_READ_ONLY') {
      if(userPerms.FORM_READ_ONLY === undefined || userPerms.FORM_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.FORM_READ_ONLY;
      }
    }
    else if (roleTitle === 'INSTANCE_ADMIN') {
      if(userPerms.INSTANCE_ADMIN === undefined || userPerms.INSTANCE_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.INSTANCE_ADMIN;
      }
    }
    else if (roleTitle === 'MODULE_ACCESS') {
      if(userPerms.MODULE_ACCESS === undefined || userPerms.MODULE_ACCESS === null) {
        return [];
      }
      else {
        return userPerms.MODULE_ACCESS;
      }
    }
    else if (roleTitle === 'MODULE_ADD') {
      if(userPerms.MODULE_ADD === undefined || userPerms.MODULE_ADD === null) {
        return [];
      }
      else {
        return userPerms.MODULE_ADD;
      }
    }
    else if (roleTitle === 'MODULE_ADMIN') {
      if(userPerms.MODULE_ADMIN === undefined || userPerms.MODULE_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.MODULE_ADMIN;
      }
    }
    else if (roleTitle === 'MODULE_DELETE') {
      if(userPerms.MODULE_DELETE === undefined || userPerms.MODULE_DELETE === null) {
        return [];
      }
      else {
        return userPerms.MODULE_DELETE;
      }
    }
    else if (roleTitle === 'MODULE_EDIT') {
      if(userPerms.MODULE_EDIT === undefined || userPerms.MODULE_EDIT === null) {
        return [];
      }
      else {
        return userPerms.MODULE_EDIT;
      }
    }
    else if (roleTitle === 'MODULE_READ_ONLY') {
      if(userPerms.MODULE_READ_ONLY === undefined || userPerms.MODULE_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.MODULE_READ_ONLY;
      }
    }
    else if (roleTitle === 'ORGANIZATION_ACCESS') {
      if(userPerms.ORGANIZATION_ACCESS === undefined || userPerms.ORGANIZATION_ACCESS === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_ACCESS;
      }
    }
    else if (roleTitle === 'ORGANIZATION_ADD') {
      if(userPerms.ORGANIZATION_ADD === undefined || userPerms.ORGANIZATION_ADD === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_ADD;
      }
    }
    else if (roleTitle === 'ORGANIZATION_ADMIN') {
      if(userPerms.ORGANIZATION_ADMIN === undefined || userPerms.ORGANIZATION_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_ADMIN;
      }
    }
    else if (roleTitle === 'ORGANIZATION_DELETE') {
      if(userPerms.ORGANIZATION_DELETE === undefined || userPerms.ORGANIZATION_DELETE === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_DELETE;
      }
    }
    else if (roleTitle === 'ORGANIZATION_EDIT') {
      if(userPerms.ORGANIZATION_EDIT === undefined || userPerms.ORGANIZATION_EDIT === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_EDIT;
      }
    }
    else if (roleTitle === 'ORGANIZATION_READ_ONLY') {
      if(userPerms.ORGANIZATION_READ_ONLY === undefined || userPerms.ORGANIZATION_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.ORGANIZATION_READ_ONLY;
      }
    }
    else if (roleTitle === 'SYSTEM_ADMIN') {
      if(userPerms.SYSTEM_ADMIN === undefined || userPerms.SYSTEM_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.SYSTEM_ADMIN;
      }
    }
    else if (roleTitle === 'SYSTEM_READ_ONLY') {
      if(userPerms.SYSTEM_READ_ONLY === undefined || userPerms.SYSTEM_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.SYSTEM_READ_ONLY;
      }
    }
    else if (roleTitle === 'VPUB_ACCESS') {
      if(userPerms.VPUB_ACCESS === undefined || userPerms.VPUB_ACCESS === null) {
        return [];
      }
      else {
        return userPerms.VPUB_ACCESS;
      }
    }
    else if (roleTitle === 'VPUB_ADD') {
      if(userPerms.VPUB_ADD === undefined || userPerms.VPUB_ADD === null) {
        return [];
      }
      else {
        return userPerms.VPUB_ADD;
      }
    }
    else if (roleTitle === 'VPUB_ADMIN') {
      if(userPerms.VPUB_ADMIN === undefined || userPerms.VPUB_ADMIN === null) {
        return [];
      }
      else {
        return userPerms.VPUB_ADMIN;
      }
    }
    else if (roleTitle === 'VPUB_DELETE') {
      if(userPerms.VPUB_DELETE === undefined || userPerms.VPUB_DELETE === null) {
        return [];
      }
      else {
        return userPerms.VPUB_DELETE;
      }
    }
    else if (roleTitle === 'VPUB_EDIT') {
      if(userPerms.VPUB_EDIT === undefined || userPerms.VPUB_EDIT === null) {
        return [];
      }
      else {
        return userPerms.VPUB_EDIT;
      }
    }
    else if (roleTitle === 'VPUB_READ_ONLY') {
      if(userPerms.VPUB_READ_ONLY === undefined || userPerms.VPUB_READ_ONLY === null) {
        return [];
      }
      else {
        return userPerms.VPUB_READ_ONLY;
      }
    }
    return [];
  }

}

/**
 * Class that stores acessible object ids for all levels.
 */
export class AdxAccessibleEntites {
  accessibleOrganizations: number[] | null = null;
  accessibleAccounts: number[] | null = null;
  accessibleApplications: number[] | null = null;
  accessibleChannels: number[] | null = null;
  accessibleVpubs: number[] | null = null;
  accessibleModules: number[] | null = null;
}
