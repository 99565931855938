<div fxLayout="column" class="insert-ars-container">
  <div class="row">
    <div fxFlex="100">
    <h4 class="moduleSelectionGroupContent">
      <span>Insert link to ARS Atom or Category</span>
    </h4>
  </div>
  </div>
  <div class="row">
    <div fxFlex="60" class="associateCategories common-container" [formGroup]="moduleSelectionGroup">
      <mat-form-field class="input-form" appearance="fill">
        <mat-label>Select Module</mat-label>
        <mat-select formControlName="selectModule" (selectionChange)="displayList()">
          <mat-option *ngFor="let module of arsModules" [value]="module.id">
            {{module.title}}
          </mat-option>
        </mat-select>
    </mat-form-field>
  </div>
  </div>
    
  <div class="container-fluid moduleArsList" *ngIf="dataSource !== null">
    <div class="row">
     <div fxFlex="50" class="common-title moduleArsListDiv">Title</div>
      <div fxFlex="17" class="moduleArsListDiv">Category</div>
      <div fxFlex="10" class="moduleArsListDiv">Visibility Status</div>
      <div fxFlex="23" class="moduleArsListDiv"></div>
    </div>
  </div>
   
  <div class="container-fluid" *ngIf="dataSource !== null">
  <div class="row moduleArsListFluid">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"> 
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node class="moduleListMatTreeNodeToggle" *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <div class="container-fluid moduleListMatTreeNode">
          <div class="row">
            <div fxFlex="53" class="input-label"> 
              <p class="moduleListMatTreeNodeTitle" matTooltip="{{node.title}}">{{node.title}}</p>              
            </div>
            <div fxFlex="17" class="input-label">
              <ng-container *ngIf="node.isCategory;  else NotCategory" mat-cell>
                <span>Yes</span>
              </ng-container>
              <ng-template #NotCategory>
                <span>No</span>
              </ng-template>
            </div>
            <div fxFlex="10" class="input-label">
              <ng-container *ngIf="node.visibility.toLowerCase() ==='visible';  else AtomHidden" mat-cell>
                <mat-icon matTooltip="Content is visible">visibility</mat-icon>
              </ng-container>
              <ng-template #AtomHidden>
                <ng-container *ngIf="node.visibility.toLowerCase() ==='hidden';  else AtomDisable">
                  <mat-icon matTooltip="Content is hidden, but can be reached using internal hyper-link">visibility_off</mat-icon>
                </ng-container>
              </ng-template>
              <ng-template #AtomDisable>
                <mat-icon matTooltip="Content is hidden and unreachable">hide_source</mat-icon>
              </ng-template>
            </div>
            <div fxFlex="20">
              <button class="accountDalLink" i18n="@@account-dalLink" (click)="addLink(node.id, node.title)"
                      mat-stroked-button>Add Link</button>
            </div>
          </div>
        </div>
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
     <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li class="mat-nested-tree-node">
        <div class="mat-tree-node ">
          <div class="container-fluid moduleListMatTreeNode">
            <div class="row">
              <div fxFlex="53" class="input-label">
                <p class="moduleListMatTreeNodeTitle" matTooltip="{{node.title}}">{{node.title}}</p>
              </div>
              <div fxFlex="17" class="input-label">
                <ng-container *ngIf="node.isCategory;  else NotCategory" mat-cell>
                  <span>Yes</span>
                </ng-container>
                <ng-template #NotCategory>
                  <span>No</span>
                </ng-template>
              </div>
              <div fxFlex="10" class="input-label">
                <ng-container *ngIf="node.visibility.toLowerCase() ==='visible';  else AtomHidden" mat-cell>
                  <mat-icon matTooltip="Content is visible">visibility</mat-icon>
                </ng-container>
                <ng-template #AtomHidden>
                  <ng-container *ngIf="node.visibility.toLowerCase() ==='hidden';  else AtomDisable">
                    <mat-icon matTooltip="Content is hidden, but can be reached using internal hyper-link">visibility_off</mat-icon>
                  </ng-container>
                </ng-template>
                <ng-template #AtomDisable>
                  <mat-icon matTooltip="Content is hidden and unreachable">hide_source</mat-icon>
                </ng-template>
              </div>
              <div fxFlex="20">
                <button class="accountDalLink" i18n="@@account-dalLink" (click)="addLink(node.id, node.title)"
                        mat-stroked-button>Add Link</button>

              </div>
            </div>
          </div>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
  </div>
  </div>
</div>
