import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticatedUser} from '../../common/model/adx-auth/authenticated-user.model';
import {Subject, Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AuthNotifierService} from '../../auth/service/auth-notifier.service';
import {AuthenticationService} from '../../auth/service/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../core/service/storage/local-storage.service';
import {takeUntil} from 'rxjs/operators';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import { AdxJwtTokenService } from 'src/app/common/services/adx-jwt-token/adx-jwt-token.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnDestroy {

  errorMessage: string | null = null;
  isUserAuthenticated = false;
  authenticatedUser: AuthenticatedUser | null = null;

  authSubscription: Subscription | undefined;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private logger: NGXLogger,
    private cd: ChangeDetectorRef,
    private authNotifier: AuthNotifierService,
    private authService: AuthenticationService,
    private storageService: LocalStorageService,
    private readonly messageNotifier: CustomNotifierService,
    private readonly jwtTokenService: AdxJwtTokenService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('received auth event');
        this.logger.debug(user);
        this.isUserAuthenticated = (user !== null? true: false);
        this.authenticatedUser = user;

        this.cd.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  logout(authenticatedUser: AuthenticatedUser | null): void {
    this.logger.debug('logout called');
    if (authenticatedUser) {
      this.authService.logout(authenticatedUser).pipe(takeUntil(this.destroy$))
        .subscribe({
        next: (data) => {
          this.storageService.remove('java_web_token');
          this.storageService.remove('auth_user');
          this.storageService.remove('accessibleEntities');
          this.authNotifier.notifyUserLogout();
          this.jwtTokenService.setToken('');
          // Show login screen
          this.router.navigate(['auth/login']).then(r => {});
        },
        complete: () => {
          // user logged-out successfully.
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `User logged-out successfully`);
        }});
    }
  }
}
