import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';

@Injectable()
export class CustomRequestInterceptor implements HttpInterceptor {
  private locatStorageService;
  constructor(private logger: NGXLogger, storageService: LocalStorageService) {
    this.locatStorageService = storageService;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwt = this.locatStorageService.get('java_web_token');

    if (jwt) {
      const clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + jwt)
      });
      this.logger.debug('set token in header');
      return next.handle(clonedReq);
    } else {
      return next.handle(req);
    }
  }
}
