import { AdxResource } from 'src/app/core/service/resource/adx-resource.model';
import { AdxRolePermission } from './adx-role-permission-model';

/**
 * Model representing the Role. Role can have list of permissions
 */
export class AdxRole extends AdxResource {
  title: string | null = null;
  description: string | null = null;
  assignable: boolean | null = null;
  scope: string | null = null;
  isCustom: boolean | null = null;
  permissions: AdxRolePermission[] = [];

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxRole {
    const toRet: AdxRole = new AdxRole(null);
    if (!json) {
      return toRet;
    }

    if (json.id !== undefined && json.id !== null) {
      toRet.id = json.id;
    }

    if (json.title !== undefined && json.title !== null) {
      toRet.title = json.title;
    }

    if (json.description !== undefined && json.description !== null) {
      toRet.description = json.description;
    }

    if (json.assignable !== undefined && json.assignable !== null) {
      toRet.assignable = json.assignable;
    }

    if (json.scope !== undefined && json.scope !== null) {
      toRet.scope = json.scope;
    }

    if (json.isCustom !== undefined && json.isCustom !== null) {
      toRet.isCustom = json.isCustom;
    }

    if (json.permissions !== undefined && json.permissions !== null) {
      let tempObj: AdxRolePermission;
      json.permissions.forEach((permission: any) => {
        tempObj = (AdxRolePermission.buildObject(permission));
        if (tempObj) {
          if (!toRet.permissions) {
            toRet.permissions = [];
          }
          toRet.permissions.push(tempObj);
        }
      });
    }

    return toRet;
  }
}
