import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountSerializer } from '../model/account-serializer';
import { Account } from '../model/account.model';
import { AdxQueryParams } from 'src/app/core/service/resource/adx-query-params.model';
import { AdxRestService } from 'src/app/core/service/resource/adx-rest-service.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AdxAuthUtils } from 'src/app/auth/utils/adx-auth-utils';

/**
 * This service is used to interact with the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private serializer: AccountSerializer = new AccountSerializer();

  constructor(private logger: NGXLogger, private restService: AdxRestService<Account, Account>) { }

  /*
   * This API is used to fetch the list of Accounts from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getAccounts(orgId: number): Observable<Account[]> {

    return this.restService.list(`orgs/${orgId}/accts`, new AdxQueryParams(), null, this.serializer)
    .pipe(
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
      }));
  }

  /*
   * This API is used to fetch the Account from backend.
   * Returns an observable. Throws exception in case of error.
  */
  getAccount(orgId: number, id: number): Observable<Account> {

    return this.restService.read(`orgs/${orgId}/accts`, id, null, this.serializer)
    .pipe(
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = `Error: ${error.message}`;
          }
          return throwError(errorMsg);
      }));
  }


  /*
   * This API is used to add Account to backend.
   * Returns an observable. Throws exception in case of error.
  */
  addAccount(orgId: number, acct: Account): Observable<Account>{
    return this.restService.create(`orgs/${orgId}/accts`, acct, null, this.serializer).pipe(
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = `Error: ${error.message}`;
          }
          this.logger.error('Error while adding Account:' + errorMsg);
          return throwError(errorMsg);
      }));
  }

  /*
   * This API is used to update Account in backend.
   * Returns an observable. Throws exception in case of error.
  */
  updateAccount(orgId: number, acct: Account): Observable<Account> {
    return this.restService.update(`orgs/${orgId}/accts/${acct.id}`, acct, null, this.serializer).pipe(
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = `Error: ${error.message}`;
          }
          this.logger.error('Error while adding Account:' + errorMsg);
          return throwError(errorMsg);
      }));
  }

  /**
   * Utility method get account ids accessible by given user
   * If user has permissions of systemadmin, instanceadmin, organizationadmin or organization readonly then he can view all accountids.
   *  That need to be handled by the caller method. This method will only return account ids accessible by user
   *
   * @param currentUser AuthenticatedUser
   * @returns number array containing the ids of accounts accessible by user
   */
  public getAccessiblAccountIdsForUser(currentUser: AuthenticatedUser): number[] {
    let userAccessibleAccountIds: number[] = [];
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(currentUser.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleAccounts !== undefined && accessibleEntities.accessibleAccounts !== null) {
        userAccessibleAccountIds = accessibleEntities.accessibleAccounts;
      }
    }
    return userAccessibleAccountIds;
  }

  /**
   * Utility method to verify whether the logged in user has access to add account
   * user with org admin or account add permission will be able to add account.
   * If user has system admin or instance admin role, then he will be able to add account
   *
   * @param orgId organization id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit account
   */
  public canUserAddAccount(orgId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser)) {
        //means input orgid is present in org-admin permission list for user. return true
        this.logger.debug('user has org admin permission');
        return true;
      }

      //now check for account add
      if (AdxAuthUtils.hasRoleForGivenId(orgId, 'ACCOUNT_ADD', currentUser)) {
        //means input orgid is present in org-edit permission list for user. return true
        this.logger.debug('user has account add permission');
        return true;
      }
    }
    return false;
  }

  /**
   * Utility method to verify whether the logged in user has access to edit account
   * user with org admin or account admin or account edit permission will be able to edit account.
   * If user has system admin or instance admin role, then he will be able to edit account
   *
   * @param orgId organization id
   * @param acctId account id
   * @param currentUser logged in user
   * @returns boolean if user is able to edit account
   */
  public canUserEditAccount(orgId: number, acctId: number, currentUser: AuthenticatedUser): boolean {
    //if not logged-in send false
    if (currentUser === undefined || currentUser === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(currentUser)) {
      this.logger.debug('sys/inst admin user, allowed to edit');
      return true;
    }
    if (currentUser !== undefined && currentUser !== null
      && currentUser.userPermissions !== undefined && currentUser.userPermissions !== null) {
      //first check for org admin
      const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(orgId, 'ORGANIZATION_ADMIN', currentUser);
      if (isOrgAdmin) {
        return true;
      }

      //now check for account admin
      const isAccountAdmin = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_ADMIN', currentUser);
      if (isAccountAdmin) {
        return true;
      }

      //now check for account edit
      //now check for account admin
      const isAccountEdit = AdxAuthUtils.hasRoleForGivenId(acctId, 'ACCOUNT_EDIT', currentUser);
      if (isAccountEdit) {
        return true;
      }
    }
    return false;
  }

  /**
   * Utility function to get accessible accounts for current user
   * @param user : logged in user
   * @returns array of numbers
   */
  public getUserAccessibleAccountIds(user: AuthenticatedUser): number[] {
    let userAccessibleAcctIds: number[] = [];
    if (user !== undefined && user !== null
      && user.userPermissions !== undefined && user.userPermissions !== null) {
      const accessibleEntities = AdxAuthUtils.getAccessibleEntites(user.userPermissions);
      if (accessibleEntities !== undefined && accessibleEntities !== null
        && accessibleEntities.accessibleAccounts !== undefined && accessibleEntities.accessibleAccounts !== null) {
        userAccessibleAcctIds = accessibleEntities.accessibleAccounts;
      }
    }
    return userAccessibleAcctIds;
  }
}

