<div class="dialog-header">
  <h2 mat-dialog-title i18n="@insert-content-label">Insert Content</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="insert-container">
  <mat-tab-group class="common-tab-group" [selectedIndex]="0">

    <mat-tab label="Content">

      <ng-template mat-tab-label i18n="@insertImage-label">
        <mat-icon class="example-tab-icon">image</mat-icon>
        Image
      </ng-template>

      <ng-template matTabContent>
        <app-adx-insert-image-input [data]="inputData" (workDone)="closeDialog()"></app-adx-insert-image-input>
      </ng-template>
    </mat-tab>

    <mat-tab i18n="@@insertEmbed-label" label="Embed">
      <ng-template mat-tab-label i18N="@embed-label">
        <mat-icon class="example-tab-icon">add_to_photos</mat-icon>
        Embed
      </ng-template>
      <ng-template matTabContent>
        <app-adx-insert-embed-input [data]="inputData" (workDone)="closeDialog()"></app-adx-insert-embed-input>
      </ng-template>
    </mat-tab>

    <mat-tab i18n="@@insertModule-label" label="Module">
      <ng-template mat-tab-label i18N="@insert-module-label">
        <mat-icon class="example-tab-icon">camera</mat-icon>
        Module
      </ng-template>
      <ng-template matTabContent>
        <app-adx-insert-module-input [data]="inputData" (workDone)="closeDialog()"></app-adx-insert-module-input>
      </ng-template>
    </mat-tab>

    <mat-tab i18n="@@insertArs-label" label="ARS">
      <app-adx-insert-ars-input [data]="inputData" (workDone)="closeDialog()"></app-adx-insert-ars-input>
    </mat-tab>

  </mat-tab-group>

</div>

