import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-adx-text-input',
  templateUrl: './adx-text-input.component.html',
  styleUrls: ['./adx-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdxTextInputComponent),
      multi: true
    }
  ]
})
export class AdxTextInputComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder: string;
  @Output() changeEmitter: EventEmitter<string> = new EventEmitter<string>();
  value: string;
  isDisabled: boolean;

  constructor() {
    this.placeholder = '';
    this.value = '';
    this.isDisabled = false;
  }

  ngOnInit(): void {
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.propogateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propogateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Triggered whenever keyboard key is pressed and released
   *
   * @param value
   */
  onKeyup(value: string): void {
    this.value = value;
    this.propogateChange(value);
    this.changeEmitter.emit(value);
  }

  /**
   * Triggered whenever input looses focus
   */
  onBlur(): void {
    this.propogateTouched();
  }

  private propogateChange: any = () => { };
  private propogateTouched: any = () => { };

}
