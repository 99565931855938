import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdxBaseTemplate} from '../../../common/template/adx-base-template';
import {NestedTreeControl} from '@angular/cdk/tree';
import {AdxModule} from '../../../adx-module/model/adx-module.model';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AdxModuleService} from '../../../adx-module/service/adx-module.service';
import {AdxInsertContentDialogData} from '../../utils/adx-insert-content-dialog-data';

@Component({
  selector: 'app-adx-insert-module-input',
  templateUrl: './adx-insert-module-input.component.html',
  styleUrls: ['./adx-insert-module-input.component.scss']
})
export class AdxInsertModuleInputComponent extends AdxBaseTemplate implements OnInit {

  @Input() data: AdxInsertContentDialogData | undefined;
  @Output() workDone = new EventEmitter<boolean>();

  orgId: number | null = null;
  accountId: number | null = null;
  vpubId: number | null = null;
  treeControl = new NestedTreeControl<AdxModule>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AdxModule>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly moduleService: AdxModuleService) {
    super();
    this.logger.debug(`In AdxInsertModuleInputComponent`);
    this.logger.debug(this.data);
  }

  hasChild = (_: number, node: AdxModule) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.logger.debug(this.data);
    if (this.data) {
      if (this.data.orgId) {
        this.orgId = this.data.orgId;
      }
      if (this.data.accountId) {
        this.accountId = this.data.accountId;
      }
      if (this.data.vpubId) {
        this.vpubId = this.data.vpubId;
      }
    }

    if (!this.orgId || !this.accountId || !this.vpubId) {
      this.logger.error('required params missing');
      return;
    }

    this.fetchModules(this.orgId, this.accountId, this.vpubId);
  }

  addLink(moduleId: number, title: string): void {
    this.logger.debug(`link for moduleId:${moduleId} title:${title}`);

    if (!this.data || !this.data.editorRef) {
      this.logger.error(`addLink:: Editor not initialized ${this.data} ${this.data?.editorRef}`);
      return;
    }

    const url = 'mfp://module/' + moduleId;

    // if they have wrapped something to link already, we want to preserve the body of that...
    if (this.data.editorRef.selection) {
      this.data.editorRef.selection.restore();
    }
    if (this.data.editorRef.selection && this.data.editorRef.selection.text()) {
      const selection = this.data.editorRef.selection.text();
      this.data.editorRef.link.insert(url, selection);
    } else {
      // use the title as the content and insert....
      this.data.editorRef.link.insert(url, title);
    }
    this.workDone.emit(true);
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for vpubId:' + vpubId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data. so set it in variable
          this.dataSource.data = data;
        }
      });
  }
}
