import { AdxUser } from '../model/adx-user-model';
import { AdxUserRole } from '../model/adx-user-role-model';

export class AdxUserUtility {

  /**
   * Utility function to filter the roles of given user by given organization id and user management level
   *
   * @param user user object containing roles
   * @param orgId id of organization
   * @param roleLevel user management level. If none specified, role at all levels are considered
   * @returns AdxUserRole[] list of roles matching the input criteria
   */
  public static filterUserRolesBasedOnOrganization(user: AdxUser, orgId: number, roleLevel: string | null): AdxUserRole[] | null {
    if (user === undefined || user === null || orgId === undefined || orgId === null) {
      return null;
    }

    const toRet: AdxUserRole[] = [];
    if (roleLevel === undefined || roleLevel === null) {
      roleLevel = 'ALL';
    }

    switch (roleLevel) {
      case 'ALL':
      // eslint-disable-next-line no-fallthrough
      case 'MODULE': {
        // get all module level roles and filter out the roles matching to the given org id
        const moduleRoles = user.moduleRoles;
        if (moduleRoles !== undefined && moduleRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByOrgId(moduleRoles, orgId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'VPUB':
        // get all VPUB level roles and filter out the roles matching to the given org id
        const vPubRoles = user.vpubRoles;
        if (vPubRoles !== undefined && vPubRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByOrgId(vPubRoles, orgId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      // eslint-disable-next-line no-fallthrough
      case 'CHANNEL': {
        // get all CHANNEL level roles and filter out the roles matching to the given org id
        const channelRoles = user.channelRoles;
        if (channelRoles !== undefined && channelRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByOrgId(channelRoles, orgId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'APPLICATION': {
        // get all APPLICATION level roles and filter out the roles matching to the given org id
        const applnRoles = user.applicationRoles;
        if (applnRoles !== undefined && applnRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByOrgId(applnRoles, orgId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'ACCOUNT': {
        // get all ACCOUNT level roles and filter out the roles matching to the given org id
        const acctRoles = user.accountRoles;
        if (acctRoles !== undefined && acctRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByOrgId(acctRoles, orgId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'ORGANIZATION': {
        // first get all organization level roles and filter out the roles matching to the given organization id
        const orgRoles = user.organizationRoles;
        if (orgRoles !== undefined && orgRoles !== null) {
          const filteredOrgRoles = AdxUserUtility.filterRolesByOrgId(orgRoles, orgId);
          if (filteredOrgRoles !== undefined && filteredOrgRoles !== null && filteredOrgRoles.length > 0) {
            toRet.push(...filteredOrgRoles);
          }
        }
        break;
      }
      default: {
        // none of the options matched
      }
    }

    return toRet;
  }

  public static filterUserRolesBasedOnAccount(user: AdxUser, accountId: number, roleLevel: string): AdxUserRole[] | null {
    if (user === undefined || user === null || accountId === undefined || accountId === null) {
      return null;
    }

    const toRet: AdxUserRole[] = [];

    switch (roleLevel) {

      // eslint-disable-next-line no-fallthrough
      case 'ACCOUNT': {
        // get all ACCOUNT level roles and filter out the roles matching to the given org id
        const acctRoles = user.accountRoles;
        if (acctRoles !== undefined && acctRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByAccountId(acctRoles, accountId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }

      // eslint-disable-next-line no-fallthrough
      case 'APPLICATION': {
        // get all APPLICATION level roles and filter out the roles matching to the given org id
        const applnRoles = user.applicationRoles;
        if (applnRoles !== undefined && applnRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByAccountId(applnRoles, accountId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }

      // eslint-disable-next-line no-fallthrough
      case 'CHANNEL': {
        // get all CHANNEL level roles and filter out the roles matching to the given org id
        const channelRoles = user.channelRoles;
        if (channelRoles !== undefined && channelRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByAccountId(channelRoles, accountId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }

      // eslint-disable-next-line no-fallthrough
      case 'VPUB':
        // get all VPUB level roles and filter out the roles matching to the given org id
        const vPubRoles = user.vpubRoles;
        if (vPubRoles !== undefined && vPubRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByAccountId(vPubRoles, accountId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }

      // eslint-disable-next-line no-fallthrough
      case 'MODULE': {
        // get all module level roles and filter out the roles matching to the given org id
        const moduleRoles = user.moduleRoles;
        if (moduleRoles !== undefined && moduleRoles !== null) {
          const filteredRoles = AdxUserUtility.filterRolesByAccountId(moduleRoles, accountId);
          if (filteredRoles !== undefined && filteredRoles !== null && filteredRoles.length > 0) {
            toRet.push(...filteredRoles);
          }
        }
      }

    }

    return toRet;
  }


  /**
   * Utility function to exclude for the given user, the roles belonging to provided organization id
   *
   * @param user user object containing roles
   * @param orgId id of organization
   * @returns AdxUserRole[] list of roles that do not belong to organization id specified
   */
  public static excludeUserRolesBasedOnOrganization(user: AdxUser, orgId: number, roleLevel: string): AdxUserRole[] | null {
    if (user === undefined || user === null || orgId === undefined || orgId === null) {
      return null;
    }

    const toRet: AdxUserRole[] = [];

    if (roleLevel === 'ORGANIZATION') {
        // get all ORGANIZATION level roles and filter out the roles matching to the given org id
        const orgRoles = user.organizationRoles;
        if (orgRoles !== undefined && orgRoles !== null) {
          const excludedRoles = AdxUserUtility.excludeRolesByOrgId(orgRoles, orgId);
          if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
            toRet.push(...excludedRoles);
          }
        }
        // at ORG_LEVEL display, all account level roles are excluded
        const acctRoles = user.accountRoles;
        if (acctRoles !== undefined && acctRoles !== null) {
          toRet.push(...acctRoles);
        }

        // at ORG_LEVEL display, all application level roles are excluded
        const applnRoles = user.applicationRoles;
        if (applnRoles !== undefined && applnRoles !== null) {
          toRet.push(...applnRoles);
        }

        // at ORG_LEVEL display, all channel level roles are excluded
        const channelRoles = user.channelRoles;
        if (channelRoles !== undefined && channelRoles !== null) {
          toRet.push(...channelRoles);
        }

        // at ORG_LEVEL display, all vpub level roles are excluded
        const vPubRoles = user.vpubRoles;
        if (vPubRoles !== undefined && vPubRoles !== null) {
          toRet.push(...vPubRoles);
        }

        // at ORG_LEVEL display, all vpub level roles are excluded
        const moduleRoles = user.moduleRoles;
        if (moduleRoles !== undefined && moduleRoles !== null) {
          toRet.push(...moduleRoles);
        }
    }

    return toRet;
  }

  public static excludeUserRolesBasedOnAccount(user: AdxUser, accountId: number, roleLevel: string): AdxUserRole[] | null {
    if (user === undefined || user === null || accountId === undefined || accountId === null) {
      return null;
    }

    const toRet: AdxUserRole[] = [];

    if (roleLevel === 'ACCOUNT') {

      // get all ORGANIZATION level roles and add to excluded list as it is ACCOUNT level access
      const orgRoles = user.organizationRoles;
      if (orgRoles !== undefined && orgRoles !== null) {
          toRet.push(...orgRoles);
      }

    // get all ACCOUNT level roles and filter out the roles matching to the given account id
    const acctRoles = user.accountRoles;
    if (acctRoles !== undefined && acctRoles !== null) {
      const excludedRoles = AdxUserUtility.excludeRolesByAccountId(acctRoles, accountId);
      if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
        toRet.push(...excludedRoles);
      }
    }

    // get all APPLICATION level roles and filter out the roles matching to the given account id
    const applnRoles = user.applicationRoles;
    if (applnRoles !== undefined && applnRoles !== null) {
      const excludedRoles = AdxUserUtility.excludeRolesByAccountId(applnRoles, accountId);
      if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
        toRet.push(...excludedRoles);
      }
    }

    // get all CHANNEL level roles and filter out the roles matching to the given account id
    const channelRoles = user.channelRoles;
    if (channelRoles !== undefined && channelRoles !== null) {
      const excludedRoles = AdxUserUtility.excludeRolesByAccountId(channelRoles, accountId);
      if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
        toRet.push(...excludedRoles);
      }
    }

    // get all VPUB level roles and filter out the roles matching to the given account id
    const vPubRoles = user.vpubRoles;
    if (vPubRoles !== undefined && vPubRoles !== null) {
      const excludedRoles = AdxUserUtility.excludeRolesByAccountId(vPubRoles, accountId);
      if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
        toRet.push(...excludedRoles);
      }
    }

    // get all MODULE level roles and filter out the roles matching to the given account id
    const moduleRoles = user.moduleRoles;
    if (moduleRoles !== undefined && moduleRoles !== null) {
      const excludedRoles = AdxUserUtility.excludeRolesByAccountId(moduleRoles, accountId);
      if (excludedRoles !== undefined && excludedRoles !== null && excludedRoles.length > 0) {
        toRet.push(...excludedRoles);
      }
    }
  }

    return toRet;
  }

  /**
   * Utility function to filter out assignable roles from the given user roles.
   *
   * @param user AdxUser object representing the user
   * @returns array of assignableRoles of the given user
   */
  public static filterUserAssignableRoles(user: AdxUser): AdxUserRole[] | null {
    if (user === undefined || user === null) {
      return null;
    }

    const toRet: AdxUserRole[] = [];
    // get all ORGANIZATION level roles and filter out the roles matching to the given org id
    const orgRoles = user.organizationRoles;
    if (orgRoles !== undefined && orgRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(orgRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }

    // get all ACCOUNT level roles and filter out the roles matching to the given org id
    const acctRoles = user.accountRoles;
    if (acctRoles !== undefined && acctRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(acctRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }

    // get all APPLICATION level roles and filter out the roles matching to the given org id
    const applnRoles = user.applicationRoles;
    if (applnRoles !== undefined && applnRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(applnRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }

    // get all CHANNEL level roles and filter out the roles matching to the given org id
    const channelRoles = user.channelRoles;
    if (channelRoles !== undefined && channelRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(channelRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }

    // get all VPUB level roles and filter out the roles matching to the given org id
    const vPubRoles = user.vpubRoles;
    if (vPubRoles !== undefined && vPubRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(vPubRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }

    // get all MODULE level roles and filter out the roles matching to the given org id
    const moduleRoles = user.moduleRoles;
    if (moduleRoles !== undefined && moduleRoles !== null) {
      const assignableRoles = AdxUserUtility.filterAssignableRoles(moduleRoles);
      if (assignableRoles !== undefined && assignableRoles !== null && assignableRoles.length > 0) {
        toRet.push(...assignableRoles);
      }
    }
    return toRet;
  }

  /**
   * Utility to filter out the roles with given scope from the input role list
   *
   * @param roles roles array
   * @param inputScope scope to be used as filter criteria
   * @returns roles array that match the given scope
   */
  public static filterRolesByScope(roles: AdxUserRole[], inputScope: string): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.role.scope === inputScope) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  /**
   * Utility method that filters out the assignable roles,  from given list of roles.
   * @param roles input roles array
   * @returns the assinable roles
   */
  public static filterAssignableRoles(roles: AdxUserRole[]): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.role.assignable) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  /**
   * Utility method that filters out the unassignable roles,  from given list of roles.
   * @param roles roles array
   * @returns array containing only unassignable roles
   */
  public static filterUnassignableRoles(roles: AdxUserRole[]): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && !userRole.role.assignable) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  /**
   * Utility method that filters out the roles for given organization id
   *
   * @param roles array
   * @param orgId organization id
   * @returns array with roles for input organization id
   */
  public static filterRolesByOrgId(roles: AdxUserRole[], orgId: number): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.organizationId !== undefined
          && userRole.organizationId !== null && userRole.organizationId === orgId) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  public static filterRolesByAccountId(roles: AdxUserRole[], accountId: number): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.accountId !== undefined
          && userRole.accountId !== null && userRole.accountId === accountId) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  /**
   * Utility method that returns the roles not belonging to given organizationid
   *
   * @param roles array
   * @param orgId organization id
   * @returns array
   */
  public static excludeRolesByOrgId(roles: AdxUserRole[], orgId: number): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.organizationId !== undefined
          && userRole.organizationId !== null && userRole.organizationId !== orgId) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }

  public static excludeRolesByAccountId(roles: AdxUserRole[], accountId: number): AdxUserRole[] {
    const toRet: AdxUserRole[] = [];
    if (roles !== undefined && roles !== null && roles.length > 0) {
      roles.forEach(userRole => {
        if (userRole.role !== undefined && userRole.role !== null && userRole.accountId !== undefined
          && userRole.accountId !== null && userRole.accountId !== accountId) {
          toRet.push(userRole);
        }
      });
    }
    return toRet;
  }
}
