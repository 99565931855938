import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AdxUser } from '../model/adx-user-model';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { blankValidator } from 'src/app/common/utils/form-utility';
import { AdxUserService } from '../service/adx-user.service';
import { CommonUtility } from 'src/app/common/utils/common-utils';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-adx-user-search',
  templateUrl: './adx-user-search.component.html',
  styleUrls: ['./adx-user-search.component.scss']
})
export class AdxUserSearchComponent extends AdxBaseTemplate implements OnInit {

  @Output() searchResult = new EventEmitter<AdxUser | null>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchUserForm: FormGroup;
  @Input() isUserAddtab: boolean = false; //need this flag to identify from which tab we are calling search API
  orgId : number | null = null;
  accountId : number | null = null;

  constructor(private readonly service: AdxUserService, private activatedRoute: ActivatedRoute) {

    super();

    this.searchUserForm = new FormGroup({
      searchUser: new FormControl<string>('', {
        updateOn: 'change', validators: [
          blankValidator,
          Validators.email,
          Validators.maxLength(100)
        ]
      })
    });
   }

   ngOnInit(): void {
    //reading org and account id which we need it foe searching the user.    
    this.orgId = CommonUtility.getId('orgId', this.activatedRoute),
    this.accountId = CommonUtility.getId('acctId', this.activatedRoute)
  }

  /**
   * Called on user clicking the search button.
   * Calls the backend to fetch the matching user
   */
  onSearch() {
    if (this.searchUserForm.dirty && this.searchUserForm.valid) {
      const userName = this.searchUserForm.get('searchUser');
      if (userName && userName.dirty && userName.valid) {
        let userNameValue: string = userName.value;
        userNameValue = userNameValue.trim();
        this.service.getUser(userNameValue, this.isUserAddtab, this.orgId, this.accountId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: AdxUser) => {
            // set user variable
            this.logger.debug(data);
            this.searchResult.emit(data);
          },
          error: () => {
            this.logger.debug(`Error while fetching data`);
            this.searchResult.emit(null);
          }
        });
      }
    }
  }

  /**
   * Callback used to enable disable button on ui
   *
   * @returns boolean
   */
  canDisable(): boolean {
    return !(this.searchUserForm.dirty && this.searchUserForm.valid);
  }

}
