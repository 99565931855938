import {AdxFormSubmissionAction} from '../../adx-form/model/adx-form-submission-action';

export class AdxFormSettings {
  submissionActions: AdxFormSubmissionAction[] | null = null;
  anonymousSubmissionEnabled: boolean | null = null;
  defaultInputsDisplay: string | null = null;

  constructor() {
  }

  static buildObject(json: any): AdxFormSettings {
    const toRet: AdxFormSettings = new AdxFormSettings();

    if (json.anonymousSubmissionEnabled !== null && json.anonymousSubmissionEnabled !== undefined) {
      toRet.anonymousSubmissionEnabled = json.anonymousSubmissionEnabled;
    }

    if (json.defaultInputsDisplay !== null && json.defaultInputsDisplay !== undefined) {
      toRet.defaultInputsDisplay = json.defaultInputsDisplay;
    }

    if (json.submissionActions !== null && json.submissionActions !== undefined) {
      json.submissionActions.forEach((subAction: any) => {
        const input: AdxFormSubmissionAction = (AdxFormSubmissionAction.buildObject(subAction));
        if (input) {
          if (!toRet.submissionActions) {
            toRet.submissionActions = [];
          }
          toRet.submissionActions.push(input);
        }
      });
    }

    return toRet;
  }
}
