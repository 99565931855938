import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organization } from '../organization/model/organization.model';
import { AuthenticatedUser } from '../common/model/adx-auth/authenticated-user.model';
import { Subscription } from 'rxjs';
import { AuthNotifierService } from '../auth/service/auth-notifier.service';
import { AdxAuthUtils } from '../auth/utils/adx-auth-utils';
import { AdxBaseTemplate } from '../common/template/adx-base-template';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  orgId: number | null = null;
  orgTitle: string | null | undefined = 'Organization Title';

  private selectedOrg?: Organization;
  private authSubscription: Subscription | undefined;

  constructor(private authNotifier: AuthNotifierService, private activatedRoute: ActivatedRoute) {
    super();
   }

  ngOnInit(): void {
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    if (this.selectedOrg && this.selectedOrg.id) {
      this.logger.debug(this.selectedOrg);
      this.orgId = this.selectedOrg?.id;
      this.orgTitle = this.selectedOrg?.title;
    }
    else {
      this.logger.error(`Unable to fetch organization OR Account details ${this.selectedOrg}`);
    }

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.currentUser = user;
      }
    );
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  /**
   * Callback to determine whether logged-in user can perform UserManagement
   * User Management can be done by system admin, instance admin and Org admin
   */
  hasPermissionToUserManagement(): boolean {
    if (this.currentUser === undefined || this.currentUser === null) {
      return false;
    }
    if (this.selectedOrg === undefined || this.selectedOrg === null) {
      return false;
    }
    if (this.selectedOrg.id === undefined || this.selectedOrg.id === null) {
      return false;
    }
    //if system or instance admin, return true
    if (AdxAuthUtils.isSystemOrInstanceAdmin(this.currentUser)) {
      return true;
    }

    //check for org admin
    const isOrgAdmin = AdxAuthUtils.hasRoleForGivenId(this.selectedOrg.id, 'ORGANIZATION_ADMIN', this.currentUser);
    if (isOrgAdmin) {
      return true;
    }
    return false;
  }

}
