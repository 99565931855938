import { AdxAuthUserPermission } from 'src/app/common/model/adx-auth/adx-auth-user-permission.model';
import { Organization } from '../model/organization.model';

/**
 * Utility class for Organization
 */
export class OrganizationUtility {

  /*
   * variable used to init the Froala editor in Organization
   */
  public static readonly options: object = JSON.parse(JSON.stringify({
    key: '6LF5g1D3E3B2C6D4E4A1xROKLJKYHROLDXDRE1b1YYGRi1Bd1C4F4B3C2G3A14A16A12C4A3==',
    attribution: false,
    linkAutoPrefix: '',
    charCounterCount: false,
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript',
          'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor',
          'inlineClass', 'inlineStyle', 'clearFormatting']
      },
      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight',
          'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle',
          'lineHeight', 'outdent', 'indent', 'quote']
      },
      moreRich: {
        buttons: ['insertLink', 'insertTable', 'image', 'insertVideo',
          'specialCharacters', 'insertHR']
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'spellChecker', 'selectAll', 'html']
      },
      align: 'right',
      buttonsVisible: 2
    },
    quickInsertButtons: ['table', 'ol', 'ul']
  }));

  /**
   * Utility method that filetrs out the organizations whose ids match the ids provided in input accessibleOrgs list
   * @param orgList Organization array
   * @param accessibleOrgs number array. Contains list of organization ids
   * @returns Organization array that contains organizations filtered based on accessibleOrgs array
   */
  public static filterAccessibleOrgs(orgList: Organization[], accessibleOrgs: number[]) {
    const toRet: Organization[] = []; // if nothing matches, then empty list is returned

    if (orgList !== undefined && orgList !== null && orgList.length > 0) {
      //verify whether accessibleOrgs suppiled or not
      if (accessibleOrgs !== undefined && accessibleOrgs !== null && accessibleOrgs.length > 0) {
        for (const orgId of accessibleOrgs) {
          const foundOrg = orgList.find(org => org.id === orgId);
          //if org is present in accessible list, add it to list to be returned
          if (foundOrg !== undefined && foundOrg !== null) {
            toRet.push(foundOrg);
          }
        }
      }
    }

    return toRet;
  }

}
