import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {AdxModule} from '../../adx-module/model/adx-module.model';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-module-group-simulator',
  templateUrl: './module-group-simulator.component.html',
  styleUrls: ['./module-group-simulator.component.scss']
})
export class ModuleGroupSimulatorComponent implements OnInit {

  @Input() moduleList: AdxModule[] = [];
  displayedColumns: string[] = ['icon', 'name'];
  dataSource: MatTableDataSource<AdxModule> = new MatTableDataSource<AdxModule>();

  constructor(private logger: NGXLogger) { }

  ngOnInit(): void {
    this.logger.debug(`In ArsSimulatorComponent ${this.moduleList}`);
    if (this.moduleList) {
      this.dataSource.data = this.moduleList;
    }
  }

}
