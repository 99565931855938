import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { EditOrganizationComponent } from './edit-organization/edit-organization.component';
import { OrganizationResolver } from './resolver/organization-resolver';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationComponent } from './organization.component';
import { AdxTimezoneResolver } from '../common/resolvers/adx-timezone-resolver';
import {AdxLocaleResolver} from '../common/resolvers/locale/adx-locale.resolver';
import { AdxOrgAuthGuard } from './guard/adx-org-auth.guard';

const orgRoutes: Routes = [
  {
    path: '',
    component: OrganizationComponent,
    children: [
      {
        path: '', component: OrganizationListComponent,
        canActivate: [AdxOrgAuthGuard]
     },
      {
        path: 'add',
        component: AddOrganizationComponent,
        resolve: { timezones: AdxTimezoneResolver, locales: AdxLocaleResolver }
      },
      {
        path: 'edit/:orgId',
        component: EditOrganizationComponent,
        resolve: {
          org: OrganizationResolver, timezones: AdxTimezoneResolver, locales: AdxLocaleResolver
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(orgRoutes)],
  exports: [RouterModule]
})
export class OrganizationRoutingModule { }
