import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError} from 'rxjs/operators';
import {AdxVPub} from '../model/adx-vpub.model';
import {AdxVpubService} from '../service/adx-vpub.service';

@Injectable({
  providedIn: 'root'
})
export class AdxVpubResolver implements Resolve<AdxVPub | null> {

  constructor(private logger: NGXLogger, private vpubService: AdxVpubService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdxVPub | null> {
    this.logger.debug('In vPub route resolve:' + route.paramMap.keys);
    const orgIdString: string | null | undefined = route.paramMap.get('orgId');
    this.logger.debug(orgIdString);
    const accountIdString: string | null = route.paramMap.get('acctId');
    this.logger.debug(accountIdString);
    const applicationIdString: string | null = route.paramMap.get('applnId');
    this.logger.debug(applicationIdString);
    const vPubIdString: string | null = route.paramMap.get('vpubId');
    this.logger.debug(vPubIdString);

    if (orgIdString) {
      if (isNaN(+orgIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed org id is not a number: ${orgIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed org id is not a number: ${orgIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (accountIdString) {
      if (isNaN(+accountIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${accountIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${accountIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (applicationIdString) {
      if (isNaN(+applicationIdString)) {
        const message =
          'Something went wrong while fetching the Application. Please try again';
        this.logger.error(`Passed account id is not a number: ${applicationIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the Application. Please try again';
      this.logger.error(`Passed account id is not a number: ${applicationIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    if (vPubIdString) {
      if (isNaN(+vPubIdString)) {
        const message =
          'Something went wrong while fetching the vPub. Please try again';
        this.logger.error(`Passed vPub id is not a number: ${vPubIdString}`);
        throwError(() => new Error(message));
        return of(null);
      }
    }
    else {
      const message =
        'Something went wrong while fetching the vPub. Please try again';
      this.logger.error(`Passed account id is not a number: ${vPubIdString}`);
      throwError(() => new Error(message));
      return of(null);
    }

    const selectedOrgId: number = +orgIdString;
    const selectedAccountId: number = +accountIdString;
    const selectedApplicationId: number = +applicationIdString;
    const vpubId: number = +vPubIdString;
    this.logger.debug(`ids received: ${selectedOrgId}: ${selectedAccountId}`);
    return this.vpubService.getVpub(selectedOrgId, selectedAccountId, selectedApplicationId, vpubId).pipe(
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return of(null);
      })
    );
  }
}
