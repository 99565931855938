import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AdxConfirmDialogComponent} from '../../../shared';
import {AdxConfirmDialogData} from '../../../shared/utils/adx-confirm-dialog-data';
import {Observable} from 'rxjs';
import {AdxAlertDialogData} from '../../../shared/utils/adx-alert-dialog-data';
import {AdxAlertDialogComponent} from '../../../shared/popups/adx-alert-dialog/adx-alert-dialog.component';
import {AdxSelectDialogData} from '../../../shared/utils/adx-select-dialog-data';
import {AdxSelectDialogComponent} from '../../../shared/popups/adx-select-dialog/adx-select-dialog.component';
import {AdxImportSubmissionStatusComponent} from '../../../adx-ars/adx-import-submission-status/adx-import-submission-status.component';
import {AdxImportResponse} from '../../model/data-transfer/adx-import-response';


@Injectable({
  providedIn: 'root'
})
export class AdxDialogService {

  constructor(private dialog: MatDialog) { }

  confirmDialog(data: AdxConfirmDialogData): Observable<boolean> {
    return this.dialog.open(AdxConfirmDialogComponent, {data, width: '60vw', disableClose: true}).afterClosed();
  }

  alertDialog(data: AdxAlertDialogData): Observable<boolean> {
    return this.dialog.open(AdxAlertDialogComponent, {data, width: '50vw', disableClose: true}).afterClosed();
  }

  selectDialog(data: AdxSelectDialogData): Observable<string> {
    return this.dialog.open(AdxSelectDialogComponent, {data, width: '60vw', disableClose: true}).afterClosed();
  }

  /**
   * Displays the Import Response Dialog
   *
   * @param data: AdxImportResponse object returned by backend
   */
  importResponseDialog(data: AdxImportResponse): Observable<string> {
    return this.dialog.open(AdxImportSubmissionStatusComponent, {panelClass: 'custom-modalbox',data, width: '80vw', minHeight: '60vh', disableClose: true}).afterClosed();
  }
}
