import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

import { AdxTimezone } from '../../common/model/adx-timezone.model';
import { AdxTimezoneService } from 'src/app/common/services/timezone/adx-timezone.service';
import { Organization } from '../../organization/model/organization.model';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {Account} from '../model/account.model';
import {AccountService} from '../service/account.service';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {AdxLocale} from '../../common/model/locale/adx-locale.model';
import {AdxLocaleSelection} from '../../common/model/locale/adx-locale-selection';
import {Address} from '../../common/model/address.model';
import {AdxStyle} from '../../common/model/adx-style.model';
import {AdxCountryUtility} from '../../common/utils/adx-country-utility';
import {AdxCountry} from '../../common/model/country/adx-country.model';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseEditorTemplate} from '../../common/template/adx-base-editor-template';
import {AdxInsertContentDialogData} from '../../shared/utils/adx-insert-content-dialog-data';
import {CommonUtility} from '../../common/utils/common-utils';
import {AdxImageLibraryType} from '../../shared/utils/adx-image-library-type';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent extends AdxBaseEditorTemplate implements OnInit, OnDestroy {

  addAccountForm: UntypedFormGroup;
  timezones: AdxTimezone[] = [];
  locales: AdxLocale[] = [];
  countries?: Array<AdxCountry>;
  tagList: string[] = [];
  visible = true;
  tagSelectable = true;
  tagRemovable = true;
  tagAddOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedOrg?: Organization;
  defaultLocale: AdxLocale | null = null;
  selectedLocales: AdxLocale[] | null = null;

  private authSubscription: Subscription | undefined;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly accountService: AccountService,
    private readonly messageNotifier: CustomNotifierService,
    private authNotifier: AuthNotifierService,
    private router: Router, private activatedRoute: ActivatedRoute
  ) {

    super();
    this.selectedOrg = this.activatedRoute.snapshot.data.org;
    this.logger.debug(`selectedOrgId: ${this.selectedOrg?.id}`);

    this.addAccountForm = new UntypedFormGroup({
      accountTitle: new UntypedFormControl(null, {updateOn: 'blur', validators: [
          blankValidator,
        Validators.minLength(3),
        Validators.maxLength(150)
      ]}),
      accountDesc: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(450)
      ]}),
      summary: new UntypedFormControl(null, {
        updateOn: 'blur', validators: [
          Validators.maxLength(150)
        ]
      }),
      accountName: new UntypedFormControl(),
      accountNumber: new UntypedFormControl(),
      accountNotes: new UntypedFormControl(),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      websiteUrl: new UntypedFormControl(),
      tags: new UntypedFormControl(),
      address: new UntypedFormGroup({
        street1: new UntypedFormControl(this.selectedOrg?.address?.street1),
        street2: new UntypedFormControl(this.selectedOrg?.address?.street2),
        city: new UntypedFormControl(this.selectedOrg?.address?.city),
        state: new UntypedFormControl(this.selectedOrg?.address?.state),
        zipcode: new UntypedFormControl(this.selectedOrg?.address?.zipCode),
        country: new UntypedFormControl(this.selectedOrg?.address?.country),
        timezone: new UntypedFormControl(this.selectedOrg?.timeZone?.id)
      }),
      style: new UntypedFormGroup({
        numOfTabs: new UntypedFormControl(this.selectedOrg?.style?.numberOfTabs),
        backgroundColor: new UntypedFormControl(this.selectedOrg?.style?.navigationBackgroundColor),
        backgroundTextColor: new UntypedFormControl(this.selectedOrg?.style?.navigationTextColor),
        topBarBackgroundColor: new UntypedFormControl(this.selectedOrg?.style?.topBarBackgroundColor),
        topBarTextColor: new UntypedFormControl(this.selectedOrg?.style?.topBarTextColor),
        bannerBackgroundColor: new UntypedFormControl(this.selectedOrg?.style?.bannerBackgroundColor),
        bannerTextColor: new UntypedFormControl(this.selectedOrg?.style?.bannerTextColor),
        buttonBackgroundColor: new UntypedFormControl(this.selectedOrg?.style?.buttonBackgroundColor),
        buttonTextColor: new UntypedFormControl(this.selectedOrg?.style?.buttonTextColor),
        bodyBackgroundColor: new UntypedFormControl(this.selectedOrg?.style?.bodyBackgroundColor),
        bodyTextColor: new UntypedFormControl(this.selectedOrg?.style?.bodyTextColor),
      }),
      accountLocale: new UntypedFormControl()
    });
  }

  get backgroundColor(): string {
    return this.addAccountForm?.get('style')?.get('backgroundColor')?.value;
  }

  get backgroundTextColor(): string {
    return this.addAccountForm?.get('style')?.get('backgroundTextColor')?.value;
  }

  get topBarBackgroundColor(): string {
    return this.addAccountForm?.get('style')?.get('topBarBackgroundColor')
      ?.value;
  }
  get topBarTextColor(): string {
    return this.addAccountForm?.get('style')?.get('topBarTextColor')?.value;
  }
  get bannerBackgroundColor(): string {
    return this.addAccountForm?.get('style')?.get('bannerBackgroundColor')
      ?.value;
  }
  get bannerTextColor(): string {
    return this.addAccountForm?.get('style')?.get('bannerTextColor')?.value;
  }
  get buttonBackgroundColor(): string {
    return this.addAccountForm?.get('style')?.get('buttonBackgroundColor')
      ?.value;
  }
  get buttonTextColor(): string {
    return this.addAccountForm?.get('style')?.get('buttonTextColor')?.value;
  }

  get bodyBackgroundColor(): string {
    return this.addAccountForm?.get('style')?.get('bodyBackgroundColor')?.value;
  }

  get bodyTextColor(): string {
    return this.addAccountForm?.get('style')?.get('bodyTextColor')?.value;
  }

  ngOnInit(): void {

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('In AdxHamburgerMenuComponent authSubscription');
        this.currentUser = user;
      }
    );

    if (!this.canAddAccount()){
      return; // no need to proceed as current user does not have permission to add account
    }

    // initialize the froala editor plugin
    this.froalaPluginSetup(this);

    this.timezones = this.activatedRoute.snapshot.data.timezones;
    this.locales = this.activatedRoute.snapshot.data.locales;

    this.countries = AdxCountryUtility.getSupportedCountries();

    const localeSelection: AdxLocaleSelection = new AdxLocaleSelection();
    if (this.selectedOrg?.defaultLocale) {
      this.logger.debug(`defaultLocale: ${this.selectedOrg?.defaultLocale}`);
      localeSelection.defaultLocale = this.selectedOrg?.defaultLocale;
    } else {
      this.logger.debug('NO default locale');
    }
    if (this.selectedOrg?.locales) {
      localeSelection.locales = this.selectedOrg?.locales;
    } else {
      this.logger.debug('NO selected locales');
    }
    this.addAccountForm.patchValue({
      accountLocale: localeSelection
    });
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  /**
   * Method returns true if logged-in user has permission to add new account
   *
   * @returns boolean. true if current user can add new org
   */
  canAddAccount(): boolean {
    if (this.selectedOrg !== undefined && this.selectedOrg !== null
       && this.selectedOrg.id !== undefined && this.selectedOrg.id !== null
        && this.currentUser !== undefined && this.currentUser !== null) {
      return this.accountService.canUserAddAccount(this.selectedOrg.id, this.currentUser);
    }
    return false;
  }

  getDataForEditor(): AdxInsertContentDialogData {
    const toRet: AdxInsertContentDialogData = {
      orgId: CommonUtility.getId('orgId', this.activatedRoute),
      accountId: null,
      vpubId: null,
      moduleId: null,
      typeId: null,
      isIcon: false,
      imgLibraryType: AdxImageLibraryType.ACCOUNT_IMG_LIB,
      editorRef: undefined,
      applicationId : null,
      atomId : null
    };
    return toRet;
  }


  save(): void {
    if (!this.canAddAccount()){
      return; // no need to proceed as current user does not have permission to add account
    }

    const title = this.addAccountForm.get('accountTitle')?.value.trim();
    if (title) {
      const accountToAdd: Account = new Account(null, title);
      accountToAdd.sortOrder = 1;
      accountToAdd.createdByUser = 1;
      this.logger.debug(`accountDesc dirty: ${this.addAccountForm.get('accountDesc')?.dirty}`);
      this.logger.debug(`accountDesc value: ${this.addAccountForm.get('accountDesc')?.dirty}`);
      if (this.addAccountForm.get('accountDesc')?.dirty) {
        accountToAdd.description = this.addAccountForm.get('accountDesc')?.value;
      }
      if (this.addAccountForm.get('summary')?.dirty) {
        accountToAdd.summary = this.addAccountForm.get('summary')?.value.trim();
      }
      if (this.addAccountForm.get('accountName')?.dirty) {
        accountToAdd.accountName = this.addAccountForm.get('accountName')?.value;
      }
      if (this.addAccountForm.get('accountNumber')?.dirty) {
        accountToAdd.accountNumber = this.addAccountForm.get('accountNumber')?.value;
      }
      if (this.addAccountForm.get('accountNotes')?.dirty) {
        accountToAdd.notes = this.addAccountForm.get('accountNotes')?.value;
      }
      if (this.addAccountForm.get('startDate')?.dirty) {
        accountToAdd.startDate = this.addAccountForm.get('startDate')?.value;
      }
      if (this.addAccountForm.get('endDate')?.dirty) {
        accountToAdd.endDate = this.addAccountForm.get('endDate')?.value;
      }
      if (this.addAccountForm.get('websiteUrl')?.dirty) {
        accountToAdd.websiteUrl = this.addAccountForm.get('websiteUrl')?.value;
      }
      if (this.tagList) {
        accountToAdd.tags = this.tagList.join(',');
      }

      if (this.addAccountForm?.get('accountLocale')?.dirty) { // not same as org locale
        this.logger.debug('Locale Changed');
        if (this.defaultLocale) {
          accountToAdd.defaultLocale = this.defaultLocale;
        }
        if (this.selectedLocales) {
          accountToAdd.locales = this.selectedLocales;
        }
      }
      else {
        accountToAdd.defaultLocale = this.selectedOrg?.defaultLocale;
        accountToAdd.locales = this.selectedOrg?.locales;
      }

      // add address
      if (this.addAccountForm.get('address')?.dirty) { // not same as org address
        const strStreet1: string = this.addAccountForm.get('address.street1')?.value.trim();
        const strStreet2: string = this.addAccountForm.get('address.street2')?.value.trim();
        const strCity: string = this.addAccountForm.get('address.city')?.value.trim();
        const strState: string = this.addAccountForm.get('address.state')?.value.trim();
        const strZip: string = this.addAccountForm.get('address.zipcode')?.value;
        const strCountry: string = this.addAccountForm.get('address.country')?.value;
        const addr = new Address(
          NaN,
          strStreet1,
          strStreet2,
          strCity,
          strState,
          '',
          strCountry,
          strZip,
          'ACCOUNT'
        );

        // TO-DO Set the logged in user here
        addr.createdByUser = 1;
        accountToAdd.address = addr;
        this.logger.debug(`from ui: ${this.addAccountForm.get('address.timezone')?.value}`);
        accountToAdd.timeZoneId = this.addAccountForm.get('address.timezone')?.value;
        this.logger.debug(`Address Changed: ${accountToAdd.timeZoneId}`);
      }
      else {
        accountToAdd.address = this.selectedOrg?.address;
        if (this.selectedOrg && this.selectedOrg?.timeZone) {
          accountToAdd.timeZoneId = this.selectedOrg?.timeZone.id;
        }
      }

      // add style
      if (this.addAccountForm?.get('style')?.dirty) { // not same as org style
        const numOfTabs = this.addAccountForm?.get('style')?.get('numOfTabs')?.value;
        const strBackgroundColor = this.backgroundColor;
        const strBackgroundTextColor = this.backgroundTextColor;
        const strTopBarColor = this.topBarBackgroundColor;
        const strTopBarTextColor = this.topBarTextColor;
        const strBannerColor = this.bannerBackgroundColor;
        const strBannerTextColor = this.bannerTextColor;
        const strButtonColor = this.buttonBackgroundColor;
        const strButtonTextColor = this.buttonTextColor;
        const strBodyColor = this.bodyBackgroundColor;
        const strBodyTextColor = this.bodyTextColor;

        const style: AdxStyle = new AdxStyle(
            NaN,
            numOfTabs,
            strBackgroundColor,
            strBackgroundTextColor,
            ' ',
            strTopBarColor,
            strTopBarTextColor,
            strBodyColor,
            strBodyTextColor,
            strBannerColor,
            strBannerTextColor,
            strButtonColor,
            strButtonTextColor,
            9,
            'ACCOUNT'
          );
        // TO-DO Set the logged in user here
        style.createdByUser = 1;
        accountToAdd.style = style;
        }
      else {
        // is not modified. so add organization style
        accountToAdd.style = this.selectedOrg?.style;
      }
      this.addAccount(accountToAdd);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tagList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tagList.indexOf(tag);

    if (index >= 0) {
      this.tagList.splice(index, 1);
    }
  }


  onDefaultLocaleChange(event: AdxLocale): void {
    this.logger.debug(`Default Locale Updated ${event.displayName}`);
    this.defaultLocale = event;
  }

  onSelectedLocaleUpdate(event: AdxLocale[]): void {
    this.logger.debug(`Selected Locales Updated to ${event.length}`);
    this.selectedLocales = event;
  }

  /*
   * This method calls service api to add organization
   */
  private addAccount(account: Account): void {
    if (!this.canAddAccount()){
      return; // no need to proceed as current user does not have permission to add account
    }
    if (this.selectedOrg && this.selectedOrg.id && account) {
      this.accountService
        .addAccount(this.selectedOrg.id, account)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            // navigate user to listing screen
            this.router.navigateByUrl(`/orgs/${this.selectedOrg?.id}/accts`).then(r => {});
          },
          error: () => {
            // navigate user to listing screen
            this.router.navigateByUrl(`/orgs/${this.selectedOrg?.id}/accts`).then(r => {});
          },
          complete: () => {
            // display success message
            this.messageNotifier.notify(CustomMessageType.SUCCESS, `${account.title} created Successfully.`);
          }
        });
    }

  }
}
