import { AdxBaseTemplate } from './adx-base-template';
import { AdxInsertContentDialogComponent } from '../../shared';
import { AdxImageLibraryType } from '../../shared/utils/adx-image-library-type';
import { AdxInjector } from '../utils/adx-injector';
import { NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdxInsertContentDialogData } from '../../shared/utils/adx-insert-content-dialog-data';
import { AdxInsertTelephoneDialogComponent } from '../../shared/popups/adx-insert-telephone-dialog/adx-insert-telephone-dialog.component';
// Import all Froala Editor plugins.
import 'froala-editor/js/plugins.pkgd.min.js';
import { AdxErrorStateMatcher } from '../error/AdxErrorStateMatcher';

// eslint-disable-next-line @typescript-eslint/naming-convention
const FroalaEditor = require('froala-editor');

export abstract class AdxBaseEditorTemplate extends AdxBaseTemplate {
  protected readonly zone: NgZone;
  protected readonly editorInsertContentDialog: MatDialog;

  protected editorRef: any;
  protected initControls: any = null;

  protected readonly adxErrorStateMatcher = new AdxErrorStateMatcher();

  protected constructor() {
    super();
    const injector = AdxInjector.getInjector();

    this.zone = injector.get(NgZone); // DI
    this.editorInsertContentDialog = injector.get(MatDialog); // DI
  }

  initializeFroalaEditor($event: object): void {
    // @ts-ignore
    this.initControls = $event;
    // @ts-ignore
    this.initControls.initialize();
    // @ts-ignore
    this.editorRef = this.initControls.getEditor();
  }

  froalaPluginSetup(parent: any): void {
    FroalaEditor.DefineIcon('insertTelephoneIcon', {
      NAME: 'insertContent',
      SVG_KEY: 'linkStyles',
    });
    FroalaEditor.RegisterCommand('insertTelephone', {
      title: 'Insert Telephone',
      focus: false,
      undo: false,
      icon: 'insertTelephoneIcon',
      refreshAfterCallback: false,

      callback: () => {
        if (parent) {
          parent.zone.run(() =>
            this.editorInsertContentDialog.open(
              AdxInsertTelephoneDialogComponent,
              {
                width: '80vw',
                height: '60vh',
                data: {
                  editorRef: parent.editorRef,
                },
              }
            )
          );
        }
      },
    });
    FroalaEditor.DefineIcon('insertContentIcon', {
      NAME: 'insertContent',
      SVG_KEY: 'insertImage',
    });
    FroalaEditor.RegisterCommand('insertContent', {
      title: 'Insert Content',
      focus: false,
      undo: false,
      icon: 'insertContentIcon',
      refreshAfterCallback: false,

      callback: () => {
        if (parent) {
          parent.zone.run(() =>
            this.editorInsertContentDialog.open(
              AdxInsertContentDialogComponent,
              {
                width: '80vw',
                height: '60vh',
                data: {
                  ...parent.getDataForEditor(),
                  isIcon: false,
                  imgLibraryType: parent.imgLibType,
                  editorRef: parent.editorRef,
                },
              }
            )
          );
        }
      },
    });
  }

  protected froalaEditorOptions(maxLen: any): object {
    return {
      key: '6LF5g1D3E3B2C6D4E4A1xROKLJKYHROLDXDRE1b1YYGRi1Bd1C4F4B3C2G3A14A16A12C4A3==',
      pluginsEnabled: [
        'align',
        'charCounter',
        'image',
        'imageManager',
        'link',
        'draggable',
        'codeView',
        'codeMirror',
        'codeBeautifier',
        'inlineClass',
        'inlineStyle',
        'fullscreen',
        'fontFamily',
        'fontSize',
        'colors',
        'emoticons',
        'lists',
        'specialCharacters',
        'url',
        'table',
        'wordPaste',
      ], //Activating Each plugin as and when required.
      codeMirror: true,
      dragInline: true,
      attribution: false,
      linkAutoPrefix: '',
      // heightMax: null,
      height: '60svh',
      width: '100%',
      charCounterCount: false,
      charCounterMax: maxLen,
      htmlExecuteScripts: false,
      htmlRemoveTags: ['script'],
      listAdvancedTypes: true,
      pastePlain: false, //Setting This to FALSE will make the wordPaste plugin to function correctly
      imageDefaultDisplay: 'inline',
      imageDefaultMargin: 0,
      imageDefaultWidth: 0,
      imageMove: true,
      imagePaste: false, // Disabled pasting of images inside the editor. This was done to avoid uploading img tags with blob
      imageSplitHTML: true,
      // imagePasteProcess: true,
      imageEditButtons: [
        'imageSize',
        'imageDisplay',
        'imageAlign',
        'imageStyle',
        '|',
        'imageLink',
        'linkOpen',
        'linkEdit',
      ], //Reduced number of options in image menu based on CES team inputs.
      videoResponsive: true,
      tableMultipleStyles: false,
      tableStyles: {
        'froala-table-resp': 'Responsive',
        'froala-noborder': 'No Border',
        'froala-solidborder': 'Solid Border',
      },
      tableDefaultWidth: '100%',
      tableCellStyles: {
        'froala-table-cell-dark-bg': 'Light Text',
        '.froala-table-cell-light-bg': 'Dark Text',
      },
      codeBeautifierOptions: {
        end_with_newline: true,
        indent_inner_html: true,
        extra_liners:
          "['p', 'a', 'img', 'span' 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
        brace_style: 'expand',
        indent_char: ' ',
        indent_size: 4,
        wrap_line_length: 0,
      }, //Enables code indentation for better visibility
      inlineStyles: {
        Default: 'text-transform: none',
        lowercase: 'text-transform: lowercase;',
        UPPERCASE: 'text-transform: uppercase',
        'Title-Case': 'text-transform: capitalize',
      }, //Added the requested styles for text editing, this mimics the feature of TinyMCE.
      wordDeniedAttrs: ['margin-left', 'margin-right', 'width'],
      events: {
        initialized: (e: any) => {
          this.editorRef = e.getEditor(); // this is the editor instance.

          // the mfp: link is being sanitized.
          // just to overwrite it, followed suggestions in https://github.com/froala/wysiwyg-editor/issues/1235
          const originalSanitizeURL = this.editorRef.helpers.sanitizeURL;
          this.editorRef.helpers.sanitizeURL = (url: string) => {
            if (url.match(/^mfp:\/\//gi)) {
              // special mfp markup
              return url;
            }
            return originalSanitizeURL(url); // use Froala Editor's original method
          };
        },
        blur: () => {
          this.editorRef.selection.save();
        },
      },
      tableResizerOffset: 10,
      tableResizingLimit: 5,
      fontFamily: {
        'Arial, sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Roboto,sans-serif': 'Roboto',
        'Oswald,sans-serif': 'Oswald',
        'Montserrat,sans-serif': 'Montserrat',
      },
      fontFamilyDefaultSelection: 'Arial',
      fontSizeDefaultSelection: '14',
      fontFamilySelection: true,
      fontSizeSelection: true,
      toolbarButtons: {
        moreText: {
          buttons: [
            'fontFamily',
            'fontSize',
            'textColor',
            'backgroundColor',
            'bold',
            'italic',
            'underline',
            'inlineStyle',
            'inlineClass',
            'superscript',
            'subscript',
            'strikeThrough',
            'clearFormatting',
          ],
          buttonsVisible: 8,
        },
        moreParagraph: {
          buttons: [
            'alignLeft',
            'alignCenter',
            'alignRight',
            'alignJustify',
            'formatOL',
            'formatUL',
            'paragraphFormat',
            'paragraphStyle',
            'lineHeight',
            'outdent',
            'indent',
            'quote',
          ],
          buttonsVisible: 6,
        },
        moreRich: {
          buttons: [
            'insertTable',
            'insertContent',
            'insertTelephone',
            'insertLink',
            'insertVideo',
            'specialCharacters',
            'insertHR',
            'emoticons',
          ],
          buttonsVisible: 3,
        },
        moreMisc: {
          buttons: ['undo', 'redo', 'fullscreen', 'html'],
        },
        buttonsVisible: 4,
      },
      quickInsertButtons: ['table', 'ol', 'ul'],
    };
  }

  abstract getDataForEditor(): AdxInsertContentDialogData;
}
