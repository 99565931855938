import {AdxResource} from '../../core/service/resource/adx-resource.model';
import {AdxVisibilityType} from '../../common/model/visibility/adx-visibility-type';
import {AdxImage} from '../../common/model/adx-image.model';
import {AdxArsImage} from './adx-ars-image-model';

/**
 * Model class corresponding to AtomResponse object from backend producer app
 */
export class AdxArs extends AdxResource {
  title: string | null = null;
  description: string | null = null;
  tags: string | null = null;
  summary: string | null = null;
  pageNumber: number | null = null;
  isCategory: boolean | null = null;
  parentIds: number[] | null = null;
  visibility: AdxVisibilityType | null = null;
  sortOrder: number | null = null;
  images: AdxArsImage[] | null = null;
  iconImage: AdxImage | null = null;
  children: AdxArs[] | null = null;
  directAccessUrl: string | null = null;
  categories: AdxArs[] | null = null;

  createdByUser: number | null = null;
  updatedByUser: number | null = null;

  selectedIds: number[] = []; // used in bulk actions
  nodeId: number | null = null; //used in navigating next/prev
  nextNodeId: number | null = null; //used in navigating next/prev
  prevNodeId: number | null = null; //used in navigating next/prev
  depthLevel: number | null = null; //used in listing category for association 

  constructor(id: number | null) {
    super(id);
  }

  static buildObject(json: any): AdxArs {
    const tempArs = new AdxArs(json.id);
    if (json.title) {
      tempArs.title = json.title;
    }
    if (json.tags !== undefined || json.pageNumber !== null) {
      tempArs.tags = json.tags;
    }
    if (json.description) {
      tempArs.description = json.description;
    }
    if (json.summary) {
      tempArs.summary = json.summary;
    }
    if (json.pageNumber !== undefined || json.pageNumber !== null) {
      tempArs.pageNumber = json.pageNumber;
    }
    if (json.isCategory !== undefined || json.isCategory !== null) {
      tempArs.isCategory = json.isCategory;
    }
    if (json.parentIds) {
      tempArs.parentIds = json.parentIds;
    }
    if (json.visibility) {
      tempArs.visibility = json.visibility;
    }
    if (json.sortOrder !== undefined || json.sortOrder !== null) {
      tempArs.sortOrder = json.sortOrder;
    }
    if (json.images) {
      let tempArsImgObj: AdxArsImage;
      json.images.forEach((arsImage: any) => {
        tempArsImgObj = (AdxArsImage.buildObject(arsImage));
        if (tempArsImgObj) {
          if (!tempArs.images) {
            tempArs.images = [];
          }
          tempArs.images.push(tempArsImgObj);
        }
      });
    }
    if (json.iconImage) {
      tempArs.iconImage = AdxImage.buildObject(json.iconImage);
    }
    if (json.children) {
      let tempArsObj: AdxArs;
      json.children.forEach((ars: any) => {
        tempArsObj = (AdxArs.buildObject(ars));
        if (tempArsObj) {
          if (!tempArs.children) {
            tempArs.children = [];
          }
          tempArs.children.push(tempArsObj);
        }
      });
    }
    if (json.categories) {
      let tempArsObj: AdxArs;
      json.categories.forEach((ars: any) => {
        tempArsObj = (AdxArs.buildObject(ars));
        if (tempArsObj) {
          if (!tempArs.categories) {
            tempArs.categories = [];
          }
          tempArs.categories.push(tempArsObj);
        }
      });
    }
    if (json.directAccessUrl) {
      tempArs.directAccessUrl = json.directAccessUrl;
    }
    if (json.createdByUser) {
      tempArs.createdByUser = json.createdByUser;
    }
    if (json.updatedByUser) {
      tempArs.updatedByUser = json.updatedByUser;
    }
    return tempArs;
  }
}
