<mat-card class="org-settings-card">
  <mat-card-header class="org-settings-header">
    <mat-card-title i18n="@@orgSettingsForm.title">Organization Setting</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="org-settings-form" [formGroup]="orgSettingsForm" autocomplete="off">
      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="30px">
        <!-- Organization Name readonly -->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <div appearance="outline" fxFlex="100" class="pr-4">
            <mat-label i18n="@@orgSettingsForm.name">Organization Name</mat-label>
            <input matInput id="input-org-settings-name" formControlName="orgName" class="orgName" [readonly]="true">
          </div>
        </div>

        <div class="button-container">
          <button mat-raised-button i18n="@@orgSettingsForm.save" type="button" id="input-org-settings-save-Btn"
                  [disabled]="!(orgSettingsForm.valid && orgSettingsForm.dirty)" (click)="update()">Save</button>
        </div>

      </div>
    </form>
  </mat-card-content>
</mat-card>
