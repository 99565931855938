import {AdxBaseSerializer} from '../../../core/service/resource/adx-base-serializer';
import {AdxResource} from '../../../core/service/resource/adx-resource.model';
import {AdxDataTransfer} from '../../model/data-transfer/adx-data-transfer-model';

export class AdxDataTransferSerializer implements AdxBaseSerializer {

  fromJson(json: any): AdxResource {
    const locale: AdxDataTransfer = AdxDataTransfer.buildObject(json);
    return locale;
  }

  toJson(resource: AdxDataTransfer): string {
    const json = JSON.stringify(resource);
    return json;
  }
}
