<div class="dialog-header">
  <h2 mat-dialog-title>Upload Images</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <ng-container *ngIf="!imageFiles || imageFiles.length === 0; else ListSelectedFiles">
    <div class="dropzone" appAdxDropzone (dropped)="imagesDropped($event)" (hovered)="toggleHover($event)"
         [class.dropzone_hovered]="isHovering">
      <span class="dropzone__label">Drop files here or</span>
      <div class="dropzone__file">
        <label class="file">
          <input class="file__input" [multiple]="data.multiple" type="file" [accept]="SUPPORTED_IMAGE_MIME_TYPE"
                 (change)="imagesSelected($event)">
          <span class="file__label">choose</span>
        </label>
      </div>
    </div>
    <div class="error" *ngIf="isError">
      Choose only one file
    </div>
  </ng-container>
  <ng-template #ListSelectedFiles>

    <div fxLayout="column">
      <div class="action-bar">
            <button i18n="@@delSel" mat-raised-button color="warn" (click)="deleteSelected()">
              Delete
            </button>
      </div>

      <div class="example-container mat-elevation-z8">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox class="selectAll-checkbox" (change)="$event ? masterToggle() : null"
                            [checked]="checklistSelection.hasValue() && isAllSelected()"
                            [indeterminate]="checklistSelection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox class="select-checkbox" (click)="$event.stopPropagation()"
                            (change)="$event ? checklistSelection.toggle(element) : null"
                            [checked]="checklistSelection.isSelected(element)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <img [src]="element.url" alt="image"/>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell i18n="@@name" *matHeaderCellDef>Image Name</mat-header-cell>
            <mat-cell i18n="@@name-val" *matCellDef="let element">
              {{ element.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef>Size (bytes)</mat-header-cell>
            <mat-cell i18n="@@name-val" *matCellDef="let element">
              {{ element.size }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button i18n="@@deleteImage" mat-stroked-button (click)="editImage(element)"><mat-icon>edit</mat-icon></button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <div class="button-container">
        <button i18n="@@editArsForm.cancel" mat-raised-button class="button-cancel" [mat-dialog-close]="false">Cancel</button>
        <button i18n="@@editArsForm.save" mat-raised-button (click)="saveImages()">Save</button>
      </div>
    </div>
  </ng-template>
</div>


