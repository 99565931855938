import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { AdxNotification } from 'src/app/common/model/notification/adx-notification.model';
import { AdxNotificationService } from 'src/app/common/services/adx-notification/adx-notification.service';
import { AdxBaseTemplate } from 'src/app/common/template/adx-base-template';
import { CommonUtility } from 'src/app/common/utils/common-utils';
import { AdxSendNotificationDialogComponent } from '../../popups/adx-send-notification-dialog/adx-send-notification-dialog.component';

/**
 * This component displays Notification list retrieved from backend.
 */
@Component({
  selector: 'app-adx-notification-list',
  templateUrl: './adx-notification-list.component.html',
  styleUrls: ['./adx-notification-list.component.scss']
})
export class AdxNotificationListComponent extends AdxBaseTemplate implements OnInit {

  @Input() orgId: number | null = null;
  @Input() accountId: number | null = null;
  @Input() applicationId: number | null = null;
  @Input() vpubId: number | null = null;

  displayedColumns: string[] = [
    'title',
    'username',
    'type',
    'timestamp'
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();
  dataSource: MatTableDataSource<AdxNotification> = new MatTableDataSource();

  constructor(private readonly notificationService: AdxNotificationService,
    private matDialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.fetchNotifications();
  }

  /**
   * Callback that displays the dialog used to send notification
   */
  sendPopup(){
    const dialogRef = this.matDialog.open(AdxSendNotificationDialogComponent, {
      width: '40vw',
      maxHeight: '70vh',
      data: {orgId: this.orgId, accountId: this.accountId, applicationId: this.applicationId, vpubId: this.vpubId},
      autoFocus: 'false'
    });

    // on close of dialog, refresh the listing page
    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug(`The dialog was closed: ${result}`);
      if (result) {
        //if response is true, sending notification is success, reload the notification list
        this.fetchNotifications();
      }
    });
  }

  /**
   * Callback to reload the data in the table
   */
  loadData(){
    this.fetchNotifications();
  }

  /*
   * Used to fetch notifications from backend
  */
  private fetchNotifications() {
    this.notificationService.getNotifications(this.orgId, this.accountId, this.applicationId, this.vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxNotification[]) => {
          // sort it by ID and set it in variable
          CommonUtility.sortByIdDescending(data);
          this.dataSource.data = data;
        }
      });
  }
}
