<div class="channel-container" fxLayout="column">

  <div class="channel-option">
    <div class="button-container">
      <button mat-raised-button i18n="@@applnList.back" type="button" id="input-channelList-back-Btn" routerLink='{{urlForAcctListing}}'> <!-- class="button-cancel" -->
        <mat-icon>keyboard_arrow_left</mat-icon> Accounts</button>
    </div>
    <div class="button-container">
    <mat-form-field appearance="fill" class="channelStatusFilter">
      <mat-label i18n="@@status">Status</mat-label>
      <mat-select [formControl]="statusFilter" id="input-channelList-statusFilter" class="channelListStatusFilter">
        <mat-option value="">-- Select a Option --</mat-option>
        <mat-option value="visible">Visible</mat-option>
        <mat-option value="hidden">Hidden</mat-option>
        <mat-option value="disable">Disable</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="button-container">
    <mat-form-field class="channel-search">
      <input i18n="@@serachChannel" id="input-channelList-searchChannels" matInput placeholder="Search Channels" [formControl]="titleFilter">
    </mat-form-field>
    </div>
    <div class="button-container" *ngIf="canAddChannel()">
      <button i18n="@@listChannel.add" id="input-channelList-addChannel" mat-raised-button color="primary" routerLink='add'>Add Channel</button>
    </div>
  </div>

  <div class="channel-list-table-div">
    <table mat-table *ngIf="dataSource !== null"  [dataSource]="dataSource" class="common-list-table">

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="common-list-th common-title">Icon</th>
        <td mat-cell *matCellDef="let element">
          <img class="iconImage" [src]="element.iconImage && element.iconImage.preSignedUrl?
                          element.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
        </td>
      </ng-container>

      <ng-container matColumnDef="channelName">
        <th i18n="@@name" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Channel Name</th>
        <td i18n="@@name-val" mat-cell *matCellDef="let element" matTooltip="{{element.title}}">
          {{ element.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="visible">
        <th mat-header-cell *matHeaderCellDef class="common-list-th common-title">Status</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.visibility.toLowerCase() ==='visible';  else ChannelHidden" mat-cell>
            <mat-icon>visibility</mat-icon>
          </ng-container>
          <ng-template #ChannelHidden>
            <ng-container *ngIf="element.visibility.toLowerCase() ==='hidden';  else ChannelDisable">
              <mat-icon>visibility_off</mat-icon>
            </ng-container>
          </ng-template>
          <ng-template #ChannelDisable>
            <mat-icon>hide_source</mat-icon>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button mat-button [matMenuTriggerFor]="menu" *ngIf="canEditChannel(element.id)"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item i18n="@@account-edit" id="input-channelList-edit-{{element.id}}" routerLink="edit/{{element.id}}">Edit</button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>
  </div>
</div>
