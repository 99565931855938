<div class="adx-user-listing">
  <div class="adx-user-listing-header">
    <app-adx-user-search  #search_component (searchResult)="updateSearchResult($event)" style="width: 95%;"></app-adx-user-search>
    <div class="adx-user-listing-actions">
      <button (click)="onRefresh()" style="max-height: 40px;"><mat-icon class="mat-18">refresh</mat-icon></button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="adx-user-listing-content" *ngIf="(dataSource !== null && dataSource.data.length > 0); else NODATA">
    <table mat-table [dataSource]="dataSource" class="common-list-table">
      <ng-container matColumnDef="username">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">Username</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="firstname">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">FirstName</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title" [matTooltip]="element.firstName">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <th i18n="@@timeTaken" mat-header-cell *matHeaderCellDef class="common-list-th common-title">LastName</th>
        <td mat-cell *matCellDef="let element" class="common-list-td common-title" [matTooltip]="element.lastName">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell i18n="@@type" *matHeaderCellDef class="common-list-th"></th>
        <td mat-cell *matCellDef="let element" class="common-list-td">
          <button mat-raised-button style="max-height: 40px;" (click)="onEdit(element.id)" disabled>Edit</button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: default"></tr>
    </table>
  </div>
  <ng-template #NODATA>
    <span style="text-align: center;"> Data Not Found </span>
  </ng-template>
  <div class="adx-user-listing-navs">
    <button (click)="prevPage()" mat-icon-button [disabled]="isPrevDisabled"><mat-icon class="mat-18">chevron_left</mat-icon></button>
    <button (click)="nextPage()" mat-icon-button [disabled]="isNextDisabled"><mat-icon class="mat-18">chevron_right</mat-icon></button>
  </div>

</div>
