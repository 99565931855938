import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { Address } from 'src/app/common/model/address.model';
import { AdxTimezone } from 'src/app/common/model/adx-timezone.model';
import { OrganizationService } from '../service/organization.service';
import { Organization } from '../model/organization.model';
import { AdxStyle } from 'src/app/common/model/adx-style.model';
import {CustomMessageType} from '../../core/service/notifier/custom-message-type';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {AdxCountryUtility} from '../../common/utils/adx-country-utility';
import {AdxCountry} from '../../common/model/country/adx-country.model';
import {blankValidator} from '../../common/utils/form-utility';
import {AdxBaseTemplate} from '../../common/template/adx-base-template';
import {CommonUtility} from '../../common/utils/common-utils';

// Import Froala Editor.
// @ts-ignore
import FroalaEditor from 'froala-editor';
import {AdxErrorStateMatcher} from '../../common/error/AdxErrorStateMatcher';
import {OrganizationUtility} from '../utils/organization-utility';
import {AdxAboutUsInfo} from '../../common/model/aboutus-info/adx-aboutus-info.model';
import {AdxHelpInfo} from '../../common/model/help-info/adx-help-info.model';
import { AuthenticatedUser } from 'src/app/common/model/adx-auth/authenticated-user.model';
import { AuthNotifierService } from 'src/app/auth/service/auth-notifier.service';
import { LocalStorageService } from 'src/app/core/service/storage/local-storage.service';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss'],
})
export class AddOrganizationComponent extends AdxBaseTemplate implements OnInit, OnDestroy {

  addOrgForm: UntypedFormGroup;

  address?: Address;
  timezone?: AdxTimezone;
  timezones: AdxTimezone[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorMsg?: string;
  countries?: Array<AdxCountry>;
  orgIconImageFile: File | null = null;
  descEditorOptions: any;
  helpEditorOptions: any;
  aboutEditorOptions: any;

  descEditor = null;
  helpEditor = null;
  aboutEditor = null;
  initControls = null;

  protected readonly options: object = OrganizationUtility.options;

  protected readonly adxErrorStateMatcher = new AdxErrorStateMatcher();

  private authSubscription: Subscription | undefined;

  constructor(
    private orgService: OrganizationService,
    private readonly messageNotifier: CustomNotifierService,
    private authNotifier: AuthNotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.addOrgForm = new UntypedFormGroup({
      orgName: new UntypedFormControl(null, {
          updateOn: 'blur', validators: [
          Validators.required,
          blankValidator,
          Validators.minLength(3)
        ]
      }),
      orgAddress: new UntypedFormGroup( {
        street1: new UntypedFormControl(),
        street2: new UntypedFormControl(),
        city: new UntypedFormControl(),
        state: new UntypedFormControl(),
        zipcode: new UntypedFormControl(),
        country: new UntypedFormControl()
      }),
      orgTimezone: new UntypedFormControl(),
      orgDescription: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(450)
      ]}),
      orgStyle: new UntypedFormGroup({
        numOfTabs: new UntypedFormControl('1'),
        backgroundColor: new UntypedFormControl('#FFFFFF'),
        backgroundTextColor: new UntypedFormControl('#000000'),
        topBarColor: new UntypedFormControl('#FFFFFF'),
        topBarTextColor: new UntypedFormControl('#000000'),
        bannerColor: new UntypedFormControl('#FFFFFF'),
        bannerTextColor: new UntypedFormControl('#000000'),
        buttonColor: new UntypedFormControl('#000000'),
        buttonTextColor: new UntypedFormControl('#FFFFFF'),
        bodyColor: new UntypedFormControl('#FFFFFF'),
        bodyTextColor: new UntypedFormControl('#000000')
      }),
      orgAbout: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(10000)
      ]}),
      orgHelp: new UntypedFormControl(null, {updateOn: 'change', validators: [
        Validators.maxLength(10000)
      ]})
    });
  }

  get backgroundColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('backgroundColor')?.value;
  }

  get backgroundTextColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('backgroundTextColor')?.value;
  }

  get topBarColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('topBarColor')?.value;
  }
  get topBarTextColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('topBarTextColor')?.value;
  }
  get bannerColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('bannerColor')?.value;
  }
  get bannerTextColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('bannerTextColor')?.value;
  }
  get buttonColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('buttonColor')?.value;
  }
  get buttonTextColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('buttonTextColor')?.value;
  }

  get bodyColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('bodyColor')?.value;
  }

  get bodyTextColor(): string {
    return this.addOrgForm?.get('orgStyle')?.get('bodyTextColor')?.value;
  }

  ngOnInit(): void {
    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('In AdxHamburgerMenuComponent authSubscription');
        this.currentUser = user;
      }
    );

    this.errorMsg = '';
    this.address = new Address(-1, '', '', '', '', '', 'USA', '',
      'Organization');
    this.countries = AdxCountryUtility.getSupportedCountries();
    this.timezones = this.route.snapshot.data.timezones;

    if (this.timezones.length !== 0) {
      this.timezone = this.timezones[0];
    }
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  /**
   * Method returns true if logged-in user has permission to add new organization
   *
   * @returns boolean. true if current user can add new org
   */
  canAddAccount(): boolean {
    if (this.currentUser !== undefined && this.currentUser !== null) {
      return this.orgService.canUserAddOrg(this.currentUser);
    }
    return false;
  }

  /*
   * This is called from html to save the org data
  */
  save(): void {
    this.logger.debug(this.addOrgForm);

    if(!this.canAddAccount()) {
      return; //if user does not have permission to add account, return
    }

    const orgName: string = this.addOrgForm.get('orgName')?.value?.trim();
    const description: string = this.addOrgForm.get('orgDescription')?.value;
    const strAbt: string = this.addOrgForm.get('orgAbout')?.value;
    const strHelp: string = this.addOrgForm.get('orgHelp')?.value;
    const tzId: number = +(this.addOrgForm.get('orgTimezone')?.value);

    const orgToAdd: Organization = new Organization(
      NaN,
      ''
    );
    orgToAdd.title = orgName;
    orgToAdd.description =  description;
    if (this.addOrgForm.get('orgAbout')?.dirty) {
      orgToAdd.aboutUsInfo = new AdxAboutUsInfo(null, null, null);
      orgToAdd.aboutUsInfo.text = strAbt;
      orgToAdd.aboutUsInfo.scope = 'ORGANIZATION';
    }
    if (this.addOrgForm.get('orgHelp')?.dirty) {
      orgToAdd.helpInfo = new AdxHelpInfo(null, null, null);
      orgToAdd.helpInfo.text = strHelp;
      orgToAdd.helpInfo.scope = 'ORGANIZATION';
    }

    const tz: AdxTimezone | undefined = this.timezones.find(tzObj => tzObj.id === tzId);
    if (tz) {
      orgToAdd.timeZoneId = tz.id;
    }

    if (this.addOrgForm.get('orgAddress')?.dirty && this.addOrgForm.get('orgAddress.street1')?.value) {
      // add address
      const strStreet1: string = this.addOrgForm.get('orgAddress.street1')?.value?.trim();
      const strStreet2: string = this.addOrgForm.get('orgAddress.street2')?.value?.trim();
      const strCity: string = this.addOrgForm.get('orgAddress.city')?.value?.trim();
      const strState: string = this.addOrgForm.get('orgAddress.state')?.value?.trim();
      const strZip: string = this.addOrgForm.get('orgAddress.zipcode')?.value;
      const strCountry: string = this.addOrgForm.get('orgAddress.country')?.value;
      const addr: Address = new Address(
        NaN,
        strStreet1,
        strStreet2,
        strCity,
        strState,
        '',
        strCountry,
        strZip,
        'ORGANIZATION'
      );
      // TO-DO Set the logged in user here
      addr.createdByUser = 1;
      orgToAdd.address = addr;
    }

    // add style
    const numOfTabs = this.addOrgForm?.get('orgStyle')?.get('numOfTabs')?.value;
    const strBackgroundColor = this.backgroundColor;
    const strBackgroundTextColor = this.backgroundTextColor;
    const strTopBarColor = this.topBarColor;
    const strTopBarTextColor = this.topBarTextColor;
    const strBannerColor = this.bannerColor;
    const strBannerTextColor = this.bannerTextColor;
    const strButtonColor = this.buttonColor;
    const strButtonTextColor = this.buttonTextColor;
    const strBodyColor = this.bodyColor;
    const strBodyTextColor = this.bodyTextColor;
    const style: AdxStyle = new AdxStyle(
      NaN,
      numOfTabs,
      strBackgroundColor,
      strBackgroundTextColor,
      '',
      strTopBarColor,
      strTopBarTextColor,
      strBodyColor,
      strBodyTextColor,
      strBannerColor,
      strBannerTextColor,
      strButtonColor,
      strButtonTextColor,
      9,
      'ORGANIZATION'
    );
    this.logger.debug('style bkgClr:' + style.navigationBackgroundColor);
    this.logger.debug('style bkgTxtClr:' + style.navigationTextColor);

    // TO-DO Set the logged in user here
    style.createdByUser = 1;
    orgToAdd.style = style;

    // TO-DO Set the logged in user here
    orgToAdd.createdByUser = 1;

    this.logger.debug('org help:' + orgToAdd.helpInfo);

    this.addOrg(orgToAdd);
  }

  onImageChanged(event: any): void {
    this.logger.debug('onImageChange: ' + event);
    this.orgIconImageFile = event;
  }

  initializeAboutUsFroalaEditor($event: object) {
    console.log(`initializeAboutUsFroalaEditor`);
    // @ts-ignore
    this.initControls = $event;
    // @ts-ignore
    this.initControls.initialize();
    // @ts-ignore
    this.aboutEditor = this.initControls.getEditor();
  }

  initializeHelpFroalaEditor($event: object) {
    console.log(`initializeHelpFroalaEditor`);
    // @ts-ignore
    this.initControls = $event;
    // @ts-ignore
    this.initControls.initialize();
    // @ts-ignore
    this.helpEditor = this.initControls.getEditor();
  }

  initializeDescFroalaEditor($event: object) {
    console.log(`initializeDescFroalaEditor`);
    // @ts-ignore
    this.initControls = $event;
    // @ts-ignore
    this.initControls.initialize();
    // @ts-ignore
    this.descEditor = this.initControls.getEditor();
  }

  /*
   * This method calls service api to add organization
   */
  private addOrg(org: Organization): void {
    if(!this.canAddAccount()) {
      return; //if user cannot add account return
    }
    this.orgService
      .addOrganization(org)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // navigate user to next screen
          this.router.navigateByUrl('orgs').then(r => {});
        },
        error: () => {
          // navigate user to next screen
          this.router.navigateByUrl('orgs').then(r => {});
        },
        complete: () => {
          // display success message
          this.messageNotifier.notify(CustomMessageType.SUCCESS, `${org.title} created Successfully.`);
        }
      });
  }
}
