import {AdxBaseSerializer} from '../../core/service/resource/adx-base-serializer';
import {AdxChannel} from './adx-channel.model';
import {AdxResource} from '../../core/service/resource/adx-resource.model';

export class AdxChannelSerializer implements AdxBaseSerializer {
  fromJson(json: any): AdxChannel {
    const channel: AdxChannel = AdxChannel.buildObject(json);
    return channel;
  }

  toJson(resource: AdxResource): any {
    return JSON.stringify(resource);
  }

}
