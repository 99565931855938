import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CustomErrorNotifierService } from 'src/app/core/service/error-handler/custom-error-notifier.service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AuthNotifierService } from '../../auth/service/auth-notifier.service';
import { AuthenticatedUser } from '../../common/model/adx-auth/authenticated-user.model';
import {CustomNotifierService} from '../../core/service/notifier/custom-notifier.service';
import {CustomNotification} from '../../core/service/notifier/custom-notification-model';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  errorMessage: string | null = null;
  isUserAuthenticated = false;
  authenticatedUser: AuthenticatedUser | null = null;

  errorSubscription: Subscription | undefined;
  messageSubscription: Subscription | undefined;
  authSubscription: Subscription | undefined;

  private readonly notifier: NotifierService;

  constructor(
    private logger: NGXLogger,
    private cd: ChangeDetectorRef,
    private errorNotifier: CustomErrorNotifierService,
    private messageNotifier: CustomNotifierService,
    private notifierService: NotifierService,
    private authNotifier: AuthNotifierService
  ) {
    this.notifier = notifierService;
  }

  //clear the error message displayed on ui
  onCloseError() {
    this.errorMessage = null;
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    // notify error if any error occurred
    this.errorSubscription = this.errorNotifier.errorSubject.subscribe(
      (msg: string) => {
        this.logger.debug('received error event' + msg);
        this.errorMessage = msg;
        this.cd.detectChanges();
      }
    );

    // notify message
    this.messageSubscription = this.messageNotifier.msgSubject.subscribe(
      (notification: CustomNotification) => {
        this.logger.debug('received message ' + notification.message);
        this.notifier.notify(notification.type.toString(), notification.message);
      }
    );

    // note change in user authentication status
    this.authSubscription = this.authNotifier.authSubject.subscribe(
      (user: AuthenticatedUser | null) => {
        this.logger.debug('received auth event');
        this.logger.debug(user);
        this.isUserAuthenticated = (user !== null? true: false);
        this.authenticatedUser = user;

        this.cd.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe();
    this.messageSubscription?.unsubscribe();
    this.authSubscription?.unsubscribe();
  }
}
