<div class="common-list-head">
  <div class="common-list-search">
    <mat-form-field class="common-search-input">
        <input i18n="@@searchOrganisation" id="input-organisationList-organisationFilter" matInput placeholder="Search Organisation" [formControl]="organisationFilter">
    </mat-form-field>
  </div>
  <div class="common-list-btn" *ngIf="canAddOrg()">
    <button i18n="@@add-org" routerLink='/orgs/add' id="organization-add-btn" mat-raised-button color="primary">Add</button>
  </div>
</div>
<div style="overflow: hidden auto; height: 87%">
 <table mat-table matSort *ngIf="dataSource !== null" [dataSource]="dataSource" class="common-list-table commonOrgAccountAppl">
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef class="common-list-th"></th>
      <td *matCellDef="let element" class="common-list-td td-img">
        <img class="iconImage" [src]="element.iconImage && element.iconImage.preSignedUrl?
                          element.iconImage.preSignedUrl : './assets/images/app-icon.png'" alt="image"/>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th i18n="@@name" mat-header-cell *matHeaderCellDef mat-sort-header class="common-list-th">Title</th>
      <td i18n="@@name-val" mat-cell *matCellDef="let element" class="common-list-td" matTooltip="{{element.title}}">
        {{ element.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="secret">
      <th i18n="@@secret" mat-header-cell *matHeaderCellDef mat-sort-header class="common-list-th">Secret</th>
      <td i18n="@@secret-val" mat-cell *matCellDef="let element" class="common-list-td" matTooltip="{{element.secret}}">
        {{ element.secret }}
      </td>
    </ng-container>

    <ng-container matColumnDef="timezone">
      <th i18n="@@orglist.timezone" mat-header-cell *matHeaderCellDef [disabled]="true" mat-sort-header class="common-list-th">
        Timezone
      </th>
      <td mat-cell *matCellDef="let element" class="common-list-td">
        <ng-container i18n="@@orglist.timezone.val1" *ngIf="element.timeZone;  else NoTZ" mat-cell>
          {{element.timeZone.abbreviation}}
        </ng-container>
        <ng-template i18n="@@orglist.timezone.val2" #NoTZ>
          "Not Configured"
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="defaultLocale">
      <th i18n="@@orglist.defaultLocale" mat-header-cell *matHeaderCellDef [disabled]="true" mat-sort-header class="common-list-th">
        Default Locale
      </th>

      <td mat-cell *matCellDef="let element" class="common-list-td">
        <ng-container i18n="@@orglist.defaultlocale.val" *ngIf="element.defaultLocale; else NoDefaultLocale" mat-cell>
          <span matTooltip="{{element.defaultLocale.displayName}}">{{element.defaultLocale.displayName}}</span>
        </ng-container>
        <ng-template i18n="@@orglist.defaultlocale.val" #NoDefaultLocale>
          <span matTooltip="Not Configured">"Not Configured"</span>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="orgListActions">
      <th mat-header-cell *matHeaderCellDef class="common-list-th"></th>
      <td mat-cell *matCellDef="let element" class="common-list-td" (click)="$event.stopPropagation()">
        <div class="orgAccountsApplicationBtns">
          <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <div *ngIf="canEditOrg(element.id)">
              <button mat-menu-item class="accountEdit" i18n="@@edit"
                    id="input-accountEdit-btn-{{element.id}}" routerLink="edit/{{element.id}}">Edit</button>
            </div>
            <div>
              <button mat-menu-item i18n="@@orgImageLib"
                    appAdxImageLibrary [id]="element.id" [type]="libraryType" [isSelect]="false" [isIcon]="false"
                    (imgSelectEmitter)="onImageSelect($event)">Image Library</button>
            </div>
          </mat-menu>
        </div>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" routerLink="{{row.id}}/accts"></tr>
  </table>
</div>
