<div>
    <div><img class='img-display' [src]="url" *ngIf="url"></div>
</div>
<div>
    <form [formGroup]="imageUploadForm" novalidate>
        <table>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Name" formControlName="name">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <textarea matInput placeholder="Description" formControlName="description"></textarea>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <button type="submit" mat-raised-button (click)="submitForm()">Upload</button>
                    <button type="button" mat-button (click)="onClose()">Cancel</button>
                </td>
            </tr>
        </table>
    </form>
</div>
