import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdxInsertContentDialogData} from '../../utils/adx-insert-content-dialog-data';
import {AdxModule} from '../../../adx-module/model/adx-module.model';
import {Subject, Subscription} from 'rxjs';
import {AdxModuleService} from '../../../adx-module/service/adx-module.service';
import {takeUntil} from 'rxjs/operators';
import {AdxBaseTemplate} from '../../../common/template/adx-base-template';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {AdxModuleType} from '../../../adx-module/model/adx-module-type';
import {AdxArs} from '../../../adx-ars/model/adx-ars-model';
import {AdxArsService} from '../../../adx-ars/service/adx-ars.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-adx-insert-ars-input',
  templateUrl: './adx-insert-ars-input.component.html',
  styleUrls: ['./adx-insert-ars-input.component.scss']
})
export class AdxInsertArsInputComponent extends AdxBaseTemplate implements OnInit {

  @Input() data: AdxInsertContentDialogData | undefined;
  @Output() workDone = new EventEmitter<boolean>();

  orgId: number | null = null;
  accountId: number | null = null;
  vpubId: number | null = null;
  selectedModuleId: number | null = null;
  arsModules: AdxModule[] = [];
  moduleSelectionGroup: UntypedFormGroup;
  treeControl = new NestedTreeControl<AdxArs>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AdxArs>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly moduleService: AdxModuleService,
              private readonly arsService: AdxArsService) {
    super();
    this.logger.debug(`In AdxInsertArsInputComponent`);
    this.logger.debug(this.data);
    this.moduleSelectionGroup = new UntypedFormGroup({
      selectModule: new UntypedFormControl()
    });
  }

  hasChild = (_: number, node: AdxArs) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.logger.debug(this.data);
    if (this.data) {
      if (this.data.orgId) {
        this.orgId = this.data.orgId;
      }
      if (this.data.accountId) {
        this.accountId = this.data.accountId;
      }
      if (this.data.vpubId) {
        this.vpubId = this.data.vpubId;
      }
    }

    if (!this.orgId || !this.accountId || !this.vpubId) {
      this.logger.error('required params missing');
      return;
    }

    this.fetchModules(this.orgId, this.accountId, this.vpubId);
  }

  displayList(): void {
    const selControl = this.moduleSelectionGroup.get('selectModule');
    if (selControl) {
      this.selectedModuleId = selControl.value;
    }
    this.logger.debug(`selected module ${this.selectedModuleId}`);
    if (!this.orgId || !this.accountId || !this.vpubId || !this.selectedModuleId) {
      this.logger.error('In displayList... required params missing');
      return;
    }

    this.fetchAtoms(this.orgId, this.accountId, this.vpubId, this.selectedModuleId);
  }

  addLink(atomId: number, title: string): void {
    this.logger.debug(`link for atomId:${atomId} title:${title}`);

    if (!this.data || !this.data.editorRef) {
      this.logger.error(`addLink:: Editor not initialized ${this.data} ${this.data?.editorRef}`);
      return;
    }

    const url = 'mfp://module/' + this.selectedModuleId + '/ars/' + atomId;
    const htmlStart = '<a href="' + url + '">';
    const htmlEnd = '</a>';

    // if they have wrapped something to link already, we want to preserve the body of that...
    if (this.data.editorRef.selection) {
      this.data.editorRef.selection.restore();
    }
    if (this.data.editorRef.selection && this.data.editorRef.selection.text()) {
      const selection = this.data.editorRef.selection.text();
      this.data.editorRef.html.insert(htmlStart + selection + htmlEnd);
    } else {
      // use the title as the content and insert....
      this.data.editorRef.html.insert(htmlStart + title + htmlEnd);
    }
    this.workDone.emit(true);
  }

  /*
   * This method fetches the accounts list from backend
  */
  private fetchModules(orgId: number, acctId: number, vpubId: number): Subscription {
    this.logger.debug('fetching modules for vpubId:' + vpubId);
    return this.moduleService.getModules(orgId, acctId, vpubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxModule[]) => {
          // successfully received the data.
          // it will be in form of tree. Make it to a list
          this.arsModules = this.convertModuleTreeToList(data);
        }
      });
  }

  /*
   * Utility function to convert module tree to list of ars modules
   * @param inputTree: list received from backend
   */
  private convertModuleTreeToList(inputTree: AdxModule[]): AdxModule[] {
    const toRet: AdxModule[] = [];
    if (!inputTree || inputTree.length < 1) {
      return toRet;
    }

    for (const module of inputTree) {
      if (module) {
        if (module.type === AdxModuleType.ARS) {
          toRet.push(module);
        }
        else if (module.type === AdxModuleType.GROUP) {
          toRet.push(...this.convertModuleTreeToList(module.children));
        }
      }
    }
    this.logger.debug(`returning ${toRet.length} ARS modules`);
    return toRet;
  }

  /*
   * This method fetches the atoms list from backend
  */
  private fetchAtoms(orgId: number, acctId: number, vpubId: number, modlId: number): Subscription {
    this.logger.debug('fetching atoms for module:' + modlId);
    return this.arsService.getAtoms(orgId, acctId, vpubId, modlId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: AdxArs[]) => {
          // successfully received the data.
          // so set it in variable
          this.dataSource.data = data;
        }
      });
  }

  private convertArsTreeToList(inputTree: AdxArs[], listToRet: AdxArs[]): void {
    if (!listToRet) {
      listToRet = [];
    }
    if (!inputTree || inputTree.length < 1) {
      this.logger.debug(`Input tree is empty`);
      return;
    }

    for (const atom of inputTree) {
      if (atom) {
        if (atom.children && atom.children.length > 0) {
          this.addIfAbsent(listToRet, atom);
          this.convertArsTreeToList(atom.children, listToRet);
        }
        else {
          this.addIfAbsent(listToRet, atom);
        }
      }
    }
    this.logger.debug(`returning ${listToRet.length} atoms`);
  }

  private addIfAbsent(toRet: AdxArs[], atom: AdxArs): void {
    const itemPresent: AdxArs[] = toRet.filter(item => item.id === atom.id);
    if (itemPresent) {
      if (itemPresent.length < 1) {
        toRet.push(atom);
        this.logger.debug(`Atom ${atom.id} added to the list`);
      }
      else {
        this.logger.debug(`Atom ${atom.id} already present in list`);
      }
    } else {
      toRet.push(atom);
      this.logger.debug(`In else.. Atom ${atom.id} added to the list`);
    }
  }
}
